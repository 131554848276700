import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Modal, Table, Select, Button, Checkbox } from "antd";
import moment from "moment";

import {
  numberFormat,
  formatDate,
  endDateFormat,
  amountFormat,
  coinMultiFormat,
  formatDateSecond,
} from "../../util";
import string from "../../string";
import { httpPut, httpUrl, httpGet } from "../../api/httpClient";
import LockCreateModal from "../common/LockCreateModal";
import SelectBox from "../common/SelectBox";
import ConfirmInputModal from "../common/ConfirmInputModal";

const Option = Select.Option;

export default class CoinLockListModal extends Component {
  state = {
    isModify: false,
    visible: false,
    coinList: {
      coinType: [],
      serialNum: "",
    },
    selectData: {},
    modalType: "create",

    deleteModalVisible: false,

    coinTypeAllChecked: true,
  };

  handleChangeData = (value, type, coinType) => {
    let tempData = this.state.selectData;
    if (type === "amount") {
      tempData[type] = coinMultiFormat(value, tempData.coinType);
    } else {
      tempData[type] = value;
    }
    this.setState({
      selectData: tempData,
    });
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().endOf("day" - 1);
  };

  handleDeleteState = (value, row) => {
    let deleted = row.deleted;
    if (value !== deleted) {
      if (deleted) {
        this.onDelete({
          idx: row.idx,
          memoDeleted: row.memoDeleted,
          deleted: row.deleted,
        });
      } else {
        this.setState({
          deleteModalVisible: true,
          selectedRow: row,
        });
      }
    }
  };

  onDelete = (params = {}) => {
    const { modalData, showCoinLockList, pagination } = this.props;
    if (
      global.confirm(
        `해당 LOCK을 ${params.deleted === 1 ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.lockDelete, [], {
        idx: params.idx,
        memoDeleted: params.memoDeleted,
      }).then((res) => {
        if (res.data.result === "SUCCESS") {
          let pageNum =
            !this.props.searchTypes.includes("deleted") &&
            pagination.current > 1 &&
            params.deleted === 0
              ? modalData.coinLockList.length === 1
                ? pagination.current - 1
                : pagination.current
              : pagination.current;

          alert(`${params.deleted === 1 ? "복구" : "삭제"}가 완료되었습니다.`);

          showCoinLockList({
            pageNum,
          });
          this.setState({
            deleteModalVisible: false,
          });
        }
        if (res.data.result === "COIN_NOT_ENOUGH") {
          alert("복구하려는 코인의 잔액이 부족합니다.");
        }
      });
    }
  };

  handleCoinCreateModal = (type, row) => {
    const { selectedSerial } = this.props;
    httpGet(httpUrl.walletList, [100, 1, selectedSerial.serial]).then((res) => {
      if (res.data.walletList !== null) {
        this.setState({
          visible: true,
          modalType: type,
          selectData: type === "edit" && {
            ...row,
            expireDate: row.expireDate,
          },
          coinList: {
            coinType: res.data.walletList.map((value) => value.coinType),
            serialNum: selectedSerial.serial,
          },
        });
      } else {
        alert("지갑이 존재하지 않습니다.");
      }
    });
  };

  onCancel = () => {
    this.props.onCancel();
    this.setState({
      isModify: false,
    });
  };

  renderOption() {
    const { coinLockCoinList } = this.props;

    let result = [{ coinType: 0, name: "전체" }, ...coinLockCoinList];
    result = result.map((item, index) => {
      return (
        <Option value={item.coinType} key={`${item}${index}`}>
          {item.name}
        </Option>
      );
    });

    return result;
  }

  render() {
    const { visible, onOk, modalData } = this.props;
    const coinList = reactLocalStorage.getObject("coinList");

    const coinLockColumns = [
      {
        title: "",
        dataIndex: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        width: 100,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {coinList.find((coin) => coin.coinType === data)
              ? coinList.find((coin) => coin.coinType === data).name
              : ""}
          </div>
        ),
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: "마감일",
        dataIndex: "expireDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => <div>{formatDate(data)}</div>,
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <span>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </span>
        ),
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{data}</div>,
      },
      {
        title: "삭제 메모",
        dataIndex: "memoDeleted",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>{row.deleted !== null && <span>{data}</span>}</div>
        ),
      },
      {
        title: "수정",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {row.deleted === 0
              ? row.expireDate !== null && (
                  <Button
                    onClick={() => {
                      this.handleCoinCreateModal("edit", row);
                    }}
                    style={{
                      marginRight: "5px",
                    }}>
                    수정
                  </Button>
                )
              : row.deleted === null
              ? "-"
              : "삭제"}
          </div>
        ),
      },
      {
        title: "상태",
        className: "support-column-number fs-15 lh-21",
        dataIndex: "deleted",
        render: (data, row, index) => (
          <div>
            {endDateFormat(row.expireDate) >
            formatDateSecond(
              moment()
                .endOf("day" - 1)
                .toString()
            ) ? (
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (parseInt(value) !== data) {
                    this.handleDeleteState(value, row);
                  }
                }}
              />
            ) : (
              <span>{`만료`}</span>
            )}
          </div>
        ),
      },
    ];

    const options = [
      { label: "삭제 포함", value: "deleted" },
      { label: "만료 포함", value: "expire" },
    ];

    return (
      <Modal
        visible={visible}
        title="코인별 LOCK"
        onText="확인"
        cancelText="취소"
        onOk={onOk}
        onCancel={this.onCancel}
        style={{ minWidth: "1400px" }}
        footer={null}>
        {this.state.deleteModalVisible && (
          <ConfirmInputModal
            rootPage="lock"
            visible={this.state.deleteModalVisible}
            onOk={this.onDelete}
            onCancel={() => {
              this.setState({
                deleteModalVisible: false,
              });
            }}
            selectedRow={this.state.selectedRow}
          />
        )}
        <LockCreateModal
          modalType={this.state.modalType}
          editData={this.state.selectData}
          visible={this.state.visible}
          coinList={this.state.coinList}
          onCancel={() => {
            this.setState({
              visible: false,
              modalType: "create",
              selectData: {},
            });
          }}
          refresh={this.props.showCoinLockList}
        />
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.handleCoinCreateModal("create");
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
          <div className="flex-row flex-center">
            <div className="flex-row flex-center m-r-2">
              <label>검색조건&nbsp;&nbsp;</label>
              <Checkbox.Group
                options={options}
                value={this.props.searchTypes}
                onChange={this.props.searchTypeHandleChange}
              />
            </div>
            <div className="flex-row flex-center">
              <label>코인종류&nbsp;</label>
              <Select
                type="coinType"
                placeholder="코인 종류를 선택해주세요."
                value={this.props.coinType}
                onChange={this.props.onChange}
                style={{ width: "240px", marginRight: "5px" }}>
                {this.renderOption()}
              </Select>
              <Button
                type="primary"
                onClick={() => {
                  // console.log(`coinType: ${this.props.coinType}`);
                  if (this.props.coinType === undefined)
                    alert("코인 종류를 선택해주세요.");
                  else this.props.onSearch();
                }}>
                검색
              </Button>
            </div>
          </div>
        </div>
        <div style={{ color: "blue" }} className="m-b-10">
          * 전체잠금이 설정된 기간동안은 개별 락의 상태와 관계없이 해당 지갑의
          해당 코인에 대해 모든 출금이 금지됩니다.
        </div>
        <Table
          className="member-coinLock-table"
          rowKey={(record) => (record.idx ? record.idx : record.coinType)}
          rowClassName={(record) => {
            // console.log(moment().endOf("day" - 1));
            return endDateFormat(record.expireDate) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) || record.deleted === 1
              ? "gray"
              : "";
          }}
          columns={coinLockColumns}
          dataSource={modalData.coinLockList}
          style={{ marginBottom: "10px" }}
          pagination={this.props.pagination}
          onChange={this.props.handleTableChange}
        />
      </Modal>
    );
  }
}
