import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, DatePicker, Descriptions, Select, Icon, Checkbox, InputNumber } from "antd";
import { httpGet, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
  coinMultiFormat
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import CoinSelectBox from "../../components/common/CoinSelectBox";

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;

class ArbitrageManualExchange extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  onCreate = (e) => {

    let {form} = this.props;
    
    e.preventDefault();
    form.validateFields((err, values) => {

      if (!err) {
        if (global.confirm('거래하시겠습니까?')) {
          values.exchangeIdx = this.props.exchangeIdx;
          
          httpPost(httpUrl.arbitrageManualExchange, [], values).then((res) => {
            if (res.data) {
              form.resetFields();
              this.props.onOk();
            } else {
              alert("거래에 실패하였습니다. 거래할 수 있는 상태가 아닙니다.");
            }
          }).catch(()=>{
            alert("알수없는 문제로 거래에 실패하였습니다..");
          });

        }
      }
    });
  }
  render() {
    const { visible, onCancel, form } = this.props;
    const { getFieldDecorator } = form;
        
    let exceptCoinType = [3];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    return (
      <Modal
        visible={visible}
        title="수동 거래 수락"
        okText="확인"
        cancelText="취소"
        onOk={this.onCreate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        <div>
          <div style={{paddingBottom: '10px'}}>
            거래를 수락할 계정을 선택해주세요. FROM코인의 자전계정으로 설정된 사용자만 선택가능합니다.
          </div>
          <Form {...formItemLayout} onSubmit={this.onCreate}>
            <Descriptions
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
              <Ditems label="사용자" span={2}>
                <FormItem>
                  {getFieldDecorator("userIdx", {
                    rules: [{ required: true, message: "거래할 사용자를 선택해주세요." }],
                  })(
                    <Select
                      style={{ width: "120px" }}
                      placeholder="사용자 선택">
                        {this.props.exchangeUsers.map(record=>{
                          return (
                            <Option value={record.userIdx} key={record.userIdx}>
                              {record.userId}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </FormItem>
              </Ditems>
              </Descriptions>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create()(ArbitrageManualExchange)