import React, { Component } from "react";

import {
    Modal,
    Descriptions,
    Input,
    Switch,
    Icon,
    Button,
    InputNumber,
    Upload
} from "antd";
import { httpPut, httpGet, httpUrl, serverUrl } from "../../api/httpClient";
import { formatDate } from "../../util";
import UserList from "./UserList";
import Address from "./Address";
import { showAlert } from "../common/AlertModal";
import BasicImage from "../common/BasicImage";
import Axios from "axios";
import OrganizationList from "./OrganizationList";
import SelectBox from "../common/SelectBox";
import string from "../../string";

const Ditems = Descriptions.Item;

class OrganizationDetailRead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailData: {},
            changeVisible: false,
            changeAttractVisible: false,
            addressVisible: false,
            thumbnail: "",
            images: ""
        };
    }

    onChangeOranization = (value) => {
        this.onChangeModal(value, "parentId");
    }
    onChangeAttractOranization = (value) => {
        this.onChangeModal(value, "attractOrganizationIdx");
    }
    onChangeModal = (value, type) => {
        var data = this.state.detailData;
        data[type] = value;
        if (
            type === "feeRatio" ||
            type === "shareRatio1" ||
            type === "shareRatio2"
        ) {
            if (value === "") data[type] = null;
            // else data[type] = value;
        }
        if (type === "parentId") {
            data[type] = value.id;
            data.parentName = value.name;
            data.parentIdx = value.idx;
        }
        if (type === "attractOrganizationIdx") {
            data[type] = value.idx;
            data.attractOrganizationName = value.name;
        }
        this.setState({
            detailData: data
        });
        // console.log(JSON.stringify(data, null, 4));
    };

    addressSeach = (value) => {
        // console.log(JSON.stringify(value, null, 4));
        var data = this.state.detailData;
        data.locationAddress = value;
        this.setState({ addressVisible: false, detailData: data });
        httpGet(httpUrl.getGeocode, [value]).then((res) => {
            let address = "";
            if (res.data.y != 0 && res.data.x != 0)
                address = `${res.data.y}, ${res.data.x}`;
            var data = this.state.detailData;
            data.locationPoint = address;
            this.setState({ detailData: data });
        });
    };


    componentDidMount() {
        const obj = Object.assign(
            {},
            {
                ...this.props.modalData,
                franchise: this.props.modalData.franchise === 1 ? true : false,
                autoAdjustment: this.props.modalData.autoAdjustment === 1 ? true : false
            }
        );
        this.setState({
            detailData: obj,
            images: obj.image,
            thumbnail: obj.thumbnail
        });
        // console.log(`detail = ${JSON.stringify(obj, null, 4)}`);
    }

    render() {
        const { visible, onCancel } = this.props;
        const { changeVisible, changeAttractVisible, addressVisible, detailData } = this.state;

        const uploadProps = {
            withCredentials: true,
            showUploadList: false,
            multiple: false,
            transformFile: async (file) => {
                let newImages = [];
                newImages = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const canvas1 = document.createElement("canvas");
                        const canvas2 = document.createElement("canvas");
                        const img = document.createElement("img");
                        img.src = reader.result;
                        this.setState({
                            preview: reader.result
                        });
                        img.onload = () => {
                            let images = [];
                            const ctx1 = canvas1.getContext("2d");
                            const ctx2 = canvas2.getContext("2d");
                            canvas1.width = 100;
                            canvas1.height = 100;
                            ctx1.drawImage(img, 0, 0, 100, 100);
                            canvas1.toBlob(
                                (blob) => {
                                    images.push(new File([blob], `100x100${file.name}`));
                                    // resolve(images);
                                },
                                "image/png",
                                0.5
                            );
                            canvas2.width = 300;
                            canvas2.height = 300;
                            ctx2.drawImage(img, 0, 0, 300, 300);
                            canvas2.toBlob(
                                (blob) => {
                                    images.push(new File([blob], `300x300${file.name}`));
                                    resolve(images);
                                },
                                "image/png",
                                0.5
                            );
                        };
                    };
                });
                await new Promise((resolve) => {
                    const formData100 = new FormData();
                    const formData300 = new FormData();
                    formData100.append("file", newImages[0]);
                    formData300.append("file", newImages[1]);
                    Axios({
                        method: "POST",
                        url: serverUrl + httpUrl.imageUpload,
                        data: formData100,
                        withCredentials: true
                    }).then((res) => {
                        const obj = this.state.detailData;
                        obj.thumbnail = res.data.data.fileId;
                        this.setState({
                            thumbnail: res.data.data.fileId,
                            detailData: obj
                        });
                    });
                    Axios({
                        method: "POST",
                        url: serverUrl + httpUrl.imageUpload,
                        data: formData300,
                        withCredentials: true
                    }).then((res) => {
                        this.setState({
                            images: res.data.data.fileId
                        });
                    });
                });
            }
        };

        return (
            <Modal
                visible={visible}
                title="조직 상세"
                onCancel={onCancel}
                cancelText="닫기"
                style={{ minWidth: "1000px" }}>
                {changeVisible && (
                    <OrganizationList
                        visible={changeVisible}
                        onCancel={() => {
                            this.setState({ changeVisible: false });
                        }}
                        onOk={this.onChangeOranization}
                        userIdx={detailData.idx}
                        classification={this.state.detailData.classification - 1}
                    />
                )}
                {changeAttractVisible && (
                    <OrganizationList
                        visible={changeAttractVisible}
                        onCancel={() => {
                            this.setState({ changeAttractVisible: false });
                        }}
                        onOk={this.onChangeAttractOranization}
                        userIdx={detailData.idx}
                        classification={0}
                    />
                )}
                {addressVisible && (
                    <Address
                        visible={addressVisible}
                        onCancel={() => {
                            this.setState({ addressVisible: false });
                        }}
                        onOk={this.addressSeach}
                    />
                )}
                <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
                    <Ditems label="조직 계정(ID / 이름)">
                        {detailData.userId} /{" "}
                        <b>{detailData.userName === null ? "-" : detailData.userName}
                        ▪{detailData.userRecommendCode}</b>
                    </Ditems>
                    <Ditems label="생성일">
                        {formatDate(detailData.createDate)}
                    </Ditems>
                    <Ditems label="유치자" className="flex-row flex-center">
                        <b>{detailData.userName2}▪{detailData.userRecommender}</b>

                    </Ditems>
                    <Ditems label="상위 조직" className="flex-row flex-center">
                        {detailData.parentName}



                    </Ditems>
                    <Ditems label="조직명">

                        {detailData.name}


                    </Ditems>
                    <Ditems label="사업자번호">

                        {detailData.businessNum}

                    </Ditems>
                    <Ditems label="전화번호">
                        {detailData.tel}
                    </Ditems>
                    <Ditems label="은행">
                        {string.bankString[detailData.bankCode]}
                    </Ditems>
                    <Ditems label="계좌번호">
                        {detailData.bankAccount}
                    </Ditems>
                    <Ditems label="예금주">
                        {detailData.accountHolder}
                    </Ditems>
                    <Ditems label="주소" span={2}>
                        {detailData.locationAddress}

                    </Ditems>
                    <Ditems label="상세주소" span={2}>
                        {detailData.locationAddressDetail}
                    </Ditems>
                    <Ditems label="주소 좌표" span={2}>
                        <div style={{ color: detailData.locationPoint ? "" : "red" }}>
                            {detailData.locationPoint
                                ? detailData.locationPoint
                                : detailData.locationAddress !== null
                                    ? "입력한 주소를 네이버 지도에서 찾지 못하였습니다. 앱에서 가맹점 위치가 정상적으로 표출되지 않을 수 있습니다."
                                    : ""}
                        </div>
                    </Ditems>
                    <Ditems label="메모" span={2}>
                        {detailData.memo}
                    </Ditems>
                    <Ditems label="가맹점 여부" span={2}>
                        {detailData.franchise ? "O" : "x"}
                    </Ditems>
                    {detailData.franchise && (
                        <Ditems
                            label={
                                <div>
                                    <div className="m-b-10">대표이미지</div>

                                </div>
                            }
                            span={2}>
                            <div style={{ maxWidth: "300px", height: "auto" }}>
                                <BasicImage
                                    image={detailData.thumbnail}
                                    src={serverUrl + httpUrl.getImage + detailData.thumbnail}
                                    style={{ maxWidth: "300px", height: "auto" }}
                                />
                            </div>
                        </Ditems>
                    )}
                    {detailData.franchise && (
                        <Ditems label="자동 정산 여부">
                            {detailData.autoAdjustment}
                        </Ditems>
                    )}
                    {detailData.franchise && (
                        <Ditems label="정산 수수료 비율 (%)">
                            {detailData.feeRatio}
                        </Ditems>
                    )}
                    {detailData.franchise && (
                        <Ditems label="수익분배1단계 (%)">
                            {detailData.shareRatio1}
                        </Ditems>
                    )}
                    {detailData.franchise && (
                        <Ditems label="수익분배2단계 (%)">
                            {detailData.shareRatio2}
                        </Ditems>
                    )}
                    {detailData.franchise && (
                        <Ditems label="외부연동KEY" span={2}>
                            {detailData.externalKey}
                        </Ditems>
                    )}
                    {detailData.franchise && (
                        <Ditems label="interfaceUri" span={2}>
                            {detailData.interfaceUri}
                        </Ditems>
                    )}
                    {/* {detailData.franchise && (
            <Ditems label="redirectUri" span={2}>
              <Input
                value={detailData.redirectUri}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "redirectUri");
                }}
              />
            </Ditems>
          )} */}
                </Descriptions>
                {detailData.franchise && (
                    <div className="m-t-10">



                    </div>
                )}
            </Modal>
        );
    }
}

export default OrganizationDetailRead;