import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Icon, Checkbox, InputNumber } from "antd";
import { httpPut, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import moment from "moment";

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;

class StakingBonusModify extends Component {
  constructor(props) {
    super(props)
    this.state = {
      useLock: this.props.stakingBonus && this.props.stakingBonus.useLock==1
    }
  }
  onUpdate = (e) => {
    let {form} = this.props;
    
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        values.coinType = this.props.stakingBonus.coinType;
        values.ratio = values.ratio/100;
        values.useLock = values.useLock ? 1 : 0;
        values.lockDate = endDateFormat(values.lockDate);
        console.log(JSON.stringify(values));
        if (global.confirm('수정하시겠습니까?')) {
          httpPost(httpUrl.stakingBonusUpdate, [], values).then((res) => {
            if (res.data !== 0) {
              this.props.onOk();
            } else {
              alert("알수없는 문제로 수정에 실패하였습니다..");
            }
          });

        }
      }
    });
  }
  render() {
    const { visible, onCancel, form } = this.props;
    const { getFieldDecorator } = form;
    
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    return (
      <Modal
        visible={visible}
        title="이자설정 수정"
        okText="수정"
        cancelText="취소"
        onOk={this.onUpdate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        <div>
          <div style={{paddingBottom: 10}}>
          스테이킹 코인으로 설정한 코인을 사용자가 스테이킹 하면,<br/>
          설정한 백분율만큼의 이자가 매일 24시 스테이킹 코인 잔고를 기준으로 계산되어<br/>
          이자지급코인에 설정된 코인으로 이자가 지급된다.<br/>
          (동일한 코인으로 이자를 지급하는 경우 이자지급코인을 스테이킹코인과 동일하게 설정하면 된다.)
          </div>
          <Form {...formItemLayout} onSubmit={this.onUpdate}>
            <Descriptions
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Ditems label="스테이킹코인">
                {this.props.stakingCoinName}
              </Ditems>
              <Ditems label="이자지급코인">
                <FormItem>
                  {getFieldDecorator("bonusCoinType", {
                    rules: [{ required: true, message: "이자로 지급할 코인을 선택해주세요." }],
                    initialValue: this.props.stakingBonus ? this.props.stakingBonus.bonusCoinType : null
                  })(
                    <CoinSelectBox
                    exceptERC
                    placeholder="코인선택"
                    style={{ width: "160px" }}
                  />
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="이자율(%/일)">
                <FormItem>
                {getFieldDecorator("ratio", {
                  initialValue: this.props.stakingBonus ? this.props.stakingBonus.ratio * 100 : 0
                })(
                  <InputNumber min={0} />
                  )}
                  </FormItem>
              </Ditems>
              <Ditems label="이자락">
                <FormItem style={{}}>
                  {getFieldDecorator("useLock", { initialValue: this.props.stakingBonus && this.props.stakingBonus.useLock==1, valuePropName: 'checked'
                  })(
                    <Checkbox 
                      style={{ marginLeft: "10px" }}
                      onChange={(e)=>{this.setState({useLock: e.target.checked})}}
                      >
                      이자LOCK (이자 지급 시 LOCK 적용)
                    </Checkbox>
                  )}
                </FormItem>
                {this.state.useLock && (
                  <FormItem className="">
                    
                    {getFieldDecorator("lockDate", {
                      initialValue: this.props.stakingBonus && this.props.stakingBonus.useLock==1 ? moment(formatDateSecond(this.props.stakingBonus.lockDate), 'YYYY-MM-DD HH:mm:ss') : null
                    })(
                      <DatePicker
                        locale={locale}
                        placeholder="LOCK 해제일시"
                        disabledDate={this.disabledDate}
                      />
                    )}
                  </FormItem>
                )}
              </Ditems>
              </Descriptions>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create()(StakingBonusModify)