import React, { Component } from "react";
import {
  SearchOutlined
} from '@ant-design/icons';
import { Table, Input, Checkbox, Button, Tooltip ,Icon,Divider} from "antd";
import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";
import { numberFormat, formatDate } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import PaybackUpdate from "../../components/system/PaybackUpdate";
const Search = Input.Search;

class PaybackRatio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText:"",
      pagination: {
        current: 0,
        total: 0
      },   
      list:[],
      detailData: {},      
      paybackRatioVisible:false,
      avg:[]
 
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getPaybackRatioList({ pageNum: pager.current });
  };





  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getPaybackRatioList();
      }
    );
  };


  getPaybackRatioList = (params = {}) => {
    const {searchText} = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getPaybackRatioList,[10, pageNum, searchText])
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.paybackRatioList,
          avg : res.data.paybackRatioAvg,
          pagination
        });
      })
      .catch((error) => { });
  };

  componentDidMount() {
    this.getPaybackRatioList();
  }

  
  render() {
    const {  
      paybackRatioList
    } = this.state;
    let classificationString = [
      '',
      '총판',
      '지사',
      '대리점',
      '가맹점',
    ]

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{numberFormat(data)}</div>
      },   
      {
        title: "조직구분",
        dataIndex: "classification",
        width: 100,
        className: "support-column-number fs-15 lh-21",
        render: (data) => {
          return (
            <div style={{fontSize:13}}>{classificationString[data]}</div>
          );
        }
      },
      {
        title: "조직명",
        dataIndex: "name",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: (data) => {
          return (
            <div>            
              {data}
            </div>
          );
        }
      },    
      {
        title: "페이백률",
        dataIndex: "paybackRatio",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => {
          return (
            <div>{data !== null ? data * 100+" %" : data}</div>
          );
        }
      },
      // {
      //   title: "평균 페이백률",
      //   dataIndex: "avg",
      //   // width: 80,
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data) => {
      //     return (
      //       <div>{data !== null ? data * 100+" %" : data}</div>
      //     );
      //   }
      // },
 
   
    ];

  


    return (    
      <div>         
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "60px",
            fontWeight: "bold"
          }}>
       <Divider orientation="left" style={{fontSize:23}}>페이백 세팅<span style={{fontSize:7}}> L7 STEM</span></Divider>
       <span>페이백 : {this.state.avg} %</span>
        </div>    
        <div className="flex-row flex-center flex-sb m-b-10">
        <div className="flex-row flex-center">         
          <Button
          onClick={()=>{this.setState({paybackRatioVisible: true})}} type="default"
          >페이백 변경</Button>
          </div>   
           <Search
              placeholder="검색어를 입력해주세요."
              enterButton={<SearchOutlined/>}
              allowClear
              onSearch={this.onSearch}
              style={{ width: 400 }}
            />
        </div>    
        <Table        
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
         <PaybackUpdate 
            visible={this.state.paybackRatioVisible}
            onOk={() => {
            this.setState({
              paybackRatioVisible: false
            });
            this.onSearch('');
          }}
          onCancel={() => {
            this.setState({
              paybackRatioVisible: false
            });
          }}
            
           />  
      </div>
    );
          }
      }
    
  
  


export default PaybackRatio;