import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

import { getCaution } from "../../contexts/asyncContext";
import { Table, Input, Button } from "antd";
import WalletRegistryModal from "../../components/wallet/WalletRegistryModal";
import { httpPut, httpUrl } from "../../api/httpClient";
import string from "../../string";
import { formatDate } from "../../util";
import SelectBox from "./../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";

const Search = Input.Search;

class Caution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cautionTypes: "ALL",
      searchText: "",
      pagination: {
        total: 0,
        current: 0
      },
      createModalVisible: false
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getCaution({
      pageSize: 10,
      pageNum: pager.current,
      cautionType: this.state.cautionTypes,
      searchText: this.state.searchText
    });
  };

  onSearch = value => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.props.getCaution({
          pageSize: 10,
          pageNum: 1,
          cautionType: this.state.cautionTypes,
          searchText: value
        });
      }
    );
  };

  onCreate = () => {
    this.setState(
      {
        createModalVisible: false,
        cautionTypes: "ALL",
        searchText: ""
      },
      () => {
        this.props.getCaution({
          pageSize: 10,
          pageNum: 1,
          cautionType: this.state.cautionTypes,
          searchText: this.state.searchText
        });
      }
    );
  };

  onDelete = (value, row) => {
    const { pagination } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    if (
      global.confirm(
        `${coinList.find(coin => coin.coinType === row.coinType).name}을(를) ${
          row.deleted ? "복구" : "삭제"
        }하시겠습니까?`
      )
    ) {
      // console.log(JSON.stringify(row, null, 4));
      httpPut(
        httpUrl.cautionWalletDelete,
        [row.address, row.coinType, row.type],
        {}
      )
        .then(result => {
          if (row.deleted) showAlert("restore");
          else showAlert("delete");
          this.props.getCaution({
            pageSize: 10,
            pageNum: pagination.current,
            cautionType: this.state.cautionTypes,
            searchText: this.state.searchText
          });
        })
        .catch(error => {});
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getCaution({
      pageSize: 10,
      pageNum: 1,
      cautionType: "ALL",
      searchText: ""
    });
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const walletColumns = [
      {
        title: "등록일",
        dataIndex: "createDate",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "종류",
        dataIndex: "type",
        // width: 160,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{string.cautionString[data]}</div>
      },
      {
        title: "상태",
        dataIndex: "deleted",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          // <Button onClick={(value) => this.onDelete(row)}>삭제하기</Button>
          <div>
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={value => {
                if (value !== row.deleted) {
                  this.onDelete(value, row);
                }
              }}
            />
          </div>
        )
      }
    ];
    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          의심지갑
        </div>
        <WalletRegistryModal
          type="cautionWallet"
          visible={this.state.createModalVisible}
          onCancel={() => {
            this.setState({ createModalVisible: false });
          }}
          onOk={this.onCreate}
        />
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ createModalVisible: true });
            }}>
            생성
          </Button>
          <div className="flex-row flex-center">
            <label htmlFor="">검색기준&nbsp;</label>
            <Search
              placeholder="지갑주소을 입력하세요"
              enterButton="검색"
              onSearch={this.onSearch}
              allowClear
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="caution-table"
          rowKey={record => record.idx}
          columns={walletColumns}
          dataSource={this.props.result.cautionWalletList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getCaution: ({ pageSize, pageNum, cautionType, searchText }) =>
      dispatch(getCaution({ pageSize, pageNum, cautionType, searchText }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Caution);
