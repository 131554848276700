import { reactLocalStorage } from "reactjs-localstorage";
import BigNumber from "bignumber.js";

const findDecimal = coinType => {
  const coinDecimal = reactLocalStorage.getObject("coinList");
  let decimal = 0;
  coinDecimal.forEach(item => {
    if (item.coinType === parseInt(coinType)) decimal = item.decimal;
  });
  if (decimal >= 9) decimal = 9;
  return decimal;
};

const formatDateNumber = num => {
  num += "";
  return num.length < 2 ? "0" + num : num;
};

const formatDate = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " " +
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes())
  );
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // return day + " " + second;
};

const formatDateSecond = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date(date);
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // let result2 = day + " " + second;
  let result =
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " " +
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes()) +
    ":" +
    formatDateNumber(newDate.getSeconds());
  // console.log(`prevResult: ${result} current:${result2}`);
  return result;
};

const dateFormat = date => {
  if (!date) return null;
  let hour = parseInt(date / 60);
  let minute = date % 60;
  return hour === 0
    ? minute + "분"
    : minute === 0
    ? hour + "시간"
    : hour + "시간 " + minute + "분";
};

const minFormat = date => {
  if (!date) return null;
  let newDate = new Date(date);
  let hour = newDate.getHours() * 60;
  let min = parseInt(newDate.getMinutes());
  let time = hour + min;
  // console.log(time);
  return time;
};

const dayFormat = date => {
  if (!date) return null;
  // console.log(date);
  for (let i = 0; i < date.length; i++) {
    // console.log(date);
    let year = date.substr(0, 4);
    let month = date.substr(4, 2);
    let day = date.substr(6, 2);
    // return new Date(year, month, day);
    let time = year + "-" + month + "-" + day;
    return time;
  }
};

const monthFormat = date => {
  if (!date) return null;
  // console.log(date);
  for (let i = 0; i < date.length; i++) {
    // console.log(date);
    let year = date.substr(0, 4);
    let month = date.substr(4, 2);
    // return new Date(year, month, day);
    let time = year + "-" + month;
    return time;
  }
};

const startDateFormat = date => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " 00:00:00"
  );
};

// const endDateFormat = date => {
//   if (!date) return null;
//   let day = date.slice(0, 10);
//   let second = date.slice(11, 19);
//   return `${day} ${second}`;
// };

const endDateFormat = date => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " 23:59:59"
  );
};

const statFormat = date => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    formatDateNumber(newDate.getMonth() + 1) +
    formatDateNumber(newDate.getDate())
  );
};

const statMonthFormat = date => {
  if (!date) return null;
  let newDate = new Date(date);
  return newDate.getFullYear() + formatDateNumber(newDate.getMonth() + 1);
};

const excelFormat = date => {
  if (!date) return null;
  let newDate = new Date(date);
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate())
  );
};

const removeComma = str => {
  if (typeof str === "number") {
    // console.log(`str:${str} str Type: ${typeof str}`);
    str = str.toString();
  }
  return str.split(",").reduce((acc, item, index, array) => {
    return acc + item;
  }, "");
};

const numberFormat = Num => {
  if (Num === null || Num === 0) return 0;
  var reg = /(^[+-]?\d+)(\d{3})/;
  var n = Num + "";
  if (Num > 0) {
    while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");
  }

  return n;
};

const bigNum = Num => {
  // console.log(`Num: ${Num}`);
  let num = new BigNumber(removeComma(Num));
  if (num.isNaN() || !num.isFinite()) {
    num = "0";
  } else {
    num = num.toFormat();
  }
  return num;
};

const amountFormat = (Num, coinType) => {
  if (Num === null || Num === 0) return 0;

  let number = Num / Math.pow(10, findDecimal(coinType));
  // let number = Num / 1000000000;
  var reg = /(^[+-]?\d+)(\d{3})/;
  var n = "";

  n = new BigNumber(number).toFixed(9);
  n = new BigNumber(n).toFormat();
  if (n > 0) {
    while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");
  }
  return n;
};

const coinMultiFormat = (Num, coinType) => {
  if (Num === null || Num === 0) return 0;

  let n = Num * Math.pow(10, findDecimal(coinType));

  n = new BigNumber(n).toNumber();
  return n;
};

const coinDivideFormat = (Num, coinType) => {
  if (Num === null || Num === 0) return 0;

  let number = parseFloat(Num / Math.pow(10, findDecimal(coinType)));
  var n = "";

  n = new BigNumber(number).toFixed(9);
  n = new BigNumber(n).toNumber();
  return n;
};

const coinMultiFormatByDecimal = (Num, decimal) => {
  if (Num === null || Num === 0) return 0;

  if (decimal >= 9) decimal = 9;
  let n = Num * Math.pow(10, decimal);

  // let n = Num * 1000000000;
  return Math.round(n);
};

const coinDivideFormatByDecimal = (Num, decimal) => {
  if (Num === null || Num === 0) return 0;
  if (decimal >= 9) decimal = 9;
  let number = Num / Math.pow(10, decimal);
  var n = "";

  n = new BigNumber(number).toFixed(9);
  n = new BigNumber(n).toNumber();
  return n;
};

const priceFormat = Num => {
  if (Num === null || Num === 0) return 0;
  let number = Num / 1000000;
  var reg = /(^[+-]?\d+)(\d{3})/;
  var n = number + "";

  while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");

  return n;
};

const priceMultiFormat = Num => {
  if (Num === null || Num === 0) return 0;

  let n = Num * 1000000;
  return n;
};

const priceDivideFormat = Num => {
  if (Num === null || Num === 0) return 0;

  let n = Num / 1000000;
  return n;
};

const minAmountByCoinType = coinType => {
  if (coinType === 0 || coinType === null || coinType === undefined) return 0;
  return 1 / Math.pow(10, findDecimal(coinType));
};

const minAmountByDecimal = decimal => {
  if (decimal === 0 || decimal === null || decimal === undefined) return 0;

  if (decimal >= 9) decimal = 9;
  return 1 / Math.pow(10, decimal);
};

// 주민번호 포맷
const registartionNumFormat = Num => {
  if (Num === null || Num === 0) return 0;
  var reg = /(\d{6})(\d{7})/;
  var n = Num + "";
  if (Num > 0) {
    while (reg.test(n)) n = n.replace(reg, "$1" + "-" + "$2");
  }

  return n;
};
// 전화번호 포맷
const phoneNumFormat = Num => {
  if (Num === null || Num === 0) return 0;
  var reg = /(\d{3})(\d{4})(\d{4})/;
  var n = Num + "";
  if (Num > 0) {
    while (reg.test(n)) n = n.replace(reg, "$1" + "-" + "$2" + "-" + "$3");
  }

  return n;
};


export {
  registartionNumFormat,
  phoneNumFormat,
  formatDate,
  formatDateSecond,
  dateFormat,
  minFormat,
  dayFormat,
  monthFormat,
  startDateFormat,
  endDateFormat,
  statFormat,
  statMonthFormat,
  excelFormat,
  numberFormat,
  amountFormat,
  coinMultiFormat,
  coinDivideFormat,
  priceFormat,
  priceMultiFormat,
  priceDivideFormat,
  minAmountByCoinType,
  minAmountByDecimal,
  coinMultiFormatByDecimal,
  coinDivideFormatByDecimal,
  bigNum,
  removeComma
};
