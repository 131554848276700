import React, { Component } from "react";

import { reactLocalStorage } from "reactjs-localstorage";
// import { NaverMap } from "react-naver-maps";
import {
  Form,
  Modal,
  Input,
  Button,

} from "antd";
import { httpPost, httpUrl, serverUrl } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";


const FormItem = Form.Item;
const AccountRegistry = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        id:"",
        password:"0000",
        phone:"",
        securityPassword:"0000",
      };
      
    }

  


    onRegistry = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        let createData = {
          ...values,
         
        };
      
        if (!err) {
          if (global.confirm("생성하시겠습니까?"))
            httpPost(httpUrl.userCreate, [], createData)
              .then((res) => {
                if (res.data) {
                  this.props.onOk();
                  form.resetFields();
                  showAlert("create");
                } else {
                  
                    alert("알수없는 오류로 실패하였습니다. 가맹점 계정이 중복일수있습니다.");
                  
                }
              })
              .catch((error) => {
                alert("알수없는 오류로 실패하였습니다. 계정이 중복일수있습니다.");

              });
        }
      });
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
      
    };

    componentDidMount() {}

    render() {
      const {
      
      } = this.state;
      const { visible, form, defaultValue, color } = this.props;
      const { getFieldDecorator } = form;
      
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      

      return (
        <Modal
          visible={visible}
          title="계정생성"
          okText="생성"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "400px" }}>
         
          <Form {...formItemLayout} onSubmit={this.onRegistry}>
                             
            <FormItem label={<span>아이디&nbsp;</span>}>
              {getFieldDecorator("id", {
                rules: [
                  {
                    required: true,
                    message: "아이디를 입력해주세요!"
                  },
                ]

              })(<Input placeholder="" />)}
            </FormItem>             
            <FormItem label={<span>이름&nbsp;</span>}>
              {getFieldDecorator("userName", {
                rules: [
                  {
                    required: true,
                    message: "이름을 입력해주세요!"
                  },
                  
                ]

              })(<Input placeholder="" />)}
            </FormItem>                
            <FormItem label={<span>비밀번호&nbsp;</span>}>
              {getFieldDecorator("password", {
                
               
                initialValue: '0000',
                rules: [
                  {
                    required: false,
                    message: "비밀번호를 입력해주세요!"
                  },
                  {
                    
                    required: false,
                    message: "비밀번호를 입력해주세요.",
                    pattern: /[0-9]+$/
                  }
                ]
              })(<Input
              
             />)}
            </FormItem>
            <FormItem label={<span>핸드폰&nbsp;</span>}>
              {getFieldDecorator("phone", {
                rules: [
                  {
                    required: true,
                    message: "전화번호를 입력해주세요!"
                  },
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            
            
            <FormItem label={<span>보안비밀번호&nbsp;</span>}>
              {getFieldDecorator("securityPassword", {
                initialValue: '0000',
                rules: [
                  {
                    required: false,
                    message: "보안비밀번호를 입력해주세요!"
                  }
                ]
              })(<Input maxLength={4} onInput={this.maxLengthCheck}/>) }
            </FormItem>
                            
          
          </Form>
        </Modal>
      );
    }
  }
);

export default AccountRegistry;
