import React, { Component } from "react";


import { Table, Button, DatePicker, Select, Icon, Tooltip ,Divider} from "antd";

import locale from "antd/es/date-picker/locale/ko_KR";
import { reactLocalStorage } from "reactjs-localstorage";
import fileDownload from "js-file-download";

import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";
import { numberFormat, amountFormat, statFormat, dayFormat, formatDate } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";
import OrganizationSelectModal from "../../components/adjustment/OrganizationSelectModal";
import ConfirmInputModal from "../../components/common/ConfirmInputModal";
import { SelectOutlined, SwitcherOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const Option = Select.Option;
var today = new Date();


class AdjustmentLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      isLoaded: false,
      coinList: [],
      adjustmentLogList: [],
      pagination: {
        current: 1,
        total: 0,
      },
      organizationIdx: 0,
      organizationName: "",
      adjustmentType: "ALL",
      coinType: 0,
      startDate: "",
      endDate: "",
      orderByType: "DESC",

      isMemoModify: false,
      isStatusComplete: false,
      selectedData: {},

      modalVisible: false,
      inputModalVisible: false,
      modifyData: "",
    };
  }

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };

  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  handleChangeAdjustmentType = (value) => {
    this.setState({
      adjustmentType: value,
      isLoaded: true,
    });
  };

  handleChangeOrderByType = (value) => {
    this.setState({
      orderByType: value,
      isLoaded: true,
    });
  };

  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : "",
      isLoaded: true,
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getAdjustmentLogList({
      pageNum: pager.current,
    });
  };

  onUpdateAdjustment = (params = {}) => {
    let updateData = {
      idx: params.idx,
      adjustmentStatus: params.adjustmentStatus,
      memo: params.memo,
      receiptPrice: params.receiptPrice,
    };
    if (global.confirm("수정하시겠습니까?")) {
      httpPut(httpUrl.updateAdjustment, [], updateData).then((res) => {
        this.getAdjustmentLogList({
          pageNum: this.state.pagination.current,
        });
        showAlert("update");
        this.setState({
          inputModalVisible: false,
        });
      });
    }
  };


  downloadExcel = () => {
    const {
      pagination,
      adjustmentType,
      startDate,
      endDate,
      coinType,
      orderByType,
      organizationIdx,
      organizationName,
      isLoaded,
    } = this.state;

    let result = [];
    let title = "정산 내역";

    if (startDate !== "" && endDate !== "") {
      result.push(`${startDate} ~ ${endDate} `);
    }

    if (organizationIdx !== 0) {
      result.push(`${organizationName} `);
    }

    if (adjustmentType !== "ALL") {
      result.push(`${string.adjustmentString[adjustmentType]} `);
    }

    let total = pagination.total <= 9 ? 10 : pagination.total;

    title =
      result.reduce((acc, item, index) => {
        return acc + item;
      }, "") + title;

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(httpUrl.downloadAdjustmentLog, [
          total,
          1,
          adjustmentType,
          startDate,
          endDate,
          organizationIdx,
          coinType,
          orderByType,
        ])
          .then((res) => {
            // console.log(JSON.stringify(res, null, 4));
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((error) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  getAdjustmentLogList = (params = {}) => {
    const {
      adjustmentType,
      startDate,
      endDate,
      coinType,
      orderByType,
      organizationIdx,
    } = this.state;

    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getAdjustmentLogList,
      [
        10,
        pageNum,
        adjustmentType,
        startDate,
        endDate,
        organizationIdx,
        coinType,
        orderByType,
      ],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        adjustmentLogList: res.data.list,
        pagination,
        isLoaded: false,
      });
    });
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");

    this.setState(
      {
        coinList: [
          {
            coinType: 0,
            name: "전체",
          },
          ...coinList,
        ],
      },
      () => {
        this.getAdjustmentLogList();
      }
    );
  }



  render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { coinList } = this.state;
    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{numberFormat(data)}</div>,
      },
      {
        title: "조직명(가맹점주)",
        dataIndex: "organizationName",
        key: "organizationName",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:12.5 ,textAlign:"left"}}>
            {data}<div style={{fontSize:11.5}}>({row.userName === null ? "-" : row.userName})</div>
          </div>
        )
      },
      {
        title: "업종",
        dataIndex: "organizationSector",
        key: "organizationSector",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{(data !== null || data !== "") && (
          <Tooltip title={data} placement="left">
            <div className="text-ellipsis m-r-10" style={{fontSize:13}}>{data ? data : "-"}</div>
          </Tooltip>
        )}</div>

       },



      {
        title: "일자",
        key: "logDate",
        dataIndex: "logDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:12 ,width:70}}>{dayFormat(data)}</div>,
      },
      {
        title: "처리일자",
        key: "updateDate",
        dataIndex: "updateDate",
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontweight: 'bold', fontSize:12 }}> {formatDate(data)}</div >,

      },
      {
        title: "은행",
        dataIndex: "bankCode",
        key: "bankCode",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:12}}>
            {data === null || data === "" ? "-" : string.bankString[data]}
          </div>
        ),
      },
      {
        title: "전화번호",
        key: "userPhone",
        dataIndex: "userPhone",
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div style={{fontSize:12.}}>
            {data}
          </div>
        )
      },
      {
        title: "계좌번호",
        dataIndex: "bankAccount",
        key: "bankAccount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:12}}>{data === null || data === "" ? "-" : data}</div>
        ),
      },
      {
        title: "예금주",
        dataIndex: "accountHolder",
        key: "accountHolder",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:12}}>{data === null || data === "" ? "-" : data}</div>
        ),
      },
      {
        title: "정산금액",
        dataIndex: "price",
        key: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div style={{fontSize:13}}>{numberFormat(data)}원</div>,
      },
      {
        title: "실지급금액",
        dataIndex: "receiptPrice",
        key: "receiptPrice",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div style={{fontSize:13}}>{numberFormat(data)}원</div>,
      },
      {
        title: "정산수수료 비율",
        dataIndex: "feeRatio",
        key: "feeRatio",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:12.5}}>{data === null ? "-" : `${numberFormat(data)} %`}</div>
        ),
      },
      {
        title: "상태",
        dataIndex: "status",
        key: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div style={{fontSize:12.5, width:50}}>{string.adjustmentString[data]}</div>,
      },
      {
        title: "지급여부",
        key: "modify",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          row.status === "CREATED" ? (
            <div className="flex-row flex-center justify-center" >
              <Button
                className="m-r-10"
                onClick={() => {
                  this.setState({
                    inputModalVisible: true,
                    modifyData: "price",
                    selectedData: row,
                  });
                }}>
                완료
              </Button>
              <Button
                onClick={() => {
                  this.onUpdateAdjustment({
                    idx: row.idx,
                    adjustmentStatus: "REJECT",
                    memo: row.memo,
                    receiptPrice: row.receiptPrice,
                  });
                }}>
                거절
              </Button>
            </div>
          ) : (
              <div>-</div>
            ),
      },
    ];

    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          title: "코인 종류",
          dataIndex: "coinType",
          key: "coinType",
          className: "support-column-number fs-15 lh-21",
          render: (data) => (
            <div style={{fontSize:13}}>
              {data
                ? coinList.find((coin) => coin.coinType === data).name
                : null}
            </div>
          ),
        },
        {
          title: "코인 수량",
          dataIndex: "amount",
          key: "amount",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => (
            <div style={{fontSize:13}}>{data ? amountFormat(data, row.coinType) : null}</div>
          ),
        },
        {
          title: "코인 단가",
          dataIndex: "unitPrice",
          key: "unitPrice",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => <div style={{fontSize:13}}>{data ? numberFormat(data) : "-"}</div>,
        },
        {
          title: "메모",
          dataIndex: "memo",
          key: "memo",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => (
            <div className="flex-row flex-center justify-center">
              <div className="m-r-10" style={{ wordBreak: "keep-all" ,fontSize:13}}>
                {data === null || data === "" ? "-" : data}
              </div>
              
            </div>
          ),
        },
        {
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => (
            <Button
              style={{fontSize:13}}
              onClick={() => {
                this.setState({
                  inputModalVisible: true,
                  modifyData: "memo",
                  selectedData: row,
                });
              }}>
              메모수정
            </Button>
          ),
        },
      ];
      return (
        <Table
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={[record]}
          pagination={false}
        />
      );
    };



    return (

      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
       <Divider orientation="left" style={{fontSize:23}}>정산내역  </Divider>
        </div>
        {this.state.modalVisible && (
          <OrganizationSelectModal
            visible={this.state.modalVisible}
            onOk={(idx, name) => {
              this.setState({
                organizationIdx: idx,
                organizationName: name,
                isLoaded: true,
              });
            }}
            onCancel={() => {
              this.setState({
                modalVisible: false,
              });
            }}
          />
        )}
        {this.state.inputModalVisible && (
          <ConfirmInputModal
            rootPage="adjustment"
            modifyData={this.state.modifyData}
            visible={this.state.inputModalVisible}
            onOk={this.onUpdateAdjustment}
            onCancel={() => {
              this.setState({
                inputModalVisible: false,
              });
            }}
            selectedRow={this.state.selectedData}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
         
            <div>
              <div className="batch">
                <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
                  <SwitcherOutlined/>선택해제
          </Button>
                <span style={{ marginLeft: 8 }}>
                  {hasSelected ? `${selectedRowKeys.length} 개 선택` : ''}
                </span>
          &nbsp;




          <Button
                  onClick={() => {

             
                    if (global.confirm('완료 처리하시겠습니까?')) {


                      httpPut(httpUrl.updateAdjustmentMulti, [], {idxs: selectedRowKeys}).then((res) => {
                        this.getAdjustmentLogList({
                          pageNum: this.state.pagination.current,
                        });
                        showAlert("update");
                      });
                    }

                  }}
                  type="primary">

                  완료
            </Button>



          &nbsp;</div>
            </div> &nbsp;
            {this.state.organizationIdx !== 0 && (
              <div>
                {`가맹점: ${this.state.organizationName}`}
                <Button
                  type="link"
                  onClick={() => {
                    this.setState({
                      organizationIdx: 0,
                    });
                  }}>
                  <Icon type="close" />
                </Button>
              </div>
            )}
          </div>
          {/* <div className="flex-row flex-center">
            <label>검색기간&nbsp;</label>
            <RangePicker
              // placeholder="시작일"
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <div className="m-r-10" />
            <Button
              type="primary"
              onClick={() => {
                this.getAdjustmentLogList();
              }}>
              조회
            </Button>
          </div> */}
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <div className="flex-row flex-center m-r-10">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  modalVisible: true,
                });
              }}>
              <SelectOutlined/>조직 선택
            </Button>
            {/* {this.state.organizationIdx !== 0 && (
              <div>
                {`가맹점: ${this.state.organizationName}`}
                <Button
                  type="link"
                  onClick={() => {
                    this.setState({
                      organizationIdx: 0,
                    });
                  }}>
                  <Icon type="close" />
                </Button>
              </div>
            )} */}
         <Button onClick={this.downloadExcel}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label>코인 종류&nbsp;</label>
              <Select
                placeholder="코인종류"
                style={{ width: "160px", marginRight: "10px" }}
                value={this.state.coinType}
                onChange={this.handleChangeCoinType}>
                {coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="flex-row flex-center m-r-10">
              <label>정산 상태&nbsp;</label>
              <SelectBox
                value={string.adjustmentString[this.state.adjustmentType]}
                codeString={string.adjustmentString}
                onChange={(value) => {
                  if (value !== this.state.adjustmentType) {
                    this.handleChangeAdjustmentType(value);
                  }
                }}
              />
            </div>
            <div className="flex-row flex-center">
              <label>순서&nbsp;</label>
              <SelectBox
                value={string.orderByString[this.state.orderByType]}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== this.state.orderByType)
                    this.handleChangeOrderByType(value);
                }}
                style={{ width: "80px" }}
              />
            </div>&nbsp;&nbsp;&nbsp;
            <label>검색기간&nbsp;</label>
            <RangePicker
              // placeholder="시작일"
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.getAdjustmentLogList();
              }}>
              조회
            </Button>
            <div className="m-r-10" />
          </div>
         
        </div>

        <Table
          rowSelection={rowSelection} 
          rowKey={(record) => record.idx}
          dataSource={this.state.adjustmentLogList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          columns={columns}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default AdjustmentLog;

