import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Modal, Input, Form, TreeSelect } from "antd";

import { httpPost, httpUrl } from "../../api/httpClient";
import CoinSelectBox from "./../common/CoinSelectBox";
import { showAlert } from "../common/AlertModal";

const FormItem = Form.Item;
const { TreeNode, SHOW_PARENT } = TreeSelect;

const AdminRegistryModal = Form.create()(
  class extends Component {
    state = {
      confirmDirty: false,
      registryData: {},
      authList: [],
      authSelect: [],
      coinAuthList: [],
      coinType: [],
      coinList: [],
      coinSelect: []
    };

    validateToNextPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    };

    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue("password")) {
        callback("비밀번호가 다릅니다!");
      } else {
        callback();
        this.onSelect(value, "password");
      }
    };

    handleConfirmBlur = (e) => {
      const { value } = e.target;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    onSelect = (value, type) => {
      var data = this.state.registryData;

      if (type === "id") {
        data.id = value;
      } else if (type === "name") {
        data.name = value;
      } else if (type === "password") {
        data.password = value;
      } else if (type === "email") {
        data.email = value;
      } else if (type === "country") {
        // console.log(value);
        data.country = value;
      } else if (type === "mobile") {
        data.mobile = value;
      } else {
        data.ipAddress = value;
      }
      this.setState({
        registryData: data
      });
      // console.log(JSON.stringify(data, null, 4));
    };

    authSelect = (value, row) => {
      let data = [...this.state.authSelect];

      if (value.includes(data.idx)) {
        // delete value;
        data.filter((idx) => idx !== value);
      } else {
        // 부모가 선택됬을때 자식에게 기능 부여
        data = [...value];
        this.state.authList.forEach((list) => {
          if (
            data.includes(list.idx) &&
            list.children &&
            list.children !== null
          ) {
            // console.log("true", list);
            list.children.forEach((subList) => {
              if (subList.children && list.children !== null) {
                subList.children.forEach((thirdList) => {
                  data.push(thirdList.idx);
                });
              } else data.push(subList.idx);
            });
          }
        });
      }
      this.setState({ authSelect: data });
      // console.log("data", data);
    };

    coinAuthSelect = (value, type) => {
      this.setState({ coinSelect: value });
    };

    onRegistry = () => {
      const { registryData, authSelect, authIdxList, coinSelect } = this.state;
      const coinAuthData = this.state.coinAuthList;
      const authParent = this.state.authParent;

      let menu = [...authSelect];
      authParent.forEach((list) => {
        if (authSelect.includes(list.idx)) {
          if (!menu.includes(list.parentIdx)) menu.push(list.parentIdx);
        }
      });
      // console.log(`menu = ${JSON.stringify(menu, null, 4)}`);

      this.props.form.validateFields((err, values) => {
        if (!err) {
          let createData = {
            ...registryData,
            // mobile:
            //   string.countryTel[registryData.country] + registryData.mobile,
            authRequest: {
              authList: authIdxList.map((list) => {
                if (menu.includes(list.idx)) {
                  return {
                    menuIdx: list.idx,
                    auth: 1
                  };
                } else {
                  return {
                    menuIdx: list.idx,
                    auth: 0
                  };
                }
              }),
              idx: 0
            },
            authCoinRequest: {
              authCoinList: coinAuthData.map((list) => {
                if (coinSelect.includes(list.coinType)) {
                  return {
                    coinType: list.coinType,
                    auth: 1
                  };
                } else {
                  return {
                    coinType: list.coinType,
                    auth: list.auth
                  };
                }
              }),
              idx: 0
            }
          };
          // console.log(`createData = ${JSON.stringify(createData, null, 4)}`);
          if (global.confirm(`생성하시겠습니까?`)) {
            httpPost(httpUrl.adminCreate, [], createData)
              .then((result) => {
                if (result.data.result === true) {
                  this.props.form.resetFields();
                  this.props.onOk();
                  showAlert("create");
                } else {
                  alert("아이디가 중복됩니다");
                }
              })
              .catch((error) => {});
          }
        }
      });
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
    };

    componentDidUpdate(prevProps) {
      if (this.props.authCoinData.length !== prevProps.authCoinData.length) {
        let arr = [];
        let coinType = this.props.authCoinData.map((list) => {
          return list.coinType;
        });
        const coinList = reactLocalStorage.getObject("coinList");
        coinList.forEach((coin) => {
          if (coinType.includes(coin.coinType)) {
            arr.push(coin.coinType);
          }
        });

        let menu = [];
        let parent = [];
        let authIdx = [];
        this.props.modalData.forEach((list) => {
          let menuObj = { ...list };
          delete menuObj.subMenu;
          delete menuObj.nameKr;
          if (list.subMenu === null || list.subMenu.length === 0) {
            menu.push({
              ...menuObj,
              children: null,
              title: list.nameKr,
              value: list.idx
            });
            authIdx.push({ idx: list.idx });
          } else {
            let subMenu = [];
            list.subMenu.forEach((subList) => {
              let subMenuObj = {
                ...subList
              };
              delete subMenuObj.subMenu;
              delete subMenuObj.nameKr;
              if (subList.subMenu === null || subList.subMenu.length === 0) {
                subMenu.push({
                  ...subMenuObj,
                  children: null,
                  title: subList.nameKr,
                  value: subList.idx
                });
                parent.push({
                  idx: subList.idx,
                  parentIdx: parseInt(subList.parentIdx)
                });
                authIdx.push({ idx: subList.idx });
              } else {
                let thirdMenu = [];
                subList.subMenu.forEach((thridList) => {
                  let thirdMenuObj = { ...thridList };
                  delete subMenuObj.subMenu;
                  delete subMenuObj.nameKr;
                  if (
                    thridList.subMenu === null ||
                    thridList.subMenu.length === 0
                  ) {
                    thirdMenu.push({
                      ...thirdMenuObj,
                      children: null,
                      title: thridList.nameKr,
                      value: thridList.idx
                    });
                    parent.push({
                      idx: thridList.idx,
                      parentIdx: parseInt(thridList.parentIdx)
                    });
                    authIdx.push({ idx: thridList.idx });
                  }
                });
                subMenu.push({
                  ...subMenuObj,
                  children: thirdMenu,
                  title: subList.nameKr,
                  value: subList.idx
                });
                parent.push({
                  idx: subList.idx,
                  parentIdx: parseInt(subList.parentIdx)
                });
                authIdx.push({ idx: subList.idx });
              }
            });
            menu.push({
              ...menuObj,
              children: subMenu,
              title: list.nameKr,
              value: list.idx
            });
            authIdx.push({ idx: list.idx });
          }
        });
        // console.log(JSON.stringify(authIdx, null, 4));
        this.setState({
          // registryData: { coinAuthList: this.props.modalData },
          authList: menu,
          authParent: parent,
          authIdxList: authIdx,
          coinAuthList: this.props.authCoinData,
          coinList: arr,
          coin: this.props.authCoinData.map((list) => {
            return {
              coinType: list.coinType,
              auth: list.auth
            };
          })
        });
      }
    }

    renderOption = () => {
      const { authList } = this.state;
      let result = [];
      authList.forEach((list) => {
        if (list.subMenu === null) {
          result.push(
            <TreeNode
              value={list.idx}
              key={`${list.idx}}`}
              title={list.nameKr}></TreeNode>
          );
        } else {
          result.push(
            <TreeNode value={list.idx} key={`${list.idx}}`} title={list.nameKr}>
              {list.subMenu.map((subList) => {
                if (subList.subMenu === null) {
                  return (
                    <TreeNode
                      value={subList.idx}
                      key={`${subList.idx}}`}
                      title={subList.nameKr}
                    />
                  );
                } else {
                  return (
                    <TreeNode
                      value={subList.idx}
                      key={`${subList.idx}}`}
                      title={subList.nameKr}>
                      {subList.subMenu.map((thirdList) => {
                        return (
                          <TreeNode
                            value={thirdList.idx}
                            key={`${thirdList.idx}}`}
                            title={thirdList.nameKr}
                          />
                        );
                      })}
                    </TreeNode>
                  );
                }
              })}
            </TreeNode>
          );
        }
      });
      return result;
    };

    render() {
      const { visible, form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      const authData = {
        treeCheckable: true,
        onChange: (value, title, row) => {
          this.authSelect(value, row);
        },
        // value: this.state.authSelect,
        treeData: this.state.authList,
        showCheckedStrategy: SHOW_PARENT,
        searchPlaceholder: "선택해주세요"
      };

      return (
        <Modal
          visible={visible}
          title="관리자"
          okText="생성"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "800px" }}>
          <div className="adminRegistryModal-wrapper">
            {/* <div>{JSON.stringify(this.state.registryData, null, 4)}</div> */}
            <Form {...formItemLayout} onSubmit={this.onRegistry}>
              <FormItem label={<span>이름&nbsp;</span>}>
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: false,
                      message: "이름을 입력해주세요!"
                    }
                  ]
                })(
                  <Input
                    allowClear
                    placeholder="홍길동"
                    onChange={(e) => {
                      this.onSelect(e.target.value, "name");
                    }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>아이디&nbsp;</span>}>
                {getFieldDecorator("id", {
                  rules: [
                    {
                      required: true,
                      message: "아이디를 입력해주세요!"
                    }
                  ]
                })(
                  <Input
                    allowClear
                    placeholder="kildong"
                    onChange={(e) => {
                      this.onSelect(e.target.value, "id");
                    }}
                  />
                )}
              </FormItem>
              <FormItem hasFeedback label={<span>비밀번호&nbsp;</span>}>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "비밀번호를 입력해주세요!"
                    },
                    {
                      validator: this.validateToNextPassword
                    }
                  ]
                })(<Input.Password />)}
              </FormItem>
              <FormItem hasFeedback label={<span>비밀번호 확인&nbsp;</span>}>
                {getFieldDecorator("confirm", {
                  rules: [
                    {
                      required: true,
                      message: "비밀번호를 다시 한번 입력해주세요!"
                    },
                    {
                      validator: this.compareToFirstPassword
                    }
                  ]
                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </FormItem>
              <FormItem label={<span>IP 주소&nbsp;</span>}>
                {getFieldDecorator("ipAddress", {
                  rules: [
                    {
                      required: true,
                      message: "IP 주소를 입력해주세요!"
                    }
                  ]
                })(
                  <Input
                    allowClear
                    placeholder="123.456.0.0"
                    onChange={(e) => {
                      this.onSelect(e.target.value, "ipAddress");
                    }}
                  />
                )}
                <div
                  style={{
                    fontSize: "14px",
                    color: "blue"
                  }}>
                  <span>*</span>을 입력하시면 모든 곳에서 접속이 가능합니다.
                </div>
              </FormItem>
              <FormItem label={<span>이메일&nbsp;</span>}>
                {getFieldDecorator(
                  "email",
                  {}
                )(
                  <Input
                    allowClear
                    placeholder="kildong@naver.com"
                    onChange={(e) => {
                      this.onSelect(e.target.value, "email");
                    }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>핸드폰번호&nbsp;</span>}>
                {getFieldDecorator(
                  "mobile",
                  {}
                )(
                  <div>
                    {/* <SelectBox
                      style={{ width: "200px" }}
                      placeholder="국가를 선택해주세요."
                      value={
                        string.countryName[this.state.registryData.country]
                      }
                      code={string.countryCode}
                      codeString={string.countryName}
                      onChange={value => {
                        this.onSelect(value, "country");
                      }}
                    /> */}
                    <Input
                      style={{ width: "200px" }}
                      placeholder="01012345678"
                      onChange={(value) => {
                        this.onSelect(value, "mobile");
                      }}
                    />
                  </div>
                )}
              </FormItem>
              <Form.Item label="권한설정">
                {getFieldDecorator("auth", {
                  rules: [
                    {
                      required: true,
                      message: "권한을 설정해주세요.",
                      type: "array"
                    }
                  ]
                })(
                  <TreeSelect {...authData} />
                  // <TreeSelect
                  //   multiple
                  //   treeCheckabl={true}
                  //   placeholder="선택해주세요"
                  //   style={{ width: "380px" }}
                  //   onChange={this.authSelect}
                  //   treeData={this.state.authList}>
                  //   {/* {this.renderOption()} */}
                  // </TreeSelect>
                )}
              </Form.Item>
              <Form.Item label="코인권한설정">
                {getFieldDecorator("coin", {
                  rules: [
                    {
                      required: true,
                      message: "코인 권한을 설정해주세요.",
                      type: "array"
                    }
                  ]
                })(
                  <CoinSelectBox
                    mode="multiple"
                    placeholder="선택해주세요"
                    style={{ width: "380px" }}
                    onChange={this.coinAuthSelect}
                  />
                )}
              </Form.Item>
              {/* <Form.Item label="코인권한설정리스트">
                {coin.map(
                  list =>
                    list.auth === 1 && (
                      <Tag
                        closable
                        onClose={() => {
                          this.coinAuthDelete(list.coinType);
                          // console.log(list.coinType);
                        }}
                      >
                        {string.coinString[list.coinType]}
                      </Tag>
                    )
                )}
              </Form.Item> */}
            </Form>
          </div>
        </Modal>
      );
    }
  }
);

export default AdminRegistryModal;
