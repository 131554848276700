import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, DatePicker, Button, Icon, Divider,Tooltip } from "antd";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import {
  numberFormat,
  formatDate,
  amountFormat,
  startDateFormat,
  excelFormat,
  endDateFormat,
} from "../../util";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import LockDetail from "../../components/foundation/LockDetail";
import fileDownload from "js-file-download";
import locale from "antd/es/date-picker/locale/ko_KR";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class FoundationLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foundationList: [],
      fCoinList: [],

      logList: [],
      pagination: {
        total: 0,
        current: 1,
      },
     
      orderType: "CREATE_DATE",
      requestType: "ALL",
      orderByType: "ASC",
      searchText: "",

      lockVisible: false,
      lockSeleted: {},
      lockDetail: [],
      lockPagination: {
        total: 0,
        current: 1,
      },
      isLoaded: false,
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getFoundationLog({
      pageNum: pager.current,
    });
  };

  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true,
    });
  };

  orderTypeHandleChange = (value) => {
    const { startDate, endDate } = this.state;
    if ( !startDate || !endDate) {
      alert("검색 기간 날짜를 선택해주세요.");
    } else {
      this.setState({
        orderType: value,
        isLoaded: true,
      });
    }
  };

  orderByTypeHandleChange = (value) => {
    const { startDate, endDate } = this.state;
    if ( !startDate || !endDate) {
      alert(" 검색 기간 날짜를 선택해주세요.");
    } else {
      this.setState({
        orderByType: value,
        isLoaded: true,
      });
    }
  };

  requestTypeHandleChange = (value) => {
    const { startDate, endDate } = this.state;
    if (!startDate || !endDate) {
      alert("검색 기간 날짜를 선택해주세요.");
    } else {
      this.setState({
        requestType: value,
        isLoaded: true,
      });
    }
  };

  handleChangeDate = (value) => {
    this.setState({
      startDate: startDateFormat(value[0]),
      endDate: endDateFormat(value[1]),
      isLoaded: true,
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  onSearch = (value) => {
    const {  startDate, endDate } = this.state;
    if (!startDate || !endDate) {
      alert("검색 기간 날짜를 선택해주세요.");
    } else {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.getFoundationLog({
            pageNum: 1,
          });
        }
      );
    }
  };

  downloadExcel = () => {
    const {
      coinType,
      pagination,
      orderType,
      requestType,
      orderByType,
      startDate,
      endDate,
      searchText,
      isLoaded,
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    if (!startDate || !endDate) {
      alert("검색 기간 날짜를 선택해주세요.");
    } else {
      // console.log(`${excelFormat(startDate)} ~ ${excelFormat(endDate)}`);
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(httpUrl.foundationCoinLogExcel, [
            pagination.total,
            1,
            coinType ? coinType : '',
            orderType,
            requestType,
            orderByType,
            startDate,
            endDate,
            searchText,
          ])
            .then((res) => {
              // console.log(JSON.stringify(res, null, 4));
              fileDownload(
                res,`재단 코인 배포 내역.xlsx`);
            })
            .catch((error) => { });
        }
      }
    }
  };

  getLockLog = (params = {}) => {
    // console.log(JSON.stringify(params.row, null, 4));
    let pageNum = params.pageNum || 1;
    if (params.refresh) {
      pageNum = this.state.lockPagination.current;
    }
    httpGet(
      httpUrl.foundationCoinLogLock,
      [10, pageNum, this.state.lockSeleted.idx],
      {}
    )
      .then((result) => {
        const lockPagination = {
          ...this.state.lockPagination,
        };
        lockPagination.current = result.data.currentPage;
        lockPagination.total = result.data.totalCount;
        this.setState({
          lockVisible: true,
          lockDetail: result.data.lockList,
          lockPagination,
        });
      })
      .catch((error) => { });
  };

  lockHandleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.lockPagination };
    pager.current = pagination.current;
    this.setState({
      lockPagination: pager,
    });
    this.getLockLog({
      pageNum: pager.current,
    });
  };

  getFoundationLog = (params = {}) => {
    const {
      coinType,
      orderType,
      requestType,
      orderByType,
      startDate,
      endDate,
      searchText,
    } = this.state;
    let pageNum = params.pageNum || 1;
    if (startDate && endDate) {
      httpGet(
        httpUrl.foundationCoinLog,
        [
          10,
          pageNum,
          coinType ? coinType : '' ,
          orderType,
          requestType,
          orderByType,
          startDate,
          endDate,
          searchText,
        ],
        {}
      )
        .then((res) => {
          const pagination = { ...this.state.pagination };
          pagination.current = res.data.currentPage;
          pagination.total = res.data.totalCount;
          this.setState({
            logList: res.data.logList,
            pagination,
            isLoaded: false,
          });
        })
        .catch((error) => { });
    }
  };

  componentDidMount() {
    httpGet(httpUrl.getFoundationList, [], {}).then((res) => {
      this.setState({
        foundationList: res.data,
        fCoinList: res.data.map((item) => {
          return item.coinType;
        }),
      });
    });
  }

  render() {
    const {
      logList,
      coinType,
      fCoinList,
      orderType,
      requestType,
      orderByType,
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const lockColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:12.5}}>{numberFormat(data)}</div>,
      },
      {
        title: "배포일",
        dataIndex: "createDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:12.5}}>{formatDate(data)}</div>,
      },
      {
        title: "배포 종류",
        dataIndex: "type",
        key: "type",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:12.5}}>{string.lockString[data]}</div>,
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{fontSize:12.5}}>
            {coinList.find((coin) => coin.coinType === data)
              ? coinList.find((coin) => coin.coinType === data).name
              : ""}
          </div>
        ),
      },
      {
        title: "이름(아이디)",
        dataIndex: "id",
        key: "id",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:12.5}}>
            {row.name} ({data}) 
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "",

      },
      {
        title: "지갑주소",
        dataIndex: "to",
        // width: 180,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div style={{fontSize:12.5}}>{data}</div>
        )
        // render: data => (
        //   <div
        //     style={{
        //       whiteSpace: "nowrap",
        //       width: "180px",
        //       overflow: "hidden",
        //       textOverflow: "ellipsis"
        //     }}
        //   >
        //     {data}
        //   </div>
        // )
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:12.5}}>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </div>
        ),
      },
      {
        title: "trxId",
        dataIndex: "trxId",
        width: 180,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div
            style={{
              whiteSpace: "nowrap",
              width: "180px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>
            {data ? data : "-"}
          </div>
        ),
      },
      {
        title: "전송 상태",
        dataIndex: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:12.5}}>{string.requestLogString[data]}</div>,
      },
      {
        title: "배포자",
        dataIndex: "adminId",
        className: "support-column-number fs-15 lh-21",
        render: data =>(
          <div style={{fontSize:12.5}}>
            
          {data}
          
            </div>
        )
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "support-column-number fs-15 lh-21",
        render: data =>(
          <Tooltip title={data} placement="left">
            <div className="text-ellipsis m-r-10" style={{fontSize:13}}>{data ? data : "-"}</div>
          </Tooltip>
        )
      },
      {
        title: "LOCK 상세",
        dataIndex: "detail",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState(
                {
                  lockSeleted: row,
                },
                () => {
                  this.getLockLog();
                }
              );
            }}>
            상세
          </Button>
        ),
      },
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
           <Divider orientation="left" style={{fontSize:23}}>재단코인배포 내역 </Divider>  
        </div>
        <div className="wrapper mt-35 width-1240" />
        <LockDetail
          title="배포 내역 별 LOCK 상세 현황"
          lockDetail={this.getLockLog}
          modalData={this.state.lockDetail}
          visible={this.state.lockVisible}
          pagination={this.state.lockPagination}
          handleTableChange={this.lockHandleTableChange}
          onOk={() => {
            this.setState({
              lockVisible: false,
            });
          }}
          selected={this.state.lockSeleted}
        />
        <div style={{ marginBottom: "10px" }}>
       
        </div>
        <div style={{ marginBottom: "10px" }}>
          
          <label htmlFor="" style={{ marginRight: "20px" }}>
            검색기간{" "}
            <RangePicker
              locale={locale}
              // placeholder="시작일"
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </label>
          <div style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="" style={{ marginRight: "5px" }}>
            코인타입{" "}
            <CoinSelectBox
              type="coinType"
              placeholder="선택해주세요."
              value={coinType}
              coinCode={fCoinList}
              onChange={(value) => {
                if (value !== coinType) this.coinTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>         
          <label htmlFor="">
            순서{" "}
            <SelectBox
              value={string.orderByString[orderByType]}
              code={string.orderByCode}
              codeString={string.orderByString}
              onChange={(value) => {
                if (value !== orderByType) this.orderByTypeHandleChange(value);
              }}
              style={{ width: "160px" ,marginRight:5 }}
            />
            기준{" "}
            <SelectBox
              value={string.foundationLogString[orderType]}
              code={string.foundationLogCode}
              codeString={string.foundationLogString}
              onChange={(value) => {
                if (value !== orderType) this.orderTypeHandleChange(value);
              }}
              style={{ width: "160px",marginRight:5 }}
            />
          </label>
            <label htmlFor="" style={{  }}>
              대상{" "}
              <SelectBox
                placeholder="선택해주세요."
                value={string.requestLogString[requestType]}
                code={string.requestLogCode}
                codeString={string.requestLogString}
                onChange={(value) => {
                  if (value !== requestType)
                    this.requestTypeHandleChange(value);
                }}
                style={{ width: "160px" ,marginRight:5}}
              />
            </label>
            <label htmlFor="">
              검색기준{" "}
              <Search
                placeholder="아이디, 주소를 입력하세요"
                enterButton="검색"
                allowClear
                onSearch={this.onSearch}
                style={{ width: "300px" }}
              />
            </label>
          </div>
        </div>
        
        <div
          style={{
            width: "100%",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
       
          <span>{`total : ${numberFormat(this.state.pagination.total)}`}</span>
          <Button onClick={this.downloadExcel}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowKey={(record) => record.idx}
          columns={lockColumns}
          dataSource={logList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default FoundationLog;
