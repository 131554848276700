import { fetchStart, fetchSuccess, fetchRejected } from "../actions/asyncFetch";
import { coinStart, coinSuccess, coinRejected } from "../actions/coinFetch";
import { httpGet, httpUrl } from "../api/httpClient";
import { reactLocalStorage } from "reactjs-localstorage";

// 회원
export const getUser = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.userList,
      [
        params.pageSize,
        params.pageNum,
        params.deleted,
        params.searchText,
        params.searchType,
        params.startDate,
        params.endDate
      ],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getUserSb = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.userSbList,
      [
        params.pageSize,
        params.pageNum,
        params.deleted,
        params.searchText,
        params.searchType,
        params.startDate,
        params.endDate
      ],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getUserStem = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.userStemList,
      [
        params.pageSize,
        params.pageNum,
        params.deleted,
        params.searchText,
        params.searchType,
        params.startDate,
        params.endDate
      ],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

// 지갑
export const getFeeWallet = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.feeWalletList,
      [params.type, params.deleted, params.searchText],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getCollection = (params = {}) => {
  return dispatch => {
    httpGet(httpUrl.collectionList, [params.pageSize, params.pageNum], {})
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getFee = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.feeLogList,
      [
        params.pageSize,
        params.pageNum,
        params.searchText,
        params.tradeType,
        params.coinType,
        params.startDate,
        params.endDate
      ],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getCaution = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.cautionList,
      [params.pageSize, params.pageNum, params.cautionType, params.searchText],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getFoundation = (params = {}) => {
  return dispatch => {
    httpGet(httpUrl.foundationList, [params.pageSize, params.pageNum], {})
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

// 거래
export const getSet = (params = {}) => {
  return dispatch => {
    httpGet(httpUrl.setList, [params.pageSize, params.pageNum], {})
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getProcess = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.processList,
      [
        params.pageSize,
        params.pageNum,
        params.type,
        params.reasonType,
        params.searchText
      ],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

// 코인
export const getDistribute = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.distributeList,
      [params.pageSize, params.pageNum, params.type, params.searchText],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getAuto = (params = {}) => {
  return dispatch => {
    httpGet(httpUrl.coinAutoList, [params.pageSize, params.pageNum], {})
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

// 게시글
export const getNotice = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.noticeList,
      [
        params.pageSize,
        params.pageNum,
        params.deleted,
        params.category,
        params.searchText
      ],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getFaq = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.faqList,
      [params.pageSize, params.pageNum, params.deleted, params.searchText],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

// 통계
export const getUserStatistics = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.userStatisticsList,
      [params.pageSize, params.pageNum, params.startDate, params.endDate],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getLoginStatistics = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.loginStatisticsList,
      [params.pageSize, params.pageNum, params.startDate, params.endDate],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getTransferStatistics = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.transferStatisticsList,
      [
        params.pageSize,
        params.pageNum,
        params.type,
        params.startDate,
        params.endDate
      ],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

// 시스템
export const getAdmin = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.adminList,
      [params.pageSize, params.pageNum, params.searchText],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getBlockChain = (params = {}) => {
  return dispatch => {
    httpGet(httpUrl.blockChainList, [params.searchText], {})
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getLimitAmount = (params = {}) => {
  return dispatch => {
    httpGet(httpUrl.getlimitAmount, [], {})
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

//재단
export const getFoundationUserList = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.foundationUserList,
      [
        params.pageSize,
        params.pageNum,
        params.coinType,
        params.orderType,
        params.orderByType,
        params.searchText
      ],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getFoundationLock = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.foundationLockList,
      [params.pageSize, params.pageNum, params.coinType, params.searchText],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getFoundationCoin = (params = {}) => {
  return dispatch => {
    httpGet(httpUrl.getFoundationCoin, [], {})
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getFoundationLog = (params = {}) => {
  return dispatch => {
    httpGet(
      httpUrl.foundationCoinLog,
      [
        params.pageSize,
        params.pageNum,
        params.coinType,
        params.orderType,
        params.requestType,
        params.orderByType,
        params.startDate,
        params.endDate,
        params.searchText
      ],
      {}
    )
      .then(res => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getCoinDecimal = (params = {}) => {
  return dispatch => {
    httpGet(httpUrl.getCoinDecimal, [params.language], {})
      .then(res => {
        reactLocalStorage.setObject("coinList", res.data);
        dispatch(coinStart(true));
        dispatch(coinSuccess(res.data));
      })
      .catch(error => {
        dispatch(coinRejected(error));
      });
  };
};
