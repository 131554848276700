import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input } from "antd";

import { getDistribute } from "../../contexts/asyncContext";
import { numberFormat, formatDate, amountFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Search = Input.Search;

class Distribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTypes: "ALL",
      searchText: "",
      pagination: {
        total: 0,
        current: 0
      }
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getDistribute({
      pageSize: 10,
      pageNum: pager.current,
      type: this.state.searchTypes,
      searchText: this.state.searchText
    });
  };

  searchTypeHandleChange = value => {
    // let pagination = this.state.pagination;
    // pagination.current = 1;
    this.setState(
      {
        searchTypes: value
        // pagination: pagination
      },
      () =>
        this.props.getDistribute({
          pageSize: 10,
          pageNum: 1,
          type: value,
          searchText: this.state.searchText
        })
    );
  };

  onSearch = value => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.props.getDistribute({
          pageSize: 10,
          pageNum: 1,
          type: this.state.searchTypes,
          searchText: value
        });
      }
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getDistribute({
      pageSize: 10,
      pageNum: 1,
      type: "ALL",
      searchText: ""
    });
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const coinColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        width: 140,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{formatDate(data)}</div>
      },
      {
        title: "배포 종류",
        dataIndex: "type",
        width: 140,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{string.distributeTypeString[data]}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        width: 140,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        width: 140,
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "배포자",
        dataIndex: "adminId",
        className: "support-column-number fs-15 lh-21"
      },
      {
        width: 300,
        title: "메모",
        dataIndex: "memo",
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: 300
            }}>
            {data ? data : "-"}
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result.coinAirDropLogList, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          배포목록
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            대상{" "}
            <SelectBox
              value={string.distributeString[this.state.searchTypes]}
              code={string.distributeCode}
              codeString={string.distributeString}
              onChange={value => {
                if (value !== this.state.searchTypes)
                  this.searchTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
          </label>
          <label htmlFor="">
            검색기준{" "}
            <Search
              placeholder="주소를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="distribute-log-table"
          rowKey={record => record.idx}
          columns={coinColumns}
          dataSource={this.props.result.coinAirDropLogList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getDistribute: ({ pageSize, pageNum, type, searchText }) =>
      dispatch(getDistribute({ pageSize, pageNum, type, searchText }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Distribute);
