import React, { Component } from "react";

import { reactLocalStorage } from "reactjs-localstorage";
// import { NaverMap } from "react-naver-maps";
import {
  Form,
  Modal,
  Input,
  Button,

} from "antd";
import { httpPost, httpUrl, serverUrl } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";


const FormItem = Form.Item;
const ProductRegistry = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        name:"",
        price:"",
        pv:"",
        


      
      };
      
    }

  


    onRegistry = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        let createData = {
          ...values,
         
        };
      
        if (!err) {
          if (global.confirm("생성하시겠습니까?"))
            httpPost(httpUrl.productCreate, [], createData)
              .then((res) => {
                if (res.data) {
                  this.props.onOk();
                  form.resetFields();
                  showAlert("create");
                } else {
                  
                    alert("알수없는 오류로 실패하였습니다.");
                  
                }
              })
              .catch((error) => {
                alert("알수없는 오류로 실패하였습니다.");

              });
        }
      });
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
      
    };

    componentDidMount() {}

    render() {
      const {
      
      } = this.state;
      const { visible, form, defaultValue, color } = this.props;
      const { getFieldDecorator } = form;
      
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      

      return (
        <Modal
          visible={visible}
          title="상품등록"
          okText="등록"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "400px" }}>
         
         <Form {...formItemLayout} onSubmit={this.onRegistry} >
        
        <FormItem label={<span>상품명&nbsp;</span>}>
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "상품명을 입력해주세요!",
                
              },
            ]
            
          })(<Input placeholder="상품명을 입력하세요" />)}
        </FormItem>             
        <FormItem label={<span>소비자가&nbsp;</span>}>
          {getFieldDecorator("price", {
            rules: [
              {
                required: true,
                message: "소비자가를 입력해주세요!"
              },
              
            ]
  
          })(<Input placeholder="소비자가를 입력하세요" />)}
        </FormItem>       
        <FormItem label={<span>소비자PV &nbsp;</span>}>
          {getFieldDecorator("pv", {
            rules: [
              {
                required: true,
                message: "소비자PV를 입력해주세요!"
              },
              
            ]
  
          })(<Input placeholder="소비자PV를 입력하세요" />)}
        </FormItem>

        <FormItem label={<span>정회원가&nbsp;</span>}>
          {getFieldDecorator("priceReorder", {
            rules: [
              {
                required: true,
                message: "정회원가를 입력해주세요!"
              },
              
            ]
  
          })(<Input placeholder="정회원가를 입력하세요" />)}
        </FormItem>       
        <FormItem label={<span>정회원PV &nbsp;</span>}>
          {getFieldDecorator("pvReorder", {
            rules: [
              {
                required: true,
                message: "정회원PV를 입력해주세요!"
              },
              
            ]
  
          })(<Input placeholder="정회원PV를 입력하세요" />)}
        </FormItem>
            
             
          </Form>
        </Modal>
      );
    }
  }
);

export default ProductRegistry;


