import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Icon, Checkbox, InputNumber } from "antd";
import { httpPut, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
  coinMultiFormat
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import CoinSelectBox from "../../components/common/CoinSelectBox";

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;

class ArbitrageSettingCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  onCreate = (e) => {
    let {form} = this.props;
    
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log(JSON.stringify(values));
        if (global.confirm('등록하시겠습니까?')) {
          values.ratio = values.ratio / 100
          values.amountMin = coinMultiFormat(values.amountMin, values.coinType);
          values.amountMax = coinMultiFormat(values.amountMax, values.coinType);
          httpPost(httpUrl.arbitrageSettingCreate, [], values).then((res) => {
            if (res.data !== 0) {
              form.resetFields();
              this.props.onOk();
            } else {
              alert("알수없는 문제로 등록에 실패하였습니다..");
            }
          }).catch(()=>{
            alert("등록에 실패하였습니다. 이미 등록된 자전일 수 있습니다.");
          });

        }
      }
    });
  }
  render() {
    const { visible, onCancel, form } = this.props;
    const { getFieldDecorator } = form;
        
    let exceptCoinType = [3];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    return (
      <Modal
        visible={visible}
        title="자전설정 등록"
        okText="등록"
        cancelText="취소"
        onOk={this.onCreate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        <div>
          <div style={{paddingBottom: '10px'}}>
            최소주기와 최대주기 사이의 랜덤한 간격으로 교환을 자동등록합니다. <br/>
            최소수량과 최대수량 사이의 랜덤값으로 코인을 등록하며 <br/>해당 코인의 가치(시세반영)만큼의 교환대상 코인의 수량을 계산합니다.<br/>
            교환대상비율이 100%인 경우 계산된 수량으로 교환이 등록되며 <br/>150%인 경우 1.5배 만큼의 교환대상코인을 등록합니다.<br/>
            등록된 교환은 취소시간만큼 뒤에 자동취소됩니다.
          </div>
          <Form {...formItemLayout} onSubmit={this.onCreate}>
            <Descriptions
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
              <Ditems label="코인종류">
                <FormItem>
                  {getFieldDecorator("coinType", {
                    rules: [{ required: true, message: "자전으로 등록할 코인을 선택해주세요." }],
                  })(
                    <CoinSelectBox
                    placeholder="코인선택"
                    exceptCoinType={exceptCoinType}
                    style={{ width: "160px" }}
                  />
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="교환대상">
                <FormItem>
                  {getFieldDecorator("targetCoinType", {
                    rules: [{ required: true, message: "교환대상 코인을 선택해주세요." }],
                  })(
                    <CoinSelectBox
                    placeholder="코인선택"
                    exceptCoinType={exceptCoinType}
                    style={{ width: "160px" }}
                  />
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="최소주기(초)">
                <FormItem>
                {getFieldDecorator("intervalMin", {
                  rules: [{ required: true, message: "최소주기를 입력해주세요." }],
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="최대주기(초)">
                <FormItem>
                {getFieldDecorator("intervalMax", {
                  rules: [{ required: true, message: "최대주기를 입력해주세요." }],
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="최소수량">
                <FormItem>
                {getFieldDecorator("amountMin", {
                  rules: [{ required: true, message: "최소수량을 입력해주세요." }],
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="최대수량">
                <FormItem>
                {getFieldDecorator("amountMax", {
                  rules: [{ required: true, message: "최대수량을 입력해주세요." }],
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="교환대상비율(%)">
                <FormItem>
                {getFieldDecorator("ratio", {
                  rules: [{ required: true, message: "비율을 입력해주세요." }],
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="취소시간(n초뒤)">
                <FormItem>
                {getFieldDecorator("cancelInterval", {
                  rules: [{ required: true, message: "취소시간을 입력해주세요." }],
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              </Descriptions>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create()(ArbitrageSettingCreate)