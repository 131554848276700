import { DatePicker, Input, Table } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl } from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { amountFormat, formatDate, numberFormat } from "../../util";



const Search = Input.Search;
const { RangePicker } = DatePicker;

class ArbitrageLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logList: [],
      pagination: {
        total: 0,
        current: 1
      },
    };
  }

  componentDidMount() {
    this.getLogList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, ()=>this.getLogList());
  };

  initialLoad = () => {
    this.setState({
      logList: [],
      pagination: {
        total: 0,
        current: 1
      }}, () => {
        this.getLogList();
      })
  }
  reload = () => {
    this.getLogList();
  }
  getLogList = () => {
    let pageNum = this.state.pagination.current;
    httpGet(httpUrl.arbitrageLogList,[10,pageNum],{}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        logList: res.data.logs,
        pagination,
      });
    });
  };
  render() {
    const { logList } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    const columns = [
      {
        title: "",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이름",
        dataIndex: "userId",
        className: "support-column fs-15 lh-21",
      },
      {
        title: "교환상태",
        dataIndex: "exchangeStatus",
        className: "support-column fs-15 lh-21",
      },
      {
        title: "코인명",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{coinList.find((coin) => coin.coinType === data).name}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType) + ' ' + coinList.find((coin) => coin.coinType === row.coinType).symbol}</div>
      },
      {
        title: "금액",
        dataIndex: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "대상코인",
        dataIndex: "targetCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{coinList.find((coin) => coin.coinType === data).name}</div>
      },
      {
        title: "수량",
        dataIndex: "targetAmount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.targetCoinType) + ' ' + coinList.find((coin) => coin.coinType === row.targetCoinType).symbol}</div>
      },
      {
        title: "금액",
        dataIndex: "targetPrice",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "주기(초)",
        dataIndex: "interval",
        className: "support-column fs-15 lh-21",
      },
      {
        title: "비율(%)",
        dataIndex: "ratio",
        className: "support-column fs-15 lh-21",
        render: (data, row) => <div>{data*100}</div>
      },
      {
        title: "생성일",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "취소일",
        dataIndex: "cancelDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          자전이력
      </div>
      <div className="wrapper mt-35 width-1240" />
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.canceled ? "gray" : "")}
          dataSource={logList}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default ArbitrageLog;
