import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, Button,Divider } from "antd";

import { login } from "../../actions/loginAction";
import { getAdmin } from "../../contexts/asyncContext";
import { numberFormat, formatDate } from "../../util";
import string from "../../string";
import { httpPut, httpUrl, httpGet } from "../../api/httpClient";
import SelectBox from "./../../components/common/SelectBox";
import AuthDetail from "./../../components/system/AuthDetail";
import AdminRegistryModal from "./../../components/system/AdminRegistryModal";
import { showAlert } from "../../components/common/AlertModal";
import { DeleteOutlined, EditOutlined ,SafetyCertificateOutlined, SearchOutlined, UsergroupAddOutlined} from "@ant-design/icons";

const Search = Input.Search;

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      pagination: {
        total: 0,
        current: 0
      },
      registryModalVisible: false,
      selectedRow: "",
      selectData: {},
      prevData: {},

      type: "",
      authVisible: false,
      authIdx: 0,
      authList: [],
      authCoinList: [],
      isModify: false,
      userInfo: reactLocalStorage.getObject("adminUser")
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getAdmin({
      pageSize: 10,
      pageNum: pager.current,
      searchText: this.state.searchText
    });
  };

  onSearch = value => {
    this.setState(
      {
        searchText: value
      },
      () =>
        this.props.getAdmin({
          pageSize: 10,
          pageNum: 1,
          searchText: value
        })
    );
  };

  onCreate = () => {
    this.setState({
      registryModalVisible: false,
      searchText: ""
    });
    this.props.getAdmin({
      pageSize: 10,
      pageNum: 1,
      searchText: ""
    });
  };

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "idx") {
      data = row;
    } else if (type === "name") {
      data.name = value;
    } else if (type === "userId") {
      data.userId = value;
    } else if (type === "password") {
      data.password = value;
    } else if (type === "ipAddress") {
      data.ipAddress = value;
    } else if (type === "country") {
      data.country = value;
    } else if (type === "mobile") {
      data.mobile = value;
    } else if (type === "email") {
      data.email = value;
    } else {
      data.superAdmin = value;
    }
    this.setState({
      selectData: data
    });
  };

  onUpdate = () => {
    const { selectData, searchText, pagination } = this.state;
    const userInfo = reactLocalStorage.getObject("adminUser");

    let updateData = {
      idx: selectData.idx,
      name: selectData.name,
      userId: selectData.userId,
      password: selectData.password,
      ipAddress: selectData.ipAddress,
      superAdmin: selectData.superAdmin,
      mobile: selectData.mobile,
      email: selectData.email
    };
    // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
    if (global.confirm(`수정하시겠습니까?`)) {
      httpPut(httpUrl.adminUpdate, [], updateData)
        .then(result => {
          this.setState({
            selectedRow: "",
            selectData: {},
            isModify: false
          });
          showAlert("update");
          this.props.getAdmin({
            pageSize: 10,
            pageNum: pagination.current,
            searchText: searchText
          });
          if (updateData.idx === userInfo.idx) {
            delete updateData.password;
            let adminData = {
              ...userInfo,
              ...updateData
            };
            this.props.onLogin({
              ...adminData
            });
          }
        })
        .catch(error => {});
    }
  };

  onDelete = row => {
    const { searchText, pagination } = this.state;
    const userInfo = reactLocalStorage.getObject("adminUser");

    let pageNum =
      this.props.result.adminList.length === 1
        ? pagination.current === 1
          ? pagination.current
          : pagination.current - 1
        : pagination.current;

    if (row.idx === userInfo.idx) {
      alert("삭제가 불가합니다.");
    } else {
      if (global.confirm(row.userId + "을 삭제하시겠습니까?")) {
        httpPut(httpUrl.adminDelete, [row.idx], {})
          .then(result => {
            showAlert("delete");
            this.props.getAdmin({
              pageSize: 10,
              pageNum: pageNum,
              searchText: searchText
            });
          })
          .catch(error => {});
      }
    }
  };

  coinAuthList = (params = {}) => {
    let idx = 0;
    if (params === 0) {
      idx = 0;
    } else idx = params.idx;
    // console.log(idx);
    httpGet(httpUrl.coinAuthList, [idx], {})
      .then(result => {
        if (params !== 0) {
          this.setState({
            authIdx: idx,
            authList: result.data,
            authVisible: true
          });
        } else this.setState({ authCoinList: result.data });
      })
      .catch(error => {});
  };

  showAuth = (params = {}) => {
    let idx = 0;
    if (params === 0) {
      idx = 0;
    } else idx = params.idx;
    // console.log(idx);
    httpGet(httpUrl.authList, [idx], {})
      .then(result => {
        this.setState({ authList: result.data });
        if (params !== 0) {
          this.setState({
            authIdx: idx,
            authVisible: true
          });
        } else this.coinAuthList(0);
      })
      .catch(error => {});
  };

  onOk = () => {
    const pager = { ...this.state.pagination };
    this.setState(
      {
        authVisible: false,
        authList: []
      },
      () => {
        this.props.getAdmin({
          pageSize: 10,
          pageNum: pager.current,
          searchText: this.state.searchText
        });
      }
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getAdmin({ pageSize: 10, pageNum: 1, searchText: "" });
  }

  render() {
    const {
      selectedRow,
      selectData,
      prevData,
      isModify,
      userInfo
    } = this.state;

    const adminColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontSize:13}}>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        key: "createDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div key={`createDate${index}`} style={{fontSize:13}}>{formatDate(data)}</div>
        )
      },
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div style={{fontSize:13,textAlign:"left"}}>
            {selectedRow !== index ? (
              data
            ) : (
              <Input
                value={selectData.name}
                onChange={e => {
                  this.onChangeModal(e.target.value, "name");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "닉네임",
        dataIndex: "userId",
        key: "userId",
        // width: 80,
        className: "support-column-number fs-15 lh-21"
        // render: (data, row, index) => (
        //   <div>
        //     {selectedRow !== index ? (
        //       data
        //     ) : (
        //       <Input
        //         value={selectData.userId}
        //         onChange={e => {
        //           this.onChangeModal(e.target.value, "userId");
        //         }}
        //       />
        //     )}
        //   </div>
        // )
      },
      isModify
        ? {
            title: "비밀번호",
            dataIndex: "password",
            key: "password",
            // width: 80,
            className: "support-column-number fs-15 lh-21",
            render: (data, row, index) => (
              <div style={{fontSize:13}}>
                {selectedRow === index && (
                  <Input.Password
                    value={selectData.password}
                    placeholder="변경 시 작성"
                    onChange={e => {
                      this.onChangeModal(e.target.value, "password");
                    }}
                  />
                )}
              </div>
            )
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          },
      {
        title: "IP",
        dataIndex: "ipAddress",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div style={{fontSize:13}}>
            {selectedRow !== index ? (
              data
            ) : (
              <Input
                value={selectData.ipAddress}
                onChange={e => {
                  this.onChangeModal(e.target.value, "ipAddress");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "핸드폰번호",
        dataIndex: "mobile",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div style={{fontSize:13}}>
            {selectedRow !== index ? (
              data
            ) : (
              <Input
                style={{ width: "150px" }}
                value={selectData.mobile}
                maxLength={11}
                onChange={e => {
                  let value = e.target.value.replace(/[^0-9]+$/g, "");
                  this.onChangeModal(value, "mobile");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "이메일",
        dataIndex: "email",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div style={{fontSize:13}}>
            {selectedRow !== index ? (
              data
            ) : (
              <Input
                value={selectData.email}
                onChange={e => {
                  this.onChangeModal(e.target.value, "email");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "관리자 등급",
        dataIndex: "superAdmin",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div style={{fontSize:13}}>
            {/* {string.adminString[data]} */}
            {selectedRow !== index ? (
              string.adminString[string.toggleCode[data]]
            ) : (
              <SelectBox
                type="superAdmin"
                value={string.adminString[selectData.superAdmin]}
                code={string.toggleCode}
                codeString={string.adminString}
                onChange={this.onChangeModal}
              />
            )}
          </div>
        )
      },
      isModify
        ? {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
        : {
            title: "코인권한",
            dataIndex: "Coin Auth",
            key: "Coin Auth",
            // width: 80,
            className: "support-column-number fs-15 lh-21",
            render: (data, row, index) => (
              <div key={`Coin Auth${index}`} style={{fontSize:13}}>
                {selectedRow === index ? (
                  ""
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({ type: "coin" });
                      this.coinAuthList({ idx: row.idx });
                    }}>
                    코인권한<SafetyCertificateOutlined />
                  </Button>
                )}
              </div>
            )
          },
      isModify
        ? {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
        : {
            title: "메뉴권한",
            dataIndex: "Auth",
            key: "Auth",
            // width: 80,
            className: "support-column-number fs-15 lh-21",
            render: (data, row, index) => (
              <div key={`Auth${index}`} style={{fontSize:13}}>
                {selectedRow === index ? (
                  ""
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({ type: "auth" });
                      this.showAuth({ idx: row.idx });
                    }}>
                    메뉴권한<SafetyCertificateOutlined />
                  </Button>
                )}
              </div>
            )
          },
      isModify
        ? {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
        : {
            title: "상태",
            dataIndex: "deleted",
            className: "support-column-number fs-15 lh-21",
            render: (data, row, index) => (
              <div style={{fontSize:13}}>
                {selectedRow === index ? (
                  "-"
                ) : (
                  <Button
                    onClick={value => {
                      if (userInfo.superAdmin === 1 || row.idx === userInfo.idx)
                        this.onDelete(row);
                      else alert("본인이외는 삭제 할 수 없습니다");
                    }}>
                    삭제하기<DeleteOutlined />
                  </Button>
                )}
              </div>
            )
          },
      {
        title: "설정",
        dataIndex: "set",
        key: "set",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div style={{fontSize:13}}>
            {selectedRow !== index && (
              <Button
                onClick={() => {
                  if (userInfo.superAdmin === 1 || row.idx === userInfo.idx) {
                    this.setState({
                      selectedRow: index,
                      prevData: row,
                      isModify: true
                    });
                    this.onChangeModal(row.idx, "idx", row);
                    // console.log(JSON.stringify(row, null, 4));
                  } else {
                    alert("본인이외는 수정 할 수 없습니다");
                  }
                }}>
                수정하기 <EditOutlined/>
              </Button>
            )}
            {selectedRow === index && (
              <div style={{fontSize:13}}>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData,

                      isModify: false
                    });
                  }}
                />
              </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          <Divider orientation="left" style={{fontSize:23}}>사용자 설정  </Divider>
        </div>
        <div className="wrapper mt-35 width-1240" />
        {this.state.authVisible && (
          <AuthDetail
            type={this.state.type}
            modalData={this.state.authList}
            visible={this.state.authVisible}
            authIdx={this.state.authIdx}
            onOk={() => {
              this.onOk();
            }}
            onCancel={() => {
              this.setState({
                authVisible: false,
                authList: []
              });
            }}
          />
        )}

        <AdminRegistryModal
          type="admin"
          visible={this.state.registryModalVisible}
          modalData={this.state.authList}
          authCoinData={this.state.authCoinList}
          onCancel={() => {
            this.setState({
              registryModalVisible: false
            });
          }}
          onOk={this.onCreate}
        />
        {userInfo.superAdmin === 1 && (
          <div style={{ float: "left", marginBottom: "10px" }}>
            <Button
              onClick={() => {
                this.setState({ registryModalVisible: true });
                // this.coinAuthList(0);
                this.showAuth(0);
              }}>
              <UsergroupAddOutlined />생성
            </Button>
          </div>
        )}
        <div className="flex-row flex-center m-b-10" style={{ float: "right" }}>
          <label>검색기준&nbsp;</label>
          <Search
            placeholder="이름, 닉네임, ip주소를 입력하세요"
            enterButton={<SearchOutlined/>}
            allowClear
            onSearch={this.onSearch}
            style={{ width: "360px" }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="admin-table"
          rowKey={record => record.idx}
          columns={adminColumns}
          dataSource={this.props.result.adminList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData,
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getAdmin: ({ pageSize, pageNum, searchText }) =>
      dispatch(getAdmin({ pageSize, pageNum, searchText })),
    onLogin: userinfo => dispatch(login(userinfo))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Admin);
