import React, { Component } from "react";
import {
    SearchOutlined
  } from '@ant-design/icons';
import { Table, Input, Checkbox, Button,Icon ,Divider} from "antd";
import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";
import { numberFormat, formatDate, startDateFormat, endDateFormat } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import OrganizationRegistry from "../../components/payment/OrganizationRegistry";
import OrganizationDetail from "../../components/payment/OrganizationDetail";
import PaymentSetting from "../../components/payment/PaymentSetting";
import LowerOrganization from "../../components/payment/LowerOrganization";
import OrganizationCategory from "../../components/payment/OrganizationCategory";
import fileDownload from "js-file-download"
import OrganizationDetailRead from "../../components/payment/OrganizationDetailRead";

const Search = Input.Search;

class OrganizationRead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationList: [],
            pagination: {
                current: 1,
                total: 0
            },
            franchiseType: "ALL",
            deleted: false,
            searchText: "",
            startDate: "",
            endDATE: "",

            createVisible: false,
            detailVisible: false,
            detailData: {}
        };
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        this.getOrganiztionList({ pageNum: pager.current });
    };

    franchiseTypeHandleChange = (value) => {
        this.setState({
            franchiseType: value
        });
    };

    deleteHandleChange = (e) => {
        this.setState({
            deleted: e.target.checked
        });
    };

    onSearch = (value) => {
        this.setState(
            {
                searchText: value
            },
            () => {
                this.getOrganiztionList();
            }
        );
    };

    showDetail = (row) => {
        this.setState({
            detailVisible: true,
            detailData: row
        });
    };

    onDelete = (row) => {
        const { pagination, organizationList, deleted } = this.state;

        let pageNum =
            pagination.current > 1 && !deleted && row.deleted === 0
                ? organizationList.length === 1
                    ? pagination.current - 1
                    : pagination.current
                : pagination.current;
        if (
            global.confirm(
                `${row.name}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`
            )
        ) {
            httpPut(httpUrl.organizationDelete, [row.idx], {})
                .then((result) => {
                    this.getOrganiztionList({
                        pageNum: pageNum
                    });
                })
                .catch((error) => { });
        }
    };

    getOrganiztionList = (params = {}) => {
        const { deleted, franchiseType, searchText, startDate, endDate } = this.state;
        let pageNum = params.pageNum || 1;

        httpGet(
            httpUrl.getOrganiztionList,
            [10, pageNum, deleted, franchiseType, searchText],
            {}
        )
            .then((res) => {
                // console.log(JSON.stringify(res.data, null, 4));
                const pagination = { ...this.state.pagination };
                pagination.current = res.data.currentPage;
                pagination.total = res.data.totalCount;
                this.setState({
                    organizationList: res.data.list,
                    pagination
                });
            })
            .catch((error) => { });
    };

    componentDidMount() {
        this.getOrganiztionList();
    }

    downloadExcel = () => {



        if (this.state.isLoaded) {
            alert("조회버튼을 먼저 클릭해주세요.");
        } else {
            if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
                httpDownload(
                    httpUrl.getOrganizationListDownload,
                    [   
                        this.state.franchiseType,
                        this.state.searchText,
                        this.state.startDate,
                        this.state.endDate,

                    ],
                    {}
                )
                    .then((res) => {
                        fileDownload(res, `조직관리 조회.xlsx`);
                    })
                    .catch((err) => { });
            }
        }
    };

    render() {
        const {
            createVisible,
            detailVisible,
            paymentVisible,
            categoryVisible,
            lowerVisible,
            franchiseType,
            organizationList
        } = this.state;
        let classificationString = [
            '',
            '총판',
            '지사',
            '대리점',
            '가맹점',
        ]

        const columns = [
            {
                title: "",
                dataIndex: "idx",
                key: "idx",
                width: 80,
                className: "support-column-number fs-15 lh-21",
                render: (data) => <div style={{fontSize:13}}>{numberFormat(data)}</div>
            },
            {
                title: "등록일",
                dataIndex: "createDate",
                width: 120,
                className: "support-column-number fs-15 lh-21",
                render: (data) => <div style={{fontSize:13}}>{formatDate(data)}</div>
            },
            {
                title: "조직구분",
                dataIndex: "classification",
                // width: 80,
                className: "support-column-number fs-15 lh-21",
                render: (data) => {
                    return (
                        <div style={{fontSize:13}}>{classificationString[data]}</div>
                    );
                }
            },
            {
                title: "조직명",
                dataIndex: "name",
                // width: 80,
                className: "support-column-number fs-15 lh-21",
                render: (data, row, index) => {
                    return (
                        <div
                            key={index}
                            style={{ cursor: "pointer", color: "blue" ,fontSize:13}}
                            onClick={() => this.showDetail(row)}>
                            {data}
                        </div>
                    );
                }
            },
            {
                title:"가맹점주▪코드",
                dataIndex: "userName",
                className: "support-column-number fs-15 lh-21",
                render:(data,row) => {
                  return(
                    <div style={{fontSize:13}}>
                  {data}<div>{row.userRecommendCode}</div>
                  </div>
                  );
                 }
             },
            {
                title: "사업자번호",
                dataIndex: "businessNum",
                className: "support-column-number",
                render: (data) => <div style={{fontSize:13}}>{data ? data : "-"}</div>
            },
            {
                title: "유치자▪코드",
                dataIndex: "userName2",
                key: "userName2",
                // width: 80,
                className: "support-column-number fs-15 lh-21",
                render: (data,row) =>{
                  return(
                  <div style={{fontSize:13}}>{data ? data : "-"}<div>{row.userRecommender}</div></div>
                  )
                } 
              },
            {
                title: "상위 조직 1단계",
                dataIndex: "parentName",
                // width: 80,
                className: "support-column-number fs-15 lh-21",
                render: (data) => <div style={{fontSize:13}}>{data ? data : "-"}</div>
            },
            {
                title: "상위 조직 2단계",
                dataIndex: "parent2Name",
                // width: 80,
                className: "support-column-number fs-15 lh-21",
                render: (data) => <div style={{fontSize:13}}>{data ? data : "-"}</div>
            },
            {
                title: "하위 조직",
                dataIndex: "under",
                className: "support-column-number fs-15 lh-21",
                render: (data, row) => (
                    <Button
                        style={{ width: "60px" }}
                        onClick={() => {
                            this.setState({
                                lowerVisible: true,
                                organizationIdx: row.idx
                            });
                        }}>
                        보기
                    </Button>
                )
            },
        ];

        return (
            <div>
                {createVisible && (
                    <OrganizationRegistry
                        visible={createVisible}
                        onOk={() => {
                            this.setState({
                                createVisible: false
                            });
                            this.getOrganiztionList();
                        }}
                        onCancel={() => {
                            this.setState({
                                createVisible: false
                            });
                        }}
                    />
                )}
                {detailVisible && (
                    <OrganizationDetailRead
                        modalData={this.state.detailData}
                        visible={detailVisible}
                        onOk={() => {
                            this.setState({
                                detailVisible: false
                            });
                            this.getOrganiztionList({
                                pageNum: this.state.pagination.current
                            });
                        }}
                        onCancel={() => {
                            this.setState({
                                detailVisible: false
                            });
                        }}
                    />
                )}
                {paymentVisible && (
                    <PaymentSetting
                        organizationIdx={this.state.organizationIdx}
                        visible={paymentVisible}
                        onCancel={() => {
                            this.setState({
                                paymentVisible: false
                            });
                        }}
                    />
                )}
                {categoryVisible && (
                    <OrganizationCategory
                        organizationIdx={this.state.organizationIdx}
                        visible={categoryVisible}
                        type={false}
                        onCancel={() => {
                            this.setState({
                                categoryVisible: false
                            });
                        }}
                    />
                )}
                {lowerVisible && (
                    <LowerOrganization
                        organizationIdx={this.state.organizationIdx}
                        visible={lowerVisible}
                        onCancel={() => {
                            this.setState({
                                lowerVisible: false
                            });
                        }}
                    />
                )}
                <div
                    style={{
                        fontSize: "20px",
                        color: "#000",
                        textAlign: "center",
                        marginBottom: "30px",
                        fontWeight: "bold"
                    }}>
                  <Divider orientation="left" style={{fontSize:23}}>조직관리 보기  </Divider>
        </div>
                <div className="flex-row flex-center flex-sb m-b-10">


                    <Button onClick={this.downloadExcel}>
                        <Icon type="download" style={{ fontSize: "20px" }} />엑셀 다운로드</Button>
                    <div className="flex-row flex-center">
                        <label htmlFor="">
                           &nbsp;&nbsp;  
                <Checkbox onChange={this.deleteHandleChange}>삭제 포함</Checkbox>
              <SelectBox
                                value={string.organizationString[franchiseType]}
                                code={string.organizationCode}
                                codeString={string.organizationString}
                                onChange={(value) => {
                                    if (value !== franchiseType)
                                        this.franchiseTypeHandleChange(value);
                                }}
                                style={{ width: "80px" }}
                            />
                        </label>

          
                        <Search
                            placeholder="조직명, 사업자번호를 입력해주세요."
                            enterButton={<SearchOutlined/>}
                            allowClear
                            onSearch={this.onSearch}
                            style={{ width: 400 }}
                        />

                    </div>

               
                </div>

                <Table
                    id="member-log-table"
                    rowKey={(record) => record.idx}
                    columns={columns}
                    dataSource={organizationList}
                    style={{ marginBottom: "10px" }}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                />
            </div>
        );
    }
}

export default OrganizationRead;
