import { Button, DatePicker, Input, Table } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { StakingBonusModify, StakingBonusRegist, StakingCoinModify, StakingCoinRegist } from "..";
import { httpGet, httpPost, httpUrl } from "../../api/httpClient";
import { formatDate } from "../../util";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class StakingBonusList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stakingCoinList: [],
      stakingBonusList: [],
      stakingBonusRegistVisible: false,
      stakingBonusModifyVisible: false,
      selectedRow: null,
    };
  }

  componentDidMount() {
    this.getCoinList();
    this.getBonusList();
  }
  getCoinList = () => {
    httpGet(httpUrl.stakingCoinList,[],{}).then((res) => {
      this.setState({
        stakingCoinList: res.data,
      });
    });
  };
  getBonusList = () => {
    httpGet(httpUrl.stakingBonusList,[],{}).then((res) => {
      this.setState({
        stakingBonusList: res.data,
      });
    });
  };
  deleteBonus = (coinType) => {
    if (global.confirm("코인타입 "+coinType+"의 스테이킹 이자 지급을 삭제하시겠습니까??")) {
      httpPost(httpUrl.stakingBonusDelete, [coinType, 0], {}).then((res) => {
        if (res.data) {
          this.getBonusList();
        } else {
          alert("알수없는 문제로 삭제에 실패하였습니다..");
        }
      });
    }
  }
  render() {
    const { stakingBonusList } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const columns = [
      {
        title: "스테이킹코인번호",
        dataIndex: "coinType",
        key: "coinType",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "스테이킹코인이름",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (<div style={{cursor: 'pointer', color: 'blue'}} onClick={()=>{
          this.setState({
            selectedRow:row,
            selectedCoinName:coinList.find((coin) => coin.coinType === data).name,
            stakingBonusModifyVisible: true
          });
        }}>{coinList.find((coin) => coin.coinType === data).name}</div>)
      },
      {
        title: "이자지급코인",
        dataIndex: "bonusCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (coinList.find((coin) => coin.coinType === data).name),
      },
      {
        title: "이자율(매일)",
        dataIndex: "ratio",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data*100+'%'),
      },
      {
        title: "이자락",
        dataIndex: "lockDate",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{row.useLock ? formatDate(data) : '-'}</div>
      },
      {
        title: "생성일",
        dataIndex: "createDate",
        key: "createDate",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "삭제",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => {
                this.deleteBonus(data);
              }}>
              삭제
            </Button>
          </div>
        ),
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          스테이킹 이자설정
      </div>
        <div className="wrapper mt-35 width-1240" />
        <div>
          <Button
            onClick={() => {
              this.setState({ stakingBonusRegistVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
        </div>
        <Table
          rowKey={(record) => record.coinType}
          dataSource={stakingBonusList}
          columns={columns}
        />
        <StakingBonusRegist
          visible={this.state.stakingBonusRegistVisible}
          stakingCoinList={this.state.stakingCoinList}
          stakingBonusList={this.state.stakingBonusList}
          onCancel={() => {
            this.setState({ stakingBonusRegistVisible: false });
          }}
          onOk={() => {
            this.setState({ stakingBonusRegistVisible: false });
            this.getBonusList();
          }}
        />
        <StakingBonusModify
          stakingBonus={this.state.selectedRow}
          stakingCoinName={this.state.selectedCoinName}
          visible={this.state.stakingBonusModifyVisible}
          onCancel={() => {
            this.setState({ stakingBonusModifyVisible: false });
          }}
          onOk={() => {
            this.setState({ stakingBonusModifyVisible: false });
            this.getBonusList();
          }}
        />
      </div>
    );
  }
}

export default StakingBonusList;
