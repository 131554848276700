import { DatePicker, Input, Table } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl } from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { amountFormat, formatDate } from "../../util";



const Search = Input.Search;
const { RangePicker } = DatePicker;

class StakingInterest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchType: string.defaultSearchTypeCode[0],
      searchText: '',
      searchCoinType: 101,
      stakingInterestList: [],
      pagination: {
        total: 0,
        current: 1
      },
    };
  }

  componentDidMount() {
    this.getStakingInterestList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, ()=>this.getStakingInterestList());
  };

  initialLoad = () => {
    this.setState({
      stakingInterestList: [],
      pagination: {
        total: 0,
        current: 1
      }}, () => {
        this.getStakingInterestList();
      })
  }
  reload = () => {
    this.getStakingInterestList();
  }
  getStakingInterestList = () => {
    let pageNum = this.state.pagination.current;
    let userId = '';
    let userName = '';
    let userTel = '';
    if (this.state.searchType == 'ID') userId = this.state.searchText;
    else if (this.state.searchType == 'NAME') userName = this.state.searchText;
    else if (this.state.searchType == 'PHONE') userTel = this.state.searchText;
    httpGet(httpUrl.stakingBonusLog,[10,pageNum,this.state.searchCoinType,userId, userName, userTel],{}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        stakingInterestList: res.data.bonusLogs,
        pagination,
      });
    });
  };
  render() {
    const { stakingInterestList } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    const stakeTypeString = {
      STAKE: '스테이킹',
      UNSTAKE: '언스테이킹',
    }
    const columns = [
      {
        title: "지급일",
        dataIndex: "logDate",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "아이디",
        dataIndex: "userId",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이름",
        dataIndex: "userName",
        className: "support-column fs-15 lh-21",
      },
      {
        title: "스테이킹코인",
        dataIndex: "coinType",
        className: "support-column fs-15 lh-21",
        render: (data, row) => (coinList.find((coin) => coin.coinType === data).name)
      },
      {
        title: "스테이킹수량",
        dataIndex: "stakeAmount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType) + ' ' + coinList.find((coin) => coin.coinType === row.coinType).symbol}</div>
      },
      {
        title: "이자지급코인",
        dataIndex: "bonusCoinType",
        className: "support-column fs-15 lh-21",
        render: (data, row) => (coinList.find((coin) => coin.coinType === data).name)
      },
      {
        title: "이자지급수량",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType) + ' ' + coinList.find((coin) => coin.coinType === row.bonusCoinType).symbol}</div>
      },
      {
        title: "생성일",
        dataIndex: "createDate",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          이자지급내역
      </div>
        <div className="wrapper mt-35 width-1240" />
          <div className="flex-row flex-center" style={{paddingBottom: '10px'}}>
            <label htmlFor="" style={{ marginRight: "20px" }}>
              코인{" "}
              <CoinSelectBox
                placeholder="선택해주세요."
                value={this.state.searchCoinType}
                exceptERC
                onChange={(value) => {
                  this.setState({searchCoinType: value})
                }}
                style={{ width: "160px" }}
              />
            </label>
            <label htmlFor="searchType">검색 대상&nbsp;</label>
            <SelectBox
              value={this.state.searchType}
              code={string.defaultSearchTypeCode}
              codeString={string.defaultSearchTypeString}
              onChange={(value) => {
                  this.setState({
                    searchType: value,
                  });
              }}
            />
            <Search
              placeholder={`검색어를 입력해주세요.`}
              enterButton="검색"
              allowClear
              onSearch={(value) => {
                this.setState({searchText: value}, ()=>this.reload())
              }}
              style={{ width: 400 }}
            />
          </div>
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={stakingInterestList}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default StakingInterest;
