import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, InputNumber, Descriptions, Upload, Button, Icon } from "antd";
import { httpPut, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from 'moment';
import CoinSelectBox from "../../components/common/CoinSelectBox";

const FormItem = Form.Item;
const Ditems = Descriptions.Item;

class EventReward extends Component {

  constructor(props) {
    super(props)
    this.state = {
      uploadFile: null,
      uploading: false,
    }
  }
  componentDidMount() {
  }
  onReward = (e) => {
    let { form } = this.props;
    const { uploadFile } = this.state;
    const { data } = this.props;

    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {

        if (uploadFile == null) {
          alert('대상자 엑셀파일을 업로드해주세요.');
          return;
        }

        let title = {};
        global.languages.map((value, index) => {
          title[value.code.toLowerCase()] = values['title' + value.code];
        });

        const formData = new FormData();

        formData.append('file', uploadFile);
        formData.append('request', JSON.stringify({
          eventIdx: data.idx,
          userIdx: 0,
          coinType: values.coinType,
          amount: values.amount * 1000000000,
          title: title
        }));
        console.log({
          eventIdx: data.idx,
          userIdx: 0,
          coinType: values.coinType,
          amount: values.amount * 1000000000,
          title: title
        })
        httpPost(httpUrl.eventRewardGiveExcel, [], formData).then((res) => {
          res = res.data;
          if (res.result == "SUCCESS") {
            alert("총 "+res.totalCount+"건 중 "+(res.totalCount-res.failCount)+"건 성공, "+res.failCount+"건 실패하였습니다. (실패사용자ID: "+res.failUserIds+")");
            
            form.resetFields();
            this.setState({uploadFile: null})
            this.props.onOk();
          }
          else if (res.result == "INVALID_FILE") {
            alert("엑셀파일을 업로드해주세요.");
          }
        });
      }
    });
  }
  render() {
    const { visible, onCancel, form, data } = this.props;
    const { getFieldDecorator } = form;
    const { uploadFile, uploading } = this.state;
    const uploadProps = {
      onRemove: file => {
        this.setState({ uploadFile: null });
      },
      beforeUpload: file => {
        this.setState({ uploadFile: file });
        return false;
      },
      fileList: uploadFile ? [uploadFile] : [],
    };
    return (
      <Modal
        visible={visible}
        title="이벤트 보상지급"
        okText="지급"
        cancelText="취소"
        onOk={this.onReward}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        {data != null && (

          <Form onSubmit={this.onReward}>
            <Descriptions
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>

              <Ditems label="이벤트" span={2}>{data.title}</Ditems>

              <Ditems label="지급코인" >
                <FormItem>
                  {getFieldDecorator("coinType", {
                    rules: [{ required: true, message: "지급할 코인을 선택해주세요." }],
                  })(
                    <CoinSelectBox
                    placeholder="코인선택"
                    style={{ width: "160px" }}
                  />
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="지급수량" >
                (엑셀파일에 기재해주세요)
                {/* <FormItem className="inline-formitem m-b-0">
                  {getFieldDecorator("amount", {
                    rules: [
                      {
                        required: true,
                        message: "수량을 입력해주세요!"
                      },
                      {
                        validator: (rule, value, cb) => value > 0,
                        message: "수량은 0보다 커야 합니다"
                      }
                    ]
                  })(
                    <InputNumber
                      min={0}
                      formatter={value =>
                        `${value}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )
                      }
                      parser={value =>
                        value.replace(/\$\s?|(,*)/g, "")
                      }
                      style={{ width: "200px" }}
                    />
                  )}
                </FormItem> */}
              </Ditems>

              {global.languages.map((value, index) => {
                return (
                  <>
                    <Ditems label={"지급메시지(" + value.name + ")"} span={2}>
                      <FormItem>
                        {getFieldDecorator("title" + value.code, {
                          rules: [{ required: value.code == "Ko", message: "제목을 입력해 주세요" }],
                        })(<Input />)}
                      </FormItem>
                    </Ditems>
                  </>
                )
              })}

              <Ditems label="대상자" >
                <Upload {...uploadProps}>
                  <Button>
                    <Icon type="upload" /> 엑셀파일
                  </Button>
                </Upload>
              </Ditems>

              <Ditems label="대상자 샘플">
                  <a href="/reward_templete.xlsx" download>
                    <Button >
                      <Icon type="download" /> 양식다운로드
                    </Button>
                  </a>
              </Ditems>

            </Descriptions>
          </Form>
        )}
      </Modal>
    );
  }
}

export default Form.create()(EventReward)