import React, { Component } from "react";
import {
  SearchOutlined,ShopOutlined ,SelectOutlined,RollbackOutlined
} from '@ant-design/icons';
import { reactLocalStorage } from "reactjs-localstorage";
import locale from "antd/es/date-picker/locale/ko_KR";
import {
  CheckCircleOutlined
} from '@ant-design/icons';
import { Table, Button, Select, DatePicker, Checkbox, Icon, Input, message,Divider} from "antd";
import { httpGet, httpUrl, httpDownload,httpPost, httpPut } from "../../api/httpClient";
import {
  numberFormat,
  formatDate,
  startDateFormat,
  endDateFormat,
  amountFormat,
} from "../../util";
import OrganizationSelectModal from "../../components/adjustment/OrganizationSelectModal";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import fileDownload from "js-file-download";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const Search = Input.Search;
const info = () => {
  message.info('This is a normal message');
};

class PaymentLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coinList: [],
      logList: [],
      pagination: {
        current: 1,
        total: 0,
      },
      organizationIdx: 0,
      organizationName: "",
      canceled: true,
      coinType: 0,
      orderByType: "DESC",
      searchText: "",
      searchType: "USERNAME",
      startDate: "",
      endDate: "",
      isLoaded: true,
    };
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getPaymentLogList({

      pageNum: pager.current,

    });
  };

  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true,
    });
  };

  handleChangeOrderByType = (value) => {
    this.setState({
      orderByType: value,
      isLoaded: true,
    });
  };

  handelChageCanceled = (e) => {
    this.setState({
      canceled: e.target.checked,
    });
  };

  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true,
    });
  };

  downloadExcel = () => {
    const {
      canceled,
      orderByType,
      startDate,
      endDate,
      organizationIdx,
      organizationName,
      coinType,
      pagination,
      isLoaded,
      searchText,
      searchType,
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    let result = [];
    let title = "결제내역 조회";

    if (startDate !== "" && endDate !== "") {
      result.push(`${startDate} ~ ${endDate} `);
    }

    if (organizationIdx !== 0) {
      result.push(`${organizationName} `);
    }

    if (coinType !== 0) {
      result.push(
        `${coinList.find((coin) => coin.coinType === coinType).name} `
      );
    }

    if (canceled) {
      result.push(`(결제 취소 내역 포함) `);
    }

    let total = pagination.total <= 9 ? 10 : pagination.total;

    title =
      result.reduce((acc, item, index) => {
        return acc + item;
      }, "") + title;

    // console.log(`title = ${title}`);
    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(httpUrl.getPaymentLogExcel, [
          total,
          1,
          canceled,
          organizationIdx,
          coinType,
          orderByType,
          startDate,
          endDate,
          searchText
        ])
          .then((res) => {
            // console.log(JSON.stringify(res, null, 4));
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((error) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };
  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getPaymentLogList({});
      }
    );
  };

  cancelPayment = (idx) => {
    
          
            // alert(JSON.stringify(idx))
            httpPost(httpUrl.paymentCancel, [], { paymentIdx: idx }).then((result) => {
              result = result.data;
              if (result == "SUCCESS") {
                this.getPaymentLogList({
                  pageNum: this.state.pagination.current,
                  
                });
             
                // this.state.tables.find(x => x.idx == rowData.idx).canceled = 1
                // this.setState({ tables: this.state.tables });
                // this.props.requestBalance(rowData.coinType);
              }
              else if (result == "INSUFFICIENT_USER_BALANCE") {
                alert(string.paymentCancelInsufficientUserBalance);
              }
              else if (result == "INSUFFICIENT_ORGANIZATION_BALANCE") {
                alert(string.paymentCancelInsufficientOrganizationBalance);

              }
              else if (result == "INVALID_PAYMENTIDX") {
                alert(string.paymentCancelInvalidPaymentIdx);

              }
              else if (result == "NOT_ORGANIZATION") {
                alert(string.paymentCancelNotOrganization);

              }
              else {
                alert(string.paymentCancelFail);

              }

            }).catch(() => {
            });
          }
      
 

  getPaymentLogList = (params = {}) => {
    const {

      canceled,
      organizationIdx,
      coinType,
      orderByType,
      startDate,
      endDate,
      searchText,
      searchType,
    } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getPaymentLogList,
      [
        10,
        pageNum,
        canceled,
        organizationIdx,
        coinType,
        orderByType,
        startDate,
        endDate,
        searchText,
        searchType,
      ],
      {}
    )
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          logList: res.data.list,
          pagination,
        });
      })
      .catch((error) => { });
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");

    this.setState(
      {
        coinList: [
          {
            coinType: 0,
            name: "전체",
          },
          ...coinList,
        ],
      },
      () => {
        this.getPaymentLogList();
      }
    );
  }

  



  render() {
    const { coinList, logList} = this.state;
    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{numberFormat(data)}</div>,
      },
      {
        title: "생성일",
        dataIndex: "createDate",
        key: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{formatDate(data)}</div>,
      },
      {
        title: "조직명",
        dataIndex: "organizationName",
        key: "organizationName",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13,textAlign:"left"}}>{data}</div>
      },
      {
        title: "결제자(번호.이름.ID)",
        dataIndex: "userIdx2",
        key: "userIdx2",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:13,textAlign:"left"}}>
           <div><span style={{fontSize:12.5}}>({data})</span> {row.userName}</div><div>{row.userId}</div>
          </div>
        ),
      },
      {
        title: "결제 방법",
        dataIndex: "payType",
        key: "payType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div style={{fontSize:13}}>{string.payType[data]}</div>,
       
      },      
      {
        title: "대납여부",
        dataIndex: "commission",
        key: "commission",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => {
        if ((amountFormat(data, row.payCoinType)) == 0) return (<div>{"-"}</div>);
        else return (<div style={{fontSize:13}}>{"O"}</div>)
        },
      },
      {
        title: "결제전잔액",
        dataIndex: "preBalance",
        key: "preBalance",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div style={{fontSize:13}}>{(amountFormat(data,row.coinType)+'').split('.')[0]}</div>,       
      },
      {
        title: "",
        key: "fromCoinType",
        dataIndex: "fromCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
            <div style={{fontSize:12.5}}>
              
            </div>
          ) 
      },
      {
        title: "결제금액",
        dataIndex: "price",
        key: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div style={{fontSize:12.5 ,textAlign:"right"}}>{numberFormat(data)}</div>,
      },
      {
        title: "",
        key: "fromCoinType",
        dataIndex: "fromCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
            <div style={{fontSize:12.5}}>
              
            </div>
          ) 
      },
      {
        title: "결제후잔액",
        dataIndex: "postBalance",
        key: "postBalance",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div style={{fontSize:13}}>{(amountFormat(data,row.coinType)+'').split('.')[0]}</div>,
        
      },
      {
        title: "수수료",
        dataIndex:"commission",
        key:"commision",
        className: "support-column-number fs-15 lh-21",
        render:(data,row) => {
          if ((amountFormat(data, row.coinType)) == 0) return (<div>{"-"}</div>);
          else return (<div style={{fontSize:13}}>{(amountFormat(data,row.coinType))}</div>)
        },
      },
      {
        title: "취소여부",
        dataIndex: "canceled",
        key: "canceled",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{data ? "O" : "-"}</div>,
      },
      {
        title: "취소날짜",
        dataIndex: "cancelDate",
        key: "cancelDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{data ? formatDate(data) : "-"}</div>,
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        className: "support-column-number fs-15 lh-21",
        render: (row) => <div>
           {row.canceled === 0 ?
     
          <Button
          style={{fontSize:13}}  
          onClick={() => {        
           if(global.confirm(row.organizationName+" "+'결제금액 '+ numberFormat(row.price)+ '원 취소 처리하시겠습니까?')){
            // alert("준비중 입니다.")
            this.cancelPayment(row.idx);
            alert("처리완료");
            
         
         
        }}}>
         <span style={{fontSize:12}}></span>취소<RollbackOutlined/>
        </Button> : <div /> }
               
        
        </div>,
      },
    ];

    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          title: "코인 종류",
          dataIndex: "coinType",
          key: "coinType",
          className: "support-column-number fs-15 lh-21",
          render: (data) => (
            <div style={{fontSize:13}}>
              {coinList.find((coin) => coin.coinType === data)
                ? coinList.find((coin) => coin.coinType === data).name
                : ""}
            </div>
          ),
        },
        {
          title: "코인 수량",
          dataIndex: "amount",
          key: "amount",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => <div style={{fontSize:13}}>{amountFormat(data, row.coinType)}</div>,
        },
        {
          title: "코인 단가",
          dataIndex: "unitPrice",
          key: "unitPrice",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => <div style={{fontSize:13}}>{numberFormat(data)}</div>,
        },
        {
          title: "실지급 코인 종류",
          dataIndex: "payCoinType",
          key: "payCoinType",
          className: "support-column-number fs-15 lh-21",
          render: (data) => (
            <div style={{fontSize:13}}>
              {coinList.find((coin) => coin.coinType === data)
                ? coinList.find((coin) => coin.coinType === data).name
                : ""}
            </div>
          ),
        },
        {
          title: "실지급 코인 수량",
          dataIndex: "payAmount",
          key: "payAmount",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => (
            <div style={{fontSize:13}}>{amountFormat(data, row.payCoinType)}</div>
          ),
        },
        {
          title: "페이백 코인 종류",
          dataIndex: "paybackCoinType",
          key: "paybackCoinType",
          className: "support-column-number fs-15 lh-21",
          render: (data) => (
            <div style={{fontSize:13}}>
              {coinList.find((coin) => coin.coinType === data)
                ? coinList.find((coin) => coin.coinType === data).name
                : "-"}
            </div>
          ),
        },
        {
          title: "페이백 조건",
          dataIndex: "paybackMethod",
          key: "paybackMethod",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => (
            <div style={{fontSize:13}}>{data ? string.paybackTypeString[data] : "-"}</div>
          ),
        },
        {
          title: "페이백 비율",
          dataIndex: "paybackRatio",
          key: "paybackRatio",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => (
            <div style={{fontSize:13}}>{data ? `${numberFormat(data)}` : "-"}</div>
          ),
        },
        {
          title: "페이백 금액",
          dataIndex: "paybackAmount",
          key: "paybackAmount",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => (
            <div style={{fontSize:13}}>{data ? amountFormat(data, row.paybackCoinType) : "-"}</div>
          ),
        },
      ];
      return (
        <Table
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={[record]}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "60px",
            fontWeight: "bold",
          }}>
             <Divider orientation="left" style={{fontSize:23}}>결제내역</Divider>
        </div>
        {this.state.modalVisible && (
          <OrganizationSelectModal
            visible={this.state.modalVisible}
            onOk={(idx, name) => {
              this.setState({
                organizationIdx: idx,
                organizationName: name,
                isLoaded: true,
              });
            }}
            onCancel={() => {
              this.setState({
                modalVisible: false,
              });
            }}
          />
        )}
       
       <div className="flex-row flex-center flex-sb m-b-10">
        <div className="flex-row flex-center">
            
              <Button
              
                className="m-r-10"
                onClick={() => {
                  this.setState({
                    modalVisible: true,
                  });
                }}>
                <SelectOutlined />조직 선택
              </Button>
        
            {this.state.organizationIdx !== 0 && (
              <div className="flex-row flex-center">
                <div>{`가맹점: ${this.state.organizationName}`}</div>
                <Button
                  type="link"
                  onClick={() => {
                    this.setState({
                      organizationIdx: 0,
                    }); 
                  }}>
                  <Icon type="close" />
                </Button>
              </div>
            )}
          <Button onClick={this.downloadExcel}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
          </div>
         
            <div className="flex-row flex-center">
            </div>
            <div className="flex-row flex-center">
              기간&nbsp;
            <RangePicker
              // placeholder="시작일"
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
              
              <label style={{marginLeft:10}}>순서&nbsp;</label>
              <SelectBox
                value={string.orderByString[this.state.orderByType]}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== this.state.orderByType)
                    this.handleChangeOrderByType(value);
                }}
                style={{ width: "80px" }}
              />
           
           <label style={{marginLeft:10}}>코인종류&nbsp;</label>
              <Select
                placeholder="코인종류"
                style={{ width: "160px"  }}
                value={this.state.coinType}
                onChange={this.handleChangeCoinType}>
                {coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
          
            {/* <Checkbox onChange={this.handelChageCanceled}>취소 포함</Checkbox> */}
          


            {/* <label htmlFor="searchType" style={{ marginLeft: '10px' }}></label> */}
           
          

            <Search
              placeholder={`이름을 입력해주세요.`}
              enterButton={<SearchOutlined/>}
              allowClear
              onSearch={this.onSearch}
              style={{ width: 300}}
            />
          </div>
    
          </div>
          
      
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={logList}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default PaymentLog;
