import React, { Component } from "react";
import {
  SearchOutlined,PlusOutlined,SettingOutlined,ToolOutlined,EyeOutlined
} from '@ant-design/icons';
import { Table, Input, Checkbox, Button, Tooltip ,Icon,Divider} from "antd";
import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";
import { numberFormat, formatDate, startDateFormat, endDateFormat } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import OrganizationRegistry from "../../components/payment/OrganizationRegistry";
import OrganizationDetail from "../../components/payment/OrganizationDetail";
import AccountRegistry from "../../components/payment/AccountRegistry";
import PaymentSetting from "../../components/payment/PaymentSetting";
import LowerOrganization from "../../components/payment/LowerOrganization";
import OrganizationCategory from "../../components/payment/OrganizationCategory";
import fileDownload from "js-file-download"

const Search = Input.Search;

class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationList: [],
      pagination: {
        current: 1,
        total: 0
      },
      franchiseType: "ALL",
      deleted: false,
      searchText: "",
      startDate: "",
      endDATE: "",
      isLoaded: true,

      accountVisible: false,
      createVisible: false,
      detailVisible: false,
      detailData: {}
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getOrganiztionList({ pageNum: pager.current });
  };

  franchiseTypeHandleChange = (value) => {
    this.setState({
      franchiseType: value
    });
  };

  deleteHandleChange = (e) => {
    this.setState({
      deleted: e.target.checked
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getOrganiztionList();
      }
    );
  };

  showDetail = (row) => {
    this.setState({
      detailVisible: true,
      detailData: row
    });
  };

  onDelete = (row) => {
    const { pagination, organizationList, deleted } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && row.deleted === 0
        ? organizationList.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;
    if (
      global.confirm(
        `${row.name}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.organizationDelete, [row.idx], {})
        .then((result) => {
          this.getOrganiztionList({
            pageNum: pageNum
          });
        })
        .catch((error) => { });
    }
  };

  getOrganiztionList = (params = {}) => {
    const { deleted, franchiseType, searchText, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getOrganiztionList,
      [10, pageNum, deleted, franchiseType, searchText],
      {}
    )
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          organizationList: res.data.list,
          pagination
        });
      })
      .catch((error) => { });
  };

  componentDidMount() {
    this.getOrganiztionList();
  }

  downloadExcel = () => {



    if (this.state.isLoaded) {
      alert("검색버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.getOrganizationListDownload,
          [
            this.state.franchiseType,
            this.state.searchText,
            this.state.startDate,
            this.state.endDate,

          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `조직관리 조회.xlsx`);
          })
          .catch((err) => { });
      }
    }
  };

  render() {
    const {
      accountVisible,
      createVisible,
      detailVisible,
      paymentVisible,
      categoryVisible,
      lowerVisible,
      franchiseType,
      organizationList
    } = this.state;
    let classificationString = [
      '',
      '총판',
      '지사',
      '대리점',
      '가맹점',
    ]

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        width: 130,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{formatDate(data)}</div>
      },
      {
        title: "조직구분",
        dataIndex: "classification",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => {
          return (
            <div style={{fontSize:13}}>{classificationString[data]}</div>
          );
        }
      },
      {
        title: "페이백 비율",
        dataIndex: "paybackRatio",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => {
          return (
            <div style={{fontSize:13}}>{data !== null ? data * 100+"%" : data}</div>
          );
        }
      },
      {
        title: "조직명",
        dataIndex: "name",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => {
          return (
            <div
              key={index}
              style={{ cursor: "pointer", color: "blue" , fontSize:13}}
              onClick={() => this.showDetail(row)}>
              {data}
            </div>
          );
        }
      },
      // {
      //   title: "유치한 총판 수",
      //   dataIndex: "chongpan",
      //   // width: 80,
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data) => {
      //     return (
      //       <div>
      //         {data}
      //       </div>
      //     );
      //   }
      // },
      // {
      //   title: "유치한 지사 수",
      //   dataIndex: "jisa",
      //   // width: 80,
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data) => {
      //     return (
      //       <div>
      //         {data}
      //       </div>
      //     );
      //   }
      // },
      // {
      //   title: "유치한 대리점 수",
      //   dataIndex: "derijum",
      //   // width: 80,
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data) => {
      //     return (
      //       <div>
      //         {data}
      //       </div>
      //     );
      //   }
      // },
      {
        title: "",
        dataIndex: "",
        className:"upport-column-number fs-15 lh-21",
        render: () => {

        }
      },
      {
         title:"가맹점주▪코드",
         dataIndex: "userName",
         className: "support-column-number fs-15 lh-21",
         render:(data,row) => {
           return(
             <div style={{fontSize:13}}>
           {data}<div style={{fontSize:13}}>{row.userRecommendCode}</div>
           </div>
           );
          }
      },
      {
        title: "업종",
        dataIndex: "sector",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{(data !== null || data !== "") && (
          <Tooltip title={data} placement="left">
            <div className="text-ellipsis m-r-10">{data ? data : "-"}</div>
          </Tooltip>
        )}</div>
      },
      {
        title: "사업자번호",
        dataIndex: "businessNum",
        className: "support-column-number",
        render: (data) => <div style={{fontSize:13}}>{data ? data : "-"}</div>
      },
      {
        title: "유치자▪코드",
        dataIndex: "userName2",
        key: "userName2",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data,row) =>{
          return(
          <div style={{fontSize:13}}>{data ? data : "-"}<div style={{fontSize:13}}>{row.userRecommender}</div></div>
          )
        } 
      },
      {
        title: "상위 조직 1단계",
        dataIndex: "parentName",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{data ? data : "-"}</div>
      },
      {
        title: "상위 조직 2단계",
        dataIndex: "parent2Name",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{data ? data : "-"}</div>
      },
      {
        title: "결제 및 페이백",
        dataIndex: "payment",
        // width: 130,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          row.franchise ? (
            <Button
              style={{ width: "60px" ,fopntSize:13}}
              onClick={() => {
                this.setState({
                  paymentVisible: true,
                  organizationIdx: row.idx
                });
              }}>
              설정<ToolOutlined />
            </Button>
          ) : (
              "-"
            )
      },
      {
        title: "카테고리",
        dataIndex: "category",
        // width: 130,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          row.franchise ? (
            <Button
              style={{ width: "60px",fontSize:14}}
              onClick={() => {
                this.setState({
                  categoryVisible: true,
                  organizationIdx: row.idx
                });
              }}>
              설정<ToolOutlined />
            </Button>
          ) : (
              "-"
            )
      },
      {
        title: "하위 조직",
        dataIndex: "under",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <Button
            style={{ width: "60px" ,fontSize:14}}
            onClick={() => {
              this.setState({
                lowerVisible: true,
                organizationIdx: row.idx
              });
            }}>
            보기
          </Button>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "support-column-number fs-15 lh-21",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            
            <SelectBox
              style={{ width: "60px" }}
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        {accountVisible && (
          <AccountRegistry
            
            visible={accountVisible}
            onOk={() => {
              this.setState({
                accountVisible: false
              });
              this.getOrganiztionList();
            }}
            onCancel={() => {
              this.setState({
                accountVisible: false
              });
            }}
          />
        )}
        
       
        {createVisible && (
          <OrganizationRegistry
            visible={createVisible}
            onOk={() => {
              this.setState({
                createVisible: false
              });
              this.getOrganiztionList();
            }}
            onCancel={() => {
              this.setState({
                createVisible: false
              });
            }}
          />
        )}
        {detailVisible && (
          <OrganizationDetail
            modalData={this.state.detailData}
            visible={detailVisible}
            onOk={() => {
              this.setState({
                detailVisible: false
              });
              this.getOrganiztionList({
                pageNum: this.state.pagination.current
              });
            }}
            onCancel={() => {
              this.setState({
                detailVisible: false
              });
            }}
          />
        )}
        {paymentVisible && (
          <PaymentSetting
            organizationIdx={this.state.organizationIdx}
            visible={paymentVisible}
            onCancel={() => {
              this.setState({
                paymentVisible: false
              });
            }}
          />
        )}
        {categoryVisible && (
          <OrganizationCategory
            organizationIdx={this.state.organizationIdx}
            visible={categoryVisible}
            type={false}
            onCancel={() => {
              this.setState({
                categoryVisible: false
              });
            }}
          />
        )}
        {lowerVisible && (
          <LowerOrganization
            organizationIdx={this.state.organizationIdx}
            visible={lowerVisible}
            onCancel={() => {
              this.setState({
                lowerVisible: false
              });
            }}
          />
        )}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "60px",
            fontWeight: "bold"
          }}>
       <Divider orientation="left" style={{fontSize:23}}>조직관리</Divider>
        </div>
        {/* <Button 
              onClick={() => {
              this.setState({ accountVisible: true });
            }}>
             가맹점계정생성
              </Button> */}
        <div className="flex-row flex-center flex-sb m-b-10">
        <div className="flex-row flex-center">
          <Button
            onClick={() => {
              this.setState({ createVisible: true });
            }}>
           <PlusOutlined /> 생성
          </Button>&nbsp;
         
          <Button onClick={this.downloadExcel}>
          <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드</Button>
              </div>
          <div className="flex-row flex-center">
              <Checkbox onChange={this.deleteHandleChange}>삭제 포함</Checkbox>

            <label htmlFor="">
           &nbsp;&nbsp;
              <SelectBox
                value={string.organizationString[franchiseType]}
                code={string.organizationCode}
                codeString={string.organizationString}
                onChange={(value) => {
                  if (value !== franchiseType)
                    this.franchiseTypeHandleChange(value);
                }}
                style={{ width: "80px"}}
              />
            </label>

          
            <Search
              placeholder="조직명, 가맹점주,사업자번호를 입력해주세요."
              enterButton={<SearchOutlined/>}
              allowClear
              onSearch={this.onSearch}
              style={{ width: 400 }}
            />

          </div>

        
        </div>

        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={organizationList}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default Organization;
