import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Button, InputNumber } from "antd";

import { getCollection } from "../../contexts/asyncContext";
import {
  amountFormat,
  coinMultiFormat,
  coinDivideFormat,
  numberFormat
} from "../../util";
import { httpPut, httpUrl } from "../../api/httpClient";
import { showAlert } from "../../components/common/AlertModal";

class Collection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusTypes: "ALL",
      pagination: {
        total: 0,
        current: 0
      },
      selectedRow: "",
      selectData: {},
      prevData: {}
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getCollection({
      pageSize: 10,
      pageNum: pager.current
    });
  };

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "coinType") {
      data.coinType = value;
      data.internalSendFee = coinDivideFormat(row.internalSendFee, value);
      data.externalSendFee = coinDivideFormat(row.externalSendFee, value);
      data.purchaseFee = coinDivideFormat(row.purchaseFee, value);
      data.tradeFee = coinDivideFormat(row.tradeFee, value);
    } else data[type] = value;
    this.setState({
      selectData: data
    });
  };

  onUpdate = () => {
    const { selectData, pagination } = this.state;
    let updateData = {
      coinType: selectData.coinType,
      internalSendFee: coinMultiFormat(
        selectData.internalSendFee,
        selectData.coinType
      ),
      externalSendFee: coinMultiFormat(
        selectData.externalSendFee,
        selectData.coinType
      ),
      purchaseFee: coinMultiFormat(selectData.purchaseFee, selectData.coinType),
      tradeFee: coinMultiFormat(selectData.tradeFee, selectData.coinType),
      unit: selectData.coinType
    };
    if (global.confirm(`수정하시겠습니까?`)) {
      // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
      httpPut(httpUrl.collectionUpdate, [], updateData)
        .then(result => {
          showAlert("update");
          this.setState({
            selectedRow: "",
            selectData: {}
          });
          this.props.getCollection({
            pageSize: 10,
            pageNum: pagination.current
          });
        })
        .catch(error => {});
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getCollection({ pageSize: 10, pageNum: 1 });
  }

  render() {
    const { selectedRow, selectData } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const walletColumns = [
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "내부 전송 수수료",
        dataIndex: "internalSendFee",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              `${amountFormat(data, row.coinType)} ${
                coinList.find(coin => coin.coinType === row.coinType).symbol
              }`
            ) : (
              <InputNumber
                min={0}
                max={coinDivideFormat(9223372036854775807, row.coinType)}
                style={{ width: "180px" }}
                value={selectData.internalSendFee}
                onChange={value => {
                  this.onChangeModal(value, "internalSendFee");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "외부 전송 수수료(실제 수수료)",
        dataIndex: "externalSendFee",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => {
          let fee = coinDivideFormat(row.realFee, row.coinType);
          if (
            coinList.find(coin => coin.coinType === row.coinType).symbol ===
            "ETH"
          ) {
            fee = coinDivideFormat(fee * 21000, row.coinType);
          } else if (
            coinList.find(coin => coin.coinType === row.coinType).category ===
            "ERC20"
          ) {
            fee = coinDivideFormat(fee * 50000, row.coinType);
          }
          return (
            <div>
              {selectedRow !== index ? (
                `${amountFormat(data, row.coinType)} ${
                  coinList.find(coin => coin.coinType === row.coinType).symbol
                }`
              ) : (
                <InputNumber
                  min={0}
                  max={coinDivideFormat(9223372036854775807, row.coinType)}
                  style={{ width: "180px" }}
                  value={selectData.externalSendFee}
                  onChange={value => {
                    this.onChangeModal(value, "externalSendFee");
                  }}
                />
              )}
              <span style={{ color: "red", marginLeft: "10px" }}>
                ({numberFormat(fee)}{" "}
                {coinList.find(coin => coin.coinType === row.coinType)
                  .category === "ERC20" || row.coinType === 2
                  ? "ETH"
                  : coinList.find(coin => coin.coinType === row.coinType)
                      .symbol}
                )
              </span>
            </div>
          );
        }
      },
      {
        title: "구매 수수료",
        dataIndex: "purchaseFee",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              `${amountFormat(data, row.coinType)} ${
                coinList.find(coin => coin.coinType === row.coinType).symbol
              }`
            ) : (
              <InputNumber
                min={0}
                max={coinDivideFormat(9223372036854775807, row.coinType)}
                style={{ width: "180px" }}
                value={selectData.purchaseFee}
                onChange={value => {
                  this.onChangeModal(value, "purchaseFee");
                }}
                // onChange={this.onChange}
              />
            )}
          </div>
        )
      },
      {
        title: "교환 수수료",
        dataIndex: "tradeFee",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              `${amountFormat(data, row.coinType)} ${
                coinList.find(coin => coin.coinType === row.coinType).symbol
              }`
            ) : (
              <InputNumber
                min={0}
                max={parseFloat(
                  coinDivideFormat(9223372036854775807, row.coinType)
                )}
                style={{ width: "180px" }}
                value={selectData.tradeFee}
                onChange={value => {
                  this.onChangeModal(value, "tradeFee");
                }}
                // onChange={this.onChange}
              />
            )}
          </div>
        )
      },
      {
        title: "설정",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index && (
              <Button
                onClick={() => {
                  this.setState({
                    selectedRow: index,
                    prevData: row
                  });
                  this.onChangeModal(row.coinType, "coinType", row);
                }}>
                수정하기
              </Button>
            )}
            {selectedRow === index && (
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: this.state.prevData
                    });
                  }}
                />
              </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          수수료징수
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          id="collection-table"
          rowKey={record => record.coinType}
          columns={walletColumns}
          dataSource={this.props.result.feeList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getCollection: ({ pageSize, pageNum }) =>
      dispatch(getCollection({ pageSize, pageNum }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Collection);
