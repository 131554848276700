import React, { Component } from "react";
import { Table, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { formatDate, amountFormat } from "../../util";
import string from "../../string";

export default class WithDrawRequestLog extends Component {
  state = {
    withDrawList: [],
    pagination: {
      total: 0,
      current: 0
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getWithDrawLog({
      pageSize: 10,
      pageNum: pager.current,
      type: this.state.searchTypes,
      searchText: this.state.searchText
    });
  };

  getWithDrawLog = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getTransferRequestList, [10, pageNum], {}).then(res => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        withDrawList: res.data.list,
        pagination
      });
    });
  };

  componentDidMount() {
    this.getWithDrawLog();
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");
    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "코인 종류",
        key: "coinType",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "생성일",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{formatDate(data)}</div>
      },
      {
        title: "보낸 지갑",
        key: "from",
        dataIndex: "from",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "받은 지갑",
        key: "to",
        dataIndex: "to",
        className: "support-column-number fs-15 lh-21"
      },

      {
        title: "승인 상태",
        key: "status",
        dataIndex: "status",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{string.requestLogString[data]}</div>
      },
      {
        title: "삭제 상태",
        key: "deleted",
        dataIndex: "deleted",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{string.deleteString[data]}</div>
      },
      {
        title: "넌스",
        key: "nonce",
        dataIndex: "nonce",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "재전송",
        dataIndex: "nonce",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => 
          <div>
            {row.status == 'PENDING' && row.trxId != 'Max rate limit reached' && (
              <a href={row.requestUrl} target='_blank' style={{marginRight: 20}}>재전송</a>
            )}
            <a href={"https://etherscan.io/tx/" + row.trxId} target='_blank'>이더스캔</a>
          </div>
      },
      // {
      //   title: "재시도",
      //   key: "idx",
      //   dataIndex: "idx",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data, row) => 
      //   row.status == "PENDING" ? 
      //     <Button
      //       onClick={() => {
      //         if (global.confirm(data+'번 트랜잭션을 다시 발행하시겠습니까? 현재 TXID가 없어진 것을 반드시 확인해 주세요. 이중지불 될 수 있습니다.')) {
                
      //           httpPut(httpUrl.transferRequestReset, [data], {}).then((res) => {
      //             if (res.data) {
      //               alert('재요청 처리되었습니다. 10분내로 트랜잭션이 BROADCAST 됩니다.');
      //               this.getWithDrawLog();
      //             }
      //             else alert('재요청에 실패하였습니다. 재요청 가능한 상태가 아닙니다.');
      //           });
      //         }
      //       }}>
      //       재시도
      //     </Button> : ""
      // }
    ];

    const expandedRowRender = record => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: "수량",
          key: "amount",
          dataIndex: "amount",
          className: "support-column-number fs-15 lh-21",
          render: data => <div>{amountFormat(data, record.coinType)}</div>
        },
        {
          title: "수수료",
          key: "fee",
          dataIndex: "fee",
          className: "support-column-number fs-15 lh-21",
          render: data => <div>{amountFormat(data, record.coinType)}</div>
        },
        {
          title: "trxId",
          key: "trxId",
          dataIndex: "trxId",
          className: "support-column-number fs-15 lh-21",
          render: data => <div>{data === null ? "-" : data}</div>
        },
        {
          title: "logIdx",
          key: "logIdx",
          dataIndex: "logIdx",
          className: "support-column-number fs-15 lh-21",
          render: data => <div>{data === null ? "-" : data}</div>
        },

        {
          title: "에러 메시지",
          key: "errorMsg",
          dataIndex: "errorMsg",
          className: "support-column-number fs-15 lh-21",
          render: data => <div>{data === null ? "-" : data}</div>
        },
        record.coinType === 3
          ? {
              title: "destinationTag",
              key: "destinationTag",
              dataIndex: "destinationTag",
              className: "support-column-number fs-15 lh-21"
            }
          : {
              colSpan: 0,
              width: 0,
              render: () => {
                return {
                  props: {
                    colSpan: 0
                  }
                };
              }
            }
      ];
      // console.log(`${JSON.stringify(dataArr, null, 4)}`);
      return (
        <Table
          rowKey={record => record.idx}
          columns={dropColumns}
          dataSource={dataArr}
          pagination={false}
        />
      );
    };
    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          외부출금요청내역
        </div>
        <div style={{paddingBottom: '10px'}}>
          이더스캔에서 트랜잭션이 보이지 않고 전송이 지연되는 경우 "재전송"을 클릭하여 트랜잭션을 다시 보내주세요.<br/>
          재전송은 계속 클릭하셔도 무방합니다.(이중지불 되지 않습니다.)
        </div>
        <Table
          id="table"
          rowKey={record => record.idx}
          columns={columns}
          dataSource={this.state.withDrawList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}
