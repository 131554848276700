import React, { Component } from "react";

import { Table, Modal, Button, Input } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { SelectOutlined } from "@ant-design/icons";

const Search = Input.Search;

class OrganizationSelectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        current: 1,
        total: 0
      },
      searchType: "ALL",
      searchText: ""
    };
  }

  onOk = (data) => {
    this.props.onOk(data.idx, data.name);
    this.props.onCancel();
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getList({
      pageNum: pager.current
    });
  };

  getList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(httpUrl.getAdjustmentOrganization, [
      10,
      pageNum,
      true,
      false,
      this.state.searchText
    ]).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.list,
        pagination
      });
    });
  };

  componentDidMount() {
    this.getList();
  }

  render() {
    const { visible, onCancel } = this.props;
    const columns = [
      {
        title: "조직명",
        key: "name",
        dataIndex: "name",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{data}</div>
      },
      {
        key: "select",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <Button
           
            onClick={() => {
              this.onOk(row);
            }}>
            <SelectOutlined/>선택
          </Button>
        )
      }
    ];
    return (
      <Modal
        visible={visible}
        title="조직 선택"
        footer={null}
        onCancel={onCancel}>
        <div className="m-b-10">
          <Search
            style={{ width: "100%" }}
            placeholder={"검색어를 입력하세요."}
            onSearch={this.onSearch}
            allowClear
            enterButton={"검색"}
          />
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          columns={columns}
        />
      </Modal>
    );
  }
}

export default OrganizationSelectModal;
