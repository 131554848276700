import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Icon, Checkbox, InputNumber } from "antd";
import { httpPut, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import CoinSelectBox from "../../components/common/CoinSelectBox";

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;

class StakingCoinRegist extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  onCreate = (e) => {
    let {form} = this.props;
    
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log(JSON.stringify(values));
        if (global.confirm('등록하시겠습니까?')) {
          httpPost(httpUrl.stakingCoinCreate, [], values).then((res) => {
            if (res.data !== 0) {
              form.resetFields();
              this.props.onOk();
            } else {
              alert("알수없는 문제로 등록에 실패하였습니다..");
            }
          });

        }
      }
    });
  }
  render() {
    const { visible, onCancel, form } = this.props;
    const { getFieldDecorator } = form;
        
    let exceptCoinType = [1,2,3];
    this.props.stakingCoinList.forEach(x=>{
      exceptCoinType.push(x.coinType);
    })
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    return (
      <Modal
        visible={visible}
        title="스테이킹 코인 등록"
        okText="등록"
        cancelText="취소"
        onOk={this.onCreate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        <div>
          <Form {...formItemLayout} onSubmit={this.onCreate}>
            <Descriptions
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Ditems label="코인종류">
                <FormItem>
                  {getFieldDecorator("coinType", {
                    rules: [{ required: true, message: "스테이킹으로 등록할 코인을 선택해주세요." }],
                  })(
                    <CoinSelectBox
                    placeholder="코인선택"
                    exceptCoinType={exceptCoinType}
                    style={{ width: "160px" }}
                  />
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="스테이킹 락업기간(일)">
                <FormItem>
                {getFieldDecorator("lockDay", {
                  initialValue: 0
                })(
                  <InputNumber min={0} />
                  )}
                  </FormItem>
                  ex) 0으로 설정하는 경우 사용자가 스테이킹 후 바로 언스테이킹이 가능하며, 10으로 설정하는 경우 사용자가 스테이킹 하면 해당 금액은 10일 이후에 언스테이킹 할 수 있다.
              </Ditems>
              </Descriptions>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create()(StakingCoinRegist)