import React, { Component } from 'react'
import { httpGet, httpUrl,httpPost, httpDelete } from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form,Divider } from 'antd'
import { reactLocalStorage } from "reactjs-localstorage";
import {
   formatDate,
 
  numberFormat,
  startDateFormat,
  endDateFormat
} from "../../util";
import string from "../../string";
import locale from "antd/es/date-picker/locale/ko_KR";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";


const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
const FormItem = Form.Item;
const CouponLog = Form.create()(

class CouponLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      searchText:"",
      pagination: {
        total: 0,
        current: 0,
      },
    
      list: [],
      visible: false,
      couponLogVisible: false,
    };


  }
 
  // handleChangeDepositType = (value) => {
  //   this.setState({
  //     depositType: value,

  //   });
  // };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getCouponLogList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getCouponLogList({});

      }
    );
  };

  



  getCouponLogList = (params = {}) => {
    const{searchText} = this.state
 
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getCouponLogList, [10, pageNum])
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.logList,
          pagination,
        })
      })

  }

  

 

  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  componentDidMount() {
    this.getCouponLogList();
  }
  

  render() {
    const {selectedRowKeys } = this.state;
    const rowSelection ={
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    const { selectedData } = this.state;
    const {form} = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      wrapperCol: {
        xs: { span: 10 },
        sm: { span: 13 }
      }
    };
    const {
      selectedRow,
    
    } = this.state;


    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div style={{fontSize:13}}>{data}</div>    
      },
      // {
      //   title: "쿠폰번호",
      //   key: "couponIdx",
      //   dataIndex: "couponIdx",
      //   className: "support-column-number fs-15 lh-21",
      //   render:(data) =>
      //   <div>{data}</div>
      // },
  
      {
        title:"회원이름",
        key:"userName",
        dataIndex:"userName",
        className:"support-column-number fs-15 lh-21",
        render: (data,row) => <div style={{fontSize:13}}>{data}</div>
      },
       {
        title:"회원아이디",
        key:"userId",
        dataIndex:"userId",
        className:"support-column-number fs-15 lh-21",
        render: (data,row) => <div style={{fontSize:13}}>{data}</div>
      },
      {
        title:"전화번호",
        key:"userPhone",
        dataIndex:"userPhone",
        className:"support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{data}</div>
      },
      {
        title: "쿠폰종류",
        key: "ccName",
        dataIndex: "ccName",
        className: "support-column-number fs-15 lh-21",
        render:(data) =>
        <div style={{fontSize:13}}>{data}</div>
      },
      {
        title:"사용시간",
        key:"createDate",
        dataIndex:"createDate",
        className:"support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{formatDate(data)}</div>
      },
   
      
   
   

    ];




    return (
      
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "70px",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{fontSize:23}}>쿠폰 사용내역 </Divider>  
        
         

          
        </div>
        {/* 메모입력,수정 Modal */}
        

        
        <Table
        
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px' , width:1000, marginLeft: 350}}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
         

      </div>
    )


  }
}
);
export default CouponLog;
