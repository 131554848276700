import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
// import { NaverMap } from "react-naver-maps";

import {
  Form,
  Modal,
  Input,
  Button,
  Switch,
  Icon,
  Tag,
  InputNumber,
  Upload,
  Select
} from "antd";
import { httpPost, httpUrl, httpGet, serverUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";
import UserList from "./UserList";
import { showAlert } from "../common/AlertModal";
import PaymentSettingRegistry from "./PaymentSettingRegistry";
import string from "../../string";
import Address from "./Address";
import OrganizationCategory from "./OrganizationCategory";
import BasicImage from "../common/BasicImage";
import Axios from "axios";
import OrganizationList from "./OrganizationList";
import SelectBox from "../common/SelectBox";
import fileDownload from "js-file-download";

const FormItem = Form.Item;
const Option = Select.Option;

const OrganizationRegistry = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userList: [],
        searchText: "",
        userVisible: false,
        selectedUser: {},
        selectedClassification: 0,
        organizationSelected: false,
        attractOrganizationSelected: false,
        locationPoint: "0,0",
        franchise: false,
        auto: true,
        orgCategory: [],
        paymentSetting: [],
        images: "",
        thumbnail: "",
        preview: "",

        uploadFiles: [],
      };
    }

    // onFranchiseChange = (value) => {
    //   this.setState({
    //     franchise: value
    //   });
    // };

    onAutoChange = (value) => {
      this.setState({
        auto: value
      });
    };

    onSelectUser = (data) => {
        this.setState({ selectedUser: data });
        this.props.form.setFieldsValue({ userIdx: data.idx });
    };
    onSelectOrganization = (data) => {
        this.props.form.setFieldsValue({ parentIdx: data.idx });
        this.setState({organizationSelected : true, organizationSelectedName: data.name});
    };
    onSelectAttractOrganization = (data) => {
        this.props.form.setFieldsValue({ attractOrganizationIdx: data.idx });
        this.setState({attractOrganizationSelected : true, attractOrganizationSelectedName: data.name});
    };
    
    // 페이백 설정
    onPaymentSetting = (data) => {
      let arr = [...this.state.paymentSetting];
      arr.push(data);
      this.setState({
        paymentSetting: arr
      });
      this.props.form.setFieldsValue({ paymentSetting: arr });
    };

    handleClose = (removedTag) => {
      const paymentSetting = this.state.paymentSetting.filter(
        (tag) => tag.coinType !== removedTag.coinType
      );
      this.setState({ paymentSetting });
      this.props.form.setFieldsValue({ paymentSetting: paymentSetting });
    };

    // 카테고리 설정
    onCategorySetting = (data) => {
      let arr = [...this.state.orgCategory];
      let body = { ...data, categoryIdx: data.idx };
      arr.push(body);
      this.setState({
        orgCategory: arr
      });
      this.props.form.setFieldsValue({ orgCategory: arr });
    };

    handleCategoryClose = (removedTag) => {
      const orgCategory = this.state.orgCategory.filter(
        (tag) => tag.idx !== removedTag.idx
      );
      this.setState({ orgCategory });
      this.props.form.setFieldsValue({ orgCategory: orgCategory });
    };

    // 이미지 삭제
    handleDeleteFile = (image) => {
      if (global.confirm("이미지를 삭제 하시겠습니까?")) {
        this.setState({ preview: "" });
      }
    };

    // 주소 찾기
    addressSeach = (value) => {
      // console.log(JSON.stringify(value, null, 4));
      this.setState({ addressVisible: false });
      this.props.form.setFieldsValue({ locationAddress: value });
      /*  주소 좌표 */
      httpGet(httpUrl.getGeocode, [value]).then((res) => {
        let address = "";
        if (res.data.y != 0 && res.data.x != 0)
          address = `${res.data.y}, ${res.data.x}`;
        this.props.form.setFieldsValue({ locationPoint: address });
        this.setState({ locationPoint: address });
      });
    };

    onHandleClassification = (e) => {
      if (e == 4) {
        this.setState({
          franchise: true,
          selectedClassification: e
        });
      }
      else {
        this.setState({
          franchise: false,
          selectedClassification: e
        });
      }
    }

    onRegistry = (e) => {
      const { franchise, paymentSetting } = this.state;
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        let createData = {
          ...values,
          franchise: franchise ? 1 : 0
        };

        if (franchise) {
          createData = {
            ...createData,
            autoAdjustment: this.state.auto ? 1 : 0,
            paymentSetting: paymentSetting,
            image: this.state.images,
            thumbnail: this.state.thumbnail,
            uploadFiles: this.state.uploadFiles.map(x=>x.idx)
          };
          // console.log(`createData: ${JSON.stringify(createData, null, 4)}`);
        }
        if (!err) {
          if (global.confirm("생성하시겠습니까?"))
            httpPost(httpUrl.organizationCreate, [], createData)
              .then((res) => {
                if (res.data.result === "SUCCESS") {
                  this.props.onOk();
                  form.resetFields();
                  showAlert("create");
                } else {
                  if (res.data.data === "ORGANIZATION_EXIST")
                    alert("조직 계정이 중복되었습니다. 다시 설정해주세요.");
                  if (res.data.data === "COIN_NULL")
                    alert("결제 및 페이백 설정을 해주세요.");
                }
              })
              .catch((error) => {});
        }
      });
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
    };

    componentDidMount() {}

    render() {
      const {
        userVisible,
        organizationVisible,
        attractOrganizationVisible,
        selectedUser,
        organizationSelected,
        organizationSelectedName,
        attractOrganizationSelected,
        attractOrganizationSelectedName,
        settingVisible,
        addressVisible,
        locationPoint,
        franchise,
        orgCategory,
        categoryVisible,
        paymentSetting,
        preview
      } = this.state;
      const { visible, form } = this.props;
      const { getFieldDecorator } = form;
      const coinList = reactLocalStorage.getObject("coinList");

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      const uploadProps = {
        withCredentials: true,
        showUploadList: false,
        multiple: false,
        transformFile: async (file) => {
          let newImages = [];
          newImages = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const canvas1 = document.createElement("canvas");
              const canvas2 = document.createElement("canvas");
              const img = document.createElement("img");
              img.src = reader.result;
              this.setState({
                preview: reader.result
              });
              img.onload = () => {
                let images = [];
                const ctx1 = canvas1.getContext("2d");
                const ctx2 = canvas2.getContext("2d");
                canvas1.width = 100;
                canvas1.height = 100;
                ctx1.drawImage(img, 0, 0, 100, 100);
                canvas1.toBlob(
                  (blob) => {
                    images.push(new File([blob], `100x100${file.name}`));
                    // resolve(images);
                  },
                  "image/png",
                  0.5
                );
                canvas2.width = 300;
                canvas2.height = 300;
                ctx2.drawImage(img, 0, 0, 300, 300);
                canvas2.toBlob(
                  (blob) => {
                    images.push(new File([blob], `300x300${file.name}`));
                    resolve(images);
                  },
                  "image/png",
                  0.5
                );
              };
            };
          });
          await new Promise((resolve) => {
            const formData100 = new FormData();
            const formData300 = new FormData();
            formData100.append("file", newImages[0]);
            formData300.append("file", newImages[1]);
            Axios({
              method: "POST",
              url: serverUrl + httpUrl.imageUpload,
              data: formData100,
              withCredentials: true
            }).then((res) => {
              this.setState({
                thumbnail: res.data.data.fileId
              });
            });
            Axios({
              method: "POST",
              url: serverUrl + httpUrl.imageUpload,
              data: formData300,
              withCredentials: true
            }).then((res) => {
              this.setState({
                images: res.data.data.fileId
              });
            });
          });
        }
      };

      const uploadFileProps = {
        action: serverUrl + httpUrl.fileUpload,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file, fileList) => {
        },
        onSuccess: (file) => {
          // console.log(`file = ${JSON.stringify(file, null, 4)}`);
          if (file.data.result) {
            Modal.info({
              title: "업로드 결과",
              content: "파일 업로드 성공"
            });
            this.state.uploadFiles.push({idx: file.data.idx, filename: file.data.filename})
            this.setState({
              uploadFiles: this.state.uploadFiles
            });
            
          }
        },
        onError(err) {
          Modal.error({
            title: "업로드 결과",
            content: "파일 업로드 실패"
          });
        }
      };
      return (
        <Modal
          visible={visible}
          title="조직 생성"
          okText="생성"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "800px" }}>
          {userVisible && (
            <UserList
              visible={userVisible}
              userIdx={0}
              onCancel={() => {
                this.setState({ userVisible: false });
              }}
              onOk={this.onSelectUser}
            />
          )}
          {organizationVisible && (
            <OrganizationList
              visible={organizationVisible}
              userIdx={0}
              onCancel={() => {
                this.setState({ organizationVisible: false });
              }}
              onOk={this.onSelectOrganization}
              classification={this.state.selectedClassification-1}
            />
          )}
          {attractOrganizationVisible && (
            <OrganizationList
              visible={attractOrganizationVisible}
              userIdx={0}
              onCancel={() => {
                this.setState({ attractOrganizationVisible: false });
              }}
              onOk={this.onSelectAttractOrganization}
              classification={0}
            />
          )}
          {categoryVisible && (
            <OrganizationCategory
              visible={categoryVisible}
              type={true}
              idx={0}
              orgCategory={this.state.orgCategory}
              onOk={this.onCategorySetting}
              onCancel={() => {
                this.setState({ categoryVisible: false });
              }}
            />
          )}
          {settingVisible && (
            <PaymentSettingRegistry
              visible={settingVisible}
              paymentSetting={this.state.paymentSetting}
              onCancel={() => {
                this.setState({ settingVisible: false });
              }}
              onOk={this.onPaymentSetting}
            />
          )}
          {addressVisible && (
            <Address
              visible={addressVisible}
              onCancel={() => {
                this.setState({ addressVisible: false });
              }}
              onOk={this.addressSeach}
            />
          )}
          <Form {...formItemLayout} onSubmit={this.onRegistry}>
            <FormItem
              label={<span>조직 계정&nbsp;</span>}
              style={{ marginBottom: 0 }}>
              {getFieldDecorator("userIdx", {
                rules: [
                  {
                    required: true,
                    message: "조직 계정을 선택해주세요!"
                  }
                ]
              })(
                <div>
                  <div className="flex-row flex-center">
                    {selectedUser.idx !== undefined && (
                      <div className="m-r-10">
                        {selectedUser.id} /{" "}
                        {selectedUser.name === null || selectedUser.name === ""
                          ? "-"
                          : selectedUser.name}
                      </div>
                    )}
                    <Button
                      onClick={() => {
                        this.setState({
                          userVisible: true
                        });
                      }}>
                      {selectedUser.idx !== undefined ? "변경" : "선택"}
                    </Button>
                  </div>
                  <div
                    style={{
                      color: "blue",
                      marginTop: 5,
                      marginBottom: 5,
                      lineHeight: '20px',

                    }}>
                    영업조직이나 가맹점을 생성하기 위해서는 지갑 앱에서
                    회원가입을 먼저 진행한 후 해당 계정을 등록하여야 합니다.
                  </div>
                </div>
              )}
            </FormItem>
            
            <FormItem
              label={<span>조직 구분&nbsp;</span>}
              style={{ marginBottom: 10 }}>
              {getFieldDecorator("classification", {
                rules: [
                  {
                    required: true,
                    message: "조직 구분을 선택해주세요!"
                  }
                ]
              })(
                  <Select placeholder="선택해주세요." style={{ width: "150px" }} onChange={this.onHandleClassification}>
                    <Option key={1} value={1}>총판</Option>
                    <Option key={2} value={2}>지사</Option>
                    <Option key={3} value={3}>대리점</Option>
                    <Option key={4} value={4}>가맹점</Option>
                  </Select>
              )}
            </FormItem>

            <FormItem label={<span>상위 조직&nbsp;</span>}>
              {getFieldDecorator("parentIdx", {
                rules: [
                  {
                    required: false,
                    message: "상위 조직을 선택해주세요!"
                  }
                ]
              })(
                <div className="flex-row flex-center">
                  {this.state.organizationSelectedName}
                  <Button
                    onClick={() => {
                      if (selectedUser.idx === undefined)
                        alert("조직 계정을 먼저 선택해주세요.");
                      else {
                        if (this.state.selectedClassification == 0 || this.state.selectedClassification == 1) {
                          alert("총판 이외의 조직 구분을 선택해야합니다.")
                        }
                        else this.setState({ organizationVisible: true });
                      }
                        
                    }}>
                    {organizationSelected ? "변경" : "선택"}
                  </Button>
                </div>
              )}
            </FormItem>
            {/* <FormItem label={<span>유치자&nbsp;</span>}>
              {getFieldDecorator("attractOrganizationIdx", {
                rules: [
                  {
                    required: false,
                    message: "유치자를 선택해주세요!"
                  }
                ]
              })(
                <div className="flex-row flex-center">
                {this.state.attractOrganizationSelectedName}
                  <Button
                    onClick={() => {
                      this.setState({ attractOrganizationVisible: true });
                    }}>
                    {attractOrganizationSelected ? "변경" : "선택"}
                  </Button>
                </div>
              )}
            </FormItem> */}
            <FormItem label={<span>조직명&nbsp;</span>}>
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "조직명을 입력해주세요!"
                  }
                ]
              })(<Input placeholder="홍길동" />)}
            </FormItem>
            <FormItem label={<span>은행&nbsp;</span>}>
              {getFieldDecorator("bankCode", {
                rules: [
                ]
              })(
                <SelectBox
                  placeholder="은행을 선택해주세요."
                  code={string.bankCode}
                  codeString={string.bankString}
                  style={{ width: "180px" }}
                />)}
            </FormItem>
            <FormItem label={<span>계좌번호&nbsp;</span>}>
              {getFieldDecorator("bankAccount", {
                rules: [
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>예금주&nbsp;</span>}>
              {getFieldDecorator("accountHolder", {
                rules: [
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>사업자번호&nbsp;</span>}>
              {getFieldDecorator("businessNum", {
                rules: [
                  {
                    required: false,
                    message: "사업자번호를 입력해주세요!"
                  },
                  {
                    required: false,
                    message: "숫자만 입력해주세요.",
                    pattern: /[0-9]+$/
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
             <FormItem label={<span>주소&nbsp;</span>}>
              {getFieldDecorator("locationAddress", {
                rules: [
                  {
                    required: false,
                    message: "주소를 입력해주세요!"
                  }
                ]
              })(
                <div>
                  {form.getFieldValue("locationAddress") && (
                    <div>{form.getFieldValue("locationAddress")}</div>
                  )}
                  <Button
                    onClick={() => {
                      this.setState({ addressVisible: true });
                    }}>
                    {form.getFieldValue("locationAddress")
                      ? "주소 변경"
                      : "주소 찾기"}
                  </Button>
                </div>
              )}
            </FormItem>
            <FormItem label={<span>상세주소&nbsp;</span>}>
              {getFieldDecorator("locationAddressDetail", {
                rules: [
                  {
                    required: false,
                    message: "상세주소를 입력해주세요!"
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>주소 좌표&nbsp;</span>}>
              {getFieldDecorator("locationPoint", {
                rules: [
                  {
                    required: false,
                    message: "주소 좌표를 입력해주세요!"
                  }
                ]
              })(
                <div style={{ color: locationPoint ? "" : "red" }}>
                  {locationPoint
                    ? locationPoint
                    : "입력한 주소를 네이버 지도에서 찾지 못하였습니다. 앱에서 가맹점 위치가 정상적으로 표출되지 않을 수 있습니다."}
                </div>
              )}
            </FormItem>
             <FormItem label={<span>업종&nbsp;</span>}>
              {getFieldDecorator("sector", {
                rules: [
                  {
                    required: true,
                    message: "업종을 입력해주세요!"
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>메모&nbsp;</span>}>
              {getFieldDecorator("memo", {
                rules: [
                  {
                    required: false,
                    message: "메모를 입력해주세요!"
                  }
                ]
              })(<Input />)}
            </FormItem>
            {/* <FormItem label={<span>가맹점 여부&nbsp;</span>}>
              <Switch
                disabled
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                // checked={franchise}
                onChange={(checked) => {
                  this.onFranchiseChange(checked);
                }}
              />
            </FormItem> */}
            {franchise && (
              <FormItem label={<span>대표 이미지&nbsp;</span>}>
                {getFieldDecorator("images", {
                  rules: [
                    {
                      required: false
                    }
                  ]
                })(
                  <div>
                    {preview !== "" && (
                      <div className="flex-row flex-sb flex-center">
                        <div style={{ maxWidth: "300px" }}>
                          <BasicImage
                            image={preview}
                            src={preview}
                            style={{ maxWidth: "300px" }}
                          />
                        </div>
                        <Icon
                          className="pointer"
                          type="delete"
                          onClick={() => {
                            this.handleDeleteFile(preview);
                          }}
                        />
                      </div>
                    )}
                    <Upload {...uploadProps}>
                      <Button>{preview ? "변경" : "추가"}</Button>
                    </Upload>
                  </div>
                )}
              </FormItem>
            )}
            {franchise && (
              <FormItem label={<span>카테고리&nbsp;</span>}>
                {getFieldDecorator("orgCategory", {
                  rules: [
                    {
                      required: true,
                      message: "카테고리를 한 개 이상 선택해주세요"
                    }
                  ]
                })(
                  <div>
                    {orgCategory.length !== 0 &&
                      orgCategory.map((tag, index) => (
                        <Tag
                          key={index}
                          closable={true}
                          onClose={() => this.handleCategoryClose(tag)}>
                          {tag.name}
                        </Tag>
                      ))}
                    <Button
                      onClick={() => {
                        this.setState({ categoryVisible: true });
                      }}>
                      {orgCategory.length === 0 ? "선택" : "추가"}
                    </Button>
                  </div>
                )}
              </FormItem>
            )}
            {franchise && (
              <FormItem
                label={<span>파일첨부&nbsp;</span>}
                style={{ marginBottom: 0 }}>
                  <Upload {...uploadFileProps} showUploadList={false}>
                    <Button>첨부하기</Button>
                  </Upload>
                  <div>
                    {this.state.uploadFiles.map(data=>{
                      return (
                        <div>

                          <a href={serverUrl + httpUrl.getFile.replace('%s', data.idx)} target='_blank'>{data.filename}</a>
                          <div style={{display: 'inline-block', paddingLeft: '5px', cursor: 'pointer'}} onClick={()=>{
                            const idx = this.state.uploadFiles.findIndex(x=>x.idx == data.idx)
                            if (idx > -1) this.state.uploadFiles.splice(idx, 1)
                            this.setState({uploadFiles: this.state.uploadFiles})
                          }}><Icon type="close" /></div>
                        </div>
                      )
                    })}
                  </div>
                </FormItem>
            )}
            {franchise && (
              <FormItem
                label={<span>자동 정산 여부&nbsp;</span>}
                style={{ marginBottom: 0 }}>
                {getFieldDecorator("autoAdjustment", {
                  rules: [
                    {
                      required: false,
                      message: "자동 정산 여부를 설정해주세요!"
                    }
                  ]
                })(
                  <div>
                    <Switch
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      checked={this.state.auto}
                      onChange={(checked) => {
                        this.onAutoChange(checked);
                      }}
                    />
                    <div>
                      <span
                        style={{
                          color: "blue",
                          marginBottom: 0,
                          lineHeight: 0.3
                        }}>
                        자동정산이 활성화 되면 매일 조직의 지갑에서 코인을
                        회수하고 입금해야하는 금액을 자동으로 집계합니다.
                      </span>
                    </div>
                  </div>
                )}
              </FormItem>
            )}
            {franchise && (
              <FormItem label={<span>정산 수수료 비율 (%)&nbsp;</span>}>
                {getFieldDecorator("feeRatio", {
                  rules: [
                    {
                      required: false,
                      message: "정산 수수료 비율을 입력해주세요!"
                    }
                  ]
                })(
                  <InputNumber
                    min={0}
                    max={100}
                    placeholder="0.6"
                    style={{ width: "380px" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                )}
              </FormItem>
            )}
            {franchise && (
              <FormItem label={<span>수익분배1단계 (%)&nbsp;</span>}>
                {getFieldDecorator("shareRatio1", {
                  rules: [
                    {
                      required: false,
                      message: "수익분배1단계를 입력해주세요!"
                    }
                  ]
                })(
                  <InputNumber
                    min={0}
                    max={100}
                    placeholder="0.6"
                    style={{ width: "380px" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                )}
              </FormItem>
            )}
            {franchise && (
              <FormItem
                label={<span>수익분배2단계 (%)&nbsp;</span>}
                style={{ marginBottom: 0 }}>
                {getFieldDecorator("shareRatio2", {
                  rules: [
                    {
                      required: false,
                      message: "수익분배2단계를 입력해주세요!"
                    }
                  ]
                })(
                  <div>
                    <InputNumber
                      min={0}
                      max={100}
                      placeholder="0.6"
                      style={{ width: "380px" }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                    <span
                      style={{
                        color: "blue",
                        marginBottom: 0,
                        lineHeight: 0.3
                      }}>
                      정산 수수료 비율, 수익분배 비율을 미입력시 결제관리의
                      설정값관리에 설정된 값이 적용됩니다. 이 가맹점만 특별히
                      다른 비율을 적용하고 싶은 경우에만 입력해주세요.
                    </span>
                  </div>
                )}
              </FormItem>
            )}
            {franchise && (
              <FormItem label={<span>외부연동KEY&nbsp;</span>}>
                {getFieldDecorator(
                  "externalKey",
                  {}
                )(
                  <Input placeholder="수익배분 매출의 외부연동이 있는 경우 외부시스템의 KEY를 입력" />
                )}
              </FormItem>
            )}
            {franchise && (
              <FormItem label={<span>interfaceUri&nbsp;</span>}>
                {getFieldDecorator("interfaceUri", {
                  rules: [
                    {
                      required: false,
                      message: "url를 입력해주세요!"
                    }
                  ]
                })(
                  <Input placeholder="http://aaa.com/pay/complete?orderid={0}&token={1}" />
                )}
              </FormItem>
            )}
            {/* {franchise && (
              <FormItem label={<span>redirectUri&nbsp;</span>}>
                {getFieldDecorator("redirectUri", {
                  rules: [
                    {
                      required: false,
                      message: "url를 입력해주세요!"
                    }
                  ]
                })(<Input />)}
              </FormItem>
            )} */}
            {franchise && (
              <FormItem label={<span>결제 및 페이백 설정&nbsp;</span>}>
                {getFieldDecorator("paymentSetting", {
                  rules: [
                    {
                      required: franchise ? true : false,
                      message: "결제 및 페이백을 설정해주세요!",
                      type: "array"
                    }
                  ]
                })(
                  <div>
                    {paymentSetting.length !== 0 &&
                      paymentSetting.map((tag, index) => (
                        <Tag
                          key={tag.coinType}
                          closable={true}
                          onClose={() => this.handleClose(tag)}>
                          결제 코인 :{" "}
                          {coinList &&
                          coinList.find(
                            (coin) => coin.coinType === tag.coinType
                          )
                            ? coinList.find(
                                (coin) => coin.coinType === tag.coinType
                              ).name
                            : ""}
                          {tag.payCoinType &&
                            `, 교환(전환) 코인 : ${
                              coinList &&
                              coinList.find(
                                (coin) => coin.coinType === tag.payCoinType
                              )
                                ? coinList.find(
                                    (coin) => coin.coinType === tag.payCoinType
                                  ).name
                                : ""
                            }`}
                          {tag.paybackCoinType &&
                            `, 페이백 코인 : ${
                              coinList &&
                              coinList.find(
                                (coin) => coin.coinType === tag.paybackCoinType
                              )
                                ? coinList.find(
                                    (coin) =>
                                      coin.coinType === tag.paybackCoinType
                                  ).name
                                : ""
                            }`}
                          {tag.paybackMethod &&
                            `, 페이백 방식 : ${
                              string.paybackTypeString[tag.paybackMethod]
                            }`}
                          {tag.paybackRatio &&
                            `, 페이백 비율 : ${numberFormat(
                              tag.paybackRatio
                            )}`}
                        </Tag>
                      ))}
                    <Button
                      onClick={() => {
                        this.setState({ settingVisible: true });
                      }}>
                      {paymentSetting.length === 0 ? "선택" : "추가"}
                    </Button>
                  </div>
                )}
              </FormItem>
            )}
          </Form>
        </Modal>
      );
    }
  }
);

export default OrganizationRegistry;
