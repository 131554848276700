import React, { Component } from "react";
// import { connect } from 'react-redux';
import { reactLocalStorage } from "reactjs-localstorage";

import { Modal, Table, Button } from "antd";
import { formatDate, amountFormat } from "../../util";
import { httpUrl, httpGet } from "../../api/httpClient";

export default class WalletModal extends Component {
  state = {};

  onUpdateBalance = data => {
    httpGet(httpUrl.updateUserBalance, [data.address, data.coinType], {}).then(
      res => {
        alert("갱신이 완료 되었습니다.");
        this.props.showSerialNumber({
          pageNum: this.props.pagination.current
        });
      }
    );
  };

  render() {
    const { visible, onOk, modalData } = this.props;
    const coinList = reactLocalStorage.getObject("coinList");

    const walletColums = [
      {
        title: "생성일",
        dataIndex: "createDate",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "잔액",
        dataIndex: "balance",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "",
        className: "support-column-number fs-15 lh-21",

      }
    ];
    return (
      <Modal
        visible={visible}
        title="지갑잔액" 
        onCancel={onOk}
        style={{ minWidth: "1400px" }}
        footer={null}>
        <div className="wallet-log-wrapper">
          <Table
            id="wallet-log-table"
            rowKey={record => record.coinType}
            columns={walletColums}
            dataSource={modalData}
            pagination={this.props.pagination}
            onChange={this.props.handleTableChange}
            style={{ marginBottom: "10px" }}
          />
        </div>
      </Modal>
    );
  }
}
