import React, { Component } from "react";
// import { connect } from 'react-redux';
import { Modal, Input, Checkbox } from "antd";
import { formatDate, numberFormat } from "../../util";
import string from "../../string";
import { httpPut, httpUrl } from "../../api/httpClient";

class DetailModal extends Component {
  state = {
    detailData: {
      title: global.lanList.map(lan => {
        return {
          content: "",
          language: lan
        };
      }),
      content: global.lanList.map(lan => {
        return {
          content: "",
          language: lan
        };
      })
    },
    checked: false
  };

  onChangeModal = (value, type, language) => {
    const data = Object.assign({}, this.state.detailData);
    let arr = data[type];
    if (type === "title") {
      arr = arr.map(item => {
        if (item.language === language) {
          return {
            content: value,
            language: language
          };
        } else {
          return item;
        }
      });
      data.title = arr;
      // console.log(JSON.stringify(arr, null, 4));
    } else if (type === "content") {
      arr = arr.map(item => {
        if (item.language === language) {
          return {
            content: value,
            language: language
          };
        } else {
          return item;
        }
      });
      data.content = arr;
    } else {
      data.main = value ? 1 : 0;
    }
    this.setState({
      detailData: data
    });
    // console.log(`detailData: ${JSON.stringify(data, null, 4)}`);
  };

  onUpdate = () => {
    const { detailData } = this.state;
    const { modalData, type } = this.props;
    let updateData = {};
    if (global.confirm(`수정하시겠습니까?`)) {
      if (type === "notice") {
        updateData = {
          idx: modalData.idx,
          category: modalData.category,
          titleIdx: modalData.titleIdx,
          title: detailData.title,
          contentIdx: modalData.contentIdx,
          content: detailData.content,
          main: detailData.main
        };
        httpPut(httpUrl.noticeUpdate, [], updateData)
          .then(result => {
            this.props.onOk();
          })
          .catch(error => {});
        // console.log(JSON.stringify(detailData, null, 4));
      } else {
        updateData = {
          idx: modalData.idx,
          titleIdx: modalData.titleIdx,
          title: detailData.title,
          contentIdx: modalData.contentIdx,
          content: detailData.content
        };
        httpPut(httpUrl.faqUpdate, [], updateData)
          .then(result => {
            this.props.onOk();
          })
          .catch(error => {});
        // console.log(JSON.stringify(detailData, null, 4));
      }
    }
  };

  onCancel = () => {
    const obj = Object.assign({}, this.props.modalData);
    let titleData = global.lanList.map((lan, index) => {
      if (this.props.modalData.title.find(item => item.language === lan)) {
        return this.props.modalData.title.find(item => item.language === lan);
      } else {
        return {
          content: "",
          language: lan
        };
      }
    });
    let contentData = global.lanList.map((lan, index) => {
      if (this.props.modalData.content.find(item => item.language === lan)) {
        return this.props.modalData.content.find(item => item.language === lan);
      } else {
        return {
          content: "",
          language: lan
        };
      }
    });
    this.setState({
      detailData: { ...obj, title: titleData, content: contentData }
    });
    this.props.onCancel();
  };

  componentDidUpdate(prevProps) {
    if (this.props.modalData.idx !== prevProps.modalData.idx) {
      const obj = Object.assign({}, this.props.modalData);
      let titleData = global.lanList.map((lan, index) => {
        if (this.props.modalData.title.find(item => item.language === lan)) {
          return this.props.modalData.title.find(item => item.language === lan);
        } else {
          return {
            content: "",
            language: lan
          };
        }
      });
      let contentData = global.lanList.map((lan, index) => {
        if (this.props.modalData.content.find(item => item.language === lan)) {
          return this.props.modalData.content.find(
            item => item.language === lan
          );
        } else {
          return {
            content: "",
            language: lan
          };
        }
      });
      // console.log(`titleData = ${JSON.stringify(titleData, null, 4)}`);
      // console.log(`contentData = ${JSON.stringify(contentData, null, 4)}`);
      this.setState({
        detailData: { ...obj, title: titleData, content: contentData }
      });
    }
  }

  render() {
    const { visible, type } = this.props;
    const { detailData } = this.state;
    return (
      <Modal
        visible={visible}
        title={type === "notice" ? "공지사항" : "FAQ"}
        okText="수정"
        cancelText="취소"
        onOk={this.onUpdate}
        onCancel={this.onCancel}
        style={{ minWidth: "800px" }}>
        {/* <div>{JSON.stringify(modalData)}</div> */}
        <div className="detailModal-wrapper">
          <table>
            <tbody>
              <tr>
                <td
                  className="grid-title"
                  style={{ width: "100px", height: "40px" }}>
                  작성일
                </td>
                <td className="grid-content" style={{ width: "300px" }}>
                  {formatDate(detailData.createDate)}
                </td>
                <td className="grid-title" style={{ width: "100px" }}>
                  조회수
                </td>
                <td className="grid-content" style={{ width: "300px" }}>
                  {numberFormat(detailData.read)}
                </td>
              </tr>
              {type === "notice" && (
                <tr>
                  <td
                    className="grid-title"
                    style={{ width: "100px", height: "40px" }}>
                    구분
                  </td>
                  <td className="grid-content" style={{ width: "300px" }}>
                    {string.noticeString[detailData.category]}
                  </td>
                  <td className="grid-title" style={{ width: "100px" }}>
                    메인 노출
                  </td>
                  <td className="grid-content" style={{ width: "300px" }}>
                    <Checkbox
                      onChange={e => {
                        this.onChangeModal(e.target.checked, "checked");
                      }}
                      checked={detailData.main === 1 ? true : false}>
                      노출
                    </Checkbox>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {global.lanList.map((lan, index) => (
            <table key={index}>
              <tbody>
                <tr>
                  <td className="grid-title" style={{ width: "100px" }}>
                    제목({lan})
                  </td>
                  <td
                    className="grid-content-subText"
                    style={{ width: "700px" }}>
                    <Input
                      allowClear
                      value={
                        detailData.title.find(item => item.language === lan)
                          .content
                      }
                      onChange={e => {
                        this.onChangeModal(e.target.value, "title", lan);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="grid-title">내용({lan})</td>
                  <td className="grid-content-subText">
                    <Input.TextArea
                      value={
                        detailData.content.find(item => item.language === lan)
                          .content
                      }
                      onChange={e => {
                        this.onChangeModal(e.target.value, "content", lan);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      </Modal>
    );
  }
}

export default DetailModal;
