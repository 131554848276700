import React, { Component } from "react";

import { Table, Input, Button, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import fileDownload from "js-file-download";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import { numberFormat, dayFormat,
  amountFormat, startDateFormat, endDateFormat } from "../../util";

const Search = Input.Search;  
const RangePicker = DatePicker.RangePicker;

class GlobalShareDetailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailList: [],
      pagination: {
        total: 0,
        current: 0,
      },
      searchText: "",
      startDate: "",
      endDate: "",
      selectedData: {},
      isLoaded: true,
      orderByType: "DESC",
    };
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getGlobalShareDetailList({
      pageSize: 10,
      pageNum: pager.current,
      searchText: this.state.searchText,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });
  };
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getGlobalShareDetailList({
          pageSize: 10,
          pageNum: 1,
          searchText: this.state.searchText,
          startDate: this.state.startDate,
          endDate: this.state.endDate
        });
      }
    );
  };
  downloadExcel = () => {
    const{
      searchText,
      startDate,
      endDate,
      pagination,
      isLoaded,
    } = this.state;

    let result = [];

    if (startDate !== "" && endDate !== "") {
      result.push(`${startDate} ~ ${endDate} `);
    }

    let total = pagination.total <= 9 ? 10 : pagination.total;

    if (isLoaded) {
      alert("검색버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.downloadGlobalShareDetail,
          [
            total,
            1,
            searchText,
            startDate,
            endDate,
          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `수익분배 상세내역.xlsx`);
          })
          .catch((err) => { }); 
      }
    }
  };

  getGlobalShareDetailList = (params = {}) => {
    const { searchText,startDate,endDate,orderByType } = this.state;
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getGlobalShareDetailList,
      [10, pageNum,searchText,startDate,endDate,orderByType],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          detailList: res.data.list,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          pagination
        });
      })
      .catch((error) => { });
  };

  componentDidMount() {
    this.getGlobalShareDetailList();
  }

  render() {
    const { detailList, selectedData, } = this.state;

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "타입",
        key: "shareType",
        dataIndex: "shareType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data === null ? "-" : data),
      },
      {
        title: "레벨",
        key: "shareLevel",
        dataIndex: "shareLevel",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data === null ? "-" : data),
      },
      {
        title: "정산 금액",
        dataIndex: "amount",
        key: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, 101)}</div>,
      },
      {
        title: "날짜",
        dataIndex: "logDate",
        key: "logDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{dayFormat(data)}</div>,
      },
      {
        title: "배분율",
        key: "shareRatio",
        dataIndex: "shareRatio",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>{data === null ? "-" : `${numberFormat(data*100)} %`}</div>
        ),
      },
      {
        title: "충전번호",
        dataIndex: "userIdx",
        key: "userIdx",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "충전인(이름/아이디)",
        dataIndex: "userName",
        key: "userName",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {data} / {row.userId}
          </div>
        ),
      },
      {
        title: "전화번호",
        dataIndex: "phone",
        key: "phone",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data === null ? "-" : data),
      },
    ];
    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          수익배분 상세내역
        </div>
        <div className="flex-row flex-center">
            <label>검색기간&nbsp;</label>
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </div>
         <div className="flex-row flex-center m-b-10">
            <label>검색기준&nbsp;</label>
            <Search
              placeholder="이름, 아이디, 전화번호"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
          <div className="flex-row flex-center flex-end m-b-10">
          <Button onClick={this.downloadExcel}>엑셀 다운로드</Button>
        </div>
        <Table
          id="admin-table"
          rowKey={(record) => record.idx}
          dataSource={detailList}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default GlobalShareDetailList;
