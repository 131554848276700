import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Button, Modal, InputNumber } from "antd";

import { getLimitAmount } from "../../contexts/asyncContext";
import {
  formatDate,
  amountFormat,
  coinMultiFormat,
  coinDivideFormat
} from "../../util";
import { httpPut, httpUrl } from "../../api/httpClient";

const { confirm } = Modal;

/**
 * 2레벨제한이 있을 경우 1레벨 삭제 불가
 * 2레벨제한 금액이 1레벨 제한 금액보다 커야 한다.
 * 둘다 금액이 0일 경우는 예외
 * 2레벨제한 금액이 0이 아닐때 1레벨제한 금액이 0일 수 없다.
 */

class LimitAmount extends Component {
  state = {
    selectedCoin: 0,
    selectedData: [],
    prevData: []
  };

  handleClickCoin = (params = {}) => {
    let coin = [];
    this.props.result.data.forEach((value, index) => {
      if (value.coinType === params.row.coinType) {
        let obj = Object.assign(
          {},
          {
            coinType: value.coinType,
            securityLevel: value.securityLevel,
            limit: coinDivideFormat(value.limit, value.coinType),
            deleted: value.deleted
          }
        );
        coin.push(obj);
      }
    });

    if (params.clickType === "modify") {
      // 수정 버튼 클릭
      if (coin[0].deleted === 1 && coin[1].deleted === 1) {
        alert(`수정할 수 없습니다. 레벨제한을 해제해주세요.`);
      } else {
        this.setState({
          selectedCoin: {
            coinType: params.row.coinType
          },
          selectedData: coin
        });
      }
    } else {
      //삭제 or 복구 버튼 클릭
      // 1레벨제한이 2레벨 제한보다 크고 2레벨이 삭제상태
      if (
        coin[0].limit >= coin[1].limit &&
        coin[0].deleted === 0 &&
        params.row.securityLevel === 2 &&
        coin[1].limit !== 0
      ) {
        // 제한금액이 input으로...
        this.setState({
          selectedCoin: {
            index: params.index
          },
          selectedData: coin
        });
      } else if (
        coin[0].deleted === 1 &&
        coin[1].deleted === 1 &&
        params.row.securityLevel === 2
        // 2레벨 복구시 1레벨도 삭제되어 있을 경우
      ) {
        alert("1레벨제한을 먼저 복구해주세요.");
      } else if (coin[0].limit < coin[1].limit && coin[0].limit === 0) {
        alert(
          "제한 금액을 확인해주세요.\n2레벨 제한금액이 0이 아닐 때 1레벨 제한금액 0일 수 없습니다."
        );
      } else if (
        params.row.securityLevel === 1 &&
        (coin[1].deleted === 0 || coin[1].deleted === null)
      ) {
        alert("2레벨제한이 존재하기 때문에 1레벨제한을 삭제할 수 없습니다.");
      } else {
        const coinList = reactLocalStorage.getObject("coinList");

        this.setState(
          {
            selectedData: coin
          },
          () => {
            confirm({
              title: `${
                coinList.find(coin => coin.coinType === params.row.coinType)
                  .name
              }의 ${params.row.securityLevel}레벨제한을 ${
                params.row.deleted === 1 ? "복구" : "삭제"
              } 하시겠습니까?`,
              onOk: () => {
                this.onDelete({
                  row: params.row,
                  coin: this.state.selectedData
                });
              },
              onCancel() {
                // console.log("Cancel");
              }
            });
          }
        );
      }
    }
  };

  onUpdate = () => {
    let updateData = this.state.selectedData.map(value => {
      return {
        ...value,
        limit: coinMultiFormat(value.limit, value.coinType)
      };
    });
    if (
      updateData[0].limit >= updateData[1].limit &&
      updateData[1].deleted !== 1 &&
      updateData[0].limit !== 0 &&
      updateData[1].limit !== 0
      // 1레벨제한금액이 더크거나 같고 2레벨이 삭제 되지 않았을 때,
      // updateData[0].limit + updateData[1].limit !== 0
    ) {
      alert(
        "제한 금액을 확인해주세요.\n2레벨 제한금액이 1레벨 제한금액 보다 커야 합니다."
      );
    } else if (
      updateData[0].limit === 0 &&
      updateData[1].limit !== 0 &&
      updateData[1].deleted === 0
      // 2레벨제한 금액이 0이 아닐때 1레벨제한 금액이 0일 수 없다.
    ) {
      alert(
        "제한 금액을 확인해주세요.\n2레벨 제한금액이 0이 아닐 때 1레벨 제한금액 0일 수 없습니다."
      );
    } else {
      // console.log(JSON.stringify(updateData, null, 4));
      if (global.confirm(`변경하시겠습니까?`)) {
        httpPut(httpUrl.limitUpdate, [], updateData)
          .then(res => {
            this.setState({
              selectedCoin: 0
            });
            this.props.getLimitAmount();
          })
          .catch(err => {});
      }
    }
  };

  onDelete = (params = {}) => {
    if (
      // 1레벨제한이 더 크고 2레벨을 삭제할때
      params.coin[0].limit > params.coin[1].limit &&
      params.coin[1].deleted === 0 &&
      params.coin[1].limit !== 0
    ) {
      alert(
        "제한 금액을 확인해주세요.\n2레벨 제한금액이 1레벨 제한금액 보다 커야 합니다."
      );
    } else if (
      //1레벨제한이 더 크고 2레벨제한 복구
      params.coin[0].limit >= params.coin[1].limit &&
      params.row.securityLevel === 2 &&
      params.coin[1].limit !== 0
    ) {
      alert(
        "제한 금액을 확인해주세요.\n2레벨 제한금액이 1레벨 제한금액 보다 커야 합니다."
      );
    } else if (
      params.row.securityLevel === 2 &&
      params.coin[0].deleted === 1 &&
      params.coin[1].deleted === 1
      // 둘다 삭제일때 2레벨 복구를 클릭했을 경우
    ) {
      alert("복구할 수 없습니다. 1레벨제한을 먼저 복구해주세요.");
    } else {
      let deleteData = {};
      params.coin.forEach(value => {
        if (params.row.securityLevel === value.securityLevel) {
          deleteData = Object.assign(
            {},
            {
              ...value,
              limit: coinMultiFormat(value.limit, value.coinType)
            }
          );
        }
      });
      // console.log(JSON.stringify(deleteData, null, 4));
      httpPut(
        httpUrl.limitDelete,
        [deleteData.coinType, deleteData.securityLevel, deleteData.limit],
        {}
      )
        .then(res => {
          this.setState({
            selectedCoin: 0
          });
          this.props.getLimitAmount();
        })
        .catch(err => {});
    }
  };

  renderInputNumber = row => {
    const { selectedData } = this.state;
    return (
      <InputNumber
        defaultValue={
          row.securityLevel === 1
            ? selectedData[0].limit
            : selectedData[1].limit
        }
        // min={0.0000000001}
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={value => value.replace(/\$\s?|(,*)/g, "")}
        onChange={value => {
          if (row.securityLevel === 1) selectedData[0].limit = value;
          else selectedData[1].limit = value;
          this.setState({
            selectedData: selectedData
          });
        }}
        style={{ width: "200px" }}
      />
    );
  };

  componentDidMount() {
    this.props.getLimitAmount();
  }

  render() {
    const { selectedCoin, selectedData } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const columns = [
      {
        title: "코인종류",
        dataIndex: "coinType",
        key: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => {
          const obj = {
            children: (
              <span>
                {coinList.find(coin => coin.coinType === data)
                  ? coinList.find(coin => coin.coinType === data).name
                  : ""}
              </span>
            ),
            props: {
              width: 120
            }
          };
          if (index % 2 === 0) obj.props.rowSpan = 2;
          else obj.props.rowSpan = 0;
          return obj;
        }
      },
      {
        title: "보안레벨",
        dataIndex: "securityLevel",
        key: "securityLevel",
        width: 120,
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "제한금액",
        dataIndex: "limit",
        width: 300,
        key: "limit",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedCoin.coinType === row.coinType && row.deleted !== 1 ? (
              this.renderInputNumber(row)
            ) : selectedCoin.index === index ? (
              this.renderInputNumber(row)
            ) : (
              <span>{amountFormat(data, row.coinType)}</span>
            )}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        key: "deleted",
        width: 180,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <span>{data === 0 || data === null ? "-" : "삭제"}</span>
        )
      },
      {
        title: "제한 생성일",
        dataIndex: "createDate",
        key: "createDate",
        width: 200,
        className: "support-column-number fs-15 lh-21",
        render: data => <span>{data === null ? "-" : formatDate(data)}</span>
      },
      {
        title: "",
        key: "modifyStatus",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => {
          const obj = {
            children: (
              <div>
                {selectedCoin.coinType === row.coinType ? (
                  <div style={{ flexDirection: "row" }}>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        this.onUpdate();
                      }}>
                      확인
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({
                          selectedCoin: 0
                        });
                      }}>
                      취소
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      this.handleClickCoin({
                        row: row,
                        clickType: "modify"
                      });
                    }}>
                    수정
                  </Button>
                )}
              </div>
            ),
            props: {
              width: 180
            }
          };
          if (index % 2 === 0) obj.props.rowSpan = 2;
          else obj.props.rowSpan = 0;
          return obj;
        }
      },
      {
        title: "",
        key: "deleteStatus",
        width: 180,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {row.createDate !== null ? (
              selectedCoin.index === index ? (
                <div style={{ flexDirection: "row" }}>
                  <Button
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      this.onDelete({
                        row: row,
                        coin: selectedData
                      });
                    }}>
                    확인
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ selectedCoin: 0, selectedData: [] });
                    }}>
                    취소
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={() => {
                    this.handleClickCoin({
                      row: row,
                      index: index,
                      clickType: "delete"
                    });
                  }}>
                  {row.deleted === 0 || row.deleted === null ? "삭제" : "복구"}
                </Button>
              )
            ) : null}
          </div>
        )
      }
    ];

    return (
      <div style={{ paddingLeft: 48, paddingRight: 48 }}>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          제한금액관리
        </div>
        <Table
          id="limitAmount-table"
          tableLayout="fixed"
          rowKey={record => `${record.coinType}${record.securityLevel}`}
          columns={columns}
          dataSource={this.props.result.data}
          pagination={false}
          bordered
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getLimitAmount: () => dispatch(getLimitAmount())
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(LimitAmount);
