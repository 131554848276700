export default {

  /////////////////////////개발/////////////////////////////

  // appName: 'stemwallet',

  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "19001",
  // // serverPort: "18001",
  // clientIp: "localhost",
  // clientPort: "3000",

  // isFoundationInFee: false,
  // isPurchaseInFee: true,

  // //naver map client key
  // clientId: "",
  // clientSecret: "",
  // languages: [
  //   {code: 'Ko', name: '한국어'},
  //   {code: 'En', name: '영어'},
  //   {code: 'Cn', name: '중국어'},
  //   {code: 'Ja', name: '일본어'},
  //   {code: 'Vi', name: '베트남어'},
  // ],

  // 개발용DB STEM
  // appName: 'stemwallet',
  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "58545",
  // // serverPort: "18001",
  // clientIp: "localhost",
  // clientPort: "3000",

  // isFoundationInFee: false,
  // isPurchaseInFee: true,

  // //naver map client key
  // clientId: "",
  // clientSecret: "",
  // languages: [
  //   {code: 'Ko', name: '한국어'},
  //   {code: 'En', name: '영어'},
  //   {code: 'Cn', name: '중국어'},
  //   {code: 'Ja', name: '일본어'},
  //   {code: 'Vi', name: '베트남어'},
  // ],

  // appName: 'sbwallet',

  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "58545",
  // // serverPort: "58545",
  // clientIp: "localhost",
  // clientPort: "3000",

  // isFoundationInFee: false,
  // isPurchaseInFee: true,

  // //naver map client key
  // clientId: "",
  // clientSecret: "",
  // languages: [
  //   {code: 'Ko', name: '한국어'},
  //   {code: 'En', name: '영어'},
  //   {code: 'Cn', name: '중국어'},
  //   {code: 'Ja', name: '일본어'},
  //   {code: 'Vi', name: '베트남어'},
  // ],



  ///////////////////////////운영/////////////////////////////

  // SB wallet
  // serverProtocol: "https",
  // appName: 'sbwallet',
  // isFoundationInFee: false,
  // isPurchaseInFee: true,
  // serverIp: "back.sbpay.co.kr",
  // serverPort: "48545",
  // clientIp: "admin.sbpay.co.kr",
  // clientPort: "443",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // languages: [
  //   {code: 'Ko', name: '한국어'},
  // ],

  // cbc wallet
  // serverProtocol: "https",
  // appName: 'cbcwallet',
  // isFoundationInFee: false,
  // isPurchaseInFee: true,
  // serverIp: "back.otcex.io",
  // serverPort: "19001",
  // clientIp: "admin.otcex.io",
  // clientPort: "443",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // languages: [
  //   {code: 'Ko', name: '한국어'},
  //   {code: 'En', name: '영어'},
  //   {code: 'Cn', name: '중국어'},
  //   {code: 'Ja', name: '일본어'},
  //   {code: 'Vi', name: '베트남어'},
  // ],

  // tiger wallet
  serverProtocol: "https",
  appName: 'tigerwallet',
  isFoundationInFee: false,
  isPurchaseInFee: true,
  serverIp: "back.bit-tiger.kr",
  serverPort: "19001",
  clientIp: "admin.bit-tiger.kr",
  clientPort: "443",
  // naver map client key
  clientId: "r06yvi25vl",
  clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  languages: [
    {code: 'Ko', name: '한국어'},
    {code: 'En', name: '영어'},
    {code: 'Cn', name: '중국어'},
    {code: 'Ja', name: '일본어'},
    {code: 'Vi', name: '베트남어'},
  ],

  // tmed wallet
  // serverProtocol: "https",
  // appName: 'MDtalk WALLET',
  // isFoundationInFee: false,
  // isPurchaseInFee: true,
  // serverIp: "admin.mdsqr.io",
  // serverPort: "19001",
  // clientIp: "admin.mdsqr.io",
  // clientPort: "443",
  // // naver map client key
  // clientId: "",
  // clientSecret: "",
  // languages: [
  //   {code: 'Ko', name: '한국어'},
  // ],

 // stem wallet
  // serverProtocol: "https",
  // appName: 'stemwallet',
  // isFoundationInFee: false,
  // isPurchaseInFee: true,
  // serverIp: "back.l7stem.com",
  // serverPort: "19001",
  // clientIp: "admin.l7stem.com",
  // clientPort: "8000",
  // // naver map client key 
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // languages: [
  //   {code: 'Ko', name: '한국어'},
  //   {code: 'En', name: '영어'},
  //   {code: 'Cn', name: '중국어'},
  //   {code: 'Ja', name: '일본어'},
  //   {code: 'Vi', name: '베트남어'},
  // ],



  // plap wallet
  // serverProtocol: "https",
  // appName: 'plapwallet',
  // isFoundationInFee: false,
  // isPurchaseInFee: true,
  // serverIp: "back.plapwallet.com",
  // serverPort: "19001",
  // clientIp: "admin.plapwallet.com",
  // clientPort: "8000",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // languages: [
  //   {code: 'Ko', name: '한국어'},
  // ],

  // mozex wallet
  // serverProtocol: "https",
  // appName: 'mozexwallet',
  // isFoundationInFee: false,
  // isPurchaseInFee: true,
  // serverIp: "back.mozexwallet.com",
  // serverPort: "19001",
  // clientIp: "admin.mozexwallet.com",
  // clientPort: "8000",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // languages: [
  //   {code: 'Ko', name: '한국어'},
  // ],


};


