import { DatePicker, Input, Table, Modal, Button } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpPost, httpUrl, serverUrl } from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { amountFormat, formatDate } from "../../util";



const Search = Input.Search;
const { RangePicker } = DatePicker;

class KycList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      tables: [],
      pagination: {
        total: 0,
        current: 1
      },
    };
  }

  componentDidMount() {
    this.getList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, ()=>this.getList());
  };

  initialLoad = () => {
    this.setState({
      tables: [],
      pagination: {
        total: 0,
        current: 1
      }}, () => {
        this.getList();
      })
  }
  reload = () => {
    this.getList();
  }
  getList = () => {
    let pageNum = this.state.pagination.current;
    httpGet(httpUrl.kycList,[10,pageNum,this.state.searchText, 'ID'],{}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        tables: res.data.list,
        pagination,
      });
    });
  };
  approve = (idx, status) => {
    const statusString = [
      '신청중',
      '승인',
      '거절'
    ];
    if (global.confirm(idx+'번 신청을 ' +statusString[status]+ ' 하시겠습니까?')) {
      httpPost(httpUrl.kycApprove,[],{
        idx, status
      }).then((res) => {
        if (res.data) {
          this.getList();
        }
        else {
          alert('실패하였습니다.')
        }
      }).catch(e=>{
        alert('실패하였습니다. 사용자가 OTP인증을 하지않았을 수 있습니다.')
      });
    }
    
  }
  render() {
    const { tables } = this.state;
    const statusString = [
      '신청중',
      '승인',
      '거절'
    ]
    const columns = [
      {
        title: "신청번호",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "아이디",
        dataIndex: "userId",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이름",
        dataIndex: "userName",
        className: "support-column fs-15 lh-21",
      },
      {
        title: "연락처",
        dataIndex: "userPhone",
        className: "support-column fs-15 lh-21",
      },
      {
        title: "사진",
        dataIndex: "fileIdx",
        className: "support-column fs-15 lh-21",
        render: (data,row) => {
          return (<div style={{cursor: 'pointer'}} onClick={()=>{
            this.setState({selectedImage: data})
          }}><img height={200} src={serverUrl + httpUrl.getFile2.replace('{idx}',data)}/></div>);
        }
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "support-column fs-15 lh-21",
        render: (data) => <div>{statusString[data]}</div>
      },
      {
        title: "승인",
        dataIndex: "status",
        className: "support-column fs-15 lh-21",
        render: (data, row) => 
        <div>
          {data == 0 ? (<>
          <Button onClick={()=>{
            this.approve(row.idx, 1)
          }}>승인</Button>
          <Button onClick={()=>{
            this.approve(row.idx, 2)
          }} style={{marginLeft: '5px'}}>거절</Button>
          </>) : (<></>)}
        </div>
      },
      {
        title: "신청일",
        dataIndex: "createDate",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          KYC 목록
      </div>
        <div className="wrapper mt-35 width-1240" />
          <div className="flex-row flex-center" style={{paddingBottom: '10px'}}>
            <Search
              placeholder={`아이디를 입력해주세요.`}
              enterButton="검색"
              allowClear
              onSearch={(value) => {
                this.setState({searchText: value}, ()=>this.reload())
              }}
              style={{ width: 400 }}
            />
          </div>
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={tables}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
        <Modal
          className="width-1240"
          visible={this.state.selectedImage}
          onCancel={()=>this.setState({selectedImage: null})}
          footer={null}>
          <div
            style={{
            }}>
              <img width={800} src={serverUrl + httpUrl.getFile2.replace('{idx}',this.state.selectedImage)}/>
          </div>
        </Modal>
      </div>
    );
  }
}

export default KycList;
