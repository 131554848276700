import React, { Component } from 'react'
import { httpGet, httpUrl,httpPost, httpDelete,httpPut,serverUrl} from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form ,Icon , Modal, Upload,Divider} from 'antd'
import { reactLocalStorage } from "reactjs-localstorage";
import {
 PlusOutlined
  } from '@ant-design/icons';
import OrganizationRegistry from "../../components/payment/OrganizationRegistry";
import {
   formatDate,
 
  numberFormat,
  startDateFormat,
  endDateFormat
} from "../../util";
import string from "../../string";
import locale from "antd/es/date-picker/locale/ko_KR";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";



class FranchiseApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
      pagination: {
        total: 0,
        current: 0,
      },
      
      list: [],
      visible: false,
      franchiseVisible: false,
      uploadFiles: [],
      createVisible: false,
     
    };


  }
 
  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getFranchiseApplyList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getFranchiseApplyList({});

      }
    );
  };


  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "idx") {
      data = row;
    } else if (type === "count") {
      data.count = value;
    } else {
      data.count = value;
    }
    this.setState({
      selectData: data
    });
  };

  



  getFranchiseApplyList = (params = {}) => {
    const{searchText} = this.state
 
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getFranchiseApplyList, [10, pageNum])
      .then(res => {
        // console.own(JSON.stringify(result.data.workOwnList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.applyList,
          pagination,
        })
      })

  }

  onUpdateFranchise = (params ={}) =>{
    let updateData = {
    idx: params.idx,
    franchiseValid: params.franchiseValid,
  
    };
  
     httpPost(httpUrl.updateFranchiseApplyList, [], updateData).then((res) => {
        this.getFranchiseApplyList({
        pageNum: this.state.pagination.current,
     });
       showAlert("valid");
  
    });
   };
 

  searchTypeHandleChange = (value) => {
    // console.own(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  componentDidMount() {
    this.getFranchiseApplyList();
  }
  

  render() {
  

    const {
     
      createVisible,
     
    } = this.state;
    let classificationString = [
      '',
      '총판',
      '지사',
      '대리점',
      '가맹점',
    ]

    const uploadFileProps = {
      action: serverUrl + httpUrl.fileUpload,
      multiple: false,
      withCredentials: true,
      beforeUpload: (file, fileList) => {
      },
      onSuccess: (file) => {
        // console.log(`file = ${JSON.stringify(file, null, 4)}`);
        if (file.data.result) {
          Modal.info({
            title: "업로드 결과",
            content: "파일 업로드 성공"
          });
          this.state.uploadFiles.push({idx: file.data.idx, name: file.data.filename})
          this.setState({
            uploadFiles: this.state.uploadFiles
          });
          
        }
      },
      onError(err) {
        Modal.error({
          title: "업로드 결과",
          content: "파일 업로드 실패"
        });
      }
    };

    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      // {
      //   title: "유저번호",
      //   key: "userIdx",
      //   dataIndex: "userIdx",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data) => 
      //   <div>{data}</div>    
      // },
      {
        title: "신청인",
        key: "userName",
        dataIndex: "userName",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => 
        <div>{data}<div style={{fontSize:12}}>{row.userId}</div></div>    
      },
      {
        title: "대표자",
        key: "owner",
        dataIndex: "owner",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "생년월일",
        key: "birthday",
        dataIndex: "birthday",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "가맹점명",
        key: "franchiseName",
        dataIndex: "franchiseName",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "업종",
        key: "franchiseType",
        dataIndex: "franchiseType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
     
      {
        title: "주소",
        key: "franchiseAddress",
        dataIndex: "franchiseAddress",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "사업자번호",
        key: "franchiseNumber",
        dataIndex: "franchiseNumber",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "유선번호",
        key: "franchisePhone",
        dataIndex: "franchisePhone",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "폰번호",
        key: "phone",
        dataIndex: "phone",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "이메일",
        key: "email",
        dataIndex: "email",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      // {
      //   title: "신청인",
      //   key: "offeror",
      //   dataIndex: "offeror",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data) => 
      //   <div>{data}</div>    
      // },
      // {
      //   title: "첨부파일",
      //   key: "uploadFile",
      //   dataIndex: "uploadFile",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data,row) => 
      //   <div>{data}  </div>
      // },
      {
        title: "첨부파일",
        key: "fileName",
        dataIndex: "fileName",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => {
          return (<div>
          {row.uploadFile.split(',').map(data => {
            return (<div><a href={serverUrl + httpUrl.getFile2.replace('{idx}',data)} target='_blank'>{data}</a></div>);
            }) }
          </div>);
      }
},
  
      {
        title: "신청일자",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{formatDate(data)}</div>    
      },
      {
        title: "",
        key: "franchiseValid",
        dataIndex: "franchiseValid",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => 
        <div>{row.franchiseValid === 1 ?         
          <Button
          onClick={() => {
          if (global.confirm(row.idx + '번 승인 처리 하시겠습니까?')) {
            this.onUpdateFranchise({
              idx: row.idx,
              franchiseValid: 0,
              
            });
          }
        }}
        >승인</Button> : <div /> } 
         {row.franchiseValid === 0 ?         
          <Button
          onClick={() => {
            alert("완료 처리된 건입니다.")
          }}
          >완료</Button> : <div /> }
        
        </div>    
      },
     
     
      
   
   

    ];




    return (
      <div>
      <div>
 {createVisible && (
          <OrganizationRegistry
            visible={createVisible}
            onOk={() => {
              this.setState({
                createVisible: false
              });
              this.getOrganiztionList();
            }}
            onCancel={() => {
              this.setState({
                createVisible: false
              });
            }}
          />
        )}

        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
          
            fontWeight: "bold",
          }}>
           <Divider orientation="left" style={{fontSize:23}}>가맹점신청 목록 </Divider>  
          
          <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ createVisible: true });
            }}>
            <PlusOutlined /> 생성
          </Button>
         </div>

          
        </div>
        {/* 메모입력,수정 Modal */}
        
        </div>
        
        <Table
        
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px' }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
         

      </div>
    )


  }
}

export default FranchiseApply;
