import React, { Component } from "react";
import {
  Modal,
  Descriptions,
  Input,
  Upload,
  InputNumber,
  Button,
  Icon,
  Select
} from "antd";
import { httpPut, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import "../../index.css";
import {
  amountFormat,
  coinMultiFormatByDecimal,
  coinDivideFormat
} from "../../util";
import { reactLocalStorage } from "reactjs-localstorage";
import { showAlert } from "../common/AlertModal";

const Ditems = Descriptions.Item;
const { Option } = Select;

export default class TokenDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailData: {
        image: "",
        decimal: "18",
        symbol: "",
        category: "",
        coinType: "",
        name: "",
        rank: "",
        fee: "",
        contract: "",
        abi: ""
      }
    };
  }

  onUpdate = () => {
    const { detailData } = this.state;
    let updateData = {};
    if (detailData.category === "MAINNET") {
      updateData = {
        idx: detailData.idx,
        image: detailData.image,
        symbol: detailData.symbol,
        decimal: detailData.decimal,
        category: detailData.category,
        coinType: detailData.coinType,
        name: detailData.name,
        nameIdx: detailData.nameIdx,
        estimationGas: detailData.estimationGas,
        fee: coinMultiFormatByDecimal(detailData.fee, detailData.decimal)
      };
    } else {
      updateData = {
        ...detailData,
        image: detailData.image,
        symbol: detailData.symbol,
        decimal: detailData.decimal,
        category: detailData.category,
        coinType: detailData.coinType,
        name: detailData.name,
        rank: this.props.lastRank,
        estimationGas: detailData.estimationGas,
        fee: coinMultiFormatByDecimal(detailData.fee, detailData.decimal),
        contract: detailData.contract,
        abi: detailData.abi
      };
    }

    let validateArr = [];
    for (let value in updateData) {
      if (value !== "deleted") {
        if (value === "name") {
          if (updateData[value].every((item) => item.content === "")) {
            validateArr.push(value);
          }
        }
        if (!updateData[value]) {
          validateArr.push(value);
        }
      }
    }
    if (validateArr.length !== 0) {
      alert(
        `${validateArr.reduce((acc, item, index) => {
          if (index === validateArr.length - 1) {
            return `${acc + item}`;
          } else {
            return `${acc + item}, `;
          }
        }, "")} 이(가) 입력되지 않았습니다. 확인해주세요.`
      );
    } else {
      // console.log(JSON.stringify(updateData, null, 4));
      if (global.confirm(`수정하시겠습니까?`)) {
        httpPut(httpUrl.blockChainUpdate, [], updateData)
          .then((res) => {
            showAlert("update");
            this.props.onOk();
            this.onCancel();
          })
          .catch((error) => {});
      }
      //  else this.onCancel();
    }
  };

  onCreate = async () => {
    const { detailData } = this.state;
    let createData = {};
    if (detailData.category === "MAINNET") {
      createData = {
        image: detailData.image,
        symbol: detailData.symbol,
        decimal: detailData.decimal,
        category: detailData.category,
        coinType: detailData.coinType,
        name: detailData.name,
        rank: this.props.lastRank,
        estimationGas: detailData.estimationGas,
        fee: coinMultiFormatByDecimal(detailData.fee, detailData.decimal)
      };
    } else {
      createData = {
        image: detailData.image,
        symbol: detailData.symbol,
        decimal: detailData.decimal,
        category: detailData.category,
        coinType: detailData.coinType,
        name: detailData.name,
        rank: this.props.lastRank,
        estimationGas: detailData.estimationGas,
        fee: coinMultiFormatByDecimal(detailData.fee, detailData.decimal),
        contract: detailData.contract,
        abi: detailData.abi
      };
    }
    // 입력하지 않은 값을 validate
    let validateArr = [];
    for (let value in createData) {
      if (value === "name") {
        if (createData[value].every((item) => item.content === "")) {
          validateArr.push(value);
        }
      }
      if (!createData[value]) {
        validateArr.push(value);
      }
    }
    if (validateArr.length !== 0) {
      alert(
        `${validateArr.reduce((acc, item, index) => {
          if (index === validateArr.length - 1) {
            return `${acc + item}`;
          } else {
            return `${acc + item}, `;
          }
        }, "")} 이(가) 입력되지 않았습니다. 확인해주세요.`
      );
    } else {
      // console.log(JSON.stringify(createData, null, 4));
      if (global.confirm(`생성하시겠습니까?`)) {
        httpPost(httpUrl.blockChainCreate, [], createData).then((res) => {
          if (res.data !== 0) {
            showAlert("create");
            this.props.onOk();
            this.onCancel();
          } else {
            alert("중복된 coinType이 있습니다.");
          }
        });
      }
    }
  };

  onChangeModal = (value, type, language) => {
    var data = Object.assign({}, this.state.detailData);
    if (type === "symbol") {
      data.symbol = value;
    } else if (type === "name") {
      let arr = data.name.map((name) => {
        if (name.language === language) {
          return {
            content: value,
            language: language
          };
        } else {
          return name;
        }
      });
      data[type] = arr;
    } else if (type === "category") {
      if (value === "ERC20") data.estimationGas = 50000;
      else data.estimationGas = 0;
      data.category = value;
    } else {
      data[type] = value;
    }
    this.setState({
      detailData: data
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.modalType !== this.props.modalType &&
      this.props.modalType === "create"
    ) {
      this.setState({
        detailData: {
          image: "",
          decimal: "18",
          category: "MAINNET",
          fee: 0,
          coinType: this.props.lastCoinType,
          rank: this.props.lastRank,
          estimationGas: 0,
          name: global.lanList.map((lan) => {
            return {
              content: "",
              language: lan
            };
          })
        }
      });
    }
    if (
      this.props.modalData.idx !== prevProps.modalData.idx &&
      this.props.modalType === "edit"
    ) {
      const obj = Object.assign({}, this.props.modalData);
      // console.log(`${JSON.stringify(obj, null, 4)}`);
      this.setState({
        detailData: {
          ...obj,
          fee: coinDivideFormat(obj.fee, obj.coinType),
          name: global.lanList.map((lan) => {
            if (
              this.props.modalData.name.find((item) => item.language === lan)
            ) {
              return this.props.modalData.name.find(
                (item) => item.language === lan
              );
            } else {
              return {
                content: "",
                language: lan
              };
            }
          })
        }
      });
    }
  }

  onOk = async () => {
    const coinList = reactLocalStorage.getObject("coinList");
    const tempData = Object.assign({}, this.state.detailData);
    let isDupl = false;
    let coinData = {};
    coinList.forEach((coin) => {
      //입력받은 coinType은 string, coinList의 coinType은 number
      if (coin.coinType === parseInt(tempData.coinType)) {
        isDupl = true;
        coinData = coin;
      }
    });

    if (this.props.modalType === "create") {
      if (isDupl) {
        alert(
          `중복된 coinType이 있습니다.\n${coinData.name} : ${coinData.coinType}`
        );
      } else {
        this.onCreate();
      }
    } else {
      if (
        isDupl &&
        parseInt(tempData.coinType) !== parseInt(this.props.modalData.coinType)
      ) {
        alert(
          `중복된 coinType이 있습니다.\n${coinData.name} : ${coinData.coinType}`
        );
      } else {
        this.onUpdate();
      }
    }
  };

  onCancel = () => {
    this.setState(
      {
        detailData: ""
      },
      () => {
        this.props.onCancel();
      }
    );
  };

  render() {
    const { detailData } = this.state;
    const { visible, modalType, modalData } = this.props;
    const imageProps = {
      action: serverUrl + httpUrl.imageEncoding,
      multiple: false,
      withCredentials: true,
      beforeUpload: (file) => {
        if (file.type !== "image/png") {
          alert("이미지파일은 png형식만 업로드 가능합니다.");
          return false;
        } else {
          if (global.confirm("업로드 하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        }
      },
      onSuccess: (file) => {
        // if (modalType === "edit") {
        //   //onOk 바깥에 table data refresh
        //   this.props.onOk();
        //   //refresh 수정 상태에서 image업로드 할 경우 사용
        //   this.props.refresh({
        //     row: detailData
        //   });
        // }
        try {
          this.onChangeModal(file.data.encodingImage, "image");
        } finally {
          alert("이미지 업로드 완료");
        }
      },
      onError(err) {
        alert("이미지 업로드 실패");
      }
    };

    let estimationGas = 1;

    if (detailData.symbol === "ETH") {
      estimationGas = 21000;
    } else if (detailData.category === "ERC20") {
      estimationGas = 50000;
    }

    return (
      <div>
        <Modal
          visible={visible}
          style={{ minWidth: "800px" }}
          title={modalType === "edit" ? "상세페이지" : "생성하기"}
          okText={modalType === "edit" ? "변경사항저장" : "생성"}
          cancelText="취소"
          onOk={this.onOk}
          onCancel={this.onCancel}>
          {/* <div>{JSON.stringify(detailData, null, 4)}</div> */}
          <Descriptions
            title={modalType === "edit" ? modalData.symbol : null}
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
            <Ditems label="symbol">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}>
                <Upload {...imageProps} showUploadList={false}>
                  {detailData.image ? (
                    <img
                      src={detailData.image}
                      alt="symbolImage"
                      style={{
                        width: "18px",
                        marginRight: "8px",
                        cursor: "pointer"
                      }}
                    />
                  ) : (
                    <Button style={{ marginRight: "8px" }}>
                      <Icon type="upload" />
                      이미지 등록
                    </Button>
                  )}
                </Upload>
                <Input
                  allowClear
                  value={detailData.symbol}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "symbol");
                  }}
                  placeholder="ex) BTC"
                />
              </div>
              {detailData.image && (
                <div
                  style={{
                    fontSize: "12px",
                    float: "left",
                    color: "red",
                    marginTop: "5px"
                  }}>
                  이미지를 변경하고 싶으시면 이미지를 클릭하세요.
                </div>
              )}
            </Ditems>

            <Ditems label="decimal">
              {/* {modalType === "edit" ? :} */}
              <Input
                allowClear
                value={detailData.decimal}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "decimal");
                }}
              />
            </Ditems>
            <Ditems label="category">
              {/* <Input
                value={detailData.category}
                onChange={e => {
                  this.onChangeModal(e.target.value, "category");
                }}
              /> */}
              <Select
                value={detailData.category}
                style={{ width: 120 }}
                onChange={(value) => {
                  this.onChangeModal(value, "category");
                }}>
                <Option value="MAINNET">MAINNET</Option>
                <Option value="ERC20">ERC20</Option>
              </Select>
            </Ditems>
            <Ditems label="coinType">
              {modalType === "create" ? (
                <Input
                  allowClear
                  value={detailData.coinType}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "coinType");
                  }}
                />
              ) : (
                <span>{detailData.coinType}</span>
              )}
            </Ditems>
            <Ditems
              label={`fee ${
                detailData.category === "ERC20" || detailData.coinType === 2
                  ? "( Gwei )"
                  : ""
              }`}
              span={2}>
              <InputNumber
                min={
                  detailData.category === "ERC20"
                    ? 1
                    : detailData.coinType === 1
                    ? 0.00014
                    : 0.0002
                }
                style={{ width: "160px" }}
                value={detailData.fee}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                formatter={(value) => {
                  if (Number.isInteger(value)) {
                    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return value;
                  }
                }}
                onChange={(value) => {
                  this.onChangeModal(value, "fee");
                }}
              />
              <div
                style={{
                  fontSize: "12px",
                  color: "red",
                  marginTop: "5px",
                  wordBreak: "keep-all"
                }}>
                {`외부전송 시 트랜잭션 생성을 위해\n소비되는 실제 수수료(ERC-20은
                ETH소모)`}
              </div>
              {(detailData.category === "ERC20" ||
                detailData.coinType === 2) && (
                <div>
                  {`Gwei = ( ${
                    detailData.fee
                  } (fee) / 1,000,000,000 * ${estimationGas} ) / 1,000,000,000 = ${amountFormat(
                    detailData.fee * estimationGas,
                    detailData.coinType
                  )}`}
                </div>
              )}
            </Ditems>
            <Ditems label="estimationGas" span={2}>
              <div className="flex-row">
                <InputNumber
                  style={{ width: "160px" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  value={detailData.estimationGas}
                  onChange={(value) => {
                    // console.log(`value: ${typeof value}`);
                    this.onChangeModal(value, "estimationGas");
                  }}
                />
                <div
                  style={{
                    marginLeft: "10px",
                    fontSize: "12px",
                    color: "red",
                    marginTop: "5px",
                    wordBreak: "keep-all"
                  }}>
                  {`특별한 경우가 아니면 변경에 주의`}
                </div>
              </div>
            </Ditems>
            <Ditems label="name" span={2}>
              {/* <Input
                value={detailData.name}
                onChange={e => {
                  this.onChangeModal(e.target.value, "name");
                }}
                placeholder="ex) 비트코인"
              /> */}
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {detailData.name
                  ? detailData.name.map((name, index) => (
                      <div
                        key={index}
                        className="flex-row flex-center m-b-1"
                        style={{ width: "40%" }}>
                        <div style={{ width: "30px" }}>
                          <span>{name.language}</span>
                        </div>
                        {/* <span>{value.content}</span> */}
                        <Input
                          allowClear
                          style={{
                            width: "160px",
                            marginLeft: "10px",
                            marginRight: "10px"
                          }}
                          value={name.content}
                          onChange={(e) => {
                            this.onChangeModal(
                              e.target.value,
                              "name",
                              name.language
                            );
                          }}
                        />
                      </div>
                    ))
                  : null}
              </div>
            </Ditems>
            {detailData.category === "ERC20" && (
              <Ditems label="contract" span={2}>
                <Input
                  allowClear
                  value={detailData.contract}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "contract");
                  }}
                />
              </Ditems>
            )}
            {detailData.category === "ERC20" && (
              <Ditems label="abi">
                <Input.TextArea
                  value={detailData.abi}
                  style={{
                    minHeight: "300px"
                  }}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "abi");
                  }}
                />
              </Ditems>
            )}
          </Descriptions>
        </Modal>
      </div>
    );
  }
}
