import React, { Component } from "react";
import { Table, Button, Input } from "antd";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import { reactLocalStorage } from "reactjs-localstorage";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import {
  formatDate,
  amountFormat,
  coinMultiFormat,
  coinDivideFormat
} from "../../util";

export default class MergeList extends Component {
  state = {
    pagination: {
      total: 0,
      current: 1
    },
    coinList: [],
    mergeList: [],

    selectedRowKeys: [],
    selectedRows: [],
    isFee: 0,
    mergeType: "MERGE"
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      selectedRowKeys: [],
      selectedRows: []
    });
    this.getMergeList({
      pageNum: pager.current
    });
  };

  handleChangeCoinType = (value) => {
    this.setState(
      {
        coinType: value,
        isFee: 0,
        selectedRowKeys: [],
        selectedRows: [],
        mergeType: this.state.coinList.find(
          (list) => list.coinType === value
        ).category === "ERC20" ? "FEE" : "MERGE"
      },
      () => {
        this.getMergeList({});
      }
    );
  };

  handleChangeIsFee = (value) => {
    let isFee = parseInt(value);
    // console.log(`isFee value: ${isFee} ${typeof isFee}`);
    this.setState(
      {
        isFee: isFee,
        selectedRowKeys: [],
        selectedRows: [],
        mergeType:
          this.state.coinType &&
          this.state.coinList.find(
            (list) => list.coinType === this.state.coinType
          ).category === "ERC20" &&
          isFee === 0
            ? "FEE"
            : "MERGE"
      },
      () => {
        this.getMergeList();
      }
    );
  };

  onRowSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows: selectedRows.map((row) => {
        return {
          ...row,
          balance: coinDivideFormat(row.balance, row.coinType)
        };
      })
    });
  };

  handleBalanceChange = (value, address) => {
    this.setState({
      selectedRows: this.state.selectedRows.map((row) => {
        if (row.address === address) {
          return {
            ...row,
            balance: value
          };
        } else {
          return row;
        }
      })
    });
  };

  onMerge = () => {
    if (this.state.selectedRows.length === 0) {
      alert("선택된 행이 없습니다.");
    } else {
      let updateData = {
        walletMergeList: this.state.selectedRows.map((row) => {
          if (this.state.mergeType === "MERGE") {
            return {
              address: row.address,
              amount: coinMultiFormat(row.balance, row.coinType),
              coinType: row.coinType,
              type: "MERGE"
            };
          } else {
            return {
              address: row.address,
              coinType: row.coinType,
              type: "FEE"
            };
          }
        })
      };
      if (
        global.confirm(
          `${
            this.state.mergeType === "MERGE" ? "지갑모으기를" : "수수료지급을"
          } 실행하시겠습니까 ?`
        )
      ) {
        // console.log(JSON.stringify(updateData, null, 4));
        httpPost(httpUrl.walletMerge, [], updateData).then((res) => {
          alert("완료되었습니다.");
          this.setState({
            selectedRowKeys: [],
            selectedRows: [],
            mergeType: "MERGE"
          });
          this.getMergeList();
        });
      }
    }
  };

  getMergeList = (params = {}) => {
    const { coinType, isFee } = this.state;
    let pageNum = params.pageNum || 1;
    let fee = isFee || 0;
    httpGet(httpUrl.getMergeList, [10, pageNum, coinType, fee], {}).then(
      (res) => {
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.totalCount;
        this.setState({
          mergeList: res.data.blockchainBalanceList,
          pagination
        });
      }
    );
  };

  componentDidMount() {
    this.setState({ coinList: reactLocalStorage.getObject("coinList") });
  }

  render() {
    const {
      coinType,
      isFee,
      mergeList,
      selectedRowKeys,
      selectedRows
    } = this.state;

    let mergeType =
      coinType &&
      this.state.coinList.find((list) => list.coinType === coinType)
        .category === "ERC20" &&
      isFee === 0
        ? "FEE"
        : "MERGE";

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onRowSelectChange,
      getCheckboxProps: (record) => ({
        disabled: record.requestFlag === 1
      })
      //record.ethFee === 1 : record.ethFee === 0
      //this.state.coinList.find(coin => coin.coinType === record.coinType).category === 'MAINNET'
    };
    const mergeListColumn = [
      {
        title: "코인종류",
        dataIndex: "coinType",
        key: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {this.state.coinList.find((list) => list.coinType === data).name}
          </div>
        )
      },
      {
        title: "일자",
        dataIndex: "updateDate",
        key: "updateDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <span>{formatDate(data)}</span>
      },
      {
        title: "주소",
        dataIndex: "address",
        key: "address",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "잔액",
        dataIndex: "balance",
        key: "balance",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {mergeType === "MERGE" &&
            selectedRows.find((sr) => sr.address === row.address) ? (
              <Input
                style={{ width: "180px" }}
                value={
                  selectedRows.find((sr) => sr.address === row.address).balance
                }
                onChange={(e) => {
                  this.handleBalanceChange(e.target.value, row.address);
                }}
              />
            ) : (
              <span>{amountFormat(data, row.coinType)}</span>
            )}
            {/* {data} */}
          </div>
        )
      },

      coinType !== 1 && coinType !== 2
        ? {
            title: "이더리움 수수료보유 여부",
            dataIndex: "ethFee",
            key: "ethFee",
            className: "support-column-number fs-15 lh-21",
            render: (data) => <span>{string.ethFeeString[data]}</span>
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          },
      {
        title: "요청 상태",
        dataIndex: "requestFlag",
        key: "requestFlag",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <span>{string.requestString[data]}</span>
      }
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          지갑 모으기
        </div>
        <div style={{ float: "left", marginBottom: "10px" }}>
          <label>코인 선택 </label>
          <CoinSelectBox
            placeholder="코인 종류를 선택해주세요."
            value={coinType}
            onChange={this.handleChangeCoinType}
            exceptCoinType={[1, 3]}
            style={{ width: "240px", marginRight: "10px" }}
          />
          {coinType &&
            this.state.coinList.find((list) => list.coinType === coinType)
              .category === "ERC20" && (
              <SelectBox
                placeholder="수수료 보유여부를 선택해주세요."
                value={string.ethFeeString[isFee]}
                code={string.toggleCode}
                codeString={string.ethFeeString}
                onChange={this.handleChangeIsFee}
                style={{ width: "280px" }}
              />
            )}
        </div>
        <div style={{ float: "right", marginBottom: "10px" }}>
          <label htmlFor="mergeType">작업 내용 : &nbsp;</label>
          <span style={{ color: "blue", font: "bold" }}>
            {coinType &&
            this.state.coinList.find((list) => list.coinType === coinType)
              .category === "ERC20" &&
            isFee === 0
              ? "수수료지급"
              : "지갑모으기"}
            &nbsp;&nbsp;
          </span>
          <Button
            onClick={() => {
              this.onMerge();
            }}
            type="primary"
            style={{ marginRight: "5px" }}>
            실행
          </Button>
          <Button
            onClick={() => {
              this.setState({
                selectedRowKeys: [],
                selectedRows: [],
                mergeType: "MERGE"
              });
            }}>
            취소
          </Button>
        </div>
        {/* {selectedRows.length !== 0 && (
        )} */}
        <div style={{ clear: "both" }} />
        <Table
          id="foundation-table"
          rowKey={(record, index) => index}
          columns={mergeListColumn}
          dataSource={mergeList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowSelection={rowSelection}
        />
      </div>
    );
  }
}
