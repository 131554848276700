import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Button } from "antd";

import { getFoundation, getCoinDecimal } from "../../contexts/asyncContext";
import { numberFormat, formatDate } from "../../util";
import string from "../../string";
import { httpPut, httpUrl } from "../../api/httpClient";
import WalletRegistryModal from "../../components/wallet/WalletRegistryModal";
import WalletDetail from "./../../components/wallet/WalletDetail";
import SelectBox from "./../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";

class Foundation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 0
      },
      createModalVisible: false,
      detailModalVisible: false,
      detailData: {}
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getFoundation({
      pageSize: 10,
      pageNum: pager.current
    });
  };

  onCreate = () => {
    this.setState({
      createModalVisible: false
    });
    this.props.getFoundation({
      pageSize: 10,
      pageNum: 1
    });
    this.props.getCoinDecimal({ language: "ko" });
  };

  showDetail = row => {
    this.setState({
      detailModalVisible: true,
      detailData: row
    });
  };

  onUpdate = () => {
    const { pagination } = this.state;
    this.setState({
      detailModalVisible: false
    });
    this.props.getFoundation({
      pageSize: 10,
      pageNum: pagination.current
    });
  };

  onDelete = (value, row) => {
    const { pagination } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    if (
      global.confirm(
        `${coinList.find(coin => coin.coinType === row.coinType).name}을(를) ${
          row.deleted ? "복구" : "삭제"
        }하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.foundationDelete, [row.idx], {})
        .then(result => {
          if (row.deleted) showAlert("restore");
          else showAlert("delete");
          this.props.getFoundation({
            pageSize: 10,
            pageNum: pagination.current
          });
          this.props.getCoinDecimal({ language: "ko" });
        })
        .catch(error => {});
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getFoundation({
      pageSize: 10,
      pageNum: 1
    });
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const walletColums = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => this.showDetail(row)}>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          // <Button onClick={value => this.onDelete(row)}>삭제하기</Button>
          (data, row) => (
            <div>
              <SelectBox
                value={string.deleteString[data]}
                data={row}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={value => {
                  if (value !== data) this.onDelete(value, row);
                }}
              />
            </div>
          )
      }
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          재단
        </div>
        <div className="wrapper mt-35 width-1240" />
        <WalletRegistryModal
          type="foundation"
          visible={this.state.createModalVisible}
          onCancel={() => {
            this.setState({ createModalVisible: false });
          }}
          onOk={this.onCreate}
        />
        <WalletDetail
          modalData={this.state.detailData}
          visible={this.state.detailModalVisible}
          onCancel={() => {
            this.setState({ detailModalVisible: false });
          }}
          onOk={this.onUpdate}
        />
        <div style={{ float: "left" }}>
          <Button
            onClick={() => {
              this.setState({ createModalVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="foundation-table"
          rowKey={record => record.idx}
          columns={walletColums}
          dataSource={this.props.result.foundationList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getFoundation: ({ pageSize, pageNum }) =>
      dispatch(getFoundation({ pageSize, pageNum })),
    getCoinDecimal: ({ language }) => dispatch(getCoinDecimal({ language }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Foundation);
