import React, { Component } from "react";
import { Modal, Table, Button } from "antd";
import SelectBox from "../common/SelectBox";
import string from "../../string";
import { reactLocalStorage } from "reactjs-localstorage";
import { numberFormat } from "../../util";
import PaymentSettingRegistry from "./PaymentSettingRegistry";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";

class PaymentSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingList: [],
      settingVisible: false,
      selectedData: undefined
    };
  }

  onDelete = (data) => {
    // console.log(`delete data = ${JSON.stringify(data, null, 4)}`);
    const coinList = reactLocalStorage.getObject("coinList");
    if (
      global.confirm(
        `${
          coinList.find((coin) => coin.coinType === data.coinType)
            ? coinList.find((coin) => coin.coinType === data.coinType).name
            : ""
        } ${data.deleted === 0 ? "삭제" : "복원"}하시겠습니까?`
      )
    )
      httpPut(
        httpUrl.paymentSettingDelete,
        [data.organizationIdx, data.coinType],
        {}
      )
        .then((res) => {
          this.getPaymentSettingList();
          alert(`${data.deleted === 0 ? "삭제" : "복원"}되었습니다.`);
        })
        .catch((error) => {});
  };

  getPaymentSettingList = () => {
    httpGet(httpUrl.getPaymentSettingList, [this.props.organizationIdx], {})
      .then((res) => {
        let payment = res.data.map((pay, index) =>
          pay.paybackMethod === null
            ? { ...pay, paybackMethod: "NULL" }
            : { ...pay }
        );
        // console.log(`payment = ${JSON.stringify(payment, null, 4)}`);
        this.setState({ settingList: payment });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.getPaymentSettingList();
  }

  render() {
    const { visible, onCancel } = this.props;
    const { settingVisible, settingList } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const columns = [
      {
        title: "결제 코인",
        dataIndex: "coinType",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {coinList.find((coin) => coin.coinType === data)
              ? coinList.find((coin) => coin.coinType === data).name
              : "-"}
          </div>
        )
      },
      {
        title: "교환(전환) 코인",
        dataIndex: "payCoinType",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {coinList.find((coin) => coin.coinType === data)
              ? coinList.find((coin) => coin.coinType === data).name
              : "-"}
          </div>
        )
      },
      {
        title: "페이백 코인",
        dataIndex: "paybackCoinType",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {coinList.find((coin) => coin.coinType === data)
              ? coinList.find((coin) => coin.coinType === data).name
              : "-"}
          </div>
        )
      },
      {
        title: "페이백 방식",
        dataIndex: "paybackMethod",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{string.paybackTypeString[data]}</div>
      },
      {
        title: "페이백 비율",
        dataIndex: "paybackRatio",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>{data !== null ? `${numberFormat(data)}` : "-"}</div>
        )
      },
      {
        title: "설정",
        dataIndex: "",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <Button
            style={{ width: "80px" }}
            onClick={() => {
              this.setState({
                settingVisible: true,
                selectedData: row
              });
            }}>
            수정
          </Button>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "support-column-number fs-15 lh-21",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <Modal
        visible={visible}
        title="결제 및 페이백 설정"
        onCancel={onCancel}
        footer={false}
        style={{ minWidth: "1200px" }}>
         {settingVisible && (
          <PaymentSettingRegistry
            type="edit"
            visible={settingVisible}
            modalData={this.state.selectedData}
            organizationIdx={this.props.organizationIdx}
            onCancel={() => {
              this.setState({ settingVisible: false });
            }}
            refresh={this.getPaymentSettingList}
          />
        )}
        <Button
          className="m-b-10"
          style={{ width: "80px" }}
          onClick={() => {
            this.setState({
              settingVisible: true,
              selectedData: undefined
            });
          }}>
          추가
        </Button>
        <Table
          rowKey={(record) => record.coinType}
          columns={columns}
          dataSource={settingList}
          style={{ marginBottom: "10px" }}
          pagination={false}
        />
      </Modal>
    );
  }
}
export default PaymentSetting;
