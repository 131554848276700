import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, DatePicker, Button, Icon } from "antd";

import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import {
  dayFormat,
  numberFormat,
  amountFormat,
  statFormat,
  excelFormat,
} from "../../util";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import fileDownload from "js-file-download";
import locale from "antd/es/date-picker/locale/ko_KR";

const { RangePicker } = DatePicker;

class FoundationLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foundationList: [],
      fCoinList: [],

      statList: [],
      pagination: {
        total: 0,
        current: 0,
      },
      orderType: "STAT_DATE",
      orderByType: "ASC",
      sum: [],

      isLoaded: false,
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getFoundationDaily({
      pageNum: pager.current,
    });
  };

  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      foundationIdx: this.state.foundationList.find(
        (coin) => coin.coinType === value
      ).idx,
      isLoaded: true,
    });
  };

  orderTypeHandleChange = (value) => {
    const { foundationIdx, startDate, endDate } = this.state;
    if (!foundationIdx || !startDate || !endDate) {
      alert("재단 및 검색 기간을 선택해주세요.");
    } else {
      this.setState({
        orderType: value,
        isLoaded: true,
      });
    }
  };

  orderByTypeHandleChange = (value) => {
    const { foundationIdx, startDate, endDate } = this.state;
    if (!foundationIdx || !startDate || !endDate) {
      alert("재단 및 검색 기간을 선택해주세요.");
    } else {
      this.setState({
        orderByType: value,
        isLoaded: true,
      });
    }
  };

  handleChangeDate = (value) => {
    this.setState({
      startDate: statFormat(value[0]),
      endDate: statFormat(value[1]),
      isLoaded: true,
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  downloadExcel = () => {
    const {
      coinType,
      pagination,
      foundationIdx,
      orderType,
      orderByType,
      startDate,
      endDate,
      isLoaded,
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    if (!foundationIdx || !startDate || !endDate) {
      alert("재단 및 검색 기간을 선택해주세요.");
    } else {
      // console.log(`${excelFormat(startDate)} ~ ${excelFormat(endDate)}`);
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(httpUrl.foundationDailyExcel, [
            pagination.total,
            1,
            foundationIdx,
            orderType,
            orderByType,
            startDate,
            endDate,
          ])
            .then((res) => {
              // console.log(JSON.stringify(res, null, 4));
              fileDownload(
                res,
                `${excelFormat(startDate)} ~ ${excelFormat(endDate)} ${
                  coinList.find((coin) => coin.coinType === coinType).name
                } 재단 자산변동 일별 현황.xlsx`
              );
            })
            .catch((error) => {});
        }
      }
    }
  };

  getFoundationDaily = (params = {}) => {
    const {
      foundationIdx,
      orderType,
      orderByType,
      startDate,
      endDate,
    } = this.state;
    let pageNum = params.pageNum || 1;
    if (foundationIdx && startDate && endDate) {
      httpGet(
        httpUrl.foundationDaily,
        [
          10,
          pageNum,
          foundationIdx,
          orderType,
          orderByType,
          startDate,
          endDate,
        ],
        {}
      )
        .then((res) => {
          const pagination = { ...this.state.pagination };
          pagination.current = res.data.currentPage;
          pagination.total = res.data.totalCount;
          this.setState({
            statList: res.data.statBudgetList,
            sum: res.data.sum,
            pagination,
            isLoaded: false,
          });
        })
        .catch((error) => {});
    }
  };

  componentDidMount() {
    httpGet(httpUrl.getFoundationList, [], {}).then((res) => {
      this.setState({
        foundationList: res.data,
        fCoinList: res.data.map((item) => {
          return item.coinType;
        }),
      });
    });
  }

  render() {
    const {
      statList,
      coinType,
      fCoinList,
      orderType,
      orderByType,
      sum,
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const statColumns = [
      {
        title: "일자",
        dataIndex: "statDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{dayFormat(data)}</div>,
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {coinList.find((coin) => coin.coinType === data)
              ? coinList.find((coin) => coin.coinType === data).name
              : ""}
          </div>
        ),
      },
      {
        title: "잔고",
        dataIndex: "balance",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>,
      },
      {
        title: "입금",
        dataIndex: "in",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>,
      },
      {
        title: "출금",
        dataIndex: "out",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>,
      },
      {
        title: "수수료",
        dataIndex: "fee",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>,
      },
      {
        title: "변동분",
        dataIndex: "change",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>,
      },
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          재단 자산변동 일별 현황
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            재단{" "}
            <CoinSelectBox
              type="coinType"
              placeholder="선택해주세요."
              value={coinType}
              coinCode={fCoinList}
              onChange={(value) => {
                if (value !== coinType) this.coinTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <label htmlFor="">
            순서{" "}
            <SelectBox
              value={string.orderByString[orderByType]}
              code={string.orderByCode}
              codeString={string.orderByString}
              onChange={(value) => {
                if (value !== orderByType) this.orderByTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
            <SelectBox
              value={string.statString[orderType]}
              code={string.statCode}
              codeString={string.statString}
              onChange={(value) => {
                if (value !== orderType) this.orderTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <label htmlFor="" style={{ float: "right" }}>
            검색기간{" "}
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                if (
                  !this.state.foundationIdx ||
                  !this.state.startDate ||
                  !this.state.endDate
                ) {
                  alert("재단 및 검색 기간을 선택해주세요.");
                } else {
                  this.getFoundationDaily();
                }
              }}>
              검색
            </Button>
          </label>
        </div>
        <div
          style={{
            width: "100%",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <span>{`total : ${numberFormat(this.state.pagination.total)}`}</span>
          <Button onClick={this.downloadExcel}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowKey={(record) =>
            `${record.statDate}${record.coinType}${record.foundationIdx}`
          }
          columns={statColumns}
          dataSource={statList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
        <table
          style={{
            width: "100%",
            marginTop: "20px",
            marginBottom: "10px",
            alignItems: "center",
            border: "1px solid",
            borderColor: "#bababa",
            backgroundColor: "#eaeef1",
          }}>
          <tbody style={{}}>
            <tr>
              <th
                rowSpan="3"
                style={{
                  height: "60px",
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "#bababa",
                }}>
                합계
              </th>
              <th
                style={{
                  height: "60px",
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "#bababa",
                }}>
                코인명
              </th>
              <th
                style={{
                  height: "60px",
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "#bababa",
                }}>
                입금
              </th>
              <th
                style={{
                  height: "60px",
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "#bababa",
                }}>
                출금
              </th>
              <th
                style={{
                  height: "60px",
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "#bababa",
                }}>
                수수료
              </th>
              <th
                style={{
                  height: "60px",
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "#bababa",
                }}>
                변동분
              </th>
            </tr>
            {Array.isArray(sum) &&
              sum.map((list, index) => (
                <tr key={`${list.coinType}${index}`}>
                  <td
                    style={{
                      height: "60px",
                      textAlign: "center",
                      border: "1px solid",
                      borderColor: "#bababa",
                    }}>
                    {
                      coinList.find((coin) => coin.coinType === list.coinType)
                        .name
                    }
                  </td>
                  <td
                    style={{
                      height: "60px",
                      textAlign: "center",
                      border: "1px solid",
                      borderColor: "#bababa",
                    }}>
                    {amountFormat(list.in, list.coinType)}
                  </td>
                  <td
                    style={{
                      height: "60px",
                      textAlign: "center",
                      border: "1px solid",
                      borderColor: "#bababa",
                    }}>
                    {amountFormat(list.out, list.coinType)}
                  </td>
                  <td
                    style={{
                      height: "60px",
                      textAlign: "center",
                      border: "1px solid",
                      borderColor: "#bababa",
                    }}>
                    {amountFormat(list.fee, list.coinType)}
                  </td>
                  <td
                    style={{
                      height: "60px",
                      textAlign: "center",
                      border: "1px solid",
                      borderColor: "#bababa",
                    }}>
                    {amountFormat(list.amount, list.coinType)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default FoundationLog;
