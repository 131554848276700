import { Button, DatePicker, Input, Table } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import MozInvestRegist from "./MozInvestRegist";
import MozInvestModify from "./MozInvestModify";
import { httpGet, httpPost, httpUrl } from "../../api/httpClient";
import { numberFormat, formatDate, amountFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class MozInvestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1
      },
      createVisible: false,
      modifyVisible: false,
      selectedRow: null,
      selectedCoinName: null,
    };
  }

  componentDidMount() {
    this.getList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, ()=>this.getList());
  };

  initialLoad = () => {
    this.setState({
      list: [],
      pagination: {
        total: 0,
        current: 1
      }}, () => {
        this.getList();
      })
  }
  reload = () => {
    this.getList();
  }
  getList = () => {
    let pageNum = this.state.pagination.current;

    httpGet(httpUrl.mozInvestList,[10,pageNum,],{}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.list,
        pagination,
      });
    });
  };
  onDeleteHandle = (row, value) => {
    if (global.confirm(`${row.idx}번 투자를 ${value == 0 ? "복구" : "삭제"}하시겠습니까?`)) {
      httpPost(httpUrl.mozInvestDelete, [row.idx, value], {}).then((res) => {
        if (res.data) {
          this.reload();
        } else {
          alert("알수없는 문제로 실패하였습니다..");
        }
      });
    }
  };
  render() {
    const { list } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "투자코인",
        dataIndex: "fromCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => coinList.find(x=>x.coinType == data).name
      },
      {
        title: "투자단위",
        dataIndex: "fromCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => {
          
          let str = "";
          if (row.fromCoinPrice) str += numberFormat(row.fromCoinPrice) + '원';
          if (row.fromCoinAmount) str += amountFormat(row.fromCoinAmount, row.fromCoinType) + ' ' + coinList.find(x=>x.coinType == data).symbol;
          
          return str;
        }
      },
      {
        title: "지급코인",
        dataIndex: "toCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => coinList.find(x=>x.coinType == data).name
      },
      {
        title: "지급단위",
        dataIndex: "toCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => {
          
          let str = "";
          if (row.toCoinPrice) str += numberFormat(row.toCoinPrice) + '원';
          if (row.toCoinAmount) str += amountFormat(row.toCoinAmount, row.toCoinType) + ' ' + coinList.find(x=>x.coinType == data).symbol;
          
          return str;
        }
      },
      {
        title: "추천인보상",
        dataIndex: "recoRatio",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data * 100) + '%'
      },
      {
        title: "투자보상",
        dataIndex: "rewardRatio",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data * 100) + '%'
      },
      {
        title: "투자보상횟수",
        dataIndex: "rewardCount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => data
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (value != row.deleted) {
                    this.onDeleteHandle(row, value);
                  }
                }}
              />
          </div>
        ),
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          투자 목록
      </div>
      <div>
        * 투자코인은 사용자가 지불하는 코인이며 지급코인은 투자 시 사용자에게 보내주는 코인이다 <br/>
        * 추천인 보상은 사용자가 추천한 사람에게 투자코인의 비율로 지급되며 투자보상은 투자한 사람에게 투자코인의 비율로 투자보상 횟수 일자만큼 매일 지급된다.<br/>
      </div>
        <div className="wrapper mt-35 width-1240" />
        <div>
          <Button
            onClick={() => {
              this.setState({ createVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
        </div>

        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
        <MozInvestRegist
          visible={this.state.createVisible}
          onCancel={() => {
            this.setState({ createVisible: false });
          }}
          onOk={() => {
            this.setState({ createVisible: false });
            this.initialLoad();
          }}
        />
        {/* <MozInvestModify
          data={this.state.selectedRow}
          visible={this.state.modifyVisible}
          onCancel={() => {
            this.setState({ modifyVisible: false });
          }}
          onOk={() => {
            this.setState({ modifyVisible: false });
            this.reload();
          }}
          updateSelectedImage={this.updateSelectedImage}
        /> */}
      </div>
    );
  }
}

export default MozInvestList;
