import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table,Select, Button, DatePicker,Divider } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import { 
  startDateFormat,
  endDateFormat,
  excelFormat,
  numberFormat,
  formatDate,
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import fileDownload from "js-file-download";
import { DownloadOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";

const Option = Select.Option;
const { RangePicker } = DatePicker;

export default class TransferLogEx extends Component {
  state = {
    fullCoinList: [],
    TransferLogEx: [],
    
    pagination: {
      current: 1,
      total: 0,
    },
   
    startDate: "",
    endDate: "",
  
  
    isLoaded: false,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getTransferLogEx({
      pageNum: pager.current,
    });
  };

  


  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true,
    });
    // console.log(JSON.stringify(value, null, 4));
  };


  getTransferLogEx = (params = {}) => {
    let {
     
      startDate,
      endDate,
      
    } = this.state;
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getTransferLogEx,
      [
        10,
        pageNum,       
        startDate,
        endDate,      
      ],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        TransferLogEx: res.data.list,
        pagination,
        isLoaded: false,
      });
    });
  };

  downloadExcel = () => {
    let {     
      startDate,
      endDate,    
      isLoaded,
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    if (!startDate || !endDate) {
      alert("검색 기간 날짜를 선택해주세요.");
    } else {
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(
            httpUrl.downloadTransferLogExcel2,
            [
              this.state.pagination.total,
              1,             
              startDate,
              endDate,
            ],
            {}
          ).then((res) => {
            fileDownload(
              res,
              // `${excelFormat(startDate)} ~ ${excelFormat(endDate)} ${
              //   coinList.find((coin) => coin.coinType === coinType).name}
               '거래현황(엑셀).xlsx'
            );
          });
        }
      }
    }
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.setState({
      fullCoinList: [
        {
          coinType: 0,
          name: "전체",
        },
        ...coinList,
      ],
    });
    this.getTransferLogEx();
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");
    

    return (
      <div>
        <div
          style={{
            fontSize: "17px",
            color: "#000",
            textAlign: "left",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
         <Divider orientation="left" style={{fontSize:23}}>거래현황 엑셀  </Divider>
        </div>

       
        <div className="m-b-10 flex-row flex-sb">
          
          <div className="flex-row flex-center">
            
          <label>검색 기간&nbsp;</label>
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <Button
              type="primary"
              style={{width:60}}
              onClick={() => {
                this.getTransferLogEx();
              }}>
              <SearchOutlined/>
            </Button>&nbsp;
            <Button onClick={this.downloadExcel} ><DownloadOutlined/>엑셀 다운로드</Button>
          </div>
          
        </div>
       
               
       
      </div>
    );
  }
}
