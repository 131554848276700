import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

// import { NaverMap } from "react-naver-maps";

import {
  Form,
  Modal,
  Input,
  Button,
  Switch,
  Icon,
  Tag,
  InputNumber,
  Upload,
  Select,
  DatePicker,
  Checkbox
} from "antd";
import { httpPost, httpUrl, httpGet, serverUrl } from "../../api/httpClient";
import { numberFormat,amountFormat } from "../../util";
import UserList2 from "./UserList2";
import ProductList from "../purchase/ProductList";
import { showAlert } from "../common/AlertModal";
import ParentUserRegistry from "../../components/purchase/ParentUserRegistry";
import string from "../../string";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import Axios from "axios";


const FormItem = Form.Item;
const Option = Select.Option;

const PurchaseRegistry = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userList: [],
        productList: [],
        searchText: "",

        productVisible:false,
        userVisible: false,
        parentVisible:false,
        selectedStemUser: {},
        selectedProduct: {},
       

       
      };
    }

    // onFranchiseChange = (value) => {
    //   this.setState({
    //     franchise: value
    //   });
    // };

    onAutoChange = (value) => {
      this.setState({
        auto: value
      });
    };

    onSelectUser = (data) => {
        this.setState({ selectedStemUser: data });
        this.props.form.setFieldsValue({ userIdx: data.idx });
    };

    onSelectProduct = (data) => {
      this.setState({ selectedProduct: data });
      this.props.form.setFieldsValue({ productIdx: data.idx });
  };
  
    
    


    onRegistry = (e) => {
      const { product, volume } = this.state;
      const { form } = this.props;
      
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm("등록하시겠습니까?"))           
          for (let i= 0; i < form.getFieldValue("volume"); i++){
            httpPost(httpUrl.createProduct, [], {...values})            
            .then((res) => {
              if (res.data) {
                this.props.onOk();
                form.resetFields();
              } 
            })
            .catch((error) => {});        
          }
        }
        else {
          alert("등록에 실패하였습니다.");
        }
      });
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
    };

    componentDidMount() {}

    render() {
      const {
        userVisible,
        productVisible,
        selectedStemUser,
        selectedProduct,
        
      } = this.state;
      const { visible, form } = this.props;
      const { getFieldDecorator } = form;
      

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };
      return (
        <Modal
          visible={visible}
          title="구매등록"
          okText="등록"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "800px" }}>
          {userVisible && (
            <UserList2
              visible={userVisible}
              userIdx={0}
              onCancel={() => {
                this.setState({ userVisible: false });
              }}
              onOk={this.onSelectUser}
            />
          )}
          {productVisible && (
            <ProductList
              visible={productVisible}
              idx={0}
              onCancel={() => {
                this.setState({ productVisible: false });
              }}
              onOk={this.onSelectProduct}
            />
          )}
        
        
       
         
          <Form {...formItemLayout} onSubmit={this.onRegistry}>
            <FormItem
              label={<span>사용자 검색&nbsp;</span>}
              style={{ marginBottom: 0 }}>
              {getFieldDecorator("userIdx", {
                rules: [
                  {
                    required: true,
                    message: "사용자 계정을 선택해주세요!"
                  }
                ]
              })(
                <div>
                  <div >
                    {selectedStemUser.idx !== undefined && (
                      <div className="m-r-10">
                       {selectedStemUser.id} {" "}  {" "}
                       
                       ({selectedStemUser.name === null || selectedStemUser.name === ""
                          ? "-"
                          : selectedStemUser.name} {" "}  {" "}              
                    
                        {selectedStemUser.recommendCode})
                       
                        
                      </div>
                    )}
                    <div style={{left:100}}>
                    <Button
                      onClick={() => {
                        this.setState({
                          userVisible: true
                        });
                      }}>
                      {selectedStemUser.idx !== undefined ? "변경" : "선택"}
                    </Button>
                    </div>
                  </div>               
                  <div
                    style={{
                      color: "blue",
                      marginTop: 5,
                      marginBottom: 5,
                      lineHeight: '20px',

                    }}>
                  
                  </div>
                </div>
              )}
            </FormItem>

            <FormItem
              label={<span>상품 검색 &nbsp;</span>}
              style={{ marginBottom: 0 }}>
              {getFieldDecorator("productIdx", {
                rules: [
                  {
                    required: true,
                    message: "상품을 선택해주세요!"
                  }
                ]
              })(
                <div>
                  <div className="flex-row-purchase flex-center">
                    {selectedProduct.idx !== undefined && (
                      <div className="m-r-10">                        
                        {selectedProduct.name === null || selectedProduct.name === ""
                          ? "-"
                          : selectedProduct.name}
                          {" "} / {" "}
                       
                           {amountFormat(selectedProduct.price)}
                        
                      </div>
                    )}
                    <Button
                      onClick={() => {
                        this.setState({
                          productVisible: true
                        });
                      }}>
                      {selectedProduct.idx !== undefined ? "변경" : "선택"}
                    </Button>
                  </div>
                  <div
                    style={{
                      color: "blue",
                      marginTop: 5,
                      marginBottom: 5,
                      lineHeight: '20px',

                    }}>
                  
                  </div>
                </div>
              )}
            </FormItem>
            
            <FormItem label={<span>구매일시&nbsp;</span>}>
                {getFieldDecorator("createDate", {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: "날짜를 선택해주세요!"
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="날짜를 선택해주세요."
                    locale={locale}
                  />
                )}
              </FormItem>

              <FormItem label={<span>수량&nbsp;</span>}>
                {getFieldDecorator("volume", {
                  initialValue:1,
                  rules: [
                    {
                      required: true,
                      message: "수량을 선택해주세요!"
                    }
                  ]
                })(
                  <InputNumber
                               
                    placeholder="수량을 선택해주세요."
                    min={0}       
                    max={100}          
                  
                  />
                )}
              </FormItem>
            
            <FormItem style={{}}>
              {getFieldDecorator("reorder", { initialValue: 0, valuePropName: 'checked'
              })(
                <Checkbox 
                  style={{ marginLeft: "10px" }}
                  >
                  재구매
                </Checkbox>
              )}
            </FormItem>
            <div ><span style={{fontWeight:'500'}}>후원인 : {" " }</span> 
         
            {selectedStemUser.userId}{" "}  {" "}{selectedStemUser.userName === null ? 
            <Button
            onClick={()=>{this.setState({parentVisible: true})}}
            >등록</Button> 
            : selectedStemUser.userName} {" "}  {" "}{selectedStemUser.userRecommendCode === null ? "" : selectedStemUser.userRecommendCode}{" "}</div>
          
          </Form>
 
          <ParentUserRegistry 
            visible={this.state.parentVisible}
            userIdx={selectedStemUser.idx}
            onOk={(recommendCode) => {
              this.state.selectedStemUser.userId = '';
              this.state.selectedStemUser.userName = '';
              this.state.selectedStemUser.userRecommendCode = recommendCode;
            this.setState({
              parentVisible: false,
              selectedStemUser: this.state.selectedStemUser
            });
          }}
          onCancel={() => {
            this.setState({
              parentVisible: false
            });
          }}
            
           />      
        </Modal>
      );
    }
  }
);

export default PurchaseRegistry;
