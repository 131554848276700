import React, { Component } from "react";

import { Button, InputNumber,Divider } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import UserList from "../../components/payment/UserList";
import { reactLocalStorage } from "reactjs-localstorage";
import OrganizationList from "../../components/payment/OrganizationList";
import { SelectOutlined } from "@ant-design/icons";
var QRCode = require("qrcode.react");

const coinList = reactLocalStorage.getObject("coinList");

class CreateQR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userVisible: false,
      selectedUser: {},
      selectedUserWalletList: [],
      price: ""
    };
  }

  onSelect = (data) => {
    this.setState({ selectedUser: data });

    httpGet(httpUrl.walletListByIdx, [100, 1, data.userIdx]).then((res) => {
      let result = res.data.walletList;
      if (result == null) result = [];
      this.setState({ selectedUserWalletList: result });
    });
  };

  render() {
    const { userVisible, selectedUser } = this.state;
    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
         <Divider orientation="left" style={{fontSize:23}}>결제QR관리   </Divider>
        </div>
        <div className="flex-row m-b-10">
          <div style={{ marginTop: "4px", marginRight: "10px" }}>가맹점 :</div>
          <Button
            onClick={() => {
              this.setState({
                type: "user",
                userVisible: true,
                selectedRecommender: {}
              });
            }}>
            {selectedUser.idx !== undefined ? "변경" : "선택"}
          </Button>
          {userVisible && (
            <OrganizationList
              visible={userVisible}
              userIdx={0}
              onCancel={() => {
                this.setState({ userVisible: false });
              }}
              onOk={this.onSelect}
              classification={4}
            />
          )}
          <div style={{ marginTop: "4px", marginLeft: "10px" }}>
            {this.state.selectedUser.name}
            {/* {JSON.stringify(this.state.selectedUser)} */}
          </div>
        </div>

        <div className="flex-row m-b-10">
          <div style={{ marginTop: "4px", marginRight: "10px" }}>
            결제금액(옵션) :
          </div>
          <InputNumber
            min={0}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => this.setState({ price: value })}
            style={{ width: "150px" }}
            placeholder="결제금액"
          />
        </div>
        <div>
          {this.state.selectedUserWalletList.map((value, index) => {
            return (
              <div style={{ padding: "30px", display: "inline-block" }}>
                <div style={{ fontSize: "28px", fontWeight: "bold" }}>
                  {coinList.find((coin) => coin.coinType === value.coinType)
                    ? coinList.find((coin) => coin.coinType === value.coinType)
                        .name
                    : ""}
                </div>
                {/* {JSON.stringify(value)} */}
                <QRCode
                  size={512}
                  value={
                    "payment?type=0" +
                    "&coinType=" +
                    value.coinType +
                    "&id=" +
                    selectedUser.userId +
                    "&price=" +
                    this.state.price +
                    "&franchiseName=" +
                    selectedUser.name
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CreateQR;
