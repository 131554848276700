import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, Button, Checkbox } from "antd";

import { getFeeWallet } from "../../contexts/asyncContext";
import { numberFormat, formatDate, amountFormat } from "../../util";
import string from "../../string";
import { httpUrl, httpPut } from "../../api/httpClient";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "./../../components/common/CoinSelectBox";
import WalletRegistryModal from "../../components/wallet/WalletRegistryModal";
import { showAlert } from "../../components/common/AlertModal";

const Search = Input.Search;

class FeeWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeType: "ALL",
      deletedType: false,
      searchText: "",
      selectedRow: "",
      isModify: false,
      editData: {
        coinType: 0,
        address: "",
        seed: "",
        memo: "",
        active: 0,
        deleted: 0
      },
      prevData: {},

      createModalVisible: false
    };
  }

  activeTypeHandleChange = value => {
    this.setState({
      activeType: value
    });
  };

  deletedTypeHandleChange = e => {
    this.setState({
      deletedType: e.target.checked
    });
  };

  onSearch = value => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.props.getFeeWallet({
          type: this.state.activeType,
          deleted: this.state.deletedType,
          searchText: value
        });
      }
    );
  };

  onCreate = () => {
    showAlert("create");
    this.setState(
      {
        createModalVisible: false,
        activeType: "ALL",
        deletedType: false,
        searchText: ""
      },
      () => {
        this.props.getFeeWallet({
          type: this.state.activeType,
          deleted: this.state.deletedType,
          searchText: this.state.searchText
        });
      }
    );
  };

  onChangeModal = (value, type) => {
    var data = Object.assign({}, this.state.editData);
    if (type === "address") {
      data.address = value;
    } else if (type === "seed") {
      data.seed = value;
    } else if (type === "memo") {
      data.memo = value;
    } else if (type === "active") {
      data.active = value;
    } else if (type === "coinType") {
      data.coinType = value;
    } else {
      data.deleted = value;
    }
    this.setState({
      editData: data
    });
  };

  handleFeeWalletUpdate = () => {
    const { editData, activeType, deletedType, searchText } = this.state;
    let updateData = {
      idx: editData.idx,
      coinType: editData.coinType,
      address: editData.address,
      seed: editData.seed,
      memo: editData.memo,
      active: editData.active
    };
    if (global.confirm(`수정하시겠습니까?`)) {
      httpPut(httpUrl.feewWalletUpdate, [], updateData).then(res => {
        showAlert("update");
        this.props.getFeeWallet({
          type: activeType,
          deleted: deletedType,
          searchText: searchText
        });
        this.setState({
          selectedRow: "",
          isModify: false
        });
      });
    }
  };

  onDelete = (value, row) => {
    const { activeType, deletedType, searchText } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    if (
      global.confirm(
        `${coinList.find(coin => coin.coinType === row.coinType).name}을(를) ${
          row.deleted ? "복구" : "삭제"
        }하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.feeWalletDelete, [row.idx], {})
        .then(result => {
          if (row.deleted) showAlert("restore");
          else showAlert("delete");
          this.props.getFeeWallet({
            type: activeType,
            deleted: deletedType,
            searchText: searchText
          });
        })
        .catch(error => {});
    }
  };

  componentDidMount() {
    this.props.getFeeWallet({ type: "ALL", deleted: false, searchText: "" });
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const feeWalletColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        key: "createDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {this.state.selectedRow !== index ? (
              coinList.find(coin => coin.coinType === data).name
            ) : (
              <CoinSelectBox
                style={{ width: "120px" }}
                value={
                  coinList.find(
                    coin => coin.coinType === this.state.editData.coinType
                  ).name
                }
                onChange={this.onChangeModal}
              />
            )}
          </div>
        )
      },
      {
        title: "지갑",
        dataIndex: "address",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {this.state.selectedRow !== index ? (
              data
            ) : (
              <Input
                allowClear
                value={this.state.editData.address}
                onChange={e => {
                  this.onChangeModal(e.target.value, "address");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "잔액",
        dataIndex: "balance",
        // width: 160,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      // {
      //   title: "SEED",
      //   dataIndex: "seed",
      //   width: 250,
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data, row, index) => (
      //     <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
      //       {this.state.selectedRow !== index ? (
      //         data
      //       ) : (
      //         <Input.TextArea
      //           style={{ height: "80px" }}
      //           value={this.state.editData.seed}
      //           onChange={e => {
      //             this.onChangeModal(e.target.value, "seed");
      //           }}
      //         />
      //       )}
      //     </div>
      //   )
      // },
      {
        title: "메모",
        dataIndex: "memo",
        width: 250,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {this.state.selectedRow !== index ? (
              data ? (
                data
              ) : (
                "-"
              )
            ) : (
              <Input.TextArea
                style={{ height: "50px" }}
                value={this.state.editData.memo}
                onChange={e => {
                  this.onChangeModal(e.target.value, "memo");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "활성화",
        dataIndex: "active",
        width: 90,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {this.state.selectedRow !== index ? (
              string.activeHandlesCodes[string.toggleCode[data]]
            ) : (
              <SelectBox
                type="active"
                value={string.activeHandlesCodes[this.state.editData.active]}
                code={string.toggleCode}
                codeString={string.activeHandlesCodes}
                onChange={this.onChangeModal}
              />
            )}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        width: 90,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {this.state.selectedRow === index ? (
              string.deleteString[data]
            ) : (
              <SelectBox
                value={string.deleteString[data]}
                data={row}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={value => {
                  if (value !== row.deleted) {
                    this.onDelete(value, row);
                  }
                }}
              />
            )}
            {/* {data} */}
          </div>
        )
      },
      {
        title: "설정",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {this.state.selectedRow !== index && (
              <Button
                onClick={() => {
                  this.setState({
                    selectedRow: index,
                    prevData: row,
                    editData: row,
                    isModify: true
                  });
                }}>
                수정하기
              </Button>
            )}
            {this.state.selectedRow === index && this.state.isModify && (
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={() => {
                    this.handleFeeWalletUpdate();
                  }}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      editData: this.state.prevData,
                      selectedRow: "",
                      isModify: false
                    });
                  }}
                />
              </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          출금지갑
        </div>
        <WalletRegistryModal
          type="feeWallet"
          visible={this.state.createModalVisible}
          onCancel={() => {
            this.setState({ createModalVisible: false });
          }}
          onOk={this.onCreate}
        />
        <div className="wrapper mt-35 width-1240" />
        <span style={{ color: "blue" }}>
          * 출금지갑은 지갑에서 외부로 코인 출금 시 블록체인으로 보낼 코인을
          담아놓는 지갑입니다. <br />
          지갑은 프로그램 코드와 연동되어 동작하기 때문에 임의로 수정하는 경우
          지갑출금에 문제가 발생할 수 있습니다.
        </span>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Button
            onClick={() => {
              this.setState({
                createModalVisible: true
              });
            }}>
            생성
          </Button>
          <div style={{ float: "right" }}>
            <label htmlFor="" style={{ marginRight: "10px" }}>
              검색조건&nbsp;&nbsp;&nbsp;
              <Checkbox
                style={{ marginRight: "10px" }}
                onChange={this.deletedTypeHandleChange}>
                삭제 계좌 포함
              </Checkbox>
              <SelectBox
                value={string.activeString[this.state.activeType]}
                code={string.activeCode}
                codeString={string.activeString}
                onChange={value => {
                  if (value !== this.state.activeType)
                    this.activeTypeHandleChange(value);
                }}
                style={{ width: "160px", marginRight: "10px" }}
              />
            </label>
            <Search
              placeholder="주소를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="feeWallet-table"
          rowKey={record => record.idx}
          columns={feeWalletColumns}
          dataSource={this.props.result.data}
          pagination={false}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getFeeWallet: ({ type, deleted, searchText }) =>
      dispatch(getFeeWallet({ type, deleted, searchText }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(FeeWallet);
