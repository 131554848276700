import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, Select, Descriptions, Button } from "antd";

import { numberFormat } from "../../util";
import { httpGet, httpPost, httpUrl } from "../../api/httpClient";
import { showAlert } from "../../components/common/AlertModal";
const { Option } = Select;
const Ditems = Descriptions.Item;
class SendPush extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiver: "",
      receivers: [],
      title: '',
      message: '',
    };
  }
  reset = () => {
    this.setState({
      receiver: "",
      receivers: [],
      title: '',
      message: '',
    })
  }
  handleSearch = (value) => {
    httpGet(
      httpUrl.checkId,
      [value],
      {}
    ).then((res) => {
        if (res.data) {
          if (this.state.receivers.indexOf(value) < 0) {
            this.state.receivers.push(value)
            this.setState({receivers: this.state.receivers, receiver: ''});
          }
          else {
            alert("이미 추가되었습니다.")
          }
        }
        else {
          alert("존재하지 않는 계정입니다.")
        }
      })
      .catch((error) => {});
  }
  handleSubmit = () => {
    if (!global.confirm("발송하시겠습니까?")) return;
    httpPost(
      httpUrl.sendPush,
      [],
      {
        title: this.state.title,
        message: this.state.message,
        targetIds: this.state.receivers
      }
    ).then((res) => {
        if (res.data) {
          alert("PUSH발송을 요청하였습니다.")
          this.reset();
        }
        else {
          alert("알수없는 이유로 실패하였습니다. 다시 시도해주세요")
        }
      })
      .catch((error) => {});
  }
  
  render() {
    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          푸쉬발송
        </div>
        <div className="">
          <Descriptions
            bordered
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
            <Ditems label="수신자">
              <Input.Search enterButton placeholder="아이디를 입력해주세요"
                value={this.state.receiver}
                onChange={(e)=>{
                  this.setState({receiver: e.target.value});
                }}
              onSearch={this.handleSearch}
              />
              <Select
              disabled
                mode="tags"
                size={200}
                placeholder=""
                value={this.state.receivers}
                onChange={(e)=>{
                  this.setState({receivers: e});
                }}
                style={{width: '100%', marginTop: 20}}
              >
              </Select>
            </Ditems>
            <Ditems label="타이틀">
              <Input onChange={(e)=>{this.setState({title: e.target.value})}} value={this.state.title}/>
            </Ditems>
            <Ditems label="메시지">
              <Input onChange={(e)=>{this.setState({message: e.target.value})}} value={this.state.message}/>
            </Ditems>
          </Descriptions>
          <Button style={{marginTop: 20}} onClick={this.handleSubmit}>보내기</Button>
        </div>
      </div>
    );
  }
}

export default SendPush;
