import React, { Component } from "react";
import { Modal, Table, Input } from "antd";

import { numberFormat, formatDate } from "../../util";
import { getUser } from "../../contexts/asyncContext";
import { connect } from "react-redux";

const Search = Input.Search;

class UserListModal extends Component {
  state = {};
  componentDidMount() {}
  onSearch = (value) => {
    this.props.getUser({
      pageSize: 10,
      pageNum: 1,
      deleted: false,
      searchText: value,
      searchType: "ID",
      startDate: "",
      endDate: ""
    });
  };
  render() {
    const { visible, onOk, onCancel } = this.props;

    const memberColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        key: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: "아이디(이메일)",
        dataIndex: "id",
        key: "id",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => {
          return (
            <div
              key={`serial${index}`}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => this.props.onOk(row.serial)}>
              {data}
            </div>
          );
        }
      },
      {
        title: "시리얼번호",
        dataIndex: "serial",
        key: "serial",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => {
          return (
            <div key={`serial${index}`} className="flex-row flex-column">
              {data}
            </div>
          );
        }
      }
    ];

    return (
      <Modal
        visible={visible}
        title="회원검색"
        onText="확인"
        cancelText="취소"
        onOk={onOk}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}
        footer={null}>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Search
            placeholder={"아이디를 입력해주세요."}
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 400 }}
          />
        </div>
        <Table
          id="member-table"
          className="flex-1"
          rowKey={(record) => record.idx}
          columns={memberColumns}
          dataSource={this.props.result.userList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    result: state.async.fetchData
  };
};
let mapDisPatchToProps = (dispatch) => {
  return {
    getUser: ({
      pageSize,
      pageNum,
      deleted,
      searchText,
      searchType,
      startDate,
      endDate
    }) =>
      dispatch(
        getUser({
          pageSize,
          pageNum,
          deleted,
          searchText,
          searchType,
          startDate,
          endDate
        })
      )
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(UserListModal);
