import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

import { getFee } from "../../contexts/asyncContext";
import { Table, Input, Button, DatePicker, Select } from "antd";
import {
  numberFormat,
  formatDate,
  amountFormat,
  startDateFormat,
  endDateFormat,
  excelFormat,
} from "../../util";
import string from "../../string";
import { httpDownload, httpUrl } from "../../api/httpClient";
import fileDownload from "js-file-download";
import SelectBox from "../../components/common/SelectBox";
import locale from "antd/es/date-picker/locale/ko_KR";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;

class Fee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 0,
      },
      searchText: "",
      coinType: 0,
      tradeType: "ALL",
      startDate: "",
      endDate: "",

      fullCoinList: [],
      isLoaded: false,
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.props.getFee({
      pageSize: 10,
      pageNum: pager.current,
      searchText: this.state.searchText,
      tradeType: this.state.tradeType,
      coinType: this.state.coinType,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  };

  handleChangeTradeType = (value) => {
    this.setState({
      tradeType: value,
      isLoaded: true,
    });
  };

  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true,
    });
  };

  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.props.getFee({
          pageSize: 10,
          pageNum: 1,
          searchText: value,
          tradeType: this.state.tradeType,
          coinType: this.state.coinType,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        });
      }
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination,
      };
    }

    return null;
  }

  downloadExcel = () => {
    const { coinType, startDate, endDate, isLoaded } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.downLoadFeeLogList,
          [
            this.state.pagination.total,
            1,
            this.state.searchText,
            this.state.tradeType,
            this.state.coinType,
            this.state.startDate,
            this.state.endDate,
          ],
          {}
        ).then((res) => {
          let title = coinList.find((coin) => coin.coinType === coinType)
            ? `${
                coinList.find((coin) => coin.coinType === coinType).name
              } 수수료내역.xlsx`
            : "전체 수수료내역.xlsx";
          if (startDate && endDate) {
            title =
              `${startDate && excelFormat(startDate)} ~ ${
                endDate && excelFormat(endDate)
              }` +
              " " +
              title;
          }
          fileDownload(res, title);
        });
      }
    }
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.setState({
      fullCoinList: [
        {
          coinType: 0,
          name: "전체",
        },
        ...coinList,
      ],
    });
    this.props.getFee({
      pageSize: 10,
      pageNum: 1,
      searchText: "",
      tradeType: this.state.tradeType,
      coinType: this.state.coinType,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  }
  render() {
    const { tradeType } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const feeColums = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: "아이디(이메일)",
        dataIndex: "id",
        key: "id",
        width: 200,
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "시리얼번호",
        dataIndex: "serial",
        key: "serial",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {coinList.find((coin) => coin.coinType === data)
              ? coinList.find((coin) => coin.coinType === data).name
              : ""}
          </div>
        ),
      },
      {
        title: "수수료",
        dataIndex: "fee",
        // width: 160,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>{`${amountFormat(data, row.coinType)} ${
            coinList.find((coin) => coin.coinType === row.coinType).symbol
          }`}</div>
        ),
      },
      {
        title: "거래종류",
        dataIndex: "type",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{string.feeLogTypeString[data]}</div>,
      },
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          수수료 내역
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <label>코인종류&nbsp;</label>
            <Select
              placeholder="코인종류"
              style={{ width: "160px", marginRight: "10px" }}
              value={this.state.coinType}
              onChange={this.handleChangeCoinType}>
              {this.state.fullCoinList.map((coin) => (
                <Option key={coin.coinType} value={coin.coinType}>
                  {coin.name}
                </Option>
              ))}
            </Select>
            <label>거래종류&nbsp;</label>
            <SelectBox
              value={string.feeLogTypeString[tradeType]}
              codeString={string.feeLogTypeString}
              onChange={this.handleChangeTradeType}
              style={{ width: "240px" }}
            />
          </div>
          <div className="flex-row flex-center">
            <label htmlFor="">검색기준&nbsp;</label>
            <Search
              placeholder="아이디를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div>
            <label>검색기간&nbsp;</label>
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </div>
          <Button onClick={this.downloadExcel}>엑셀 다운로드</Button>
        </div>
        <Table
          id="foundation-table"
          rowKey={(record) => record.idx}
          columns={feeColums}
          dataSource={this.props.result.feeLogList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    result: state.async.fetchData,
  };
};

let mapDisPatchToProps = (dispatch) => {
  return {
    getFee: ({
      pageSize,
      pageNum,
      searchText,
      tradeType,
      coinType,
      startDate,
      endDate,
    }) =>
      dispatch(
        getFee({
          pageSize,
          pageNum,
          searchText,
          tradeType,
          coinType,
          startDate,
          endDate,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Fee);
