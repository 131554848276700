import React, { Component } from "react";

import { Table, InputNumber, Tooltip, Button, DatePicker, Icon, Form, Modal, Input } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import fileDownload from "js-file-download";

import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";
import { numberFormat, statFormat, dayFormat, amountFormat } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import OrganizationSelectModal from "../../components/adjustment/OrganizationSelectModal";
import { showAlert } from "../../components/common/AlertModal";
import GlobalShareDetail from "../../components/adjustment/GlobalShareDetail";
import ConfirmInputModal from "../../components/common/ConfirmInputModal";

const { RangePicker } = DatePicker;

class GlobalShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coinList: [],
      globalList: [],
      pagination: {
        current: 1,
        total: 0,
      },
      userIdx: 0,
      organizationName: "",
      globalType: "ALL",
      coinType: 0,
      startDate: "",
      endDate: "",
      orderByType: "DESC",

      selectedData: {},

      detailModalVisible: false,
      detailData: {},
      isLoaded: false,

      memoModalVisible: false,
      statusModalVisible: false,
      memoData: '',
        };
  }

  handleChangeGlobalType = (value) => {
    this.setState({
      globalType: value,
      isLoaded: true,
    });
  };

  handleChangeOrderByType = (value) => {
    this.setState({
      orderByType: value,
      isLoaded: true,
    });
  };

  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : "",
      isLoaded: true,
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getGlobalShareList({
      pageNum: pager.current,
    });
  };

  //지급여부와 금액메모
  onUpdateGlobalShare = (params = {}) => {
    let updateData = {
      idx: params.idx,
      globalStatus: params.globalStatus,
      memo: params.memo,
      receiptAmount: params.receiptAmount,
    };
    if (global.confirm("수정하시겠습니까?")) {
      httpPut(httpUrl.updateGlobalShareStatus, [], updateData).then((res) => {
        this.getGlobalShareList({
          pageNum: this.state.pagination.current,
        });
        showAlert("update");
        this.setState({
          statusModalVisible: false,
        });
      });
    }
  };

  downloadExcel = () => {
    const {
      globalType,
      startDate,
      endDate,
      orderByType,
      userIdx,
      organizationName,
      pagination,
      isLoaded,
    } = this.state;

    let result = [];
    let title = "수익배분 내역";

    if (startDate !== "" && endDate !== "") {
      result.push(`${startDate} ~ ${endDate} `);
    }

    if (userIdx !== 0) {
      result.push(`${organizationName} `);
    }

    if (globalType !== "ALL") {
      result.push(`${string.globalString[globalType]} `);
    }

    let total = pagination.total <= 9 ? 10 : pagination.total;

    title =
      result.reduce((acc, item, index) => {
        return acc + item;
      }, "") + title;

    if (isLoaded) {
      alert("검색버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(httpUrl.downloadGlobalShare, [
          total,
          1,
          startDate,
          endDate,
          userIdx,
          globalType,
          orderByType,
        ])
          .then((res) => {
            // console.log(JSON.stringify(res, null, 4));
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((error) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  getGlobalShareList = (params = {}) => {
    const {
      globalType,
      startDate,
      endDate,
      orderByType,
      userIdx,
    } = this.state;

    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getGlobalShareList,
      [
        10,
        pageNum,
        startDate,
        endDate,
        userIdx,
        globalType,
        orderByType,
      ],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        globalList: res.data.list,
        pagination,
        isLoaded: false,
      });
    });
  };

  componentDidMount() {
    this.getGlobalShareList();
  }
  render() {
    const { selectedData } = this.state;
    const classifications = [
      '',
      '총판',
      '지사',
      '대리점',
      '가맹점',
    ]

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "날짜",
        dataIndex: "logDate",
        key: "logDate",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "아이디",
        dataIndex: "userId",
        key: "userId",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이름",
        dataIndex: "userName",
        key: "userName",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "조직이름",
        dataIndex: "organizationName",
        key: "organizationName",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "조직구분",
        key: "organizationClassification",
        dataIndex: "organizationClassification",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{classifications[data]}</div>,
      },
      // {
      //   title: "은행",
      //   dataIndex: "bankCode",
      //   key: "bankCode",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data, row) => (
      //     <div>
      //       {data === null || data === "" ? "-" : string.bankString[data]}
      //     </div>
      //   ),
      // },
      // {
      //   title: "계좌번호",
      //   dataIndex: "bankAccount",
      //   key: "bankAccount",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data, row) => (
      //     <div>{data === null || data === "" ? "-" : data}</div>
      //   ),
      // },
      // {
      //   title: "예금주",
      //   dataIndex: "accountHolder",
      //   key: "accountHolder",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data, row) => (
      //     <div>{data === null || data === "" ? "-" : data}</div>
      //   ),
      // },
      {
        title: "정산 금액",
        dataIndex: "amount",
        key: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, 101)}</div>,
      },
      {
        title: "메모",
        dataIndex: "memo",
        key: "memo",
        className: "support-column-number fs-15 lh-21",
        width: 100,
        render: (data, row) => (
          <div className="flex-row flex-center justify-center">
            {(data !== null || data !== "") && (
              <Tooltip title={data} placement="left">
                <div className="text-ellipsis m-r-10">{data ? data : "-"}</div>
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: "",
        className: "support-column-number fs-15 lh-21",
        width: 180,
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({
                memoModalVisible: true,
                selectedData: row,
                memoData: row.memo
              });
            }}>
            메모 수정
          </Button>
        ),
      },
      {
        title: "상태",
        dataIndex: "status",
        key: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{string.globalString[data]}</div>,
      },
      {
        key: "detail",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({
                detailModalVisible: true,
                detailData: row,
              });
            }}>
            상세보기
          </Button>
        ),
      },
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          수익배분 내역
        </div>
        {this.state.detailModalVisible && (
          <GlobalShareDetail
            visible={this.state.detailModalVisible}
            onCancel={() => {
              this.setState({
                detailModalVisible: false,
                detailData: {},
              });
            }}
            detailData={this.state.detailData}
          />
        )}

        {/* 메모입력,수정 Modal */}
        <Modal
          visible={this.state.memoModalVisible}
          onText="확인"
          cancelText="취소"
          onOk={()=>{
            httpPut(httpUrl.updateGlobalShareMemo, [], {
              idx: selectedData.idx, memo: this.state.memoData}).then((res) => {
              this.getGlobalShareList({
                pageNum: this.state.pagination.current,
              });
              this.setState({
                memoModalVisible: false,
              });
            });
          }}
          onCancel={()=>{
            this.setState({memoModalVisible: false})
          }}>
            <Input.TextArea
              rows={4}
              value={this.state.memoData}
              onChange={(e)=>{
                this.setState({memoData: e.target.value})
              }}
            ></Input.TextArea>
        </Modal>
        {/* <Modal
          visible={this.state.priceModalVisible}
          modifyData={this.state.modifyData}
          onText="확인"
          cancelText="취소"
          onOk={()=>{
            alert("서버전송="+this.state.memoData);
            if (global.confirm("수정하시겠습니까?")) {
              httpPut(httpUrl.updateGlobalSharePrice, [], {
                idx: selectedData.idx, memo: this.state.memoData,
                globalStatus:this.state.globalStatus,
                receiptAmount: this.state.receiptAmount
              }).then((res) => {
                this.getGlobalShareList({
                  pageNum: this.state.pagination.current,
                });
                showAlert("update");
                this.setState({
                  priceModalVisible: false,
                });
              });
            }
          }}
          onCancel={()=>{
            this.setState({priceModalVisible: false})
          }}>
            <Input.TextArea
              rows={4}
              value={this.state.memoData}
              onChange={(e)=>{
                this.setState({memoData: e.target.value})
              }}
            ></Input.TextArea>
        </Modal> */}

        {/* 지급내역과 금액메모 Modal */}
        {this.state.statusModalVisible && (
          <ConfirmInputModal
            rootPage="adjustment"
            modifyData={this.state.modifyData} 
            visible={this.state.statusModalVisible}
            onOk={this.onUpdateGlobalShare}
            onCancel={() => {
              this.setState({
                statusModalVisible: false,
              });
            }}
            selectedRow={this.state.selectedData}
          />
        )}

        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <label>검색기간&nbsp;</label>
            <RangePicker
              // placeholder="시작일"
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.getGlobalShareList();
              }}>
              조회
            </Button>
            <div className="m-r-10" />
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <div className="flex-row flex-center m-r-10">
              <label>정산 상태&nbsp;</label>
              <SelectBox
                value={string.globalString[this.state.globalType]}
                codeString={string.globalString}
                onChange={(value) => {
                  if (value !== this.state.globalType) {
                    this.handleChangeGlobalType(value);
                  }
                }}
              />
            </div>
            <div className="flex-row flex-center">
              <label>순서&nbsp;</label>
              <SelectBox
                value={string.orderByString[this.state.orderByType]}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== this.state.orderByType)
                    this.handleChangeOrderByType(value);
                }}
                style={{ width: "160px" }}
              />
            </div>
          </div>
          <Button onClick={this.downloadExcel}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.globalList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          columns={columns}
        />
      </div>
    );
  }
}

export default GlobalShare;
