import React, { Component } from "react";
import {
  Modal,
  Upload,
  Form,
  Button,
  Icon,
  Input,
  Row,
  Col,
  InputNumber
} from "antd";

import { httpUrl, serverUrl, httpPost } from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import { coinMultiFormat } from "../../util";

const FormItem = Form.Item;

const Manual = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        coinType: 0
      };
    }

    coinTypeHandleChange = value => {
      this.setState({
        coinType: value
      });
    };

    onSubmit = e => {
      const { form } = this.props;
      let createData = form.getFieldsValue();
      if (createData.amount) {
        createData = {
          ...createData,
          amount: coinMultiFormat(createData.amount, createData.coinType)
        };
      }
      if (!createData.memo) {
        createData = { ...createData, memo: null };
      }
      e.preventDefault();
      const self=this;
      form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm("코인 배포를 하시겠습니까?")) {
            httpPost(httpUrl.manualSingleCreate, [], createData)
              .then(res => {
                if (res.data.result !== "FAIL") {
                  alert("전송이 완료되었습니다.");
                  this.props.form.resetFields();
                  this.setState({
                    coinType: 0
                  });
                } else {
                  if (res.data.data === "ADDRESS_NOT_EXIST") {
                    alert("주소가 올바르지 않습니다. 다시 확인해주세요.");
                  }
                }
              })
              .catch(error => {
                self.props.form.resetFields();
                Modal.error({
                  title: "배포결과",
                  content: "실패"
                });

              });
          }
          // console.log(`createData = ${JSON.stringify(createData, null, 4)}`);
        }
      });
    };

    render() {
      const { coinType } = this.state;
      const { getFieldDecorator } = this.props.form;

      const self=this;
      const props = {
        action: serverUrl + httpUrl.manualCreate,
        multiple: false,
        withCredentials: true,
        data: {
          password: this.props.form.getFieldValue("password"),
          otp: this.props.form.getFieldValue("otp"),
        },
        beforeUpload: file => {
          if (global.confirm("배포 하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: file => {
          self.props.form.resetFields();

          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s =
            "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
          Modal.info({
            title: "배포결과",
            content: (
              <div>
                <p>
                  {file.data.failAddresses.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- 실패한 배포\n`}
                      {file.data.failAddresses.length > 0 &&
                        file.data.failAddresses.map(value => (
                          <div>{value}</div>
                        ))}
                    </div>
                  ) : (
                    <div>{s}</div>
                  )}
                </p>
              </div>
            ),
            onOk() {}
          });
          // if (file.data.failAddresses.length > 0) {
          //   file.data.failAddresses = file.data.failAddresses.map(
          //     value => "\n" + value
          //   );
          //   s = s + "- 실패한 Card" + file.data.failAddresses;
          // }
          // alert(s);
        },
        onError(err) {
          self.props.form.resetFields();
          Modal.error({
            title: "배포결과",
            content: "파일 업로드 실패"
          });
        }
      };

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 16,
            offset: 8
          }
        }
      };

      return (
        <div>
          <div
            style={{
              fontSize: "20px",
              color: "#000",
              textAlign: "center",
              marginBottom: "30px",
              fontWeight: "bold"
            }}>
            수동배포
          </div>
          <div className="wrapper mt-35 width-1240" />

          <div
            style={{
              maxWidth: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 0,
              marginBottom: 0,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <Form {...formItemLayout} onSubmit={this.onSubmit}>
              <FormItem label={<span></span>} colon={false}>
                <Upload {...props} accept=".xlsx, .xls" showUploadList={false}>
                  <Button style={{ marginRight: "20px" }}>
                    <Icon type="upload" style={{ fontSize: "20px" }} /> 파일
                    업로드
                  </Button>
                </Upload>
                <a href={"/airdropFormat.xlsx"} download>
                  <Button>
                    <Icon type="download" style={{ fontSize: "20px" }} />
                    양식 다운로드
                  </Button>
                </a>
                <span style={{ color: "blue", marginLeft: "10px" }}>
                  * 여러 사람에게 배포 시 파일업로드 사용
                </span>
              </FormItem>
              <FormItem label={<span>coinType&nbsp;</span>}>
                <Row>
                  <Col span={6}>
                    {getFieldDecorator("coinType", {
                      rules: [
                        {
                          required: true,
                          message: "코인 종류를 선택해주세요!"
                        }
                      ]
                    })(
                      <CoinSelectBox
                        onChange={this.coinTypeHandleChange}
                        placeholder="선택해주세요."
                        style={{ width: "160px" }}
                      />
                    )}
                  </Col>
                  <Col span={18}>
                    {coinType === 3 && (
                      <FormItem style={{ marginBottom: 0 }}>
                        {getFieldDecorator("destinationTag", {
                          rules: [
                            {
                              required: true,
                              message: "destinationTag를 입력해주세요."
                            }
                          ]
                        })(
                          <Input
                            allowClear={true}
                            placeholder="destinationTag"
                            style={{ width: "300px" }}
                          />
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
              </FormItem>
              <FormItem label={<span>address&nbsp;</span>}>
                {getFieldDecorator("to", {
                  rules: [
                    {
                      required: true,
                      message: "주소를 입력해주세요!"
                    }
                  ]
                })(
                  <Input
                    allowClear={true}
                    style={{ width: "360px" }}
                    placeholder="주소를 입력해주세요"
                  />
                )}
              </FormItem>
              <FormItem label={<span>amount&nbsp;</span>}>
                {getFieldDecorator("amount", {
                  rules: [
                    {
                      required: true,
                      message: "amount를 입력해주세요!"
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (value && coinType === 0) {
                          cb("코인 종류를 선택해주세요.");
                        }
                        cb();
                      }
                    }
                  ]
                })(
                  <InputNumber
                    allowClear
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "300px" }}
                    placeholder="수량을 입력해주세요."
                  />
                )}
              </FormItem>
              <FormItem label={<span>메모&nbsp;</span>}>
                {getFieldDecorator("memo", {
                  rules: [
                    {
                      required: false,
                      message: "메모를 입력해주세요!"
                    }
                  ]
                })(
                  <Input.TextArea
                    placeholder="메모를 입력해주세요"
                    allowClear={true}
                    style={{ width: "360px", minHeight: "30px" }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>password&nbsp;</span>}>
                {getFieldDecorator("password", {
                })(
                  <Input
                    allowClear
                    style={{ width: "300px" }}
                    placeholder="패스워드를 입력하세요"
                    type="password"
                    defaultValue={""}
                  />
                )}
              </FormItem>
              <FormItem label={<span>otp&nbsp;</span>}>
                {getFieldDecorator("otp", {
                })(
                  <Input
                    allowClear
                    style={{ width: "300px" }}
                    placeholder="otp를 입력하세요"
                    defaultValue={""}
                  />
                )}
              </FormItem>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  보내기
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      );
    }
  }
);

export default Manual;
