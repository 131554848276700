import React, { Component } from 'react'
import { httpGet, httpPost, httpUrl,httpDownload } from '../../api/httpClient';
import { Table, Divider,Button,Icon } from 'antd';
import fileDownload from "js-file-download";
import RequestGoods from "../../components/purchase/RequestGoodsList";
import {
  formatDate, amountFormat,registartionNumFormat
} from "../../util";
import string from '../../string';

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      isLoaded: true,
      pagination: {
        total: 0,
        current: 0,
      },
    
      list: [],
      visible: false,

    };


  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getRequestList({
      pageNum: pager.current,
    });
  };

  getRequestList = (params = {}) => {
    const { searchText } = this.state

    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getRequestList, [10, pageNum, searchText])
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.productPurchaseRequestList,
          pagination,
        })
      })

  }

  componentDidMount() {
    this.getRequestList();
  }


  downloadExcel = () => {


    {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.requestListDownload,
          [
            this.state.searchText
          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `신청목록 조회.xlsx`);
          })
          .catch((err) => { });
      }
    }
  };


  showDetail = (row) => {

    this.setState({
      detailVisible: true,
      detailData: row,
    });
  };

  depositManual = (idx, method) => {
    
    if (global.confirm("결제하면 취소할 수 없습니다. " + idx + "번을 결제처리하시겠습니까?"))
      httpPost(httpUrl.purchaseDepositManual, [], {idx, method}).then((res) => {
          if (res.data) {
            this.getRequestList();
          } else {
            alert("결제처리에 실패하였습니다. ");
          }
        })
        .catch((error) => {
          alert("알수없는 오류로 실패하였습니다.");
        });
  }

  render() {   

    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        width: 60,
        render: (data) =>
          <div >{data}</div>
      },
      {
        title: "신청인",
        key: "userIdx",
        dataIndex: "userIdx",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          <div style={{textAlign:"left"}}>
          {row.userName} 
          <div>{row.userRecommendCode}</div>
          <div>{row.userId}</div></div>
      },  
      
      {
        title: "은행명",
        key: "bankCode",
        dataIndex: "bankCode",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) =>
          <div>{data ? <div style={{textAlign:"left"}}>{string.bankString[data]}</div> : "-" }</div>
      },    
      {
        title: "예금주",
        key: "accountHolder",
        dataIndex: "accountHolder",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) =>
        <div >{data ? data : "-"}</div>
      },
      {
        title: "계좌번호",
        key: "bankAccount",
        dataIndex: "bankAccount",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) =>
          <div >{data ? <div style={{textAlign:"right"}}>{data}</div> : "-"}</div>
      },
      
      {
        title: "소속",
        key: "group",
        dataIndex: "group",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) =>
          <div >{data ? data : "-"}</div>
      },
      {
        title: "추천인코드",
        key: "recommenderCode",
        dataIndex: "recommenderCode",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div >{data ? data : "-"}</div>
      },
      {
        title: "후원인코드",
        key: "parentCode",
        dataIndex: "parentCode",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div >{data ? data : "-"}</div>
      },
      {
        title: "지불코드",
        key: "paymentCode",
        dataIndex: "paymentCode",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>{data}</div>
      },
      {
        title: "총 금액",
        key: "totalPrice",
        dataIndex: "totalPrice",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div style={{textAlign:"right"}}>{amountFormat(data)}</div>
      },
      {
        title: "상태", 
        key: "paymentStatus",
        dataIndex: "paymentStatus",
        className: "support-column-number fs-15 lh-21",
        render: (data) => {
         if (data === 0) return <div>{"미결"}</div>;
         if (data === 1) return <div>{"현금"}</div>;
         if (data === 2) return <div>{"카드"}</div>;
         if (data === 3) return <div>{"기타"}</div>;
         if (data === 4) return <div>{"취소"}</div>;
         else return <div>{"X"}</div>  
        }
      },      
      {
        title: "주민등록번호/구매내역",
        key: "registrationNumber",
        dataIndex: "registrationNumber",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) =>
          <Button
          key={`serial${index}`}
          style={{ cursor: "pointer", color: "",fontSize:14, width:140}}
          onClick={() => this.showDetail(row)}>
          
          {data ? registartionNumFormat(data) : "구매내역"}  
         
       
      
        </Button>
      },
      {
        title: "결제처리",
        key: "paymentStatus",
        dataIndex: "paymentStatus",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => {
         if (data === 0) return (
           <div>
              <Button style={{margin: '2px'}} onClick={()=>{this.depositManual(row.idx, 1)}}>현금결제</Button>
              <Button style={{margin: '2px'}} onClick={()=>{this.depositManual(row.idx, 2)}}>카드결제</Button>
           </div>
         );
        }
      },
      {
        title: "날짜",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>{formatDate(data)}</div>
      },
    
    



    ];




    return (

      <div>
       
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{ fontSize: 21.5 }}>신청목록 <span style={{ fontSize: 7 }}>L7 STEM</span></Divider>
      
        </div>
          <div className="purchase-request-reg">
          <div className="purchase-request-excel">
            <Button onClick={this.downloadExcel}>
              <Icon type="download" style={{ fontSize: "20px" }} />
              엑셀 다운로드
            </Button>
          </div>
          </div>
        {this.state.detailVisible && (
          <RequestGoods
            rootPage="requestGoods"
            modalData={this.state.detailData}
            visible={this.state.detailVisible}
            onOk={() => {
              this.onOk();
              this.setState({
                detailVisible: false,
              });
            }}
            onCancel={() => {
              this.setState({
                detailVisible: false,
              });
            }}
          />
        )}
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />


      </div>

    )


  }
}

export default Request;
