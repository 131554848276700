import { DatePicker, Input, Table } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl } from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { amountFormat, formatDate } from "../../util";



const Search = Input.Search;
const { RangePicker } = DatePicker;

class MiningList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      tables: [],
      pagination: {
        total: 0,
        current: 1
      },
    };
  }

  componentDidMount() {
    this.getList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, ()=>this.getList());
  };

  initialLoad = () => {
    this.setState({
      tables: [],
      pagination: {
        total: 0,
        current: 1
      }}, () => {
        this.getList();
      })
  }
  reload = () => {
    this.getList();
  }
  getList = () => {
    let pageNum = this.state.pagination.current;
    httpGet(httpUrl.investMiningList,[10,pageNum,this.state.searchText],{}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        tables: res.data.list,
        pagination,
      });
    });
  };
  render() {
    const { tables } = this.state;
    const rewardStatusString = [
      '채굴중',
      '만료',
      '환불'
    ]
    const columns = [
      {
        title: "아이디",
        dataIndex: "userId",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이름",
        dataIndex: "userName",
        className: "support-column fs-15 lh-21",
      },
      {
        title: "연락처",
        dataIndex: "userPhone",
        className: "support-column fs-15 lh-21",
      },
      {
        title: "이더수량",
        dataIndex: "amount1",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, 2)} ETH</div>
      },
      {
        title: "ZZ수량",
        dataIndex: "amount2",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{data ? amountFormat(data, 2):0} ZZ</div>
      },
      {
        title: "ZZ LOCK수량",
        dataIndex: "amount2Lock",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{data?amountFormat(data, 2):0} ZZ LOCK</div>
      },
      {
        title: "보상누적지급수량",
        dataIndex: "rewardAmountSum",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, 2)} ZZ</div>
      },
      {
        title: "상태",
        dataIndex: "rewardStatus",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{rewardStatusString[data]}</div>
      },
      {
        title: "투자일",
        dataIndex: "createDate",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          채굴투자내역
      </div>
        <div className="wrapper mt-35 width-1240" />
          <div className="flex-row flex-center" style={{paddingBottom: '10px'}}>
            <Search
              placeholder={`아이디를 입력해주세요.`}
              enterButton="검색"
              allowClear
              onSearch={(value) => {
                this.setState({searchText: value}, ()=>this.reload())
              }}
              style={{ width: 400 }}
            />
          </div>
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={tables}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default MiningList;
