import React, { Component } from "react";
import UserList from "./UserList";
import UserList2 from "./UserList";
import { reactLocalStorage } from "reactjs-localstorage";
// import { NaverMap } from "react-naver-maps";
import {
  Form,
  Modal,
  Input,
  Button,

} from "antd";
import { httpPost, httpUrl, serverUrl } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";
import SelectBox from "../common/SelectBox";
import string from "../../string";

const FormItem = Form.Item;
const IncentiveRegistry = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        incentiveType: "",

        userVisible: false,
        userVisible2: false,
        selectedUser: {},
        selectedUser2: {},
      };

    }

    onSelectUser = (data) => {
      this.setState({ selectedUser: data });
      this.props.form.setFieldsValue({ receiveUserIdx: data.idx });
    };

    onSelectUser2 = (data) => {
      this.setState({ selectedUser2: data });
      this.props.form.setFieldsValue({ referUserIdx: data.idx });
    };

    onRegistry = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        let createData = {
          ...values,

        };

        if (!err) {
          if (global.confirm("생성하시겠습니까?"))
            httpPost(httpUrl.createIncentiveLogList, [], createData)
              .then((res) => {
                if (res.data) {
                  this.props.onOk();
                  form.resetFields();
                  showAlert("create");
                } else {

                  alert("알수없는 오류로 실패하였습니다. 계정이 중복일수있습니다.");

                }
              })
              .catch((error) => {
                alert("알수없는 오류로 실패하였습니다. 계정이 중복일수있습니다.");

              });
        }
      });
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();

    };

    componentDidMount() { }

    render() {
      const {
        selectedUser, userVisible, selectedUser2, userVisible2
      } = this.state;
      const { visible, form, defaultValue, color } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };



      return (
        <Modal
          visible={visible}
          title="생성"
          okText="생성"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "900px" }}>

          {userVisible && (
            <UserList
              visible={userVisible}
              userIdx={0}
              onCancel={() => {
                this.setState({ userVisible: false });
              }}
              onOk={this.onSelectUser}
            />
          )}
          {userVisible2 && (
            <UserList2
              visible={userVisible2}
              userIdx={0}
              onCancel={() => {
                this.setState({ userVisible2: false });
              }}
              onOk={this.onSelectUser2}
            />
          )}
          <div style={{paddingBottom: '10px'}}>
            지급할 수당을 수동으로 등록하는 화면입니다.<br/>
            <span style={{fontWeight: 'bold'}}>1. 비상금대출</span><br/>
            대상금액은 0으로 하고, 관련사용자만 비상금대출을 받은 계정을 선택하면 추천인을 기준으로 해당하는 사업자에게 수당이 지급됩니다.<br/>
            <span style={{fontWeight: 'bold'}}>2. 충전수당</span><br/>
            기본적으로 자동 입력되나 입금자명 오류로 입금처리 안되고 있다가 2일이 지난후 수동매핑하는경우 자동으로 항목이 잡히지 않을때 수동으로 등록하여 줍니다. 대상금액에 충전한 금액을 관련사용자에 충전한 사용자를 선택합니다.<br/>
            <span style={{fontWeight: 'bold'}}>3. 추천수당</span><br/>
            사업자를 유치한 경우 유치금액을 대상금액에 입력하고 관련사용자를 선택하면 추천인 중 첫번째 사업자가 수당대상으로 자동선택됩니다.<br/>
          </div>
          <Form {...formItemLayout} onSubmit={this.onRegistry}>

            <FormItem label={<span>수당종류&nbsp;</span>}>
              {getFieldDecorator("incentiveType", {
                rules: [
                  {
                    required: true,
                    message: "수당종류를 선택하세요!"
                  }
                ]
              })(
                <SelectBox
                  placeholder="수당종류를 선택해주세요."
                  code={string.incentiveTypeCode}
                  codeString={string.incentiveTypeString}
                  style={{ width: "250px" }}
                />)}
            </FormItem>
            {/*            
            <FormItem
              label={<span>수당지급자&nbsp;</span>}
              style={{ marginBottom: 0 }}>
              {getFieldDecorator("receiveUserIdx", {
                rules: [
                  {
                    required: true,
                    message: "수당지급자 계정을 선택해주세요!"
                  }
                ]
              })(
                <div>
                  <div className="flex-row flex-center">
                    {selectedUser.idx !== undefined && (
                      <div className="m-r-10">
                        {selectedUser.idx} {"▪"}{selectedUser.name}
                       
                      </div>
                    )}
                    <Button
                      onClick={() => {
                        this.setState({
                          userVisible: true
                        });
                      }}>
                      {selectedUser.idx !== undefined ? "변경" : "검색"}
                    </Button>
                  </div>
                  <div
                    style={{
                      color: "blue",
                      marginTop: 5,
                      marginBottom: 5,
                      lineHeight: '20px',

                    }}>
                    
                  </div>
                </div>
              )}
                  </FormItem> */}
            <br />

            <FormItem label={<span>대상금액&nbsp;</span>}>
              {getFieldDecorator("orgPrice", {



                rules: [
                  {
                    required: true,
                    message: "대상금액을 입력해주세요!"
                  },

                ]
              })(<Input placeholder="금액을 입력해주세요"

              />)}
            </FormItem>




            {<FormItem
              label={<span>관련사용자&nbsp;</span>}
              style={{ marginBottom: 0 }}>
              {getFieldDecorator("referUserIdx", {
                rules: [
                  {
                    required: true,
                    message: "관련사용자 계정을 선택해주세요!"
                  }
                ]
              })(
                <div>
                  <div className="flex-row flex-center">
                    {selectedUser2.idx !== undefined && (
                      <div className="m-r-10">
                        {selectedUser2.idx}{"▪"}{selectedUser2.name}

                      </div>
                    )}
                    <Button
                      onClick={() => {
                        this.setState({
                          userVisible2: true
                        });
                      }}>
                      {selectedUser2.idx !== undefined ? "변경" : "검색"}
                    </Button>
                  </div>
                  <div
                    style={{
                      color: "blue",
                      marginTop: 5,
                      marginBottom: 5,
                      lineHeight: '20px',

                    }}>

                  </div>
                </div>
              )}
            </FormItem>}




          </Form>
        </Modal>
      );
    }
  }
);

export default IncentiveRegistry;
