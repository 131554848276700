import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, DatePicker, Descriptions, Select, Icon, Checkbox, InputNumber } from "antd";
import { httpGet, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
  coinMultiFormat
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import CoinSelectBox from "../../components/common/CoinSelectBox";

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;

class ArbitrageManualCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arbitrageUsers: []
    }
  }
  onCreate = (e) => {

    let {form} = this.props;
    
    e.preventDefault();
    form.validateFields((err, values) => {

      if (!err) {
        if (global.confirm('등록하시겠습니까?')) {
          values.fromAmount = coinMultiFormat(values.fromAmount, values.fromCoinType);
          values.toAmount = coinMultiFormat(values.toAmount, values.toCoinType);
          
          httpPost(httpUrl.arbitrageManualCreate, [], values).then((res) => {
            if (res.data) {
              form.resetFields();
              this.props.onOk();
            } else {
              alert("등록에 실패하였습니다. 자전사용자정보가 잘못되었을수 있습니다.");
            }
          }).catch(()=>{
            alert("알수없는 문제로 등록에 실패하였습니다..");
          });

        }
      }
    });
  }
  getArbitrageUser = (value) => {
    httpGet(httpUrl.arbitrageUserList, [value], {}).then((res) => {
      this.setState({
        arbitrageUsers: res.data,
      });
    });
  }
  render() {
    const { visible, onCancel, form } = this.props;
    const { getFieldDecorator } = form;
        
    let exceptCoinType = [3];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    return (
      <Modal
        visible={visible}
        title="자전 수동 등록"
        okText="등록"
        cancelText="취소"
        onOk={this.onCreate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        <div>
          <div style={{paddingBottom: '10px'}}>
            FROM코인을 선택하고 거래를 등록할 사용자를 선택해주세요. 자전계정으로 설정된 사용자만 선택가능합니다.
          </div>
          <Form {...formItemLayout} onSubmit={this.onCreate}>
            <Descriptions
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
              <Ditems label="사용자" span={2}>
                <FormItem>
                  {getFieldDecorator("userIdx", {
                    rules: [{ required: true, message: "자전을 등록할 사용자를 선택해주세요." }],
                  })(
                    <Select
                      style={{ width: "120px" }}
                      placeholder="사용자 선택">
                        {this.state.arbitrageUsers.map(record=>{
                          return (
                            <Option value={record.userIdx} key={record.userIdx}>
                              {record.userId}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="FROM코인">
                <FormItem>
                  {getFieldDecorator("fromCoinType", {
                    rules: [{ required: true, message: "코인을 선택해주세요." }],
                  })(
                    <CoinSelectBox
                    placeholder="코인선택"
                    exceptCoinType={exceptCoinType}
                    style={{ width: "160px" }}
                    onChange={this.getArbitrageUser}
                  />
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="FROM코인수량">
                <FormItem>
                  {getFieldDecorator("fromAmount", {
                    rules: [{ required: true, message: "수량을 입력해주세요." }],
                  })(
                    <Input/>
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="TO코인">
                <FormItem>
                  {getFieldDecorator("toCoinType", {
                    rules: [{ required: true, message: "코인을 선택해주세요." }],
                  })(
                    <CoinSelectBox
                    placeholder="코인선택"
                    exceptCoinType={exceptCoinType}
                    style={{ width: "160px" }}
                  />
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="TO코인수량">
                <FormItem>
                  {getFieldDecorator("toAmount", {
                    rules: [{ required: true, message: "수량을 입력해주세요." }],
                  })(
                    <Input/>
                  )}
                </FormItem>
              </Ditems>
              </Descriptions>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create()(ArbitrageManualCreate)