import React, { Component } from "react";
import { Modal, Form, Input, InputNumber, Button, Upload, Icon } from "antd";
import {
  httpPost,
  httpUrl,
  httpPut,
  imageType,
  serverUrl
} from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";
import BasicImage from "../common/BasicImage";

const FormItem = Form.Item;
const BannerModal = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        image: ""
      };
    }

    handleDeleteFile = (image) => {
      if (global.confirm("이미지를 삭제하시겠습니까?")) {
        this.setState({
          image: ""
        });
      }
    };

    onOk = (e) => {
      const { form, type, data } = this.props;
      const { image } = this.state;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          let body = {
            ...values,
            image: image
          };
          // console.log(`create = ${JSON.stringify(body, null, 4)}`);
          if (!type) {
            if (global.confirm("배너를 생성하시겠습니까?"))
              httpPost(httpUrl.bannerCreate, [], body)
                .then((res) => {
                  if (res.data.result === "SUCCESS") {
                    this.props.onOk();
                    this.props.onCancel();
                    showAlert("create");
                  } else {
                    alert("위치가 중복되었습니다.");
                  }
                })
                .catch((e) => {});
          } else {
            body = {
              ...body,
              idx: data.idx
            };
            // console.log(`update = ${JSON.stringify(body, null, 4)}`);
            if (global.confirm("배너를 수정하시겠습니까?"))
              httpPut(httpUrl.bannerUpdate, [], body)
                .then((res) => {
                  if (res.data.result === "SUCCESS") {
                    this.props.onOk();
                    this.props.onCancel();
                    showAlert("update");
                  } else {
                    alert("위치가 중복되었습니다.");
                  }
                })
                .catch((e) => {});
          }
        }
      });
    };

    componentDidMount() {
      // console.log(`data = ${JSON.stringify(this.props.data, null, 4)}`);
      const { type, data, form } = this.props;

      if (type) {
        this.setState({ image: data.image });
        form.setFieldsValue({
          position: data.position,
          url: data.url
        });
      }
    }

    render() {
      const { image } = this.state;
      const { visible, type, onCancel } = this.props;
      const { getFieldDecorator } = this.props.form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      const uploadProps = {
        action: serverUrl + httpUrl.imageUpload,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file, fileList) => {
          if (!imageType.find((type) => type === file.type)) {
            Modal.error({
              title: "업로드 결과",
              content: (
                <span>
                  파일 업로드 실패
                  <br />
                  파일 형식을 확인해주세요.
                </span>
              )
            });
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`file = ${JSON.stringify(file, null, 4)}`);
          if (file.data.result) {
            Modal.info({
              title: "업로드 결과",
              content: "파일 업로드 성공"
            });
            this.setState({
              image: file.data.fileId
            });
          }
        },
        onError(err) {
          Modal.error({
            title: "업로드 결과",
            content: "파일 업로드 실패"
          });
        }
      };

      return (
        <Modal
          style={{ minWidth: "800px" }}
          visible={visible}
          title={type ? "배너 상세" : "배너 생성"}
          okText={type ? "변경" : "생성"}
          onOk={this.onOk}
          cancelText="취소"
          onCancel={onCancel}>
          <Form {...formItemLayout} onSubmit={this.onOk}>
            <FormItem label={<span>위치&nbsp;</span>}>
              {type
                ? this.props.data.position
                : getFieldDecorator("position", {
                    rules: [
                      {
                        required: true,
                        message: "위치를 입력해주세요!"
                      }
                    ]
                  })(<InputNumber placeholder="1" min={1} />)}
            </FormItem>
            <FormItem label={<span>URL&nbsp;</span>}>
              {getFieldDecorator("url", {
                rules: [
                  {
                    required: true,
                    message: "url을 입력해주세요!"
                  }
                ]
              })(<Input placeholder="https://www.google.kr" />)}
            </FormItem>
            <FormItem label={<span>이미지&nbsp;</span>}>
              {getFieldDecorator("image", {
                rules: [
                  {
                    required: false,
                    message: "이미지를 선택해주세요!"
                  }
                ]
              })(
                <div>
                  {image !== "" && image && (
                    <div className="flex-row flex-sb flex-center m-b-10">
                      <BasicImage
                        image={image}
                        src={serverUrl + httpUrl.getImage + image}
                        style={{ width: "300px", height: "150px" }}
                      />
                      <Icon
                        className="pointer"
                        type="delete"
                        onClick={() => {
                          this.handleDeleteFile(image);
                        }}
                      />
                    </div>
                  )}
                  <Upload {...uploadProps} showUploadList={false}>
                    <Button>{image ? "변경" : "선택"}</Button>
                  </Upload>
                </div>
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default BannerModal;
