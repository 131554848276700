import React, { Component } from "react";

import {
  Modal,
  Form,
  DatePicker,
  Radio,
  Input,
  InputNumber,
  Row,
  Col,
  Checkbox,
  Button
} from "antd";
import { BigNumber } from "bignumber.js";

import CoinSelectBox from "./CoinSelectBox";
import { httpUrl, httpGet, httpPut } from "../../api/httpClient";
import {
  coinMultiFormat,
  amountFormat,
  endDateFormat,
  coinDivideFormat,
  removeComma,
  bigNum
} from "../../util";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import locale from "antd/es/date-picker/locale/ko_KR";

const FormItem = Form.Item;

const LockCreateModal = Form.create()(
  class extends Component {
    state = {
      lockStatus: "ALL",
      isRepeat: false,
      maxAmount: 0,
      coinList: [],
      formData: {
        amount: 0,
        coinType: 0,
        expireDate: "",
        serialNum: "",
        count: 1,
        date: 0,
        memo: ""
      },
      validateAddress: false
    };

    handleCalculateMaxAmount = coinType => {
      const { pageType, coinList } = this.props;
      if (pageType) {
        const tempData = Object.assign({}, this.state.formData);
        let coinParams =
          pageType === "foundationStatus"
            ? coinList.coinType
            : tempData.coinType;
        let addrParams =
          pageType === "foundationStatus" ? coinList.address : tempData.address;
        httpGet(httpUrl.getSearchSerial, [coinParams, addrParams]).then(res => {
          if (res.data.data !== "ADDRESS_NOT_EXIST") {
            if (
              pageType === "lockManagement" &&
              this.props.modalType === "create"
            )
              alert("검증이 완료되었습니다.");
            tempData.coinType = coinParams;
            tempData["serialNum"] = res.data.serialNum;
            this.props.form.setFieldsValue({ address: addrParams });
            this.setState({
              maxAmount: res.data.balance,
              formData: tempData,
              validateAddress: true
            });
          } else {
            alert("지갑주소가 존재하지 않습니다.");
            tempData.address = "";
            tempData.serialNum = "";
            this.props.form.setFieldsValue({ address: "" });
            this.setState({
              formData: tempData
            });
          }
        });
      } else {
        httpGet(
          httpUrl.coinLockBalance,
          [this.props.coinList.serialNum, coinType],
          {}
        ).then(res => {
          this.setState({
            maxAmount: res.data
          });
        });
      }
    };

    handleChangeFormData = (value, type) => {
      const { formData, validateAddress } = this.state;
      formData[type] = value;
      let validateAddr = validateAddress;
      if (type === "coinType") {
        if (this.state.lockStatus === "AMOUNT")
          this.props.form.setFieldsValue({
            amount: ""
          });
        if (!this.props.pageType) this.handleCalculateMaxAmount(value);
      }
      if (type === "address") {
        validateAddr = false;
        formData.serialNum = "";
      }
      this.setState({
        formData,
        validateAddress: validateAddr
      });
    };

    handleCreateCoinLock = () => {
      const { formData, lockStatus, isRepeat } = this.state;
      let amount = coinMultiFormat(formData.amount, formData.coinType);
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (this.props.pageType) {
            let createData = {
              ...formData,
              expireDate: endDateFormat(formData.expireDate),
              amount: lockStatus === "ALL" ? -1 : amount,
              date: 0,
              count: 1
            };
            let isPassed = false;
            if (isRepeat) {
              if (amount / formData.count > 0) {
                createData.date = formData.date;
                createData.count = formData.count;
              } else {
                alert(`수량이 최소금액보다 적거나 0입니다.`);
                isPassed = true;
              }
            } else {
              if (createData.amount === 0) {
                alert(`수량이 최소금액보다 적거나 0입니다.`);
                isPassed = true;
              }
            }
            if (!isPassed || amount !== 0) {
              if (this.props.modalType === "edit") {
                delete createData.deleted;
                delete createData.memoDeleted;
                delete createData.createDate;
              }
              if (this.props.pageType === "lockManagement") {
                delete createData["address"];
              }
              // console.log(
              //   `isPassed!!\n${JSON.stringify(
              //     createData,
              //     null,
              //     4
              //   )} validateAddress: ${this.state.validateAddress}`
              // );
              if (
                global.confirm(
                  `${
                    this.props.modalType === "create" ? "생성" : "수정"
                  }하시겠습니까?`
                )
              ) {
                httpPut(httpUrl.lockUpdate, [], createData).then(res => {
                  this.initFormData();
                  //foundationStatus: 보유 현황 조회
                  let refresh = this.props.modalType === "edit" ? true : false;
                  this.props.refresh({
                    refresh: refresh,
                    pageNum: 1
                  });
                  this.onCancel();
                });
              }
            }
          } else {
            let createData = {
              ...formData,
              expireDate: endDateFormat(formData.expireDate),
              serialNum: this.props.coinList.serialNum,
              amount: lockStatus === "ALL" ? -1 : amount,
              date: 0,
              count: 1
            };
            let isPassed = false;
            if (isRepeat) {
              if (amount / formData.count > 0) {
                createData.date = formData.date;
                createData.count = formData.count;
              } else {
                alert(`수량이 최소금액보다 적거나 0입니다.`);
                isPassed = true;
              }
            } else {
              if (lockStatus === "AMOUNT" && (amount < 0 || amount === 0)) {
                alert(`수량이 최소금액보다 적거나 0입니다.`);
                isPassed = true;
              }
            }
            if (!isPassed || amount !== 0) {
              if (this.props.modalType === "edit") {
                delete createData.deleted;
                delete createData.memoDeleted;
                delete createData.createDate;
              }
              if (
                global.confirm(
                  `${
                    this.props.modalType === "create" ? "생성" : "수정"
                  }하시겠습니까?`
                )
              ) {
                httpPut(httpUrl.coinLockUpdate, [], createData).then(res => {
                  this.initFormData();
                  this.onCancel();
                  this.props.refresh();
                });
              }
            }
          }
        }
      });
    };

    handleCalculateAvg = () => {
      const { formData } = this.state;
      if (
        formData.count > 0 &&
        formData.amount / formData.count > 0 &&
        formData.date !== 0
      ) {
        let amount = formData.amount;
        let count = formData.count;
        let avg = Math.floor((amount / count / amount) * 100 * 100) / 100;
        let rest = ((100 - avg * (count - 1)) * 100) / 100;
        return (
          <FormItem
            label={<span></span>}
            colon={false}>{`LOCK 해제비율: ${avg}% 마지막 해제: ${rest.toFixed(
            2
          )}%`}</FormItem>
        );
      } else {
        return null;
      }
    };

    disabledDate = current => {
      // Can not select days before today and today
      return current < moment().endOf("day" - 1);
    };

    onCancel = () => {
      this.initFormData();
      this.props.onCancel();
      this.props.form.resetFields();
    };

    initFormData = () => {
      this.setState({
        lockStatus: "ALL",
        isRepeat: false,
        maxAmount: 0,
        coinList: [],
        validateAddress: false,
        formData: {
          amount: 0,
          coinType: 0,
          expireDate: "",
          serialNum: "",
          count: 1,
          date: 0,
          memo: ""
        }
      });
    };

    componentDidUpdate(prevProps) {
      const { formData } = this.state;
      const { modalType, editData, form, visible } = this.props;
      if (
        modalType === "edit" &&
        prevProps.editData.idx !== this.props.editData.idx
      ) {
        let amount = bigNum(
          coinDivideFormat(
            this.props.editData.amount,
            this.props.editData.coinType
          )
        );
        if (this.props.editData.amount === -1) amount = 0;
        this.setState(
          {
            formData: Object.assign(
              {},
              { ...this.props.editData, amount: amount }
            ),
            lockStatus: this.props.editData.amount === -1 ? "ALL" : "AMOUNT"
          },
          () => {
            delete formData.deleted;
            delete formData.memoDeleted;
            delete formData.createDate;
            this.handleCalculateMaxAmount(editData.coinType);
          }
        );
        form.setFieldsValue({
          coinType: editData.coinType,
          serialNum: editData.serialNum,
          expireDate: moment(editData.expireDate),
          memo: editData.memo,
          lock: editData.amount === -1 ? "ALL" : "AMOUNT",
          amount: amount,
          address: editData.address
        });
        // if (this.props.pageType !== "lockManagement")
      } else if (modalType !== prevProps.modalType && modalType === "create") {
        this.initFormData();
      } else if (visible !== prevProps.visible && modalType === "create") {
        if (this.props.pageType === "foundationStatus") {
          this.handleCalculateMaxAmount(this.props.coinList.coinType);
        }
      }
    }

    componentDidMount() {
      // console.log(`formData: ${JSON.stringify(this.state.formData, null, 4)}`);
    }

    render() {
      const { formData, isRepeat } = this.state;
      const { visible, coinList, pageType, form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      const blockChainCoinList = reactLocalStorage.getObject("coinList");

      return (
        visible && (
          <Modal
            visible={visible}
            title={`코인별 LOCK ${
              this.props.modalType === "create" ? "생성" : "수정"
            } `}
            okText="확인"
            cancelText="취소"
            onCancel={this.onCancel}
            onOk={() => {
              if (this.props.pageType === "lockManagement") {
                if (this.state.validateAddress) this.handleCreateCoinLock();
                else alert("주소검증이 필요합니다.");
              } else {
                this.handleCreateCoinLock();
              }
            }}
            style={{ minWidth: "800px" }}>
            {/* <div>{JSON.stringify(this.props.coinList, null, 4)}</div> */}
            <Form {...formItemLayout}>
              {/* 회원관리, LOCK관리에서는 coinType선택가능 아니면 coinType fix */}

              {this.props.modalType === "create" ? (
                <div>
                  {Array.isArray(coinList.coinType) && (
                    <FormItem label={<span>코인종류&nbsp;</span>}>
                      {getFieldDecorator("coinType", {
                        rules: [
                          {
                            required: true,
                            message: "코인종류를 선택해주세요!"
                          }
                        ]
                      })(
                        <CoinSelectBox
                          type="coinType"
                          placeholder="코인종류"
                          coinCode={coinList.coinType}
                          style={{
                            width: "160px"
                          }}
                          onChange={this.handleChangeFormData}
                        />
                      )}
                    </FormItem>
                  )}
                </div>
              ) : (
                <FormItem label={<span>코인종류&nbsp;</span>}>
                  {
                    blockChainCoinList.find(
                      coin => coin.coinType === this.props.editData.coinType
                    ).name
                  }
                </FormItem>
              )}
              {pageType === "lockManagement" && (
                <FormItem label={<span>address&nbsp;</span>}>
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "주소를 입력해주세요!"
                      },
                      {
                        validator: (rule, value, cb) => {
                          if (
                            value &&
                            formData.serialNum === "" &&
                            !this.state.validateAddress
                          ) {
                            cb("주소검증이 필요합니다.");
                          }
                          cb();
                        }
                      }
                    ]
                  })(
                    <div>
                      {this.props.modalType === "create" ? (
                        <Input
                          allowClear
                          onChange={e =>
                            this.handleChangeFormData(e.target.value, "address")
                          }
                          style={{ width: "360px" }}
                          placeholder="주소를 입력해주세요"
                        />
                      ) : (
                        <span>{this.props.editData.address}</span>
                      )}
                    </div>
                  )}
                </FormItem>
              )}
              {pageType === "lockManagement" &&
                this.props.modalType === "create" && (
                  <FormItem label={<span></span>} colon={false}>
                    {/* {getFieldDecorator("validateAddress", {
                    rules: [
                      {
                        validator: (rule, value, cb) => {
                          if (
                            formData.address &&
                            formData.serialNum === "" &&
                            !this.state.validateAddress
                          ) {
                            cb("주소검증이 필요합니다.");
                          }
                          cb();
                        }
                      }
                    ]
                  })(
                    <div>

                    </div>
                  )} */}
                    {this.state.validateAddress ? (
                      <div>검증완료</div>
                    ) : (
                      <Button
                        onClick={() => {
                          if (formData.coinType !== 0 && formData.address) {
                            this.handleCalculateMaxAmount();
                          } else if (formData.coinType === 0)
                            alert("코인을 선택해주세요.");
                          else alert("주소를 입력해주세요.");
                        }}>
                        주소검증
                      </Button>
                    )}
                  </FormItem>
                )}
              <FormItem label={<span>LOCK 해제일 설정&nbsp;</span>}>
                {getFieldDecorator("expireDate", {
                  rules: [
                    {
                      required: true,
                      message: "날짜를 선택해주세요!"
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="날짜를 선택해주세요."
                    disabledDate={this.disabledDate}
                    onChange={date => {
                      this.handleChangeFormData(date, "expireDate");
                    }}
                    locale={locale}
                    // onOk={value => {
                    //   this.handleChangeFormData(value, "expireDate");
                    // }}
                  />
                )}
              </FormItem>
              <FormItem label={<span>LOCK 설정&nbsp;</span>}>
                {getFieldDecorator("lock", {
                  rules: [
                    {
                      required: true,
                      message: "LOCK 설정을 선택해주세요!"
                    }
                  ]
                })(
                  <Radio.Group
                    disabled={this.props.modalType === "create" ? false : true}
                    onChange={e => {
                      formData.count = 1;
                      formData.date = 0;
                      this.setState({
                        lockStatus: e.target.value,
                        isRepeat: false,
                        formData
                      });
                    }}>
                    <Radio value={"ALL"}>전체잠금</Radio>
                    <Radio value={"AMOUNT"}>수량잠금</Radio>
                  </Radio.Group>
                )}
              </FormItem>
              {this.state.lockStatus === "AMOUNT" &&
                this.props.modalType === "create" && (
                  <FormItem label={<span>반복&nbsp;</span>}>
                    <Row>
                      <Col span={2}>
                        <Checkbox
                          checked={isRepeat}
                          onChange={e => {
                            formData.count = 1;
                            formData.date = 0;
                            this.setState({
                              isRepeat: e.target.checked,
                              formData
                            });
                          }}
                        />
                      </Col>
                      {isRepeat && (
                        <span>
                          <Col span={7}>
                            <FormItem className="inline-formitem m-b-0">
                              {getFieldDecorator("date", {
                                rules: [
                                  {
                                    required: true,
                                    message: "간격을 입력해주세요!"
                                  }
                                ]
                              })(
                                <InputNumber
                                  min={0}
                                  formatter={value =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  parser={value =>
                                    value.replace(/\$\s?|(,*)/g, "")
                                  }
                                  onChange={value =>
                                    this.handleChangeFormData(value, "date")
                                  }
                                  style={{ width: "150px" }}
                                  placeholder="LOCK 해제간격"
                                />
                              )}
                            </FormItem>
                          </Col>
                          <Col span={1} />
                          <Col span={7}>
                            <FormItem className="inline-formitem m-b-0">
                              {getFieldDecorator("count", {
                                rules: [
                                  {
                                    required: true,
                                    message: "횟수를 입력해주세요!"
                                  }
                                ]
                              })(
                                <InputNumber
                                  min={1}
                                  formatter={value =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  parser={value =>
                                    value.replace(/\$\s?|(,*)/g, "")
                                  }
                                  onChange={value =>
                                    this.handleChangeFormData(value, "count")
                                  }
                                  style={{ width: "150px" }}
                                  placeholder="LOCK 해제횟수"
                                />
                              )}
                            </FormItem>
                          </Col>
                        </span>
                      )}
                    </Row>
                  </FormItem>
                )}
              {this.state.lockStatus === "AMOUNT" && (
                <FormItem label={<span>수량&nbsp;</span>}>
                  {getFieldDecorator("amount", {
                    initialValue:
                      this.props.modalType === "edit"
                        ? this.props.editData.amount === -1
                          ? 0
                          : bigNum(
                              coinDivideFormat(
                                this.props.editData.amount,
                                this.props.editData.coinType
                              )
                            )
                        : 0,
                    rules: [
                      {
                        required: true,
                        message: "수량을 입력해주세요!"
                      },
                      {
                        validator: (rule, value, cb) => {
                          if (
                            value &&
                            Array.isArray(coinList.coinType) &&
                            formData.coinType === 0
                          ) {
                            cb("코인 종류를 선택해주세요.");
                          }
                          cb();
                        }
                      },
                      {
                        validator: (rule, value, cb) => {
                          value = new BigNumber(removeComma(value)).toNumber();
                          if (
                            value &&
                            formData.coinType !== 0 &&
                            value >
                              coinDivideFormat(
                                this.state.maxAmount,
                                formData.coinType
                              )
                          ) {
                            cb("수량이 잔액 보다 큽니다");
                          }
                          cb();
                        }
                      }
                    ]
                  })(
                    <Input
                      allowClear
                      style={{ width: "160px" }}
                      onChange={e => {
                        this.handleChangeFormData(e.target.value, "amount");
                      }}
                      onBlur={() => {
                        this.props.form.setFieldsValue({
                          amount: bigNum(formData.amount)
                        });
                        this.props.form.validateFields(["amount"], {
                          force: true
                        });
                      }}
                    />
                  )}
                  <span
                    style={{
                      marginLeft: "10px"
                    }}>
                    {formData.coinType !== 0 &&
                      `잔액: ${
                        formData.coinType !== 0
                          ? amountFormat(
                              this.state.maxAmount,
                              formData.coinType
                            )
                          : 0
                      }`}
                  </span>
                </FormItem>
              )}
              {this.handleCalculateAvg()}
              <FormItem label={<span>메모</span>}>
                {getFieldDecorator("memo", {
                  rules: [
                    {
                      required: false,
                      message: "메모를 입력해주세요!"
                    }
                  ]
                })(
                  <Input.TextArea
                    rows={4}
                    onChange={e =>
                      this.handleChangeFormData(e.target.value, "memo")
                    }
                    placeholder="메모를 입력해주세요."
                    style={{ width: "360px" }}
                    maxLength={100}
                  />
                )}
              </FormItem>
            </Form>
          </Modal>
        )
      );
    }
  }
);

export default LockCreateModal;
