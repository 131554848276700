import React, { Component } from "react";
import { Table, Input, Button, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import fileDownload from "js-file-download";
import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";
import { numberFormat, amountFormat, 
  formatDate,startDateFormat,endDateFormat } from "../../util";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class SignupRewardLogDix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signupList: [],
      pagination: {
        total: 0,
        current: 0
      },
      searchText: "",
      startDate: "",
      endDate: "",
      selectedData: {},
      isModify: false,
      isLoaded: true,
      orderByType: "DESC"
    };
  }
  
  
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getSignupRewardLog({
      pageSize: 10,
      pageNum: pager.current,
      searchText: this.state.searchText,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });
  };
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getSignupRewardLog({
          pageSize: 10,
          pageNum: 1,
          searchText: this.state.searchText,
          startDate: this.state.startDate,
          endDate: this.state.endDate
        });
      }
    );
  };

  downloadExcel = () => {
    const{
      searchText,
      startDate,
      endDate,
      pagination,
      isLoaded,
    } = this.state;

    let result = [];

    if (startDate !== "" && endDate !== "") {
      result.push(`${startDate} ~ ${endDate} `);
    }

    let total = pagination.total <= 9 ? 10 : pagination.total;

    if (isLoaded) {
      alert("검색버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.downloadSignupRewardLog,
          [
            total,
            1,
            searchText,
            startDate,
            endDate,
          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `회원가입보상 목록조회.xlsx`);
          })
          .catch((err) => { });
      }
    }
  };

  getSignupRewardLog = (params = {}) => {
    const { searchText,startDate,endDate,orderByType } = this.state;
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getSignupRewardLog,
      [10, pageNum,searchText,startDate,endDate,orderByType],
      {}
    )
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          signupList: res.data.list,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          pagination
        });
      })
      .catch((error) => { });
  };
  

  componentDidMount() {
    this.getSignupRewardLog();
  }


  render() {
    const { signupList, selectedData } = this.state;
    
    //substring
    const stringsub = str => {
      if (str === null) {
        return null;
      }
      str = str.substr(0,16)
      return str;
    }

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "아이디",
        dataIndex: "id",
        key: "id",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data === null ? "-" : data),
      },
      {
        title: "전화번호",
        dataIndex: "phone",
        key: "phone",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data === null ? "-" : data),
      },
      {
        title: "회원가입보상",
        dataIndex: "rewardAmount1",
        key: "rewardAmount1",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, 101)}</div>,
      },
      {
        title: "추천인회원가입보상",
        dataIndex: "rewardAmount2",
        key: "rewardAmount2",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, 101)}</div>,
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },
    ];
    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          회원 보상 목록
        </div>
          <div className="flex-row flex-center">
            <label>검색기간&nbsp;</label>
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </div>
         <div className="flex-row flex-center m-b-10">
            <label>검색기준&nbsp;</label>
            <Search
              placeholder="이름, 아이디, 전화번호"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
          <div className="flex-row flex-center flex-end m-b-10">
          <Button onClick={this.downloadExcel}>엑셀 다운로드</Button>
        </div>
        <Table
          id="admin-table"
          rowKey={(record) => record.idx}
          dataSource={signupList}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default SignupRewardLogDix;
