import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Icon, Checkbox, InputNumber } from "antd";
import { httpPut, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
  amountFormat,
  coinMultiFormat
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import { reactLocalStorage } from "reactjs-localstorage";

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;

class ArbitrageSettingModify extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  onUpdate = (e) => {
    let {form} = this.props;
    
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log(JSON.stringify(values));
        if (global.confirm('수정하시겠습니까?')) {
          values.coinType = this.props.data.coinType;
          values.targetCoinType = this.props.data.targetCoinType;
          
          values.ratio = values.ratio / 100
          values.amountMin = coinMultiFormat(values.amountMin, values.coinType);
          values.amountMax = coinMultiFormat(values.amountMax, values.coinType);

          httpPost(httpUrl.arbitrageSettingModify, [], values).then((res) => {
            if (res.data !== 0) {
              this.props.onOk();
            } else {
              alert("알수없는 문제로 수정에 실패하였습니다..");
            }
          });

        }
      }
    });
  }
  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const { visible, onCancel, form } = this.props;
    const { getFieldDecorator } = form;
        
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    return (
      <Modal
        visible={visible}
        title="자전설정 수정"
        okText="수정"
        cancelText="취소"
        onOk={this.onUpdate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        <div>
          <Form {...formItemLayout}>
            <Descriptions
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>

              <Ditems label="코인종류">
                {this.props.data && coinList.find((coin) => coin.coinType == this.props.data.coinType) ? coinList.find((coin) => coin.coinType == this.props.data.coinType).name : ''}
              </Ditems>
              <Ditems label="교환대상">
                {this.props.data && coinList.find((coin) => coin.coinType == this.props.data.targetCoinType) ? coinList.find((coin) => coin.coinType == this.props.data.targetCoinType).name : ''}
              </Ditems>
              <Ditems label="최소주기(초)">
                <FormItem>
                {getFieldDecorator("intervalMin", {
                  rules: [{ required: true, message: "최소주기를 입력해주세요." }],
                  initialValue: this.props.data ? this.props.data.intervalMin : null
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="최대주기(초)">
                <FormItem>
                {getFieldDecorator("intervalMax", {
                  rules: [{ required: true, message: "최대주기를 입력해주세요." }],
                  initialValue: this.props.data ? this.props.data.intervalMax : null
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="최소수량">
                <FormItem>
                {getFieldDecorator("amountMin", {
                  rules: [{ required: true, message: "최소수량을 입력해주세요." }],
                  initialValue: this.props.data ? amountFormat(this.props.data.amountMin, this.props.data.coinType) : null
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="최대수량">
                <FormItem>
                {getFieldDecorator("amountMax", {
                  rules: [{ required: true, message: "최대수량을 입력해주세요." }],
                  initialValue: this.props.data ? amountFormat(this.props.data.amountMax, this.props.data.coinType) : null
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="교환대상비율(%)">
                <FormItem>
                {getFieldDecorator("ratio", {
                  rules: [{ required: true, message: "비율을 입력해주세요." }],
                  initialValue: this.props.data ? this.props.data.ratio : null
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>
              <Ditems label="취소시간(n초뒤)">
                <FormItem>
                {getFieldDecorator("cancelInterval", {
                  rules: [{ required: true, message: "취소시간을 입력해주세요." }],
                  initialValue: this.props.data ? this.props.data.cancelInterval : null
                })(
                  <Input/>
                )}
                </FormItem>
              </Ditems>

              </Descriptions>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create()(ArbitrageSettingModify)