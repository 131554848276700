import React, { Component } from "react";
import { Modal, Descriptions, Input, Switch, Icon, Button } from "antd";

import { httpPut, httpUrl, httpGet } from "../../api/httpClient";
import RequestGoods from '../../pages/incentiveManagement/RequestGoods';
import Const from "../../const";

const Ditems = Descriptions.Item;

class RequestGoodsList extends Component {
  state = {
    detailData: {},
    changePasswordUrl: ""
  };


  onCancel = () => {
    // const obj = Object.assign({}, this.props.modalData);
    // this.setState({
    //   detailData: obj
    // });
    this.props.onCancel();
  };

 
  componentDidMount() {
    const obj = Object.assign({}, this.props.modalData);
    this.setState({
      detailData: obj
    });
  }

  render() {
    const { visible, rootPage } = this.props;
    const { detailData } = this.state;
    return (
      <Modal
        visible={visible}
        title="구매상품목록"
        onCancel={this.onCancel}        
        cancelText="닫기"
      
        style={{ minWidth: "1000px" }}>
        {/* <div>{JSON.stringify(modalData)}</div> */}
        <RequestGoods modalData={this.props.modalData}/>
        <div style={{ height: "10px" }} />
        
      </Modal>
    );
  }
}

export default RequestGoodsList;