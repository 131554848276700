import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Radio, Checkbox, InputNumber } from "antd";
import { httpPut, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import CoinSelectBox from "../../components/common/CoinSelectBox";

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;

class MozInvestRegist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fromCoinMethod: 'coin',
      toCoinMethod: 'coin'
    }
  }
  onCreate = (e) => {
    let {form} = this.props;
    
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log(JSON.stringify(values));
        if (global.confirm('등록하시겠습니까?')) {
          httpPost(httpUrl.mozInvestCreate, [], values).then((res) => {
            if (res.data !== 0) {
              form.resetFields();
              this.props.onOk();
            } else {
              alert("알수없는 문제로 등록에 실패하였습니다..");
            }
          });

        }
      }
    });
  }
  render() {
    const { visible, onCancel, form } = this.props;
    const { getFieldDecorator } = form;
        
    let exceptCoinType = [1];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    return (
      <Modal
        visible={visible}
        title="투자 등록"
        okText="등록"
        cancelText="취소"
        onOk={this.onCreate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        <div>
          <Form {...formItemLayout} onSubmit={this.onCreate}>
            <Descriptions
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
              <Ditems label="투자코인">
                <FormItem>
                  {getFieldDecorator("fromCoinType", {
                    rules: [{ required: true, message: "코인을 선택해주세요." }],
                  })(
                    <CoinSelectBox
                    placeholder="코인선택"
                    style={{ width: "160px" }}
                  />
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="투자금액">
                <Radio.Group onChange={(e) => this.setState({fromCoinMethod: e.target.value})} value={this.state.fromCoinMethod}
                style={{marginBottom: 10}}>
                  <Radio value="krw">원화</Radio>
                  <Radio value="coin">코인</Radio>
                </Radio.Group>
                {this.state.fromCoinMethod == 'krw' && (
                <FormItem>
                {getFieldDecorator("fromCoinPrice", {
                  initialValue: 0
                })(
                  <InputNumber min={0} />
                  )}
                  </FormItem>
                )}
                {this.state.fromCoinMethod == 'coin' && (
                <FormItem>
                {getFieldDecorator("fromCoinAmount", {
                  initialValue: 0
                })(
                  <InputNumber min={0} />
                  )}
                  </FormItem>
                  
                )}
              </Ditems>
              <Ditems label="지급코인">
                <FormItem>
                  {getFieldDecorator("toCoinType", {
                    rules: [{ required: true, message: "코인을 선택해주세요." }],
                  })(
                    <CoinSelectBox
                    placeholder="코인선택"
                    style={{ width: "160px" }}
                  />
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="지급금액">
                <Radio.Group onChange={(e) => this.setState({toCoinMethod: e.target.value})} value={this.state.toCoinMethod}
                style={{marginBottom: 10}}>
                  <Radio value="krw">원화</Radio>
                  <Radio value="coin">코인</Radio>
                </Radio.Group>
                {this.state.toCoinMethod == 'krw' && (
                <FormItem>
                {getFieldDecorator("toCoinPrice", {
                  initialValue: 0
                })(
                  <InputNumber min={0} style={{width:'180px'}}/>
                  )}
                  </FormItem>
                )}
                {this.state.toCoinMethod == 'coin' && (
                <FormItem>
                {getFieldDecorator("toCoinAmount", {
                  initialValue: 0
                })(
                  <InputNumber min={0} style={{width:'180px'}}/>
                  )}
                  </FormItem>
                  
                )}
              </Ditems>
              <Ditems label="추천인보상" span={2}>
                * 10%인 경우 0.1로 입력
                <FormItem>
                  {getFieldDecorator("recoRatio", {
                    rules: [{ required: true, message: "추천인 보상 비율(%)을 입력해주세요." }],
                  })(
                    <InputNumber min={0} style={{width:'180px'}}/>
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="투자보상">
                * 10%인 경우 0.1로 입력
                <FormItem>
                  {getFieldDecorator("rewardRatio", {
                    rules: [{ required: true, message: "투자 보상 비율(%)을 입력해주세요." }],
                  })(
                    <InputNumber min={0} style={{width:'180px'}}/>
                  )}
                </FormItem>
              </Ditems>
              <Ditems label="투자보상횟수">
                <FormItem>
                  {getFieldDecorator("rewardCount", {
                    rules: [{ required: true, message: "횟수를 입력해주세요" }],
                  })(
                    <InputNumber min={0} style={{width:'180px'}}/>
                  )}
                </FormItem>
              </Ditems>
              </Descriptions>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create()(MozInvestRegist)