import React, { Component } from "react";
import { Modal, Table } from "antd";
import moment from "moment";

import {
  formatDate,
  numberFormat,
  endDateFormat,
  formatDateSecond,
  amountFormat,
} from "../../util";
import string from "../../string";
import SelectBox from "../common/SelectBox";
import { httpPut, httpUrl } from "../../api/httpClient";
import ConfirmInputModal from "../common/ConfirmInputModal";

class LockDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModalVisible: false,
      selectedRow: 0,
    };
  }

  onDeleteHandle = (row) => {
    if (row.deleted === 1) {
      this.onDelete({
        idx: row.idx,
        memoDeleted: row.memoDeleted,
        deleted: row.deleted,
      });
    } else {
      this.setState({
        deleteModalVisible: true,
        selectedRow: row,
      });
    }
  };

  onDelete = (params = {}) => {
    if (
      global.confirm(
        `해당 LOCK을 ${params.deleted === 1 ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.lockDelete, [], {
        idx: params.idx,
        memoDeleted: params.memoDeleted,
      }).then((res) => {
        alert(`${params.deleted === 1 ? "복구" : "삭제"}가 완료되었습니다.`);
        if (res.data.result === "SUCCESS") {
          this.props.lockDetail({
            refresh: true,
          });

          if (this.props.pageType === "foundationStatus") {
            this.props.refresh({
              refresh: true,
            });
          }
          this.setState({
            deleteModalVisible: false,
          });
        }
        if (res.data.result === "COIN_NOT_ENOUGH") {
          alert("복구하려는 코인의 잔액이 부족합니다.");
        }
      });
    }
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().endOf("day" - 1);
  };

  render() {
    const { visible, title, onOk, modalData } = this.props;

    const lockColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "등록일자",
        dataIndex: "createDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{formatDate(data)}</div>,
      },
      {
        title: "마감일자",
        dataIndex: "expireDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{formatDate(data)}</div>,
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {data === -1
              ? "전체잠금"
              : this.props.selectedRow
              ? amountFormat(data, row.coinType)
              : amountFormat(data, this.props.selected.coinType)}
          </div>
        ),
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {data ? data : "-"}
          </div>
        ),
      },
      {
        title: "삭제 메모",
        dataIndex: "memoDeleted",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {data ? data : "-"}
          </div>
        ),
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {endDateFormat(row.expireDate) >
            formatDateSecond(
              moment()
                .endOf("day" - 1)
                .toString()
            ) ? (
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (value !== row.deleted) {
                    this.onDeleteHandle(row);
                  }
                }}
              />
            ) : (
              <span>{`만료`}</span>
            )}
          </div>
        ),
      },
    ];

    return (
      <Modal
        visible={visible}
        title={title}
        onCancel={onOk}
        style={{ minWidth: "1400px" }}
        footer={null}>
        <div style={{ clear: "both" }} />
        {/* <div>{JSON.stringify(modalData)}</div> */}
        {this.state.deleteModalVisible && (
          <ConfirmInputModal
            rootPage="lock"
            visible={this.state.deleteModalVisible}
            onOk={this.onDelete}
            onCancel={() => {
              this.setState({
                deleteModalVisible: false,
              });
            }}
            selectedRow={this.state.selectedRow}
          />
        )}
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowClassName={(record) => {
            // console.log(moment().endOf("day" - 1));
            return endDateFormat(record.expireDate) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) || record.deleted === 1
              ? "gray"
              : "";
          }}
          columns={lockColumns}
          rowKey={(record) => record.idx}
          style={{ marginBottom: "10px" }}
          dataSource={modalData}
          pagination={this.props.pagination}
          onChange={this.props.handleTableChange}
        />
      </Modal>
    );
  }
}

export default LockDetail;
