import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, Button, Icon, Upload, Modal, Divider } from "antd";

import { getFoundationLock } from "../../contexts/asyncContext";
import {
  numberFormat,
  amountFormat,
  coinMultiFormat,
  endDateFormat,
  formatDate,
  formatDateSecond
} from "../../util";
import string from "../../string";
import {
  httpPut,
  httpUrl,
  httpGet,
  httpDownload,
  serverUrl
} from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import moment from "moment";
import LockCreateModal from "../../components/common/LockCreateModal";
import ConfirmInputModal from "../../components/common/ConfirmInputModal";
import fileDownload from "js-file-download";

const Search = Input.Search;

class FoundationLock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fCoinList: [],
      lockList: [],
      searchText: "",
      coinType: undefined,
      pagination: {
        total: 0,
        current: 0
      },
      orderType: "IDX",
      orderByType: "ASC",

      prevData: {},
      isModify: false,
      lockStatus: "ALL",

      selectData: {},
      modalType: "create",
      visible: false,
      coinList: {},

      deleteModalVisible: false
    };
  }

  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value
    });
  };

  orderByTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState({
        orderByType: value
      });
    }
  };

  orderTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState({
        orderType: value
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState(
      {
        pagination: pager
      },
      () => {
        this.getFoundationLock({
          pageNum: pager.current
        });
      }
    );
  };

  onSearch = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState(
        {
          searchText: value
        },
        () => {
          this.getFoundationLock({
            pageNum: 1
          });
        }
      );
    }
  };

  handleClickModify = (row, index) => {
    httpGet(httpUrl.getSearchSerial, [row.coinType, row.address], {}).then(
      (res) => {
        this.setState({
          visible: true,
          modalType: "edit",
          selectData: {
            ...row,
            expireDate: row.expireDate,
            maxAmount: res.data
          },
          coinList: {
            coinType: this.state.fCoinList,
            serialNum: row.serial
          }
        });
      }
    );
  };

  handleChangeData = (value, type, coinType) => {
    let tempData = this.state.selectData;
    if (type === "amount") {
      tempData[type] = coinMultiFormat(value, coinType);
    } else {
      tempData[type] = value;
    }
    this.setState({
      selectData: tempData
    });
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().endOf("day" - 1);
  };

  handleDeleteState = (value, row) => {
    let deleted = row.deleted;
    if (value !== deleted) {
      if (deleted) {
        this.onDelete({
          idx: row.idx,
          memoDeleted: row.memoDeleted,
          deleted: row.deleted
        });
      } else {
        this.setState({
          deleteModalVisible: true,
          selectedRow: row
        });
      }
    }
  };

  onDelete = (params = {}) => {
    const { pagination } = this.state;
    if (
      global.confirm(
        `해당 LOCK을 ${params.deleted === 1 ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.lockDelete, [], {
        idx: params.idx,
        memoDeleted: params.memoDeleted
      }).then((res) => {
        if (res.data.result === "SUCCESS") {
          alert(`${params.deleted === 1 ? "복구" : "삭제"}가 완료되었습니다.`);
          this.getFoundationLock({
            pageNum: pagination.current
          });
          this.setState({
            deleteModalVisible: false
          });
        }
        if (res.data.data === "COIN_NOT_ENOUGH") {
          alert("코인의 잔액이 부족합니다.");
        }
      });
    }
  };

  downloadExcel = () => {
    const {
      coinType,
      pagination,
      searchText,
      orderType,
      orderByType
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      // console.log(`${excelFormat(startDate)} ~ ${excelFormat(endDate)}`);
      httpDownload(httpUrl.foundationLockExcel, [
        pagination.total,
        1,
        coinType,
        searchText,
        orderType,
        orderByType
      ])
        .then((res) => {
          // console.log(JSON.stringify(res, null, 4));
          fileDownload(
            res,
            `${
              coinList.find((coin) => coin.coinType === coinType).name
            } LOCK 관리.xlsx`
          );
        })
        .catch((error) => {});
    }
  };

  getFoundationLock = (params = {}) => {
    const { coinType, searchText, orderType, orderByType } = this.state;
    let pageNum = params.pageNum || 1;

    // console.log(`refresh!! ${JSON.stringify(params, null, 4)}`);
    if (params.refresh) {
      pageNum = this.state.pagination.current;
    }

    httpGet(
      httpUrl.foundationLockList,
      [10, pageNum, coinType, searchText, orderType, orderByType],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          lockList: res.data.lockList,
          pagination
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    httpGet(httpUrl.getFoundationList, [], {}).then((res) => {
      this.setState({
        foundationList: res.data,
        fCoinList: res.data.map((item) => item.coinType),
        coinList: {
          coinType: res.data.map((item) => item.coinType)
        }
      });
    });
  }

  render() {
    const {
      coinType,
      lockList,
      fCoinList,
      orderByType,
      orderType
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const uploadProps = {
      action: serverUrl + httpUrl.foundationLockCreateExcel,
      multiple: false,
      withCredentials: true,
      beforeUpload: (file) => {
        if (global.confirm("LOCK을 생성하시겠습니까?")) {
          return true;
        } else {
          return false;
        }
      },
      onSuccess: (file) => {
        var success = file.data.success;
        var fail = file.data.fail;
        var total = success + fail;

        var s =
          "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
        Modal.info({
          title: "LOCK 결과",
          content: (
            <div>
              <p>
                {file.data.failAddresses.length > 0 ? (
                  <div>
                    <div>{`${s}`}</div>
                    <br />
                    {`- 실패한 LOCK\n`}
                    {file.data.failAddresses.length > 0 &&
                      file.data.failAddresses.map((value) => (
                        <div>{value}</div>
                      ))}
                  </div>
                ) : (
                  <div>{s}</div>
                )}
              </p>
            </div>
          ),
          onOk() {}
        });
      },
      onError(err) {
        Modal.error({
          title: "LOCK 결과",
          content: "파일 업로드 실패"
        });
      }
    };

    const approvalColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        width: 100,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {coinList.find((coin) => coin.coinType === data)
              ? coinList.find((coin) => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "마감일",
        dataIndex: "expireDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => <div>{formatDate(data)}</div>
      },
      {
        title: "아이디(이메일)",
        dataIndex: "id",
        key: "id",
        // width: 80,
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        key: "address",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div
            style={
              {
                // whiteSpace: 'nowrap',
                // width: '120px',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis'
              }
            }>
            {data}
          </div>
        )
      },
      {
        title: "수량",
        dataIndex: "amount",
        key: "amount",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <span>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </span>
        )
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{data === null ? "-" : data}</div>
      },
      {
        title: "삭제 메모",
        dataIndex: "memoDeleted",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{data === null ? "-" : data}</div>
      },
      {
        title: "상태",
        dataIndex: "deleted",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {endDateFormat(row.expireDate) >
            formatDateSecond(
              moment()
                .endOf("day" - 1)
                .toString()
            ) ? (
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  this.handleDeleteState(value, row);
                }}
              />
            ) : (
              <span>{`만료`}</span>
            )}
          </div>
        )
      },
      {
        title: "수정",
        dataIndex: "",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {row.deleted === 0
              ? row.expireDate !== null && (
                  <Button
                    onClick={() => {
                      this.handleClickModify(row, index);
                    }}>
                    수정
                  </Button>
                )
              : row.deleted === null
              ? "-"
              : "삭제"}
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          <Divider orientation="left" style={{fontSize:23}}>LOCK 설정 </Divider>
        </div>
        <div className="wrapper mt-35 width-1240" />
        <LockCreateModal
          pageType="lockManagement"
          modalType={this.state.modalType}
          editData={this.state.selectData}
          visible={this.state.visible}
          coinList={this.state.coinList}
          onCancel={() => {
            this.setState({
              visible: false,
              modalType: "create",
              selectData: {}
            });
          }}
          refresh={this.getFoundationLock}
        />
        {this.state.deleteModalVisible && (
          <ConfirmInputModal
            rootPage="lock"
            visible={this.state.deleteModalVisible}
            onOk={this.onDelete}
            onCancel={() => {
              this.setState({
                deleteModalVisible: false
              });
            }}
            selectedRow={this.state.selectedRow}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <label style={{ marginRight: "20px" }}>
              코인{" "}
              <CoinSelectBox
                type="coinType"
                placeholder="선택해주세요."
                value={coinType}
                coinCode={fCoinList}
                onChange={(value) => {
                  if (value !== coinType) this.coinTypeHandleChange(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
            <label>
              순서{" "}
              <SelectBox
                value={string.orderByString[orderByType]}
                code={string.orderByCode}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== orderByType)
                    this.orderByTypeHandleChange(value);
                }}
                style={{ width: "160px", marginRight: "10px" }}
              />
              <SelectBox
                value={string.foundationLockString[orderType]}
                code={string.foundationLockCode}
                codeString={string.foundationLockString}
                onChange={(value) => {
                  if (value !== orderType) this.orderTypeHandleChange(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
          </div>
          <div className="flex-row flex-center m-b-10">
            <label htmlFor="" style={{}}>
              검색기준&nbsp;
            </label>
            <Search
              placeholder="지갑주소를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "400px" }}
            />
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div>{`total : ${numberFormat(this.state.pagination.total)}`}</div>
          <div className="flex-row">
            <Button
              onClick={() => {
                this.setState({
                  visible: true,
                  modalType: "create"
                });
              }}>
              LOCK 생성
            </Button>
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div style={{ color: "blue" }}>
            * 전체잠금이 설정된 기간동안은 개별 락의 상태와 관계없이 해당 지갑의
            해당 코인에 대해 모든 출금이 금지됩니다.
          </div>
          <div className="flex-row">
            <a href="/LOCKFormat.xlsx" download>
              <Button className="m-r-10">
                <Icon type="download" style={{ fontSize: "20px" }} />
                양식 다운로드
              </Button>
            </a>
            <Upload
              {...uploadProps}
              accept=".xlsx, .xls"
              showUploadList={false}>
              <Button className="m-r-10">
                <Icon type="upload" style={{ fontSize: "20px" }} />
                엑셀 업로드
              </Button>
            </Upload>
            <Button onClick={this.downloadExcel}>
              <Icon type="download" style={{ fontSize: "20px" }} />
              LOCK 조회목록 다운로드
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowClassName={(record) => {
            // console.log(moment().endOf("day" - 1));
            return endDateFormat(record.expireDate) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) || record.deleted === 1
              ? "gray"
              : "";
          }}
          rowKey={(record) => record.idx}
          columns={approvalColumns}
          dataSource={lockList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = (dispatch) => {
  return {
    getFoundationLock: ({ pageSize, pageNum, coinType, searchText }) =>
      dispatch(getFoundationLock({ pageSize, pageNum, coinType, searchText }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(FoundationLock);
