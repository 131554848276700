import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from '../src/Root';
import { AppContainer } from 'react-hot-loader';
import * as serviceWorker from './serviceWorker';
import Const from "./const";
global.languages = Const.languages;

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root')
  );
};

render(Root);

if (module.hot) {
  module.hot.accept('../src/Root.js', () => {
    render(Root);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
