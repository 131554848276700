export function showAlert(alertType) {
  if (alertType === "create") alert("생성이 완료되었습니다.");
  if (alertType === "update") alert("수정이 완료되었습니다.");
  if (alertType === "modify") alert("변경이 완료되었습니다.");
  if (alertType === "delete") alert("삭제가 완료되었습니다.");
  if (alertType === "authUpdate") alert("권한이 수정되었습니다.");
  if (alertType === "restore") alert("복구가 완료되었습니다.");
  if (alertType === "add") alert("추가 되었습니다.");
  if (alertType === "valid") alert("승인처리되었습니다.");
}
