import React from "react";
import { Layout } from "antd";

const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      ADMIN ©2020 Created by ADMIN.
    </Layout.Footer>
  );
};

export default Footer;
