import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Form, Icon, Input, Button, Checkbox } from "antd";

import { login } from "../actions/loginAction";
import { httpPost, httpUrl } from "../api/httpClient";
import { getCoinDecimal } from "../contexts/asyncContext";
import InputOtp from "../components/common/InputOtp";
import { withRouter } from "react-router-dom";
import Const from "../const";

const FormItem = Form.Item;

class Login extends Component {
  state = { otpVisible: false };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        httpPost(httpUrl.idPwdCheck, [], {
          id: values.id,
          password: values.password,
        })
          .then((res) => {
            // this.optLogin();
            if (res.data.idPw === true && res.data.otp === false) {
              this.optLogin();
            } else if (res.data.idPw === true && res.data.otp === true) {
              this.setState({ otpVisible: true });
            } else {
              alert(
                "로그인이 실패하였습니다.\n아이디나 패스워드를 확인해주세요."
              );
            }
          })
          .catch((error) => {});
      }
    });
  };

  optLogin = (params = {}) => {
    let otp = params.otp || "";
    httpPost(httpUrl.login, [], {
      id: this.props.form.getFieldValue("id"),
      password: this.props.form.getFieldValue("password"),
      key: otp,
    }).then((res) => {
      if (res.data.result) {
        if (this.props.form.getFieldValue("remember")) {
          reactLocalStorage.set("adminId", this.props.form.getFieldValue("id"));
          reactLocalStorage.set("idRemember", true);
        } else {
          reactLocalStorage.remove("adminId");
          reactLocalStorage.set("idRemember", false);
        }
        this.props.onLogin({
          ...res.data.adminUser,
          authList: res.data.adminAuth,
          authCoinList: res.data.adminAuthCoin,
        });
        let path =
          res.data.adminAuth[0].subMenu === null
            ? res.data.adminAuth[0].path
            : res.data.adminAuth[0].subMenu[0].subMenu === null
            ? res.data.adminAuth[0].subMenu[0].path
            : res.data.adminAuth[0].subMenu[0].subMenu[0].path;
        this.props.history.push(path);
      } else {
        if (res.data.reason === "WRONG_IPADDRESS") {
          alert("접근이 제한된 IP주소 입니다.");
          this.setState({ otpVisible: false });
          this.props.form.resetFields(["id", "password"]);
        }
        if (res.data.reason === "INVALID_LOGIN_INFO") {
          alert("아이디나 비밀번호가 잘못되었습니다.");
        }
        if (res.data.reason === "WRONG_OTP") {
          alert("OPT를 잘못입력하셨습니다. 다시 입력해주세요.");
        }
      }
    });
  };

  componentDidMount() {
    let pathname = this.props.location.pathname.split("/");
    if (pathname[1] !== "changePassword") {
      let idRemember = reactLocalStorage.get("idRemember");
      if (idRemember === false || idRemember === "false") idRemember = false;
      else idRemember = true;

      this.props.getCoinDecimal({ language: "ko" });
      let id = idRemember ? reactLocalStorage.get("adminId") : "";
      this.props.form.setFieldsValue({ id: id, remember: idRemember });
    }
  }

  render() {
    const { otpVisible } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="login-container">
        {otpVisible && (
          <InputOtp
            visible={otpVisible}
            onCancel={() => {
              this.setState({ otpVisible: false });
            }}
            onOk={this.optLogin}
          />
        )}
        <Form onSubmit={this.handleSubmit} className="login-form">
          {Const.appName == 'sbwallet' && (
            <img src={require('../img/logo/logo_sbwallet.jpg')} className="logo" />
          )}
          <FormItem>
            {getFieldDecorator("id", {
              rules: [{ required: true, message: "아이디를 입력해주세요" }],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="아이디"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "비밀번호를 입력해주세요." }],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="비밀번호"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("remember", {
              valuePropName: "checked",
            })(<Checkbox className="checkbox mt-15">아이디 저장</Checkbox>)}
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}>
              Log in
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo)),
    getCoinDecimal: ({ language }) => dispatch(getCoinDecimal({ language })),
  };
};

let LoginForm = Form.create()(Login);
LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginForm);
export default withRouter(LoginForm);
