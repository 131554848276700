import React, { Component } from 'react'
import { httpGet, httpUrl,httpPost, httpDelete,httpPut } from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form ,Divider} from 'antd'
import { reactLocalStorage } from "reactjs-localstorage";
import {
  EditOutlined
   } from '@ant-design/icons';
import {
   formatDate,
 
  numberFormat,
  startDateFormat,
  endDateFormat
} from "../../util";
import string from "../../string";
import locale from "antd/es/date-picker/locale/ko_KR";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";



const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
const FormItem = Form.Item;
const CouponOwn = Form.create()(

class CouponOwn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      searchText:"",
      pagination: {
        total: 0,
        current: 0,
      },
      
      serviceData: [],
      selectedRow: "",
      selectData: {},
      prevData: {},

      list: [],
      visible: false,
      couponOwnVisible: false,
     
    };


  }
 
  // handleChangeDepositType = (value) => {
  //   this.setState({
  //     depositType: value,

  //   });
  // };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getCouponOwnList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getCouponOwnList({});

      }
    );
  };


  
  onUpdate = () => {
    const { selectData } = this.state;
    let updateData = {
      idx: selectData.idx,
      count: selectData.count,
    };
    if (global.confirm(`수정하시겠습니까?`)) {
      httpPost(httpUrl.CouponOwnUpdate, [], updateData)
        .then(result => {
          showAlert("update");
          this.setState({
            selectedRow: "",
            selectData: {},
            prevData: {}
          });
          this.getCouponOwnList();
        })
        .catch(error => {});
    }
  };

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "idx") {
      data = row;
    } else if (type === "count") {
      data.count = value;
    } else {
      data.count = value;
    }
    this.setState({
      selectData: data
    });
  };

  



  getCouponOwnList = (params = {}) => {
    const{searchText} = this.state
 
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getCouponOwnList, [10, pageNum])
      .then(res => {
        // console.own(JSON.stringify(result.data.workOwnList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.ownList,
          pagination,
        })
      })

  }


 

  searchTypeHandleChange = (value) => {
    // console.own(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  componentDidMount() {
    this.getCouponOwnList();
  }
  

  render() {
    const {selectedRowKeys } = this.state;
    const rowSelection ={
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    const { selectedData } = this.state;
    const {form} = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      wrapperCol: {
        xs: { span: 10 },
        sm: { span: 13 }
      }
    };
    const {
      selectedRow,selectData,prevData,serviceData
    
    } = this.state;


    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div style={{fontSize:13}}>{data}</div>    
      },
      // {
      //   title: "쿠폰카테고리번호",
      //   key: "couponCategoryIdx",
      //   dataIndex: "couponCategoryIdx",
      //   className: "support-column-number fs-15 lh-21",
      //   render:(data) =>
      //   <div>{data}</div>
      // },
      // {
      //   title:"회원번호",
      //   key:"userIdx",
      //   dataIndex:"userIdx",
      //   className:"support-column-number fs-15 lh-21",
      //   render: (data) => <div>{data}</div>
      // },
      {
        title:"회원이름",
        key:"userName",
        dataIndex:"userName",
        className:"support-column-number fs-15 lh-21",
        render: (data,row) => <div style={{fontSize:13}}>{data}</div>
      },
       {
        title:"회원아이디",
        key:"userId",
        dataIndex:"userId",
        className:"support-column-number fs-15 lh-21",
        render: (data,row) => <div style={{fontSize:13}}>{data}</div>
      },
      {
        title:"회원전화번호",
        key:"userPhone",
        dataIndex:"userPhone",
        className:"support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{data}</div>
      },
      {
        title: "쿠폰종류",
        key: "ccName",
        dataIndex: "ccName",
        className: "support-column-number fs-15 lh-21",
        render:(data) =>
        <div style={{fontSize:13}}>{data}</div>
      },
      {
        title:"잔여수량",
        key:"count",
        dataIndex:"count",
        className:"support-column-number fs-15 lh-21",
        render: (data,index) => (
          // <div>{numberFormat(data)}</div>
          <div style={{fontSize:13}}>
          {selectedRow !== index ? (
            numberFormat(data)
          ) : (
            <Input
              style={{width:"100px"}}
              value={selectData.count}
              onChange={e => {
                this.onChangeModal(e.target.value, "count");
              }}
            />
          )}
        </div>
          )},
    //  {
    //     title:"생성일",
    //     key:"createDate",
    //     dataIndex:"createDate",
    //     className:"support-column-number fs-15 lh-21",
    //     render: (data) => <div>{formatDate(data)}</div>
    //   },
      {
        title:"",
        key:"",
        dataIndex:"",
        className:"support-column-number fs-15 lh-21",
        render:(row, index)=>
        <div>
          {selectedRow !== index && (
        <Button
                style={{fontSize:13}}
                onClick={value => {
                  this.onChangeModal(row.idx, "idx", index);
                  this.setState({
                    selectedRow: index,
                    selectData: row,
                    prevData: row
                  });
                }}>
                <EditOutlined />변경
              </Button>
          )}
           {selectedRow === index && (
              <div>
                <Button
                  style={{ marginRight: "8px" ,fontSize:13 }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                 style={{ fontSize:13 }}
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData
                    });
                  }}
                />
              </div>
            )}
            </div>
      },
      
   
   

    ];




    return (
      
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{fontSize:23}}>보유쿠폰 설정 </Divider>  
         

          
        </div>
        {/* 메모입력,수정 Modal */}
        

        
        <Table
        
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px', width:1200, marginLeft:250 }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
         

      </div>
    )


  }
}
);
export default CouponOwn;
