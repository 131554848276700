import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Button, InputNumber } from "antd";

import { getAuto } from "../../contexts/asyncContext";
import { amountFormat, coinMultiFormat, coinDivideFormat } from "../../util";
import string from "../../string";
import { httpPut, httpUrl } from "../../api/httpClient";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";

class Auto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusTypes: "ALL",
      pagination: {
        total: 0,
        current: 0
      },
      dropPagination: {
        total: 0,
        current: 0
      },
      selectedCoin: "",
      selectedData: []
    };
  }

  func = num => {
    return num;
  };

  onUpdate = () => {
    let data = {
      airDropList: this.state.selectedData.map((value, index) => {
        return {
          ...value,
          amount: coinMultiFormat(value.amount, this.state.selectedCoin)
        };
      }),
      coinType: this.state.selectedCoin
    };

    // let num = data.airDropList[0].amount;
    // console.log(JSON.stringify(data, null, 4));
    if (global.confirm(`변경하시겠습니까?`)) {
      httpPut(httpUrl.autoUpdate, [], data)
        .then(res => {
          showAlert("modify");
          this.setState(
            {
              selectedCoin: ""
            },
            () => {
              this.props.getAuto({ pageSize: 10, pageNum: 1 });
            }
          );
        })
        .catch(error => {});
    }
  };

  onChangeModal = (value, row, type) => {
    this.setState({
      selectedData: this.state.selectedData.map((item, index) => {
        if (item.dropType === row.dropType) {
          if (type === "active") {
            return {
              ...item,
              active: value
            };
          } else {
            if (
              coinMultiFormat(parseInt(value), row.coinType) >=
              9223372036854775807
            ) {
              alert(`Too much amount: ${value}`);
              return {
                ...item
              };
            } else {
              return {
                ...item,
                amount: value
              };
            }
          }
        } else {
          return {
            ...item
          };
        }
      })
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getAuto({ pageSize: 10, pageNum: 1 });
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const coinColumns = [
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      }
    ];

    const expandedRowRender = record => {
      const dropColumns = [
        {
          title: "타입",
          dataIndex: "dropType",
          className: "support-column-number fs-15 lh-21",
          render: data => <div>{string.dropString[data]}</div>
        },
        {
          title: "금액",
          dataIndex: "amount",
          className: "support-column-number fs-15 lh-21",
          render: (data, row, index) => (
            <div>
              {this.state.selectedCoin === record.coinType ? (
                <InputNumber
                  min={0}
                  // max={9223372036}
                  style={{ width: "130px" }}
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  value={this.state.selectedData[index].amount}
                  onChange={value => {
                    this.onChangeModal(value, row, "amount");
                  }}
                />
              ) : (
                amountFormat(data, record.coinType)
              )}
            </div>
          )
        },
        {
          title: "상태",
          dataIndex: "active",
          className: "support-column-number fs-15 lh-21",
          render: (data, row, index) => (
            <div>
              {this.state.selectedCoin === record.coinType ? (
                <SelectBox
                  value={
                    string.activeHandlesCodes[
                      this.state.selectedData[index].active
                    ]
                  }
                  code={string.toggleCode}
                  data={row}
                  page="active"
                  codeString={string.activeHandlesCodes}
                  onChange={this.onChangeModal}
                />
              ) : (
                string.activeHandlesCodes[data]
              )}
            </div>
          )
        }
      ];
      return (
        <div>
          <div style={{ float: "right" }}>
            {this.state.selectedCoin !== record.coinType ? (
              <Button
                size="small"
                onClick={() => {
                  this.setState({
                    selectedCoin: record.coinType,
                    selectedData: record.airDropList.map((value, index) => {
                      return {
                        ...value,
                        amount: coinDivideFormat(value.amount, record.coinType)
                      };
                    })
                  });
                }}>
                수정
              </Button>
            ) : (
              <div>
                <Button
                  size="small"
                  style={{ marginRight: "8px" }}
                  onClick={() => {
                    this.onUpdate();
                  }}>
                  확인
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    this.setState({
                      selectedCoin: ""
                    });
                  }}>
                  취소
                </Button>
              </div>
            )}
          </div>
          <div style={{ clear: "both" }} />
          <Table
            rowKey={record => `record: ${record.dropType}`}
            columns={dropColumns}
            dataSource={record.airDropList}
          />
        </div>
      );
    };

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          자동배포
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          id="process-table"
          rowKey={record => record.coinType}
          columns={coinColumns}
          dataSource={this.props.result.coinAutoList}
          pagination={this.state.pagination}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getAuto: ({ pageSize, pageNum }) => dispatch(getAuto({ pageSize, pageNum }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Auto);
