import React, { Component } from "react";

import { Table, Button, InputNumber,Divider } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import string from "../../string";
import { numberFormat } from "../../util";
import { showAlert } from "../../components/common/AlertModal";

class GlobalSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingList: [],
      selectedData: {},
      isModify: false
    };
  }

  onUpdateSettingValue = () => {
    let updateData = { ...this.state.selectedData };
    if (global.confirm("수정하시겠습니까?")) {
      httpPut(httpUrl.updateGlobalSetting, [], updateData).then((res) => {
        showAlert("update");
        this.setState({
          isModify: false,
          selectedData: {}
        });
      });
    }
  };

  getGlobalSetting = (params = {}) => {
    // let pageNum = params.pageNum || 1;
    httpGet(httpUrl.getGlobalSetting, [], {}).then((res) => { 
      this.setState({
        settingList: res.data
      });
    });
  };

  componentDidMount() {
    this.getGlobalSetting();
  }

  render() {
    const { settingList, selectedData, isModify } = this.state;

    const columns = [
      {
        title: "종류",
        key: "type",
        dataIndex: "type",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{string.globalSettingString[data]}</div>
      },
      {
        title: "설정 값",
        key: "value",
        dataIndex: "value",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          selectedData.type && selectedData.type === row.type && isModify ? (
            <InputNumber
              value={selectedData.value}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={(value) => {
                selectedData.value = value;
                this.setState({
                  selectedData
                });
              }}
              style={{ width: "180px" }}
            />
          ) : (
            <div>{numberFormat(data)}</div>
          )
      },
      {
        key: "modify",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          selectedData.type && selectedData.type === row.type && isModify ? (
            <div className="flex-row flex-center justify-center">
              <Button
                className="m-r-10"
                onClick={() => {
                  this.onUpdateSettingValue();
                }}>
                확인
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    isModify: false,
                    selectedData: {}
                  });
                }}>
                취소
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                this.setState({
                  isModify: true,
                  selectedData: row
                });
              }}>
              정보수정
            </Button>
          )
      }
    ];
    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
         <Divider orientation="left" style={{fontSize:23}}>설정값관리 </Divider>
        </div>
        <div className="flex-row flex-center flex-end m-b-10"></div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={settingList}
          columns={columns}
        />
      </div>
    );
  }
}

export default GlobalSetting;
