import React, { Component } from 'react'
import { httpGet, httpUrl, httpPost, httpDownload, httpDelete, makeUrl } from '../../api/httpClient';
import { Table, Input, Button, DatePicker, Form, Icon, Upload, Divider, Modal, } from 'antd'
import fileDownload from "js-file-download";
import {
  formatDate, amountFormat
} from "../../util";
import PurchaseRegistry from "../../components/purchase/PurchaseRegistry";
import {
  FormOutlined
} from '@ant-design/icons';
const Search = Input.Search;
const FormItem = Form.Item;
class Purchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      isLoaded: true,
      pagination: {
        total: 0,
        current: 0,
      },
      createVisible: false,
      list: [],
      visible: false,

    };


  }

  // handleChangeDepositType = (value) => {
  //   this.setState({
  //     depositType: value,

  //   });
  // };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getPurchaseList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getPurchaseList({});

      }
    );
  };

  cancelPurchase = (idx) => {

    if (global.confirm(idx + `번 구매정보를 정말 삭제하시겠습니까?`)) {
      httpPost(httpUrl.createProductCancel, [idx], {})
        .then((result) => {
          if (result.data === true) {
            this.getPurchaseList({});
          } else {
            alert("삭제에 실패하였습니다.");
          }
        })
        .catch((error) => { });
    }
  }



  getPurchaseList = (params = {}) => {
    const { searchText } = this.state

    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getPurchaseList, [10, pageNum, searchText])
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.purchaseList,
          pagination,
        })
      })

  }


  downloadExcel = () => {


    {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.purchaseListDownload,
          [
            this.state.searchText
          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `구매관리 조회.xlsx`);
          })
          .catch((err) => { });
      }
    }
  };



  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  componentDidMount() {
    this.getPurchaseList();
  }


  render() {
    const {

      createVisible,

    } = this.state;

    const formItemLayout = {

    };

    const tailFormItemLayout = {

    };

    const props = {
      action: `${makeUrl(httpUrl.purchaseCreateExcel, null)}`,
      withCredentials: true,
      multiple: false,
      beforeUpload: file => {
        if (global.confirm("등록 하시겠습니까?")) {
          return true;
        } else {
          return false;
        }
      },
      onSuccess: file => {
        var success = file.data.success;
        var fail = file.data.fail;
        var total = success + fail;

        var s =
          "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
        Modal.info({
          title: "등록결과",
          content: (
            <div>
              <p>
                {file.data.failMessages.length > 0 ? (
                  <div>
                    <div>{`${s}`}</div>
                    <br />
                    {`- 실패한 구매\n`}
                    {file.data.failMessages.length > 0 &&
                      file.data.failMessages.map(value => (
                        <div>{value}</div>
                      ))}
                  </div>
                ) : (
                  <div>{s}</div>
                )}
              </p>
            </div>
          ),
          onOk() { }
        });
        this.getPurchaseList();
      },
      onError(err) {
        Modal.error({
          title: "등록결과",
          content: "파일 업로드 실패"
        });
      }
    };


    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        width: 100,
        render: (data) =>
          <div>{data}</div>
      },
      {
        title: "회원명",
        key: "userIdx",
        dataIndex: "userIdx",
        width: 250,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          <div>{row.userIdx}/ {row.id} / {row.name}</div>
      },
      {
        title: "상품명",
        key: "productIdx",
        dataIndex: "productIdx",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          <div>{row.pname}</div>
      },
      {
        title: "금액",
        key: "price",
        dataIndex: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>&#8361; {amountFormat(data)}</div>
      },
      {
        title: "PV",
        key: "pv",
        dataIndex: "pv",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>{amountFormat(data)} PV</div>
      },
      {
        title: "재구매",
        key: "reorder",
        dataIndex: "reorder",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>{data == 1 ? 'O' : '-'}</div>
      },
      {
        title: "생성일",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>{formatDate(data)}</div>
      },
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <Button onClick={() => this.cancelPurchase(data)}>삭제</Button>
      },


      // {
      //   title:"삭제여부",
      //   key:"deleted",
      //   dataIndex:"deleted",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data)=> {
      //     if(data == 0) return ("-");
      //     else return ("O");
      //   }

      // },
      // {
      //   title:"삭제일",
      //   key:"deletedDate",
      //   dataIndex:"deletedDate",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data)=> {
      //     if(data == null) return ("-");
      //     else return (<div>{data}</div>);
      //   }

      // },




    ];




    return (

      <div>
        {createVisible && (
          <PurchaseRegistry
            visible={createVisible}
            onOk={() => {
              this.setState({
                createVisible: false
              });
              this.getPurchaseList();
            }}
            onCancel={() => {
              this.setState({
                createVisible: false
              });
            }}
          />
        )}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{ fontSize: 21.5 }}>구매관리 <span style={{ fontSize: 7 }}>L7 STEM</span></Divider>


        </div>
        {/* 메모입력,수정 Modal */}


        {/* <Form {...formItemLayout} onSubmit={this.onSubmit}>
              <FormItem label={<span></span>} colon={false}>                
                <Upload  accept=".xlsx, .xls" showUploadList={false}>
                  <Button style={{ marginRight: "10px" }}>
                    <Icon type="upload" style={{ fontSize: "20px" }} />
                    등록
                  </Button>
                </Upload>
                <a href={"/foundationFormat.xlsx"} download>
                  <Button>
                    <Icon type="download" style={{ fontSize: "20px" }} />
                    엑셀양식
                  </Button>
                </a>
              </FormItem>                        
            </Form> */}

        <div className="purchase-reg">
          <Form {...formItemLayout} onSubmit={this.onSubmit}>
            <FormItem label={<span></span>} colon={false}>
              <Button
                onClick={() => {
                  this.setState({ createVisible: true });
                }}>
                <FormOutlined />등록
              </Button>

              &nbsp;
              <Upload {...props} accept=".xlsx, .xls" showUploadList={false}>
                <Button style={{ marginRight: "10px" }}>
                  <Icon type="upload" style={{ fontSize: "20px" }} />
                  엑셀파일로 등록
                </Button>
              </Upload>
              <a href={"/purchase_create_templete.xlsx"} download>
                <Button>
                  <Icon type="download" style={{ fontSize: "20px" }} />
                  엑셀양식 받기
                </Button>
              </a>

            </FormItem>
          </Form>
          <div className="purchase-excel">
            <Button onClick={this.downloadExcel}>
              <Icon type="download" style={{ fontSize: "20px" }} />
              엑셀 다운로드
            </Button>
          </div>
          <div className="purchase-search">
            <Search
              placeholder={`회원명 또는 회원아이디를 입력해주세요`}
              enterButton={`검색`}
              allowClear
              onSearch={this.onSearch}
              style={{ width: 350 }}
            />
          </div>
        </div>

        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />


      </div>

    )


  }
}

export default Purchase;
