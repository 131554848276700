import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, Button } from "antd";

import { numberFormat } from "../../util";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";
import { showAlert } from "../../components/common/AlertModal";

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: reactLocalStorage.getObject("adminUser"),
      serviceData: [],
      selectedRow: "",
      selectData: {},
      prevData: {}
    };
  }

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "idx") {
      data = row;
    } else if (type === "status") {
      data.status = value;
    } else {
      data.memo = value;
    }
    this.setState({
      selectData: data
    });
  };

  onUpdate = () => {
    const { selectData } = this.state;
    let updateData = {
      idx: selectData.idx,
      status: selectData.status,
      memo: selectData.memo
    };
    if (global.confirm(`수정하시겠습니까?`)) {
      httpPut(httpUrl.serviceUpdate, [], updateData)
        .then(result => {
          showAlert("update");
          this.setState({
            selectedRow: "",
            selectData: {},
            prevData: {}
          });
          this.serviceList();
        })
        .catch(error => {});
    }
  };

  serviceList() {
    httpGet(httpUrl.serviceList, [], {})
      .then(result => {
        // console.log(result.data);
        this.setState({ serviceData: result.data });
      })
      .catch(error => {});
  }

  componentDidMount() {
    this.serviceList();
  }

  render() {
    const { serviceData, selectData, prevData, selectedRow } = this.state;

    const serviceColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "타입",
        dataIndex: "type",
        key: "type",
        width: 80,
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "서비스",
        dataIndex: "service",
        key: "service",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "상태",
        dataIndex: "status",
        key: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              data
            ) : (
              <Input
                value={selectData.status}
                onChange={e => {
                  this.onChangeModal(e.target.value, "status");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "메모",
        dataIndex: "memo",
        key: "memo",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              data
            ) : (
              <Input
                allowClear
                value={selectData.memo}
                onChange={e => {
                  this.onChangeModal(e.target.value, "memo");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "",
        dataIndex: "update",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index && (
              <Button
                onClick={value => {
                  this.onChangeModal(row.idx, "idx", index);
                  this.setState({
                    selectedRow: index,
                    selectData: row,
                    prevData: row
                  });
                }}>
                수정하기
              </Button>
            )}
            {selectedRow === index && (
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData
                    });
                  }}
                />
              </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          서비스설정
        </div>
        {/* <div>{JSON.stringify(serviceData)}</div> */}
        <div className="wrapper mt-35 width-1240" />
        {/* {userInfo.superAdmin === 1 && (
          <div style={{ float: "left" }}>
            <Button
              onClick={() => {
                this.setState({ registryModalVisible: true });
                this.coinAuthList(0);
              }}
            >
              생성
            </Button>
          </div>
        )} */}
        <Table
          id="admin-table"
          rowKey={record => record.idx}
          columns={serviceColumns}
          dataSource={serviceData}
          pagination={false}
        />
      </div>
    );
  }
}

export default Service;
