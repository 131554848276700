import React, { Component } from "react";

import { Table, Modal } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat, dayFormat, amountFormat } from "../../util";

class GlobalShareDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        current: 1,
        total: 0,
      },
      searchType: "ALL",
      searchText: "",
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getList({
      pageNum: pager.current,
    });
  };

  getList = (params = {}) => {
    const { detailData } = this.props;
    let pageNum = params.pageNum || 1;
    httpGet(httpUrl.getGlobalShareDetail, [ 
      10,
      pageNum,
      detailData.logDate,
      detailData.userIdx,
    ]).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.list,
        pagination,
      });
    });
  };

  componentDidMount() {
    this.getList();
  }

  render() {
    const { detailData, visible, onCancel } = this.props;
    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "일자",
        key: "logDate",
        dataIndex: "logDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{dayFormat(data)}</div>,
      },
      {
        title: "아이디",
        key: "userId",
        dataIndex: "userId",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이름",
        key: "userName",
        dataIndex: "userName",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "수익배분금액",
        key: "amount",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, 101)}</div>,
      },
      {
        title: "배분율",
        key: "shareRatio",
        dataIndex: "shareRatio",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>{data === null ? "-" : `${numberFormat(data*100)} %`}</div>
        ),
      },
    ];
    return (
      <Modal
        className="width-1240"
        visible={visible}
        onCancel={onCancel}
        footer={null}>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          {`${dayFormat(detailData.logDate)} 수익배분 내역`}
        </div>
        <div className="flex-row flex-center flex-end m-b-10"></div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          columns={columns}
        />
      </Modal>
    );
  }
}

export default GlobalShareDetail;
