import { DatePicker, Input, Table } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl } from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { amountFormat, formatDate, numberFormat } from "../../util";



const Search = Input.Search;
const { RangePicker } = DatePicker;

class ArbitrageFlowTotal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flowTotal: [],
    };
  }

  componentDidMount() {
    this.getFlowTotal();
  }
  initialLoad = () => {
    this.setState({
      flowTotal: []
      }, () => {
        this.getFlowTotal();
      })
  }
  reload = () => {
    this.getFlowTotal();
  }
  getFlowTotal = () => {
    httpGet(httpUrl.arbitrageFlowTotal,[],{}).then((res) => {
      this.setState({
        flowTotal: res.data,
      });
    });
  };
  render() {
    const { flowTotal } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    const columns = [
      {
        title: "코인명",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{coinList.find((coin) => coin.coinType === data).name}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType) + ' ' + coinList.find((coin) => coin.coinType === row.coinType).symbol}</div>
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          외부입출집계
      </div>
      <div className="wrapper mt-35 width-1240" />
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.amount < 0 ? "font-red" : "")}
          dataSource={flowTotal}
          columns={columns}
        />
      </div>
    );
  }
}

export default ArbitrageFlowTotal;
