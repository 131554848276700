import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Icon, Switch } from "antd";
import { httpPut, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from 'moment';

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;

class EventModify extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
    
  }
  componentDidMount() {
  }
  onUpdate = (e) => {
    let {form} = this.props;
    if (this.props.data && this.props.data.event) this.date=[moment(formatDateSecond(this.props.data.event.startDate), 'YYYY-MM-DD HH:mm:ss'),moment(formatDateSecond(this.props.data.event.endDate), 'YYYY-MM-DD HH:mm:ss')];

    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        values.idx = this.props.data.event.idx;
        values.image = this.props.data.event.image;
        if (this.date != null && this.date.length == 2) {
          values.startDate = formatDate(this.date[0])+":00";
          values.endDate = formatDate(this.date[1])+":00";
        }
        values.title = {};
        values.content = {};
        global.languages.map((value, index)=>{
          values.title[value.code.toLowerCase()] = values['title'+value.code];
          values.content[value.code.toLowerCase()] = values['content'+value.code];
        });
        values.linkTarget = values.linkTarget ? 1:0;
        console.log(JSON.stringify(values))
        httpPost(httpUrl.eventModify, [], values).then((res) => {
          if (res.data !== 0) {
            form.resetFields();
            this.setState({image: null})
            this.props.onOk();
          } else {
            alert("알수없는 문제로 등록에 실패하였습니다..");
          }
        });
      }
    });
  }
  handleDateChange = (value)=>{
    this.date = value;
  }
  render() {
    const { visible, onCancel, form, data } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    const imageProps = {
      action: serverUrl + httpUrl.imageEncoding,
      multiple: false,
      withCredentials: true,
      beforeUpload: (file) => {
        if (file.type !== "image/png") {
          alert("이미지파일은 png형식만 업로드 가능합니다.");
          return false;
        } else {
          if (global.confirm("업로드 하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        }
      },
      onSuccess: (file) => {
        try {
          this.props.updateSelectedImage(file.data.encodingImage)
        } catch {
          alert("이미지 업로드 실패");
        }
      },
      onError(err) {
        alert("이미지 업로드 실패");
      }
    };
    return (
      <Modal
        visible={visible}
        title="이벤트 수정"
        okText="수정"
        cancelText="취소"
        onOk={this.onUpdate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        {data != null && (
        <div>
          <Form {...formItemLayout} onSubmit={this.onUpdate}>
            <Descriptions
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Ditems label="이미지">
                <Upload {...imageProps} showUploadList={false}>
                  {data.event.image ? (
                    <img
                      src={data.event.image}
                      alt="symbolImage"
                      style={{
                        width: "100%",
                        marginRight: "8px",
                        cursor: "pointer"
                      }}
                    />
                  ) : (
                    <Button style={{ marginRight: "8px" }}>
                      <Icon type="upload" />
                      이미지 등록
                    </Button>
                  )}
                </Upload>
              </Ditems>
              
              {global.languages.map((value, index)=>{
                let title = data.titles.find(x=>x.language==value.code.toLowerCase());
                let content = data.contents.find(x=>x.language==value.code.toLowerCase());
                title = title ? title.content : "";
                content = content ? content.content : "";
                return (
                <>
                  <Ditems label={"제목("+value.name+")"}>
                    <FormItem>
                    {getFieldDecorator("title"+value.code, { 
                      rules: [ { required: value.code=="Ko", message: "제목을 입력해 주세요" } ] ,
                      initialValue: title
                    })(<Input/>)}
                    </FormItem>
                  </Ditems>
                  <Ditems label={"내용("+value.name+")"}>
                    <FormItem >
                    {getFieldDecorator("content"+value.code, { 
                      rules: [ { required: value.code=="Ko", message: "내용을 입력해 주세요" } ]  ,
                      initialValue: content
                    })(<Input.TextArea/>)}
                      </FormItem>
                  </Ditems>
                </>
                )
              })}
              <Ditems label="기간">
                  <RangePicker 
                    showTime={{ format: 'HH:mm' }}
                    onChange={this.handleDateChange}
                    format="YYYY-MM-DD HH:mm:00"
                    locale={locale}
                    defaultValue={[moment(formatDateSecond(data.event.startDate), 'YYYY-MM-DD HH:mm:ss'),moment(formatDateSecond(data.event.endDate), 'YYYY-MM-DD HH:mm:ss')]}
                  />
              </Ditems>
              <Ditems label="링크">
                <FormItem>
                {getFieldDecorator("link", {
                      initialValue: data.event.link
                    })(
                    <Input/>
                  )}
                  </FormItem>
                  * 외부 링크가 있는 경우 (https://www.naver.com)과 같이 입력해주세요.
              </Ditems>
              <Ditems label="링크타겟(새창)">
                <FormItem>
                {getFieldDecorator("linkTarget", {
                  initialValue: data.event.linkTarget == 1, valuePropName: 'checked'
                })(
                    <Switch />
                  )}
                  </FormItem>
                  * ON인 경우 링크가 브라우저에서 열리고, OFF인 경우 앱 내부에서 열립니다.
              </Ditems>
              </Descriptions>
          </Form>
        </div>

        )}
      </Modal>
    );
  }
}

export default Form.create()(EventModify)