import React, { Component } from "react";

import { Table, DatePicker, Input, Button, InputNumber } from "antd";

import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import { formatDate, startDateFormat, endDateFormat, amountFormat, coinMultiFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "../../components/common/CoinSelectBox";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class ExchangeSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: [],
      selectedData: {},
      isModify: false,
    };
  }

  componentDidMount() {
    this.getExchangeSettingList();
  }
  getExchangeSettingList = () => {
    httpGet(httpUrl.getExchangeSettingList, [], {}).then((res) => {
      this.setState({
        tables: res.data,
      });
    });
  };
  onUpdateSettingValue = (callback) => {
    httpPost(httpUrl.updateExchangeSetting, [], this.state.selectedData).then((res) => {
      callback();
    }).catch(()=>{
      alert('수정 중 오류가 발생하였습니다.')
    });
  }
  render() {
    const { tables, selectedData, isModify } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    const columns = [
      {
        title: "코인명",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{coinList.find((coin) => coin.coinType === data).name}</div>
      },
      {
        title: "코인타입",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "최소수량",
        dataIndex: "minAmount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          selectedData.coinType && selectedData.coinType === row.coinType && isModify ? (
            <InputNumber
              value={amountFormat(selectedData.minAmount, row.coinType)}
              onChange={(value) => {
                selectedData.minAmount = coinMultiFormat(value, row.coinType);
                this.setState({
                  selectedData
                });
              }}
              style={{ width: "180px" }}
            />
          ) : (
            <div>{amountFormat(data, row.coinType)}</div>
          )
      },
      {
        key: "modify",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          selectedData.coinType && selectedData.coinType === row.coinType && isModify ? (
            <div className="flex-row flex-center justify-center">
              <Button
                className="m-r-10"
                onClick={() => {
                  this.onUpdateSettingValue(()=>{
                    this.setState({
                      isModify: false,
                      selectedData: {}
                    });
                  });
                }}>
                확인
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    isModify: false,
                    selectedData: {}
                  });
                }}>
                취소
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                this.setState({
                  isModify: true,
                  selectedData: row
                });
              }}>
              정보수정
            </Button>
          )
      }
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          교환설정
      </div>
        <div className="wrapper mt-35 width-1240" />

        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={tables}
          columns={columns}
        />
      </div>
    );
  }
}

export default ExchangeSetting;
