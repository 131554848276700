import React, { Component } from "react";
import { Modal, Table, Button, Input } from "antd";
import { httpPost, httpPut, httpUrl, httpGet } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";

const Search = Input.Search;

class OrganizationCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      searchText: "",

      settingData: []
    };
  }

  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getCategoryList();
      }
    );
  };

  onOk = (row) => {
    if (this.state.settingData.includes(row.name)) {
      alert("선택하신 카테고리가 중복 되었습니다. 다시 선택해주세요.");
    } else {
      this.props.onOk(row);
      this.props.onCancel();
    }
  };

  onUpdate = (row) => {
    let data = {
      ...row,
      categoryIdx: row.idx,
      organizationIdx: this.props.idx
    };
    let arr = [];
    arr.push(data);
    if (global.confirm("추가 하시겠습니까?"))
      httpPost(httpUrl.organizationCategoryCreate, [], arr)
        .then((res) => {
          showAlert("add");
          this.getCategoryList();
          this.props.refresh({ idx: this.props.idx });
        })
        .catch((e) => {});
  };

  onDelete = (row) => {
    let body = {
      categoryIdx: row.categoryIdx,
      organizationIdx: this.props.organizationIdx
    };
    if (global.confirm("삭제하시겠습니까?"))
      httpPut(httpUrl.organizationCategoryDelete, [], body)
        .then((res) => {
          showAlert("delete");
          this.getOrganizationCategoryList();
        })
        .catch((e) => {});
  };

  getOrganizationCategoryList = (params = {}) => {
    let idx = params.idx || this.props.organizationIdx;

    httpGet(httpUrl.getOrganizationCategoryList, [idx], {})
      .then((res) => {
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  getCategoryList = (params = {}) => {
    httpGet(
      httpUrl.getCategoryList,
      [this.props.idx, this.state.searchText],
      {}
    )
      .then((res) => {
        // console.log(JSON.stringify(res, null, 4));
        this.setState({
          list: res.data.list
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const { type, orgCategory } = this.props;
    if (!type) {
      this.getOrganizationCategoryList();
    } else {
      this.getCategoryList();
      if (orgCategory !== undefined) {
        let arr = [];
        orgCategory.map((list) => {
          if (!arr.includes(list.name)) arr.push(list.name);
          return this.setState({ settingData: arr });
        });
      }
    }
  }

  render() {
    const { visible, onCancel, type } = this.props;
    const { categoryVisible, list } = this.state;

    const columns = [
      {
        title: "카테고리",
        dataIndex: "name",
        key: "name",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "색상",
        dataIndex: "color",
        key: "color",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "",
        dataIndex: "under",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <Button
            className="m-l-10"
            onClick={() => {
              type
                ? this.props.idx === 0
                  ? this.onOk(row)
                  : this.onUpdate(row)
                : this.onDelete(row);
            }}>
            {type ? "추가" : "삭제"}
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title="카테고리 설정"
        onCancel={onCancel}
        footer={null}>
        {categoryVisible && (
          <OrganizationCategory
            idx={this.props.organizationIdx}
            visible={categoryVisible}
            type={true}
            onCancel={() => {
              this.setState({
                categoryVisible: false
              });
            }}
            refresh={this.getOrganizationCategoryList}
          />
        )}
        {!type && (
          <Button
            className="m-b-10"
            style={{ width: "80px" }}
            onClick={() => {
              this.setState({
                categoryVisible: true
              });
            }}>
            추가
          </Button>
        )}
        {type && (
          <Search
            placeholder="카테고리를 입력해주세요."
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ marginBottom: "10px" }}
          />
        )}
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          style={{ marginBottom: "10px" }}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default OrganizationCategory;
