import React, { Component } from "react";
import {
  SearchOutlined,WalletOutlined,AccountBookOutlined,LockOutlined,UserAddOutlined,UnorderedListOutlined, WalletTwoTone,HistoryOutlined
} from '@ant-design/icons';
import { connect } from "react-redux";
import { Table, Input, Button, Checkbox, DatePicker,Tooltip,Icon,Divider } from "antd";
import { getUserSb } from "../../contexts/asyncContext";
import {
  numberFormat,
  formatDate,
  startDateFormat,
  endDateFormat,
} from "../../util";
import string from "../../string";
import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";
import SelectBox from "../../components/common/SelectBox";
import SelectBox2 from "../../components/common/SelectBox2";
import WalletModal from "./../../components/user/WalletModal";
import WalletLogModalSB from "./../../components/user/WalletLogModalSB";
import CoinLockListModal from "../../components/user/CoinLockListModal";
import UserDetail from "../../components/user/UserDetail";
import { reactLocalStorage } from "reactjs-localstorage";
import fileDownload from "js-file-download";
import locale from "antd/es/date-picker/locale/ko_KR";
import AccountRegistry from "../../components/payment/AccountRegistry";
import { Redirect } from "react-router-dom";
const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class MemberSb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberData: [],
      deletedSearchType: false,
      searchType: "NAME",
      searchText: "",
      startDate: "",
      endDate: "",
      pagination: {
        total: 0,
        current: 0,
      },
      detailVisible: false,
      detailData: {},
      selectedSerial: "",

      walletVisible: false,
      walletList: [],
      walletPagination: {},

      walletLogVisible: false,

      coinLockVisible: false,
      coinLockList: [],
      coinLockPagination: {},
      coinLockCoinType: 0,
      coinLockSearchType: [],
      coinLockCoinList: [],
      isLoaded: true,
      accountVisible: false,
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.props.getUserSb({
      pageSize: 10,
      pageNum: pager.current,
      deleted: this.state.deletedSearchType,
      searchText: this.state.searchText,
      searchType: this.state.searchType,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  };

  deleteSearchTypeHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      deletedSearchType: e.target.checked,
      isLoaded: true,
    });
  };

  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.props.getUserSb({
          pageSize: 10,
          pageNum: 1,
          deleted: this.state.deletedSearchType,
          searchText: this.state.searchText,
          searchType: this.state.searchType,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        });
      }
    );
  };

  onOk = () => {
    const pager = { ...this.state.pagination };
    this.props.getUserSb({
      pageSize: 10,
      pageNum: pager.current,
      deleted: this.state.deletedSearchType,
      searchText: this.state.searchText,
      searchType: this.state.searchType,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  };

  showDetail = (row) => {
    this.setState({
      detailVisible: true,
      detailData: row,
    });
  };

  showSerialNumber = (params = {}) => {
    let page = params.pageNum || 1;
    httpGet(
      httpUrl.walletList,
      [10, page, this.state.selectedSerial.serial],
      {}
    ).then((result) => {
      const walletPagination = { ...this.state.walletPagination };
      walletPagination.total = result.data.totalCount;
      walletPagination.current = result.data.currentPage;
      this.setState({
        walletVisible: true,
        walletList: result.data.walletList,
        walletPagination,
      });
    });
  };

  walletHandleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.walletPagination };
    pager.current = pagination.current;
    this.setState({
      walletPagination: pager,
    });
    this.showSerialNumber({
      pageNum: pager.current,
    });
  };

  showCoinLockList = (params = {}) => {
    let page = params.pageNum || 1;
    const coinList = reactLocalStorage.getObject("coinList");

    let deleted = false;
    let expire = false;

    this.state.coinLockSearchType.forEach((type) => {
      if (type === "deleted") deleted = true;
      else if (type === "expire") expire = true;
    });

    httpGet(httpUrl.walletList, [100, 1, this.state.selectedSerial.serial], {})
      .then((res) => {
        let arr = [];
        arr = res.data.walletList.map((value) => {
          if (coinList.find((coin) => coin.coinType === value.coinType)) {
            return coinList.find((coin) => coin.coinType === value.coinType);
          } else return null;
        });
        this.setState({
          coinLockCoinList: [...arr],
        });
      })
      .catch((error) => { });
    httpGet(
      httpUrl.coinLockList,
      [
        10,
        page,
        this.state.selectedSerial.serial,
        this.state.coinLockCoinType,
        deleted,
        expire,
      ],
      {}
    ).then((result) => {
      const coinLockPagination = { ...this.state.coinLockPagination };
      coinLockPagination.total = result.data.totalCount;
      coinLockPagination.current = result.data.currentPage;
      this.setState({
        coinLockVisible: true,
        coinLockList: result.data,
        coinLockPagination,
      });
    });
  };

  coinLockHandleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.coinLockPagination };
    pager.current = pagination.current;
    this.setState({
      coinLockPagination: pager,
    });
    this.showCoinLockList({
      pageNum: pagination.current,
    });
  };

  handleCoinLockSearch = (value) => {
    this.showCoinLockList();
  };

  handleCoinLockCoinType = (value) => {
    this.setState({
      coinLockCoinType: value,
    });
  };

  handleCoinLockSearchType = (checkedValues) => {
    this.setState({
      coinLockSearchType: checkedValues,
    });
  };

  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true,
    });
  };

  onDelete = (row) => {
    const { searchText, deletedSearchType, pagination } = this.state;

    let pageNum =
      pagination.current > 1 && !deletedSearchType && row.deleted === 0
        ? this.props.result.userSbList.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;
    if (
      global.confirm(
        `${row.id}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.userDelete, [row.idx], {})
        .then((result) => {
          this.props.getUserSb({
            pageSize: 10,
            pageNum,
            deleted: deletedSearchType,
            searchText: searchText,
            searchType: this.state.searchType,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          });
        })
        .catch((error) => { });
    }
  };

  downloadExcel = () => {
    if (this.state.isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.userSbListDownload,
          [
            this.state.pagination.total,
            1,
            this.state.searchText,
            this.state.deletedSearchType,
            this.state.searchType,
            this.state.startDate,
            this.state.endDate,
          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `회원목록 조회.xlsx`);
          })
          .catch((err) => { });
      }
    }
  };

  componentDidMount() {
    const userInfo = reactLocalStorage.getObject("adminUser");
    if (userInfo.authList) {
      this.props.getUserSb({
        pageSize: 10,
        pageNum: 1,
        deleted: false,
        searchText: "",
        searchType: this.state.searchType,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      });
    }
    // console.log(global.lanList);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination,
      };
    }

    return null;
  }

  render() {
    const memberColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{numberFormat(data)}</div>,
      },
      {
        title: "아이디(가입일)",
        dataIndex: "createDate",
        key: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
        <div>
          <div
              key={`serial${index}`}
              style={{ cursor: "pointer", color: "blue",fontSize:12.5}}
              onClick={() => this.showDetail(row)}>
              {row.id}  
             
           
            </div>
           <div key={`createDate${index}`} style={{fontSize:12.5}}>{formatDate(data)}</div> 
           </div>
        ),
      },
      // {
      //   title: "아이디",
      //   dataIndex: "id",
      //   key: "id",
      //   // width: 80,
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data, row, index) => {
      //     return (
      //       <div
      //         key={`serial${index}`}
      //         style={{ cursor: "pointer", color: "blue" }}
      //         onClick={() => this.showDetail(row)}>
      //         {data}
      //       </div>
      //     );
      //   },
      // },
      {
        title: "이름(코드)",
        dataIndex: "name",
        key: "name",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:12.5}}>
            <b>{data}</b><div style={{fontSize:12}}>{row.recommendCode}</div>
            {/* {data}▪<div><b>{row.recommendCode}</b></div> */}
          </div>
        )
      },
      {
        title: "전화번호",
        dataIndex: "phone",
        key: "phone",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
         <div style={{fontSize:12.5}}>
           {data}
         </div>
          ),
      },
      {
        title: "추천인(코드)",
        dataIndex: "recommender",
        key: "recommender",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div style={{fontSize:12.5}}>
            <b>{row.recommenderName}</b><div><div style={{fontSize:12}}>{data ? data : "-"}</div></div>
          </div>
        ),
      },
      {
        title:"SB(1+2)",
        dataIndex: "pay",
        key: "pay",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{fontSize:12.5, textAlign:"right"}}>
          <b>{numberFormat(Math.round(data / 1000000000))}</b><div> SB(1+2) </div>
          </div>
          ),    
      },
      {
        title:"SB1",
        dataIndex: "sb1",
        key: "sb1",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{fontSize:12.5, textAlign:"right"}}>
            <b>{numberFormat(Math.round(data / 1000000000))}</b><div > SB1</div>
          </div>
          ),    
      },
      {
        title:"SB2",
        dataIndex: "sb2",
        key: "sb2",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => (
          <div style={{fontSize:12.5, textAlign:"right"}} >
          <b>{numberFormat(Math.round((row.pay-row.sb1) / 1000000000))}</b><div> SB2 </div>
          </div>
          ),    
      },
      {
        title:"SBL",
        dataIndex: "point",
        key:"point",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{fontSize:12.5, textAlign:"right"}}>
        <b> {numberFormat(Math.round(data / 1000000000))}</b><div >SBL</div>
          </div>
          ),
      },
      {
        title:"SBC",
        dataIndex: "coin",
        key:"coin",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{fontSize:12.5, textAlign:"right"}}> 
         <b>{numberFormat(Math.round(data / 1000000000))}</b><div>SBC</div>
          </div>
          ),
      },
      {
        title:"SBCL",
        dataIndex: "coinL",
        key:"coinL",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{fontSize:12.5, textAlign:"right"}}>
        <b> {numberFormat(Math.round(data / 1000000000))}</b><div >SBCL</div>
          </div>
          ),
      },
      {
        title:"SBCP",
        dataIndex: "coinP",
        key:"coinP",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{fontSize:12.5, textAlign:"right"}}>
        <b> {numberFormat(Math.round(data / 1000000000))}</b><div >SBCP</div>
          </div>
          ),
      },
      // {
      //   title: "보안레벨",
      //   dataIndex: "securityLevel",
      //   key: "securityLevel",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data) => (
      //     <div>
      //       {data} LEVEL
      //     </div>
      //   ),
      // },
       {
        title: "SB지갑주소",
        dataIndex: "serial",
        key: "serial",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => {
          return (
            <div key={`serial${index}`} className="flex-row flex-column" style={{fontSize:12}}>
              {/* {data} */}
             
              <div className="m-b-1">
                <Button
                    style={{height:25 }}
                    onClick={() => {
                    this.setState(
                      {
                        selectedSerial: row,
                      },
                      () => {
                        this.showSerialNumber();
                      }
                    );
                  }}>
                  <div style={{fontSize:12.5}}><WalletOutlined twoToneColor="#FD5A03" /> 지갑잔액</div>
                </Button>
                &nbsp;
            <Button
              style={{height:25}}
              onClick={() => {
                this.setState(
                  {
                    selectedSerial: row,
                  },
                  () => {
                    this.setState({
                      walletLogVisible: true,
                    });
                  }
                );
              }}>
             <div style={{fontSize:12.5}}><HistoryOutlined /> 거래내역</div> 
            </Button>          
              </div>
              {row.address}
            </div>
          );
        },
      },
      // {
      //   title: "거래내역",
      //   dataIndex: "log",
      //   key: "log",
      //   // width: 80,
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data, row, index) => (
      //     <div key={`log${index}`}>
      //       <Button
      //         onClick={() => {
      //           this.setState(
      //             {
      //               selectedSerial: row,
      //             },
      //             () => {
      //               this.setState({
      //                 walletLogVisible: true,
      //               });
      //             }
      //           );
      //         }}>
      //         조회
      //       </Button>
      //     </div>
      //   ),
      // },
      {
        title: "메모사항",
        dataIndex: "memo",
        key: "memo",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
          <div>
            {(data !== null || data !== "") && (
            <Tooltip title={data} placement="left">
              <div className="text-ellipsis2 m-r-10" style={{fontSize:12.5}}>{data ? data : "-"}</div>
            </Tooltip>
        )}
        </div>
        
      },
     
    ];
    const expandedRowRender = (record) => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: "LOCK",
          dataIndex: "lock",
          key: "lock",
          // width: 80,
          className: "support-column-number fs-15 lh-21",
          render: (data, row, index) => (
            <div key={`lock${index}`}>
              <Button
              style={{fontSize:12.5}}
                onClick={() => {
                  this.setState(
                    {
                      selectedSerial: row,
                    },
                    () => {
                      this.showCoinLockList();
                    }
                  );
                }}>
               <LockOutlined />LOCK
              </Button>
            </div>
          ),
        },
        {
          title: "상태",
          dataIndex: "deleted",
          className: "support-column-number fs-15 lh-21",
          render:
            // data => <div>{data === 0 ? '삭제' : '-'}</div>
            (data, row) => (
              <div>
                <SelectBox2
                
                  value={string.deleteString[data]}
                  code={string.toggleCode}
                  codeString={string.deleteString}
                  onChange={(value) => {
                    if (parseInt(value) !== row.deleted) {
                      this.onDelete(row);
                    }
                  }}
                />
              </div>
            ),
        },
       
      ];
      return (
        <Table
          rowKey={(record) => record.idx}
          columns={dropColumns}
          dataSource={dataArr}
          pagination={false}
        />
      );
    };
    

    return (
      <div className="flex-row flex-column">
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
            
            <Divider orientation="left" style={{fontSize:21.5}}>회원관리 <span style={{fontSize:7}}>SB PAY</span></Divider>
        </div>
        {this.state.detailVisible && (
          <UserDetail
            rootPage="member"
            modalData={this.state.detailData}
            visible={this.state.detailVisible}
            onOk={() => {
              this.onOk();
              this.setState({
                detailVisible: false,
              });
            }}
            onCancel={() => {
              this.setState({
                detailVisible: false,
              });
            }}
          />
        )}
        <WalletModal
          modalData={this.state.walletList}
          visible={this.state.walletVisible}
          pagination={this.state.walletPagination}
          handleTableChange={this.walletHandleTableChange}
          onOk={() => {
            this.setState({
              walletVisible: false,
            });
          }}
          showSerialNumber={this.showSerialNumber}
        />
        {this.state.walletLogVisible && (
          <WalletLogModalSB
            visible={this.state.walletLogVisible}
            selectedSerial={this.state.selectedSerial}
            onOk={() => {
              this.setState({
                walletLogVisible: false,
              });
            }}
          />
        )}
        {this.state.coinLockVisible && (
          <CoinLockListModal
            modalData={this.state.coinLockList}
            visible={this.state.coinLockVisible}
            pagination={this.state.coinLockPagination}
            coinType={this.state.coinLockCoinType}
            coinLockCoinList={this.state.coinLockCoinList}
            onChange={this.handleCoinLockCoinType}
            searchTypes={this.state.coinLockSearchType}
            onSearch={this.handleCoinLockSearch}
            handleTableChange={this.coinLockHandleTableChange}
            searchTypeHandleChange={this.handleCoinLockSearchType}
            selectedSerial={this.state.selectedSerial}
            showCoinLockList={this.showCoinLockList}
            onOk={() => {
              this.onOk();
              this.setState({
                coinLockVisible: false,
              });
            }}
            onCancel={() => {
              this.setState({
                coinLockVisible: false,
                coinLockList: [],
                coinLockCoinType: 0,
                coinLockSearchType: [],
                coinLockCoinList: [],
              });
            }}
          />
        )}

        <div className="flex-row flex-center flex-end m-b-10">
           </div>
        <div className="flex-row flex-center m-b-10 flex-sb">
          <div className="flex-row flex-center">
           
           <Button onClick={()=>{this.setState({accountVisible: true})}} style={{marginRight: 10}}><UserAddOutlined />계정생성</Button>
             <Button onClick={this.downloadExcel} >
             <Icon type="download" style={{ fontSize: "20px" }} />
             엑셀 다운로드
             </Button>
          </div>
          <div className="flex-row flex-center">
            <label htmlFor="" style={{ marginRight: "10px" }}>
              &nbsp;&nbsp;&nbsp;
              <Checkbox onChange={this.deleteSearchTypeHandleChange}>
                삭제 포함
              </Checkbox>
            </label>
            <label></label>
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <label htmlFor="searchType">&nbsp;</label>
            <SelectBox
              value={this.state.searchType}
              code={string.memberSearchTypeCode}
              codeString={string.memberSearchTypeString}
              onChange={(value) => {
                if (value !== this.state.searchType) {
                  this.searchTypeHandleChange(value);
                }
              }}
            />
            <Search
              placeholder={`검색어를 입력해주세요.`}
              enterButton={<SearchOutlined/>}
              allowClear
              onSearch={this.onSearch}
              style={{ width: 400 }}
            />
          </div>
        </div>
      
        <Table
          id="member-table"
          className="flex-1"
          rowKey={(record) => record.idx}
          columns={memberColumns}
          dataSource={this.props.result.userSbList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
        <AccountRegistry
          visible={this.state.accountVisible}
          onOk={() => {
            this.setState({
              accountVisible: false
            });
            this.onSearch('');
          }}
          onCancel={() => {
            this.setState({
              accountVisible: false
            });
          }}
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    result: state.async.fetchData,
  };
};

let mapDisPatchToProps = (dispatch) => {
  return {
    getUserSb: ({
      pageSize,
      pageNum,
      deleted,
      searchText,
      searchType,
      startDate,
      endDate,
    }) =>
      dispatch(
        getUserSb({
          pageSize,
          pageNum,
          deleted,
          searchText,
          searchType,
          startDate,
          endDate,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(MemberSb);
