import React, { Component } from "react";

import { Table, DatePicker, Input, Button } from "antd";

import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import { formatDate, statFormat, endDateFormat, amountFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import locale from "antd/es/date-picker/locale/ko_KR";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class EventRewardStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      eventRewardStat: [],
      pagination: {
        total: 0,
        current: 1
      },
    };
  }

  componentDidMount() {
    this.getEventRewardStat();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, () => this.getEventRewardStat());
  };

  initialLoad = () => {
    this.setState({
      eventRewardStat: [],
      pagination: {
        total: 0,
        current: 1
      }
    }, () => {
      this.getEventRewardStat();
    })
  }
  reload = () => {
    this.getEventRewardStat();
  }
  getEventRewardStat = () => {
    let pageNum = this.state.pagination.current;
    let { startDate, endDate } = this.state;
    httpGet(httpUrl.getEventRewardStats, [10, pageNum, startDate, endDate], {}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        eventRewardStat: res.data.statEventReward,
        pagination,
      });
    });
  };
  render() {
    const { eventRewardStat } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    const columns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "코인종류",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>{coinList.find((coin) => coin.coinType === data).name}</div>
        ),
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>,
      },
      {
        title: "건수",
        dataIndex: "count",
        className: "support-column-number fs-15 lh-21",
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          이벤트 보상 지급통계
      </div>
        <div className="wrapper mt-35 width-1240" />

        <div className="flex-row flex-center" style={{paddingBottom: '10px'}}>
          <label>검색 기간&nbsp;</label>
          <RangePicker locale={locale} onChange={(date) => {
            this.setState({
              startDate: date.length !== 0 ? statFormat(date[0]) : "",
              endDate: date.length !== 0 ? statFormat(date[1]) : ""
            });
          }} />
          <Button
            type="primary"
            onClick={() => {
              this.setState({
                eventRewardStat: [],
                pagination: {
                  total: 0,
                  current: 1
                },
              }, ()=>this.getEventRewardStat());
            }}>
            검색
          </Button>
        </div>
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={eventRewardStat}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default EventRewardStatistics;
