import React, { Component } from "react";

import { Table, InputNumber, Tooltip, Button, DatePicker, Icon } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import fileDownload from "js-file-download";

import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";
import { numberFormat, statFormat, dayFormat } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import OrganizationSelectModal from "../../components/adjustment/OrganizationSelectModal";
import { showAlert } from "../../components/common/AlertModal";
import AdjustmentShareDetail from "../../components/adjustment/AdjustmentShareDetail";
import ConfirmInputModal from "../../components/common/ConfirmInputModal";

const { RangePicker } = DatePicker;

class AdjustmentShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coinList: [],
      adjustmentLogList: [],
      pagination: {
        current: 1,
        total: 0,
      },
      organizationIdx: 0,
      organizationName: "",
      adjustmentType: "ALL",
      coinType: 0,
      startDate: "",
      endDate: "",
      orderByType: "DESC",

      isModify: false,
      selectedData: {},

      modalVisible: false,
      detailModalVisible: false,
      detailData: {},
      isLoaded: false,
    };
  }

  handleChangeAdjustmentType = (value) => {
    this.setState({
      adjustmentType: value,
      isLoaded: true,
    });
  };

  handleChangeOrderByType = (value) => {
    this.setState({
      orderByType: value,
      isLoaded: true,
    });
  };

  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : "",
      isLoaded: true,
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getAdjustmentShareList({
      pageNum: pager.current,
    });
  };

  onUpdateAdjustmentShare = (params = {}) => {
    let updateData = {
      idx: params.idx,
      adjustmentStatus: params.adjustmentStatus,
      memo: params.memo,
      receiptPrice: params.receiptPrice,
    };
    if (global.confirm("수정하시겠습니까?")) {
      httpPut(httpUrl.updateAdjustmentShare, [], updateData).then((res) => {
        this.getAdjustmentShareList({
          pageNum: this.state.pagination.current,
        });
        showAlert("update");
        this.setState({
          inputModalVisible: false,
        });
      });
    }
  };

  downloadExcel = () => {
    const {
      adjustmentType,
      startDate,
      endDate,
      orderByType,
      organizationIdx,
      organizationName,
      pagination,
      isLoaded,
    } = this.state;

    let result = [];
    let title = "수익배분 내역";

    if (startDate !== "" && endDate !== "") {
      result.push(`${startDate} ~ ${endDate} `);
    }

    if (organizationIdx !== 0) {
      result.push(`${organizationName} `);
    }

    if (adjustmentType !== "ALL") {
      result.push(`${string.adjustmentString[adjustmentType]} `);
    }

    let total = pagination.total <= 9 ? 10 : pagination.total;

    title =
      result.reduce((acc, item, index) => {
        return acc + item;
      }, "") + title;

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(httpUrl.downloadAdjustmentShare, [
          total,
          1,
          startDate,
          endDate,
          organizationIdx,
          adjustmentType,
          orderByType,
        ])
          .then((res) => {
            // console.log(JSON.stringify(res, null, 4));
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((error) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  getAdjustmentShareList = (params = {}) => {
    const {
      adjustmentType,
      startDate,
      endDate,
      orderByType,
      organizationIdx,
    } = this.state;

    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getAdjustmentShareList,
      [
        10,
        pageNum,
        startDate,
        endDate,
        organizationIdx,
        adjustmentType,
        orderByType,
      ],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        adjustmentLogList: res.data.list,
        pagination,
        isLoaded: false,
      });
    });
  };

  componentDidMount() {
    this.getAdjustmentShareList();
  }
  render() {
    const { isModify, selectedData } = this.state;

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "조직 이름",
        dataIndex: "organizationName",
        key: "organizationName",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "일자",
        key: "logDate",
        dataIndex: "logDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{dayFormat(data)}</div>,
      },
      {
        title: "은행",
        dataIndex: "bankCode",
        key: "bankCode",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {data === null || data === "" ? "-" : string.bankString[data]}
          </div>
        ),
      },
      {
        title: "계좌번호",
        dataIndex: "bankAccount",
        key: "bankAccount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>{data === null || data === "" ? "-" : data}</div>
        ),
      },
      {
        title: "예금주",
        dataIndex: "accountHolder",
        key: "accountHolder",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>{data === null || data === "" ? "-" : data}</div>
        ),
      },
      {
        title: "수익배분금액",
        dataIndex: "price",
        key: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "실 지급금액",
        dataIndex: "receiptPrice",
        key: "receiptPrice",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          selectedData.idx && selectedData.idx === row.idx && isModify ? (
            <InputNumber
              value={selectedData.receiptPrice}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={(value) => {
                selectedData.receiptPrice = value;
                this.setState({
                  selectedData,
                });
              }}
            />
          ) : (
            <div>{numberFormat(data)}</div>
          ),
      },
      {
        title: "메모",
        dataIndex: "memo",
        key: "memo",
        className: "support-column-number fs-15 lh-21",
        width: 180,
        render: (data, row) => (
          <div className="flex-row flex-center justify-center">
            {(data !== null || data !== "") && (
              <Tooltip title={data} placement="left">
                <div className="text-ellipsis m-r-10">{data ? data : "-"}</div>
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: "",
        className: "support-column-number fs-15 lh-21",
        width: 180,
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({
                inputModalVisible: true,
                modifyData: "memo",
                selectedData: row,
              });
            }}>
            메모 수정
          </Button>
        ),
      },
      {
        title: "상태",
        dataIndex: "status",
        key: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{string.adjustmentString[data]}</div>,
      },
      {
        title: "지급여부",
        key: "modify",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          row.status === "CREATED" ? (
            <div className="flex-row flex-center justify-center">
              <Button
                className="m-r-10"
                onClick={() => {
                  this.setState({
                    inputModalVisible: true,
                    modifyData: "price",
                    selectedData: row,
                  });
                }}>
                완료
              </Button>
              <Button
                onClick={() => {
                  this.onUpdateAdjustmentShare({
                    idx: row.idx,
                    adjustmentStatus: "REJECT",
                    memo: row.memo,
                    receiptPrice: row.receiptPrice,
                  });
                }}>
                거절
              </Button>
            </div>
          ) : (
            <div>-</div>
          ),
      },
      {
        key: "detail",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({
                detailModalVisible: true,
                detailData: row,
              });
            }}>
            상세보기
          </Button>
        ),
      },
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          수익배분 내역
        </div>
        {this.state.modalVisible && (
          <OrganizationSelectModal
            visible={this.state.modalVisible}
            onOk={(idx, name) => {
              this.setState({
                organizationIdx: idx,
                organizationName: name,
                isLoaded: true,
              });
            }}
            onCancel={() => {
              this.setState({
                modalVisible: false,
              });
            }}
          />
        )}
        {this.state.detailModalVisible && (
          <AdjustmentShareDetail
            visible={this.state.detailModalVisible}
            onCancel={() => {
              this.setState({
                detailModalVisible: false,
                detailData: {},
              });
            }}
            detailData={this.state.detailData}
          />
        )}
        {this.state.inputModalVisible && (
          <ConfirmInputModal
            rootPage="adjustment"
            modifyData={this.state.modifyData}
            visible={this.state.inputModalVisible}
            onOk={this.onUpdateAdjustmentShare}
            onCancel={() => {
              this.setState({
                inputModalVisible: false,
              });
            }}
            selectedRow={this.state.selectedData}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  modalVisible: true,
                });
              }}>
              조직 선택
            </Button>
            {this.state.organizationIdx !== 0 && (
              <div>
                {`가맹점: ${this.state.organizationName}`}
                <Button
                  type="link"
                  onClick={() => {
                    this.setState({
                      organizationIdx: 0,
                    });
                  }}>
                  <Icon type="close" />
                </Button>
              </div>
            )}
          </div>
          <div className="flex-row flex-center">
            <label>검색기간&nbsp;</label>
            <RangePicker
              // placeholder="시작일"
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <div className="m-r-10" />
            <Button
              type="primary"
              onClick={() => {
                this.getAdjustmentShareList();
              }}>
              조회
            </Button>
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <div className="flex-row flex-center m-r-10">
              <label>정산 상태&nbsp;</label>
              <SelectBox
                value={string.adjustmentString[this.state.adjustmentType]}
                codeString={string.adjustmentString}
                onChange={(value) => {
                  if (value !== this.state.adjustmentType) {
                    this.handleChangeAdjustmentType(value);
                  }
                }}
              />
            </div>
            <div className="flex-row flex-center">
              <label>순서&nbsp;</label>
              <SelectBox
                value={string.orderByString[this.state.orderByType]}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== this.state.orderByType)
                    this.handleChangeOrderByType(value);
                }}
                style={{ width: "160px" }}
              />
            </div>
          </div>
          <Button onClick={this.downloadExcel}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.adjustmentLogList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          columns={columns}
        />
      </div>
    );
  }
}

export default AdjustmentShare;
