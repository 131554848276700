import React, { Component } from "react";
import { Table, DatePicker, Button,Divider } from "antd";

import {
  numberFormat,
  formatDate,
  startDateFormat,
  endDateFormat
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import { httpGet, httpUrl } from "../../api/httpClient";
import string from "../../string";
import { SearchOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

class AdminLoginLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1
      },
      startDate: "",
      endDate: ""
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getAdminLoginLog({
      pageNum: pager.current
    });
  };

  handleChangeDate = value => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : ""
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  getAdminLoginLog = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getAdminLoginLog,
      [10, pageNum, this.state.startDate, this.state.endDate],
      {}
    )
      .then(res => {
        const pagination = {
          ...this.state.pagination,
          total: res.data.totalCount
        };
        this.setState({
          list: res.data.login,
          pagination
        });
      })
      .catch(error => {});
  };

  componentDidMount() {
    this.getAdminLoginLog();
  }

  render() {
    const statisticsColumns = [
      {
        title: "",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontSize:13}}>{numberFormat(data)}</div>
      },
      {
        title: "날짜",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontSize:13}}>{formatDate(data)}</div>
      },
      {
        title: "이름",
        dataIndex: "adminId",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => <div style={{fontSize:13}}>{row.adminName}</div>
      },
      {
        title: "성공여부",
        dataIndex: "result",
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontSize:13}}>{string.loginString[data]}</div>
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
           <Divider orientation="left" style={{fontSize:23}}>관리자 로그인이력</Divider>  
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div className="flex-row flex-center m-b-10" style={{ float: "right" ,marginRight:300}}>
          <label style={{fontSize:13}}>검색기간&nbsp;</label>

          <RangePicker
            locale={locale}
            onChange={date => {
              this.handleChangeDate(date);
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              this.getAdminLoginLog();
            }}>
            <SearchOutlined/>
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          style={{width:1000, marginLeft:320}}
          id="admin-table"
          columns={statisticsColumns}
          rowKey={record => record.idx}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default AdminLoginLog;
