import React, { Component } from "react";

import {
  Modal,
  Descriptions,
  Input,
  Switch,
  Icon,
  Button,
  InputNumber,
  Upload
} from "antd";
import { httpPut, httpGet, httpUrl, serverUrl } from "../../api/httpClient";
import { formatDate } from "../../util";
import UserList from "./UserList";
import Address from "./Address";
import { showAlert } from "../common/AlertModal";
import BasicImage from "../common/BasicImage";
import Axios from "axios";
import OrganizationList from "./OrganizationList";
import SelectBox from "../common/SelectBox";
import string from "../../string";
import { PlusOutlined } from '@ant-design/icons';



function getBase64(file = []) {

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const Ditems = Descriptions.Item;



class OrganizationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
       detailData: {},
      changeVisible: false,
      changeAttractVisible: false,
      addressVisible: false,
      thumbnail: "",
      images: "",


      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],


      fileList: "", 
      uploadFiles: [],

    };
  }
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });
  

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  

  onChangeOranization = (value) => {
    this.onChangeModal(value, "parentId");
  }
  onChangeAttractOranization = (value) => {
    this.onChangeModal(value, "attractOrganizationIdx");
  }
  onChangeModal = (value, type) => {
    var data = this.state.detailData;
    data[type] = value;
    if (
      type === "feeRatio" ||
      type === "shareRatio1" ||
      type === "shareRatio2"
    ) {
      if (value === "") data[type] = null;
      // else data[type] = value;
    }
    if (type === "parentId") {
      data[type] = value.id;
      data.parentName = value.name;
      data.parentIdx = value.idx;
    }
    if (type === "attractOrganizationIdx") {
      data[type] = value.idx;
      data.attractOrganizationName = value.name;
    }
    this.setState({
      detailData: data
    });
    // console.log(JSON.stringify(data, null, 4));
  };

  addressSeach = (value) => {
    // console.log(JSON.stringify(value, null, 4));
    var data = this.state.detailData;
    data.locationAddress = value;
    this.setState({ addressVisible: false, detailData: data });
    httpGet(httpUrl.getGeocode, [value]).then((res) => {
      let address = "";
      if (res.data.y != 0 && res.data.x != 0)
        address = `${res.data.y}, ${res.data.x}`;
      var data = this.state.detailData;
      data.locationPoint = address;
      this.setState({ detailData: data });
    });
  };

  onUpdate = () => {
    const { detailData } = this.state;
    let updateData = {
      ...detailData,
      franchise: detailData.franchise ? 1 : 0,
      autoAdjustment: detailData.autoAdjustment ? 1 : 0,
      thumbnail: this.state.thumbnail,
      image: this.state.images,
      uploadFiles: this.state.uploadFiles.map(x=>x.idx)
    };
    // console.log(`update = ${JSON.stringify(updateData, null, 4)}`);
    if (global.confirm(`변경하시겠습니까?`))
      httpPut(httpUrl.organizationUpdate, [], updateData)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            this.props.onOk();
            showAlert("modify");
          }
        })
        .catch((error) => { });
  };

  componentDidMount() {
    const obj = Object.assign(
      {},
      {
        ...this.props.modalData,
        franchise: this.props.modalData.franchise === 1 ? true : false,
        autoAdjustment: this.props.modalData.autoAdjustment === 1 ? true : false
      }
    );
    this.setState({
      detailData: obj,
      images: obj.image,
      thumbnail: obj.thumbnail
    });
    httpGet(httpUrl.getFileList, ['ORGANIZATION', this.props.modalData.idx]).then((res) => {
      this.setState({uploadFiles: res.data});
    });
    // console.log(`detail = ${JSON.stringify(obj, null, 4)}`);
  }

  render() {    
    const { visible, onCancel } = this.props;


    const { changeVisible, changeAttractVisible, addressVisible, detailData,  previewVisible, previewImage, fileList, previewTitle  } = this.state;
   
    

    const uploadFileProps = {
      action: serverUrl + httpUrl.fileUpload,
      multiple: false,
      withCredentials: true,
      beforeUpload: (file, fileList) => {
      },
      onSuccess: (file) => {
        // console.log(`file = ${JSON.stringify(file, null, 4)}`);
        if (file.data.result) {
          Modal.info({
            title: "업로드 결과",
            content: "파일 업로드 성공"
          });
          this.state.uploadFiles.push({idx: file.data.idx, name: file.data.filename})
          this.setState({
            uploadFiles: this.state.uploadFiles
          });
          
        }
      },
      onError(err) {
        Modal.error({
          title: "업로드 결과",
          content: "파일 업로드 실패"
        });
      }
    };

    return (
      <Modal
        visible={visible}
        title="조직 상세"
        onCancel={onCancel}
        onOk={this.onUpdate}
        okText={"변경사항저장"}
        cancelText="닫기"
        style={{ minWidth: "1000px" }}>
        {changeVisible && (
          <OrganizationList
            visible={changeVisible}
            onCancel={() => {
              this.setState({ changeVisible: false });
            }}
            onOk={this.onChangeOranization}
            userIdx={detailData.idx}
            classification={this.state.detailData.classification - 1}
          />
        )}
        {changeAttractVisible && (
          <OrganizationList
            visible={changeAttractVisible}
            onCancel={() => {
              this.setState({ changeAttractVisible: false });
            }}
            onOk={this.onChangeAttractOranization}
            userIdx={detailData.idx}
            classification={0}
          />
        )}
        {addressVisible && (
          <Address
            visible={addressVisible}
            onCancel={() => {
              this.setState({ addressVisible: false });
            }}
            onOk={this.addressSeach}
          />
        )}
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
          <Ditems label="조직 계정(ID ▪ 이름)">
            {detailData.userId} {" "}
            <b>{detailData.userName === null ? "-" : detailData.userName}
            ▪{detailData.userRecommendCode}</b>
          </Ditems>
          <Ditems label="생성일">
            {formatDate(detailData.createDate)}
          </Ditems>
          <Ditems label="유치자" className="flex-row flex-center">
            {/* {detailData.attractOrganizationName === null ? "-" : detailData.attractOrganizationName} */}
            {/* <Button
              style={{ marginLeft: "10px" }}
              onClick={() => {
                this.setState({
                  changeAttractVisible: true
                });
              }}>
              수정
            </Button> */}
            <b>{detailData.userName2}▪{detailData.userRecommender}</b>
          </Ditems>
          <Ditems label="상위 조직" className="flex-row flex-center">
            {detailData.parentName === null ? "-" : detailData.parentName}
            {this.state.detailData.classification > 1 && (
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  this.setState({
                    changeVisible: true
                  });
                }}>
                수정
              </Button>
            )}
          </Ditems>
          <Ditems label="조직명">
            <Input
              placeholder="조직명을 입력해주세요."
              value={detailData.name}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "name");
              }}
            />
          </Ditems>
          <Ditems label="사업자번호">
            <Input
              placeholder="사업자번호를 입력해주세요."
              value={detailData.businessNum}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]/g, "");
                this.onChangeModal(value, "businessNum");
              }}
            />
          </Ditems>
          <Ditems label="전화번호">
            {detailData.tel}
          </Ditems>
          <Ditems label="은행">
            <SelectBox
              placeholder="은행을 선택해주세요."
              value={string.bankString[detailData.bankCode]}
              code={string.bankCode}
              codeString={string.bankString}
              onChange={(value) => {
                if (value !== detailData.bankCode) {
                  this.onChangeModal(value, "bankCode");
                }
              }}
              style={{ width: "180px" }}
            />
          </Ditems>
          <Ditems label="계좌번호">
            <Input
              allowClear
              placeholder="계좌를 입력해주세요."
              value={detailData.bankAccount}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "bankAccount");
              }}
            />
          </Ditems>
          <Ditems label="예금주">
            <Input
              allowClear
              placeholder="예금주명을 입력해주세요."
              value={detailData.accountHolder}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "accountHolder");
              }}
            />
          </Ditems>
          <Ditems label="주소" span={2}>
            {detailData.locationAddress}
            <Button
              className={detailData.locationAddress ? "m-l-10" : ""}
              onClick={() => {
                this.setState({ addressVisible: true });
              }}>
              {detailData.locationAddress ? "주소 변경" : "주소 찾기"}
            </Button>
          </Ditems>
          <Ditems label="상세주소" span={2}>
            <Input
              placeholder="상세주소를 입력해주세요."
              value={detailData.locationAddressDetail}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "locationAddressDetail");
              }}
            />
          </Ditems>
          <Ditems label="주소 좌표" span={2}>
            <div style={{ color: detailData.locationPoint ? "" : "red" }}>
              {detailData.locationPoint
                ? detailData.locationPoint
                : detailData.locationAddress !== null
                  ? "입력한 주소를 네이버 지도에서 찾지 못하였습니다. 앱에서 가맹점 위치가 정상적으로 표출되지 않을 수 있습니다."
                  : ""}
            </div>
          </Ditems>
          <Ditems label="메모" span={2}>
            <Input.TextArea
              placeholder="메모를 입력해주세요."
              value={detailData.memo}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "memo");
              }}
            />
          </Ditems>
          <Ditems label="업종" >
          <Input
              allowClear
              placeholder="업종을 입력해주세요."
              value={detailData.sector}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "sector");
              }}
            />            
          
          </Ditems>
          <Ditems label="가맹점 여부">
            {detailData.franchise ? "O" : "x"}
          </Ditems>
          {detailData.franchise && (
            <Ditems

              label={
                <div>
        
                         

                  <div className="m-b-10">이미지</div>
      
                 
                </div>
              }
              span={2}>
            <div style={{ maxWidth: "300px", height: "auto" }}>
             
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
   
           
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>

                
  
              </div>
          
              <Upload {...uploadFileProps} showUploadList={false}>
                <Button>첨부하기</Button>
              </Upload>
              {this.state.uploadFiles.map(data=>{
                return (
                  <div>
                    <a href={serverUrl + httpUrl.getFile.replace('%s', data.idx)} target='_blank'>{data.name}</a>
                    <div style={{display: 'inline-block', paddingLeft: '5px', cursor: 'pointer'}} onClick={()=>{
                      const idx = this.state.uploadFiles.findIndex(x=>x.idx == data.idx)
                      if (idx > -1) {
                        this.state.uploadFiles.splice(idx, 1)
                      }
                      this.setState({uploadFiles: this.state.uploadFiles})
                    }}><Icon type="close" /></div>
                  </div>
                )
              })}
                

            </Ditems>
            
          )}
          {detailData.franchise && (
            <Ditems label="자동 정산 여부">
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={detailData.autoAdjustment}
                onChange={(checked) => {
                  this.onChangeModal(checked, "autoAdjustment");
                }}
              />
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="정산 수수료 비율 (%)">
              <InputNumber
                min={0}
                max={100}
                style={{ width: "200px" }}
                value={detailData.feeRatio}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => {
                  let feeRatio = value;
                  this.onChangeModal(feeRatio, "feeRatio");
                }}
              />
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="수익분배1단계 (%)">
              <InputNumber
                min={0}
                max={100}
                style={{ width: "200px" }}
                value={detailData.shareRatio1}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => {
                  let shareRatio1 = value;
                  this.onChangeModal(shareRatio1, "shareRatio1");
                }}
              />
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="수익분배2단계 (%)">
              <InputNumber
                min={0}
                max={100}
                style={{ width: "200px" }}
                value={detailData.shareRatio2}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => {
                  let shareRatio2 = value;
                  this.onChangeModal(shareRatio2, "shareRatio1");
                }}
              />
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="외부연동KEY" span={2}>
              <Input
                placeholder="수익배분 매출의 외부연동이 있는 경우 외부시스템의 KEY를 입력"
                value={detailData.externalKey}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "externalKey");
                }}
              />
            </Ditems>
          )}
          {detailData.franchise && (
            <Ditems label="interfaceUri" span={2}>
              <Input
                placeholder="http://aaa.com/pay/complete?orderid={0}&token={1}"
                value={detailData.interfaceUri}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "interfaceUri");
                }}
              />
            </Ditems>
          )}
          {/* {detailData.franchise && (
            <Ditems label="redirectUri" span={2}>
              <Input
                value={detailData.redirectUri}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "redirectUri");
                }}
              />
            </Ditems>
          )} */}
        </Descriptions>
        {detailData.franchise && (
          <div className="m-t-10">
            <span style={{ color: "blue" }}>
              정산 수수료 비율, 수익분배 비율을 미입력시 결제관리의 설정값관리에
              설정된 값이 적용됩니다.
              <br />이 가맹점만 특별히 다른 비율을 적용하고 싶은 경우에만
              입력해주세요.
            </span>
          </div>
        )}
      </Modal>
    );
  }
}

export default OrganizationDetail;
