import React, { Component } from 'react'
import {
    WifiOutlined,SearchOutlined,CloseCircleOutlined,AlertOutlined,ExclamationCircleOutlined,SwitcherTwoTone,LoadingOutlined,CheckCircleOutlined,ThunderboltTwoTone
  } from '@ant-design/icons';
import { httpGet, httpUrl, httpDownload, httpPost } from '../../api/httpClient';
import { Table, Input, Button, DatePicker, Tooltip ,Icon,Divider} from 'antd'
import { reactLocalStorage } from "reactjs-localstorage";
import {
    formatDate,
    numberFormat,
    startDateFormat,
    endDateFormat,
    phoneNumFormat
} from "../../util";
import string from "../../string";
import locale from "antd/es/date-picker/locale/ko_KR";
import fileDownload from "js-file-download";
import SelectBox from "../../components/common/SelectBox";



const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;


export default class DepositListRead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchType: "NAME",
            searchText: "",
            startDate: "",
            endDate: "",
            startDate2:"",
            endDate2:"",
            depositType: "ALL",
            pagination: {
                total: 0,
                current: 0,
            },

            list: [],
            visible: false
        };


    }

    handleChangeDate = (date) => {
        // console.log(JSON.stringify(date))
        this.setState({
            startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
            endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
            // isLoaded: true,
        });
    };

    handleChangeDate2 = (date) => {
        // console.log(JSON.stringify(date))
        this.setState({
          startDate2: date.length !== 0 ? startDateFormat(date[0]) : "",
          endDate2: date.length !== 0 ? endDateFormat(date[1]) : "",
          // isLoaded: true,
        });
      };

    handleChangeDepositType = (value) => {
        this.setState({
            depositType: value,

        });
    };

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        this.getDepositListRead({
            pageNum: pager.current,
        });
    };

    onSearch = (value) => {
        this.setState(
            {
                searchText: value,
                isLoaded: false,
            },
            () => {
                this.getDepositListRead({});

            }
        );
    };

    getDepositListRead = (params = {}) => {
        const { searchType, searchText, startDate, endDate, startDate2,endDate2,depositType } = this.state
        let pageNum = params.pageNum || 1;

        httpGet(httpUrl.getDepositListRead, [10, pageNum, searchType, searchText, startDate, endDate, startDate2, endDate2, depositType], {})
            .then(res => {
                // console.log(JSON.stringify(res.data.depositList))
                const pagination = { ...this.state.pagination }
                pagination.total = res.data.totalCount
                this.setState({
                    list: res.data.depositList,
                    pagination,
                })
            })

    }



    searchTypeHandleChange = (value) => {
        // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
        this.setState({
            searchType: value,
            isLoaded: true,
        });
    };

    componentDidMount() {
        this.getDepositListRead();
    }

    downloadExcel = () => {


        if (this.state.isLoaded) {
            alert("조회버튼을 먼저 클릭해주세요.");
        } else {
            if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
                httpDownload(
                    httpUrl.depositListDownload,
                    [
                        this.state.searchType,
                        this.state.searchText,
                        this.state.startDate,
                        this.state.endDate,
                        this.state.startDate2,
                        this.state.endDate2,
                    ],
                    {}
                )
                    .then((res) => {
                        fileDownload(res, `은행입금목록 조회.xlsx`);
                    })
                    .catch((err) => { });
            }
        }
    };

    onSelect = (depositRequestIdx) => {
        this.setState({ visible: false });
        httpPost(httpUrl.depositMapping, [], { depositIdx: this.depositIdx, depositRequestIdx: depositRequestIdx })
            .then((res) => {
                if (res.data) {
                    this.getDepositListRead();
                }
                else {
                    alert("실패하였습니다. 충전대상의 지갑이 미 생성 상태일 수 있습니다.");
                }
            })
            .catch((e) => {
                alert("실패하였습니다. 충전대상의 지갑이 미 생성 상태일 수 있습니다.");
            });
    }

    render() {
        const {
            selectedRow,
            depositType,



        } = this.state;
        const coinList = reactLocalStorage.getObject("coinList");

        const columns = [
            {
                title: "",
                key: "idx",
                dataIndex: "idx",
                className: "support-column-number fs-15 lh-21",
                render: (data) =>(
                    <div style={{fontSize:12.5}}>{data}</div>
                  )
                
            },
            {
                title: "입금번호",
                key: "depositKey",
                dataIndex: "depositKey",
                className: "support-column-number fs-15 lh-21",
                render: (data) =>(
                    <div style={{fontSize:12.5}}>{data}</div>
                  )
            },
            {
                title: "이름(ID)",
                key: "name",
                dataIndex: "name",
                className: "support-column-number fs-15 lh-21",
                render: (data, row) => (
                    <div style={{fontSize:12.5}}>
                         {data}<div style={{fontSize:12}}>{row.id ? row.id : ""}</div>
                    </div>

                )
            },
            {
                title: "휴대폰번호",
                key: "phone",
                dataIndex: "phone",
                className: "support-column-number fs-15 lh-21",
                render: (data) =>(
                    <div style={{fontSize:12.5}}>{data ? phoneNumFormat(data) : ""}</div>
                  )
            },
            {
                title: "충전코드",
                key: "code",
                dataIndex: "code",
                className: "support-column-number fs-15 lh-21",
                render: (data) => (
                    <div style={{fontSize:12.5}}>{data}</div>
                  ),
            },
            {
                title: "금액",
                key: "price",
                dataIndex: "price",
                className: "support-column-number fs-15 lh-21",
                render: (data, row) => <div style={{fontSize:12.5 ,textAlign:"right"}}>{numberFormat(data)}</div>,
            },
            {
                title: "거래번호",
                key: "logIdx",
                dataIndex: "logIdx",
                className: "support-column-number fs-15 lh-21",
                render: (data) =>(
                    <div style={{fontSize:12.5}}>{data ? data : ""}</div>
                  )
            },
            {
                title: "코인명",
                key: "coinType",
                dataIndex: "coinType",
                className: "support-column-number fs-15 lh-21",
                render: (data) => (
                    <div style={{fontSize:12.5}}>
                        {coinList.find((coin) => coin.coinType === data)
                            ? coinList.find((coin) => coin.coinType === data).name
                            : ""}
                    </div>
                )
            },
            {
                title: "입금현황",
                key: "status",
                dataIndex: "status",
                className: "support-column-number fs-15 lh-21",
                // render: (data) => <div>{string.ChargeString[data]}</div>,
                render: (data) => {
                  if (data == "PENDING") return (<div style={{ color: '#C52727' ,fontSize:12.5, textAlign:"right"}}><LoadingOutlined/> {string.ChargeString[data]}</div>);
                  if (data == "MANUAL") return (<div style={{ color: '#FD5A03' ,fontSize:12.5, textAlign:"right"}}> {string.ChargeString[data]}</div>);
                  if (data == "REFUND") return (<div style={{ color: '#3A4BC7',fontSize:12.5, textAlign:"right"}}>{string.ChargeString[data]}</div>);
                  if (data == "WITHDRAW") return (<div style={{ color: '#3A4BC7' ,fontSize:12.5, textAlign:"right"}}> {string.ChargeString[data]}</div>);
                  else return (<div style={{ color: '#1F8301' ,fontSize:12.5 , textAlign:"right"}}>  {string.ChargeString[data]}</div>);
                },
        
              },
            {
                title: "입금시간",
                key: "createDate",
                dataIndex: "createDate",
                className: "support-column-number fs-15 lh-21",
                render: data => <div style={{fontSize:12.5}}>{formatDate(data)}</div>
            },
            {
                title: "충전시간",
                key: "updateDate",
                dataIndex: "updateDate",
                className: "support-column-number fs-15 lh-21",
                render: (data,row) => {
                  if (row.status == "PENDING") return (<div style={{fontSize:12.5 ,color:"#C52727"}}></div>);
                  if (row.status == "WITHDRAW") return (<div style={{fontSize:12.5, color:"#3A4BC7"}}></div>);
                  if (row.status == "MANUAL") return (<div style={{fontSize:12.5}}>{formatDate(data)}</div>);
                  if (row.status == "COMPLETE") return (<div>{formatDate(row.createDate)}</div>);
                  else return (<div style={{ fontSize:12.5 }}>{formatDate(data)}</div>);
                }
        
              },
              {
                title: "충전신청번호",
                key: "depositRequestIdx",
                dataIndex: "depositRequestIdx",
                className: "support-column-number fs-15 lh-21",
                render:(data) => (
                  <div style={{fontSize:12.5}}>{data}</div>
                )
              },
            {
                title: "메모사항",
                dataIndex: "memo",
                key: "memo",
                className: "support-column-number fs-15 lh-21",
                width: 100,
                render: (data, row) => (
                  <div className="flex-row flex-center justify-center" style={{fontSize:12.5}}>
                    {(data !== null || data !== "") && (
                      <Tooltip title={data} placement="left">
                        <div className="text-ellipsis2 m-r-10">{data ? data : ""}</div>
                      </Tooltip>
                    )}
                  </div>
                ),
              },
        ];




        return (


            <div>
                <div
                    style={{
                        fontSize: "20px",
                        color: "#000",
                        textAlign: "center",
                        marginBottom: "60px",
                        fontWeight: "bold",
                    }}>
                    <Divider orientation="left" style={{fontSize:23}}>은행입금목록 <span style={{fontSize:5}}>보기</span></Divider>  
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
        <Button onClick={this.downloadExcel}>
                    <Icon type="download" style={{ fontSize: "20px" }} />
                        엑셀 다운로드</Button>
                        <div className="flex-row flex-center">
                <label>입금시간&nbsp;</label>
                <RangePicker
                    locale={locale}
                    onChange={(date) => {
                        this.handleChangeDate(date);
                    }}
                />
                <label>충전시간&nbsp;</label>
                <RangePicker
                    locale={locale}
                    onChange={(date) => {
                        this.handleChangeDate2(date);
                    }}
                />

            <label htmlFor="">입금현황
                <SelectBox
                        value={string.ChargeString[depositType]}
                        code={string.ChargeCode}
                        codeString={string.ChargeString}
                        onChange={(value) => {
                            if (value !== depositType) {
                                this.handleChangeDepositType(value);
                            }
                        }}
                        style={{ width: "80px", marginLeft: "10px" }}
                    />
                </label>
                &nbsp;
                {/* <label htmlFor="searchType" style={{ marginLeft: '10px' }}></label> */}
                <SelectBox
                    value={this.state.searchType}
                    code={string.depositSearchTypeCode}
                    codeString={string.depositSearchTypeString}
                    onChange={(value) => {
                        if (value !== this.state.searchType) {
                            this.searchTypeHandleChange(value);
                        }
                    }}
                    style={{ width: "80px"}}
                />
                <Search
                    placeholder={`검색어를 입력해주세요.`}
                    enterButton={<SearchOutlined />}
                    allowClear
                    onSearch={this.onSearch}
                    style={{ width: 300 }}
                />
        &nbsp;&nbsp;
              
                </div></div>


                <Table
                    rowKey={(record) => record.idx}
                    columns={columns}
                    dataSource={this.state.list}
                    style={{ marginBottom: "10px", marginTop: '10px' }}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                />

            </div>
        )


    }

}
