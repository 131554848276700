import React, { Component } from "react";

import { Table, DatePicker, Input, Button } from "antd";

import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import { formatDate, startDateFormat, endDateFormat } from "../../util";
import EventRegist from "./EventRegist";
import EventModify from "./EventModify";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import EventReward from "./EventReward";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventList: [],
      pagination: {
        total: 0,
        current: 1
      },
      eventRegistVisible: false,
      eventModifyVisible: false,
      eventRewardVisible: false,
      selectedRow: null,
      selectedRewardRow: null,
    };
  }

  componentDidMount() {
    this.getEventList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, ()=>this.getEventList());
  };

  initialLoad = () => {
    this.setState({
      eventList: [],
      pagination: {
        total: 0,
        current: 1
      }}, () => {
        this.getEventList();
      })
  }
  reload = () => {
    this.getEventList();
  }
  getEventList = () => {
    let pageNum = this.state.pagination.current;

    httpGet(httpUrl.getEventList,[10,pageNum,],{}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        eventList: res.data.events,
        pagination,
      });
    });
  };
  updateSelectedImage = (image) => {
    this.state.selectedRow.event.image = image;
    this.setState({selectedRow: this.state.selectedRow});
  }
  deleteEvent = (idx) => {
    
    if (global.confirm(idx+"번 이벤트를 삭제하시겠습니까??")) {
      httpPost(httpUrl.eventDelete, [idx], {}).then((res) => {
        if (res.data !== 0) {
          this.reload();
        } else {
          alert("알수없는 문제로 등록에 실패하였습니다..");
        }
      });
    }
  }
  onDeleteHandle = (row, value) => {
    if (global.confirm(`${row.idx}번 이벤트를 ${value == 0 ? "복구" : "삭제"}하시겠습니까?`)) {
      httpPost(httpUrl.eventDelete, [row.idx, value], {}).then((res) => {
        if (res.data) {
          this.reload();
        } else {
          alert("알수없는 문제로 실패하였습니다..");
        }
      });
    }
  };
  render() {
    const { eventList } = this.state;

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이미지",
        dataIndex: "image",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data === null ? "-" : <img src={data} style={{ width: '200px' }} />),
      },
      {
        title: "제목",
        dataIndex: "title",
        key: "title",
        className: "fs-15 lh-21",
        render: (data, row, index) => <div style={{cursor: 'pointer', color: 'blue'}} onClick={()=>{
          httpGet(httpUrl.getEventDetail,[row.idx],{}).then((res) => {
            this.setState({
              selectedRow:res.data,
              eventModifyVisible:true
            });
          });
        }}>{data}</div>
      },
      {
        title: "시작일",
        dataIndex: "startDate",
        key: "startDate",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "종료일",
        dataIndex: "endDate",
        key: "enddate",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "링크",
        dataIndex: "link",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data === null ? "" :
          (<Button
            onClick={() => {
              global.window.open(data)
            }}>
            열기
          </Button>)
        ),
      },
      {
        title: "보상",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (data === null ? "" :
          (
            <div>
              <div style={{paddingBottom: '5px'}}>(지급: {row.rewardCount}명)</div>
              <Button
                onClick={() => {
                  this.setState({eventRewardVisible: true, selectedRewardRow: row})
                }}>
                보상지급하기
              </Button>
            </div>
          )
        ),
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (value != row.deleted) {
                    this.onDeleteHandle(row, value);
                  }
                }}
              />
          </div>
        ),
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          이벤트 목록
      </div>
        <div className="wrapper mt-35 width-1240" />
        <div>
          <Button
            onClick={() => {
              this.setState({ eventRegistVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
        </div>
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={eventList}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
        <EventRegist
          visible={this.state.eventRegistVisible}
          onCancel={() => {
            this.setState({ eventRegistVisible: false });
          }}
          onOk={() => {
            this.setState({ eventRegistVisible: false });
            this.initialLoad();
          }}
        />
        <EventModify
          data={this.state.selectedRow}
          visible={this.state.eventModifyVisible}
          onCancel={() => {
            this.setState({ eventModifyVisible: false });
          }}
          onOk={() => {
            this.setState({ eventModifyVisible: false });
            this.reload();
          }}
          updateSelectedImage={this.updateSelectedImage}
        />
        <EventReward
          data={this.state.selectedRewardRow}
          visible={this.state.eventRewardVisible}
          onCancel={() => {
            this.setState({ eventRewardVisible: false });
          }}
          onOk={() => {
            this.setState({ eventRewardVisible: false });
          }}
        />
      </div>
    );
  }
}

export default EventList;
