import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, Button, InputNumber } from "antd";

import { getSet } from "../../contexts/asyncContext";
import {
  amountFormat,
  dateFormat,
  coinDivideFormat,
  coinMultiFormat
} from "../../util";
import string from "../../string";
import { httpPut, httpUrl } from "../../api/httpClient";
import SelectBox from "../../components/common/SelectBox";

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeType: "ALL",
      pagination: {
        total: 0,
        current: 0
      },
      selectedRow: "",
      selectData: {},
      prevData: {}
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getSet({
      pageSize: 10,
      pageNum: pager.current
    });
  };

  searchTypeHandleChange = value => {
    this.setState(
      {
        activeType: value
      },
      () =>
        this.props.getSet({
          pageSize: 10,
          pageNum: 1
        })
    );
  };

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "coinType") {
      data.coinType = value;
      data.amount = coinDivideFormat(row.amount, value);
      data.delay = row.delay;
      data.active = row.active;
    } else if (type === "amount") {
      data.amount = value;
    } else if (type === "delay") {
      // data.delay = minFormat(value);
      data.delay = value;
    } else {
      data.active = value;
    }
    this.setState({
      selectData: data
    });
    // console.log(
    //   `selectData: ${JSON.stringify(data, null, 4)}\n${typeof data.amount}`
    // );
  };

  onUpdate = () => {
    const { selectData, pagination } = this.state;
    let updateData = {
      coinType: selectData.coinType,
      amount: coinMultiFormat(selectData.amount, selectData.coinType),
      delay: selectData.delay,
      active: selectData.active
    };
    // console.log(JSON.stringify(updateData, null, 4));
    if (selectData.active === 0 && selectData.delay === null) {
      alert("대기시간을 입력해주세요");
    } else {
      if (global.confirm(`변경하시겠습니까?`)) {
        httpPut(httpUrl.setUpdate, [], updateData)
          .then(result => {
            this.setState({
              selectedRow: "",
              selectData: {}
            });
            this.props.getSet({
              pageSize: 10,
              pageNum: pagination.current
            });
          })
          .catch(error => {});
      }
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getSet({ pageSize: 10, pageNum: 1 });
  }

  render() {
    const { selectedRow, selectData } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const approvalColumns = [
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              amountFormat(data, row.coinType)
            ) : (
              <InputNumber
                min={0}
                max={coinDivideFormat(9223372036854775807, row.coinType)}
                style={{ width: "180px" }}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                value={selectData.amount}
                onChange={value => {
                  this.onChangeModal(value, "amount");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "대기시간",
        dataIndex: "delay",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              data ? (
                dateFormat(data)
              ) : (
                "-"
              )
            ) : (
              <div>
                <Input
                  allowClear
                  style={{ width: "160px", marginRight: "8px" }}
                  value={selectData.delay === 0 ? "" : selectData.delay}
                  placeholder={"ex)1시간 -> 60분"}
                  onChange={e => {
                    this.onChangeModal(e.target.value, "delay");
                  }}
                />
                분
              </div>
              // <TimePicker
              //   format={format}
              //   placeholder={data ? `${dateFormat(data)}` : 'Select Time'}
              //   // onOk={value => {
              //   //   this.onChangeModal(value, 'delay');
              //   // }}
              //   onChange={value => {
              //     this.onChangeModal(value, 'delay');
              //   }}
              // />
            )}
          </div>
        )
      },
      {
        title: "활성화",
        dataIndex: "active",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              string.activeHandlesCodes[string.toggleCode[data]]
            ) : (
              <SelectBox
                type="active"
                value={string.activeHandlesCodes[this.state.selectData.active]}
                code={string.toggleCode}
                codeString={string.activeHandlesCodes}
                onChange={this.onChangeModal}
              />
            )}
          </div>
        )
      },
      {
        title: "설정",
        dataIndex: "set",
        key: "set",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index && (
              <Button
                onClick={() => {
                  this.setState({
                    selectedRow: index,
                    prevData: row
                  });
                  this.onChangeModal(row.coinType, "coinType", row);
                }}>
                수정하기
              </Button>
            )}
            {selectedRow === index && (
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: this.state.prevData
                    });
                  }}
                />
              </div>
              // <div>
              //   <Button onClick={this.onUpdate} style={{ marginRight: '10px' }}>
              //     확인
              //   </Button>
              //   <Button
              //     onClick={() => {
              //       this.setState({
              //         selectedRow: '',
              //         selectData: this.state.prevData
              //       });
              //     }}
              //   >
              //     취소
              //   </Button>
              // </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          승인거래설정
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          id="setting-table"
          rowKey={record => record.coinType}
          columns={approvalColumns}
          dataSource={this.props.result.approvalSettingList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getSet: ({ pageSize, pageNum }) => dispatch(getSet({ pageSize, pageNum }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Setting);
