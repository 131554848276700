import Axios from "axios";
import util from "util";
import Const from "../const";
import { reactLocalStorage } from "reactjs-localstorage";
let loadingCount = 0;

global.language = "ko";
global.lanList = ["ko", "en", "ja", "zh"];

const serverUrl =
  Const.serverProtocol + "://" + Const.serverIp + ":" + Const.serverPort;

const makeUrl = (url, params) => {
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const httpExec = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1)
    global.document.getElementById("loadingSpinner").style.display = "block";

  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // if (
        //   url === serverUrl + httpUrl.login ||
        //   url === serverUrl + httpUrl.logout
        // ) {
        // } else {
        //   if (
        //     (method === 'PUT' || method === 'POST' || method === 'DELETE') &&
        //     response.data.result === 'SUCCESS'
        //   ) {
        //     alert('완료');
        //   }
        // }
        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      })
      .catch((error) => {
        // console.log(JSON.stringify(error, null, 4));
        if (error.message.includes("401")) {
          alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove("adminUser");
          global.location.href = "/";
        }
        // if (error.response.data.message === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('장기간 미사용으로 자동 로그아웃 되었습니다.');
        //     global.location.href = '/';
        //   }
        // } else if (error.response.data.data === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('접근 권한이 없습니다. 로그인 해주세요.');
        //     global.location.href = '/';
        //   }
        // }
        // alert(JSON.stringify(error));
        reject(error);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      });
  });
};

const httpGet = (url, params, data) => {
  return httpExec("GET", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.get(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpPut = (url, params, data) => {
  return httpExec("PUT", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.put(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpPost = (url, params, data) => {
  return httpExec("POST", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.post(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpDownload = (url, params, data) => {
  // return httpExec('GET', makeUrl(url, params), data);
  return new Promise((resolve, reject) => {
    Axios({
      method: "GET",
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpUrl = {
  idPwdCheck: "/idPwCheck",
  login: "/login",
  logout: "/logout",

  updateUserBalance: "/wallet/balance/refresh/%s/%s",

  //blockchain coin decimal
  getCoinDecimal: "/blockchain/list/decimal?language=%s",
  getSearchSerial: "/foundation/search?coinType=%s&address=%s",
  //member
  userIdx: '/user/idx/%s',
  userList:
    "/user/listOrigin?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s&searchType=%s&startDate=%s&endDate=%s",
  userSbList:
  "/user/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s&searchType=%s&startDate=%s&endDate=%s",
  userStemList:
  "/user/stemlist?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s&searchType=%s&startDate=%s&endDate=%s",
  
  userStemUpdate:"/user/stemupdate",
  userUpdate: "/user/update",
  userDelete: "/user/delete/%s",
  userCreate: "/user/create",
  walletList: "/user/walletList?pageSize=%s&pageNum=%s&serial=%s",
  walletListByIdx: "/user/walletListByIdx?pageSize=%s&pageNum=%s&idx=%s",
  walletLogList:
    "/user/walletLogList?pageSize=%s&pageNum=%s&serial=%s&type=%s&searchText=%s&transferType=%s&coinType=%s",
  coinLockList:
    "/user/coinLockList?pageSize=%s&pageNum=%s&serial=%s&coinType=%s&deleted=%s&expire=%s",
  coinLockUpdate: "/user/coinLockUpdate",
  coinLockBalance: "/user/coinLockBalance?serialNum=%s&coinType=%s",
  userListDownload:
    "/user/listOrigin/excel?pageSize=%s&pageNum=%s&searchText=%s&deleted=%s&searchType=%s&startDate=%s&endDate=%s",
  userSbListDownload:
    "/user/list/excel?pageSize=%s&pageNum=%s&searchText=%s&deleted=%s&searchType=%s&startDate=%s&endDate=%s",
    userStemListDownload:
    "/user/listStem/excel?pageSize=%s&pageNum=%s&searchText=%s&deleted=%s&searchType=%s&startDate=%s&endDate=%s",  
  walletLogModalDownload:
    "/user/walletLogList/excel?pageSize=%s&pageNum=%s&searchTypes=%s&searchText=%s&transferType=%s&coinType=%s&serial=%s",
    walletLogModalSBDownload:
    "/user/walletLogListSB/excel?pageSize=%s&pageNum=%s&searchTypes=%s&searchText=%s&transferType=%s&coinType=%s&serial=%s",
    walletLogModalSTEMDownload:
    "/user/walletLogListSTEM/excel?pageSize=%s&pageNum=%s&searchTypes=%s&searchText=%s&transferType=%s&coinType=%s&serial=%s",
  getUserToken: "/user/get/token/%s",
  resetSecurityPassword: "/user/securityPassword/reset/%s",
  changePassword: "/user/change/password",
  checkId: '/user/check/id/%s',
  kycList: '/user/kyc/list?pageSize=%s&pageNum=%s&searchText=%s&searchType=%s',
  kycApprove: '/user/kyc/approve',

  //SignupRewardLog
  getSignupRewardLog:
    "/signuprewardlog/log/list?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s",
  downloadSignupRewardLog:
    "/signuprewardlog/log/list/excel?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s",

  //wallet
  feeWalletList: "/wallet/feeWallet/list?type=%s&deleted=%s&searchText=%s",
  feeWalletCreate: "/wallet/feeWallet/create",
  feewWalletUpdate: "/wallet/feeWallet/update",
  feeWalletDelete: "/wallet/feeWallet/delete/%s",
  collectionList: "/wallet/fee/list?pageSize=%s&pageNum=%s",
  collectionUpdate: "/wallet/fee/update",
  feeLogList:
    "/wallet/feeLogList?pageSize=%s&pageNum=%s&searchText=%s&type=%s&coinType=%s&startDate=%s&endDate=%s",
  downLoadFeeLogList:
    "/wallet/feeLogList/excel?pageSize=%s&pageNum=%s&searchText=%s&type=%s&coinType=%s&startDate=%s&endDate=%s",
  cautionList:
    "/wallet/cautionWallet/list?pageSize=%s&pageNum=%s&cautionType=%s&searchText=%s",
  cautionWalletCreate: "/wallet/cautionWallet/create",
  cautionWalletDelete: "/wallet/cautionWallet/delete/%s/%s/%s",
  foundationList: "/wallet/foundation/list?pageSize=%s&pageNum=%s",
  foundationCreate: "/wallet/foundation/create",
  foundationUpdate: "/wallet/foundation/update",
  foundationDelete: "/wallet/foundation/delete/%s",
  getMergeList: "/wallet/merge/list?pageSize=%s&pageNum=%s&coinType=%s&fee=%s",
  walletMerge: "/wallet/merge",
  getMergeLogList:
    "/wallet/merge/list/mergeLog?pageSize=%s&pageNum=%s&logType=%s&coinType=%s&searchText=%s&startDate=%s&endDate=%s",
  getEthLogList:
    "/wallet/merge/list/ethFeeLog?pageSize=%s&pageNum=%s&logType=%s&coinType=%s&searchText=%s&startDate=%s&endDate=%s",
  downLoadEthLogList:
    "/wallet/merge/list/ethFeeLog/excel?pageSize=%s&pageNum=%s&logType=%s&coinType=%s&searchText=%s&startDate=%s&endDate=%s",

  //transaction
  setList: "/transaction/approvalSetting/list?pageSize=%s&pageNum=%s",
  setUpdate: "/transaction/approvalSetting/update",
  processList:
    "/transaction/approvalStandby/list?pageSize=%s&pageNum=%s&type=%s&reasonType=%s&searchText=%s",
  processUpdate: "/transaction/approvalStandby/update",
  getlimitAmount: "/transaction/limitAmount/list",
  limitUpdate: "/transaction/limitAmount/update",
  limitDelete: "/transaction/limitAmount/delete/%s/%s/%s",
  getTransferRequestList:
    "/transaction/transferRequestList?pageSize=%s&pageNum=%s",
  transferRequestReset: "/transaction/transferRequest/reset/%s",
  getTransferLog:
  "/transaction/transferLogList?pageSize=%s&pageNum=%s&coinType=%s&requestType=%s&searchType=%s&startDate=%s&endDate=%s&searchText=%s&transferType=%s",
  getTransferLogEx:
  "/transaction/transferLogList2?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s", 
  downloadTransferLogExcel:
  "/transaction/transferLogList/excel?pageSize=%s&pageNum=%s&coinType=%s&requestType=%s&searchType=%s&startDate=%s&endDate=%s&searchText=%s",
  downloadTransferLogExcel2:
  "/transaction/transferLogList2/excel?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  getMoveLogList:"/transaction/moveLogList?pageSize=%s&pageNum=%s&searchText=%s",
  //coin
  distributeList:
    "/coin/airDropLogList?pageSize=%s&pageNum=%s&type=%s&searchText=%s",
  manualCreate: "/coin/manual/excel",
  manualSingleCreate: "/coin/manual/single",
  coinAutoList: "/coin/auto/list?pageSize=%s&pageNum=%s",
  autoUpdate: "/coin/auto/update",

  //community
  noticeList:
    "/community/notice/list?pageSize=%s&pageNum=%s&deleted=%s&category=%s&searchText=%s",
  noticeCreate: "/community/notice/create",
  noticeUpdate: "/community/notice/update",
  noticeDelete: "/community/notice/delete/%s?flag=%s",
  faqList:
    "/community/faq/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s",
  faqCreate: "/community/faq/create",
  faqUpdate: "/community/faq/update",
  faqDelete: "/community/faq/delete/%s",
  getLinkList: "/community/link/list?pageSize=%s&pageNum=%s&language=%s",
  linkCreate: "/community/link/create",
  linkUpdate: "/community/link/update",
  linkDelete: "/community/link/delete/%s",
  linkRankUpdate: "/community/link/update/rank",

  //statistics
  userStatisticsList:
    "/statistics/user/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  loginStatisticsList:
    "/statistics/login/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  transferStatisticsList:
    "/statistics/transfer/list?pageSize=%s&pageNum=%s&type=%s&startDate=%s&endDate=%s",

  getFeeDateStatistic:
    "/statistics/feeDate/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  getFeeMonthStatistic:
    "/statistics/feeMonth/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  getAdminLoginLog:
    "/statistics/adminLoginLog/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",

  //foundation
  getFoundationList: "/foundation/list",
  foundationUserList:
    "/foundation/list/user?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&searchText=%s",
  foundationListExcel:
    "/foundation/list/user/excel?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&searchText=%s",
  foundationUserLockList:
    "/foundation/list/user/lock?pageSize=%s&pageNum=%s&coinType=%s&address=%s",
  foundationUserLockDetail:
    "/foundation/list/user/lockDetail?pageSize=%s&pageNum=%s&coinType=%s&address=%s&expireDate=%s",
  foundationLockList:
    "/foundation/lockList?pageSize=%s&pageNum=%s&coinType=%s&searchText=%s&orderType=%s&orderByType=%s",
  foundationLockExcel:
    "/foundation/lockList/excel?pageSize=%s&pageNum=%s&coinType=%s&searchText=%s&orderType=%s&orderByType=%s",
  foundationLockCreateExcel: "/foundation/lock/excelUpload",
  lockUpdate: "/foundation/lock/update",
  lockDelete: "/foundation/lock/delete",
  foundationSend: "/foundation/send/fee",
  foundationSendNormal: "/foundation/send/normal",
  foundationSendDivide: "/foundation/send/divide",
  foundationExcel: "/foundation/send/excel/fee",
  foundationExcelNormal: "/foundation/send/excel/normal",
  foundationExcelDivide: "/foundation/send/excel/divide",
  foundationCoinLog:
    "/foundation/logList?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&requestType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s",
  foundationCoinLogExcel:
    "/foundation/logList/excel?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&requestType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s",
  foundationCoinLogLock:
    "/foundation/logList/lock?pageSize=%s&pageNum=%s&idx=%s",
  designationList:
    "/foundation/lockSelectLogList?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&dateType=%s&deleted=%s&allLock=%s&startDate=%s&endDate=%s&searchText=%s&expireDate=%s",
  designationListExcel:
    "/foundation/lockSelectLogList/excel?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&dateType=%s&deleted=%s&allLock=%s&startDate=%s&endDate=%s&searchText=%s",
  designationStatus:
    "/foundation/lockSelectList?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&allLock=%s&startDate=%s&endDate=%s&searchText=%s&expireDate=%s",
  designationStatusExcel:
    "/foundation/lockSelectList/excel?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&allLock=%s&startDate=%s&endDate=%s&searchText=%s",
  foundationDaily:
    "/foundation/statBudgetList?pageSize=%s&pageNum=%s&foundationIdx=%s&orderType=%s&orderByType=%s&startDate=%s&endDate=%s",
  foundationDailyExcel:
    "/foundation/statBudgetList/excel?pageSize=%s&pageNum=%s&foundationIdx=%s&orderType=%s&orderByType=%s&startDate=%s&endDate=%s",
  foundationDetail:
    "/foundation/budgetLogList?pageSize=%s&pageNum=%s&foundationIdx=%s&orderType=%s&orderByType=%s&selectType=%s&startDate=%s&endDate=%s",
  foundationDetailExcel:
    "/foundation/budgetLogList/excel?pageSize=%s&pageNum=%s&foundationIdx=%s&orderType=%s&orderByType=%s&selectType=%s&startDate=%s&endDate=%s",

  //system
  adminList: "/system/admin/list?pageSize=%s&pageNum=%s&searchText=%s",
  adminCreate: "/system/admin/create",
  adminUpdate: "/system/admin/update",
  adminDelete: "/system/admin/delete/%s",
  coinAuthList: "/system/admin/authCoinDetail/%s",
  authList: "/system/admin/authDetail/%s",
  authUpdate: "/system/admin/authUpdate",
  authCoinUpdate: "/system/admin/authCoinUpdate",
  serviceList: "/system/serviceStatus/list",
  serviceCreate: "/system/serviceStatus/create",
  serviceUpdate: "/system/serviceStatus/update",
  serviceDelete: "/system/serviceStatus/delete/%s",
  blockChainList: "/system/blockchain/list?searchText=%s",
  blockChainDetail: "/system/blockchain/detail/%s",
  blockChainCreate: "/system/blockchain/create",
  blockChainUpdate: "/system/blockchain/update",
  blockChainDelete: "/system/blockchain/delete/%s",
  blockChainRankUpdate: "/system/blockchain/update/rank",
  sendPush: '/push/send',
  getHolidayList: "/system/holidaylist?pageSize=%s&pageNum=%s&",
  HolidayCreate: "/system/holiday/create",
  HolidayDelete: "/system/holiday/delete",
  HolidayDeleteMulti: "/system/holiday/deleteMulti",
  getWorkLogList: "/system/workloglist?pageSize=%s&pageNum=%s&searchText=%s&searchType=%s",
  getDailyAgreeMent: "/system/dailylist?pageSize=%s&pageNum=%s&searchText=%s",
  DailyDelete:"/system/dailylist/delete",
  DailyDeleteAll:"/system/dailylist/deleteall",
  DailyDeleteSelective:"/system/dailylist/deleteselective",
  DailyCreate:"/system/dailylist/create",
  dailyCreateExcel: "/system/dailylist/create/excel",
  getPaybackRatioList:
    "/system/paybackratiolist?pageSize=%s&pageNum=%s&searchText=%s",
  paybackRatioUpdate: "/system/paybackratiolist/update",
  //apply
  getFranchiseApplyList:
  "/apply/applyList?pageSize=%s&pageNum=%s",
  updateFranchiseApplyList:
  "/apply/applyUpdate",
  
  //franchise
  getFranchiseList:
    "/franchise/franchise/list?pageSize=%s&pageNum=%s&statusType=%s&searchText=%s",
  franchiseCreate: "/franchise/franchise/create",
  franchiseUpdate: "/franchise/franchise/update",
  franchiseDelete: "/franchise/franchise/delete/%s",
  franchiseStatusUpdate: "/franchise/franchise/update/status/%s&%s",
  franchiseCoinType: "/franchise/coinType?language=%s",
  getPurchaseLog:
    "/franchise/purchaseLog?pageSize=%s&pageNum=%s&searchType=%s&searchText=%s&coinType=%s",
  getPaybackLog:
    "/franchise/paybackLog?pageSize=%s&pageNum=%s&coinType=%s&searchType=%s&searchText=%s",
  getUserDetail: "/franchise/purchaseLog/user/%s",
  getFranchiseDetail: "/franchise/purchaseLog/franchise/%s",
  getPaybackSetting: "/franchise/paybackSetting/list",
  paybackUpdate: "/franchise/paybackSetting/update",
  paybackDelete: "/franchise/paybackSetting/delete/%s",

  //trade
  getTradeLog:
    "/trade/logList?pageSize=%s&pageNum=%s&tradeType=%s&searchText=%s&coinType=%s",

  // adjustment
  getAdjustmentLogList:
    "/adjustment/list?pageSize=%s&pageNum=%s&adjustmentStatus=%s&startDate=%s&endDate=%s&organizationIdx=%s&coinType=%s&orderByType=%s",
  getAdjustmentLogListRead:
    "/adjustment/list?pageSize=%s&pageNum=%s&adjustmentStatus=%s&startDate=%s&endDate=%s&organizationIdx=%s&coinType=%s&orderByType=%s",
  updateAdjustment: "/adjustment/list/statusUpdate",
  updateAdjustmentMulti: "/adjustment/list/statusUpdateMulti",
  getAdjustmentOrganization:
    "/adjustment/list/organization?pageSize=%s&pageNum=%s&franchise=%s&deleted=%s&searchText=%s",
  getAdjustmentShareList:
    "/adjustment/share/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&organizationIdx=%s&status=%s&orderByType=%s",
  updateAdjustmentShare: "/adjustment/share/statusUpdate",
  getAdjustmentShareDetail:
    "/adjustment/share/detail?pageSize=%s&pageNum=%s&logDate=%s&organizationIdx=%s",
  downloadAdjustmentLog:
    "/adjustment/list/excel?pageSize=%s&pageNum=%s&adjustmentStatus=%s&startDate=%s&endDate=%s&organizationIdx=%s&coinType=%s&orderByType=%s",
  downloadAdjustmentShare:
    "/adjustment/share/list/excel?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&organizationIdx=%s&status=%s&orderByType=%s",
  getIncentiveLogList:
    "/adjustment/incentiveLog/list?pageSize=%s&pageNum=%s",
  incentiveLogListDownload:
    "/adjustment/incentiveLog/list/excel?pageSize=%s&pageNum=%s",
    createIncentiveLogList:
    "/adjustment/incentiveLog/create",
    updateIncentiveLogList:
    "/adjustment/incentiveLog/update",
  //coupon
  getCouponLogList:"/coupon/loglist?pageSize=%s&pageNum=%s&",
  getCouponOwnList:"/coupon/ownlist?pageSize=%s&pageNum=%s&",
  getCouponCategoryList:"/coupon/categorylist?pageSize=%s&pageNum=%s&",
  CouponCategoryDelete: "/coupon/categorylist/delete",
  CouponCategoryCreate: "/coupon/categorylist/create",
  CouponCategoryUpdate: "/coupon/categorylist/update",
  CouponOwnUpdate: "/coupon/ownlist/update",
  
  //globalShare
  getGlobalShareList:
    "/globalshare/share/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&userIdx=%s&status=%s&orderByType=%s",
  updateGlobalShareMemo: "/globalshare/share/memoUpdate",
  updateGlobalShareStatus: "/globalshare/share/statusUpdate",
  getGlobalShareDetail:
    "/globalshare/share/detail?pageSize=%s&pageNum=%s&logDate=%s&userIdx=%s",
  downloadGlobalLog:
    "/globalshare/list/excel?pageSize=%s&pageNum=%s&adjustmentStatus=%s&startDate=%s&endDate=%s&userIdx=%s&coinType=%s&orderByType=%s",
  downloadGlobalShare:
    "/globalshare/share/list/excel?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&userIdx=%s&status=%s&orderByType=%s",
  //globalShareDetail
  getGlobalShareDetailList:
    "/globalshare/share/detailList?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s&orderByType=%s",
  downloadGlobalShareDetail:
    "/globalshare/share/detailList/excel?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s&orderByType=%s",
  // payment
  getOrganiztionList:
    "/payment/organization/list?pageSize=%s&pageNum=%s&deleted=%s&franchiseType=%s&searchText=%s",
  getOraganiztionListRead:
    "/payment/organization/list?pageSize=%s&pageNum=%s&deleted=%s&franchiseType=%s&searchText=%s",
  getOrganizationListDownload:
    "/payment/organization/list/excel?franchiseType=%s&searchText=%s&startDate=%s&endDate=%s",
  getLowerOrganizationList: "/payment/organization/list/lowerOrg/%s",
  organizationCreate: "/payment/organization/create",
  getUserList:
    "/payment/user/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s&searchType=%s",
  getUserRecommender: "/payment/user/recommend/%s",
  getOrganizationChoiceList:
    "/payment/organizationChoice/list?pageSize=%s&pageNum=%s&idx=%s&searchText=%s&classification=%s",
  organizationUpdate: "/payment/organization/update",
  organizationDelete: "/payment/organization/delete/%s",
  getOrganizationCategoryList: "/payment/organization/category/%s",
  organizationCategoryCreate: "/payment/organization/category/create",
  organizationCategoryDelete: "/payment/organization/category/delete",
  getPaymentSettingList: "/payment/paymentSetting/list/%s",
  paymentSettingUpdate: "/payment/paymentSetting/update",
  paymentSettingDelete: "/payment/paymentSettiㅇng/delete/%s/%s",
  getPaymentLogList:
    "/payment/paymentLog/list?pageSize=%s&pageNum=%s&canceled=%s&organizationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s&searchType=%s",
    getPaymentLogListRead:
    "/payment/paymentLog/list?pageSize=%s&pageNum=%s&canceled=%s&organizationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s&searchType=%s",
  getPaymentLogExcel:
    "/payment/paymentLog/list/excel?pageSize=%s&pageNum=%s&canceled=%s&organizationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s&searchType=%s",
  paymentCancel:
  "/payment/paymentLog/cancel", 
   getAdjustmentSetting: "/payment/adjustmentSetting",
  updateAdjustmentSetting: "/payment/adjustmentSetting/update",
  getGlobalSetting: "/payment/globalSetting",
  updateGlobalSetting: "/payment/globalSetting/update",

  getCategoryList: "/payment/category/list?idx=%s&searchText=%s",
  categoryCreate: "/payment/category/create",
  categoryUpdate: "/payment/category/update",
  categoryDelete: "/payment/category/delete/%s",  

  

  // banner
  getBannerList: "/banner/list?deleted=%s&orderByType=%s",
  bannerCreate: "/banner/create",
  bannerUpdate: "/banner/update",
  bannerDelete: "/banner/delete/%s",

  // adminUserInfo
  adminUserUpdate: "/myPage/update",
  otpUpdate: "/myPage/otp/change/key",

  //image
  imageUpload: "/image/upload",
  getImage: "/image/",
  imageEncoding: "/image/imageEncoding",
  fileUpload: "/file/upload",
  getFile: "/file/%s",
  getFileList: "/file/list?refTable=%s&refIdx=%s",
  getFile2: "/file/{idx}",
  //map
  getGeocode: "/map/geocode?address=%s",

  //
  getAddress: "/foundation/send/getAddress?coinType=%s&serial=%s",

  //charge
  getDepositList: "/deposit/list?pageSize=%s&pageNum=%s&searchType=%s&searchText=%s&startDate=%s&endDate=%s&startDate2=%s&endDate2=%s&depositStatus=%s",
  getDepositListRead: "/deposit/list?pageSize=%s&pageNum=%s&searchType=%s&searchText=%s&startDate=%s&endDate=%s&startDate2=%s&endDate2=%s&depositStatus=%s",
  getDepositRequestList: "/deposit/requestlist?pageSize=%s&pageNum=%s&searchType=%s&searchText=%s&statusType=%s&startDate=%s&endDate=%s",
  updateDeposit: "/deposit/list/statusUpdate",
  updateDepositListMemo: "/deposit/list/memoUpdate",
  depositListDownload:
    "/deposit/list/excel?searchType=%s&searchText=%s&startDate=%s&endDate=%s&startDate2=%s&endDate2=%s",
  depositRequestListDownload:
    "/deposit/requestlist/excel?searchType=%s&searchText=%s&statusType=%s&startDate=%s&endDate=%s",
  depositMapping: "/deposit/mapping",

 //purchase
  getPurchaseList:
  "/purchase/list?pageSize=%s&pageNum=%s&searchText=%s",
  getRequestList:
  "/purchase/request/list?pageSize=%s&pageNum=%s&searchText=%s",
  getRequestGoodsList:
  "/purchase/requestgoods/list?pageSize=%s&pageNum=%s&idx=%s",
  requestMapping: "/request/mapping",
  purchaseListDownload:
  "/purchase/list/excel?searchText=%s",
  extraPayListDownload:
  "/purchase/extrapay/list/excel?searchType=%s&searchText=%s&startDate=%s&endDate=%s",
  getExtraPayList:
  "/purchase/extrapaylist?pageSize=%s&pageNum=%s&searchType=%s&searchText=%s&startDate=%s&endDate=%s&categoryStatus=%s",
  updateExtraPay:
  "/purchase/extrapaylist/update",
  updateExtraPay2:
  "/purchase/extrapaylist/update2",
  updateExtraPayCancelMulti: "/purchase/extrapaylist/paid2UpdateMulti",
  updateExtraPayMulti: "/purchase/extrapaylist/paid2UpdateMulti2",
  updateExtraPayPointMulti: "/purchase/extrapaylist/paidUpdateMulti",
  getPurchaseTree: "/purchase/tree/%s",
  getPurchaseReorder: "/purchase/reorder/%s",
  requestListDownload: "/purchase/requestlist/excel?searchText=%s",

  getProductList:
  "/purchase/product/list?pageSize=%s&pageNum=%s&searchText=%s",
  productCreate: "/purchase/productcreate",
  productDelete: "/purchase/product/delete", 
  productUpdate: "/purchase/product/update",
  createProduct: "/purchase/product/create",
  createProductCancel: "/purchase/product/cancel/%s",
  createParent: "/purchase/create/parent/%s/%s",
  purchaseDepositManual: "/purchase/deposit/manual",
  
  purchaseCreateExcel: "/purchase/product/create/excel",

  getProductSumList:"/purchase/product/sumlist?pageSize=%s&pageNum=%s&searchText=%s",

  getUserStemList:
    "/purchase/user/stemlist?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s&searchType=%s",
  //event
  getEventList: "/event/list?pageSize=%s&pageNum=%s",
  getEventRegist: "/event/regist",
  getEventDetail: "/event/detail/%s",
  eventModify: "/event/modify",
  eventDelete: "/event/delete/%s?flag=%s",
  eventRewardGive: "/event/reward/give",
  eventRewardGiveExcel: "/event/reward/give/excel",
  eventRewardGiveDownload: "/event/reward/excel?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s",
  getEventRewardList: "/event/reward/list?pageSize=%s&pageNum=%s&userId=%s&userName=%s&userTel=%s",
  getEventRewardStats: "/event/reward/stats?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",

  //exchange
  getExchangeList: "/exchange/list?pageSize=%s&pageNum=%s&fromCoinType=%s&toCoinType=%s&status=%s",
  getExchangeSettingList: "/exchange/setting/list",
  updateExchangeSetting: "/exchange/setting/update",
  getExchangeFeeStats: "/exchange/stats?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",

  //staking
  stakingCoinCreate: "/staking/create",
  stakingCoinUpdate: "/staking/update",
  stakingCoinDelete: "/staking/delete/%s",
  stakingCoinList: "/staking/list",
  stakingBonusCreate: "/staking/bonus/create",
  stakingBonusUpdate: "/staking/bonus/update",
  stakingBonusDelete: "/staking/bonus/delete/%s/%s",
  stakingBonusList: "/staking/bonus/list",
  stakingBalance: "/staking/balance/list?pageSize=%s&pageNum=%s&coinType=%s&userId=%s&userName=%s&userTel=%s",
  stakingLog: "/staking/log?pageSize=%s&pageNum=%s&coinType=%s&userId=%s&userName=%s&userTel=%s",
  stakingBonusLog: "/staking/bonus/log?pageSize=%s&pageNum=%s&coinType=%s&userId=%s&userName=%s&userTel=%s",

  //invest
  investMiningList: '/invest/mining/list?pageSize=%s&pageNum=%s&searchText=%s',

  //arbitrage
  arbitrageSettingList: '/arbitrage/setting/list/%s',
  arbitrageSettingModify: '/arbitrage/setting/modify',
  arbitrageSettingActivate: '/arbitrage/setting/activate',
  arbitrageSettingCreate: '/arbitrage/setting/create',
  arbitrageSettingDelete: '/arbitrage/setting/delete/%s/%s',
  arbitrageLogList: '/arbitrage/log/list?pageSize=%s&pageNum=%s',
  arbitrageUserList: '/arbitrage/user/list/%s',
  arbitrageUserCreate: '/arbitrage/user/create',
  arbitrageUserDelete: '/arbitrage/user/delete',
  arbitrageFlowTotal: '/arbitrage/flow/total',
  arbitrageFlowList: '/arbitrage/flow/list?pageSize=%s&pageNum=%s',
  arbitrageManualLogList: '/arbitrage/manual/list?pageSize=%s&pageNum=%s',
  arbitrageManualCreate: '/arbitrage/manual/create',
  arbitrageManualCancel: '/arbitrage/manual/cancel/%s',
  arbitrageManualExchange: '/arbitrage/manual/exchange',

  //moz invest
  mozInvestCreate: '/moz/invest/create',
  mozInvestModify: '/moz/invest/modify',
  mozInvestDelete: '/moz/invest/delete/%s?flag=%s',
  mozInvestList: '/moz/invest/list?pageSize=%s&pageNum=%s',
  mozInvestRewardList: '/moz/invest/reward/list?pageSize=%s&pageNum=%s',
};

const imageType = ["image/jpeg", "image/png", "image/bmp"];

export {
  serverUrl,
  httpExec,
  makeUrl,
  httpGet,
  httpUrl,
  httpPut,
  httpPost,
  httpDownload,
  imageType,
};
