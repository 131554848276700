import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Modal, Form, Input, Select } from "antd";
import string from "../../string";
import SelectBox from "../common/SelectBox";
import CoinSelectBox from "../common/CoinSelectBox";
import { httpPut, httpUrl } from "../../api/httpClient";

const FormItem = Form.Item;
const Option = Select.Option;

const PaymentSettingRegistry = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = { settingData: [] };
    }

    onOk = () => {
      const { form, type, organizationIdx, modalData } = this.props;

      form.validateFields((err, values) => {
        if (!err) {
          if (type) {
            let data = {
              ...values,
              create: 1,
              paybackMethod: values.paybackMethod || "NULL",
              organizationIdx: organizationIdx
            };
            if (modalData !== undefined)
              data = { ...data, create: 0, coinType: modalData.coinType };
            httpPut(httpUrl.paymentSettingUpdate, [], data)
              .then((res) => {
                // console.log(JSON.stringify(data, null, 4));
                if (res.data.result === "SUCCESS") {
                  this.props.refresh();
                  this.props.onCancel();
                } else {
                  alert(
                    "선택하신 결제 코인이 중복되었습니다. 다시 선택해주세요."
                  );
                }
              })
              .catch((error) => {});
          } else {
            if (this.state.settingData.includes(values.coinType)) {
              alert("선택하신 결제 코인이 중복되었습니다. 다시 선택해주세요.");
            } else {
              let data = {
                ...values,
                paybackMethod: values.paybackMethod || "NULL"
              };
              this.props.onOk(data);
              this.props.onCancel();
            }
          }
        }
      });
    };

    componentDidMount() {
      const { paymentSetting, modalData, form } = this.props;

      if (paymentSetting !== undefined) {
        let arr = [];
        paymentSetting.map((list) => {
          if (!arr.includes(list.coinType)) arr.push(list.coinType);
          return this.setState({ settingData: arr });
        });
      }
      if (modalData !== undefined) {
        form.setFieldsValue({
          payCoinType: modalData.payCoinType,
          paybackCoinType: modalData.paybackCoinType,
          paybackMethod: modalData.paybackMethod,
          paybackRatio: modalData.paybackRatio
        });
      }
    }

    renderOption() {
      const coinList = reactLocalStorage.getObject("coinList");

      let result = [{ coinType: null, name: "선택 안함" }, ...coinList];
      result = result.map((item, index) => {
        return (
          <Option value={item.coinType} key={`${item}${index}`}>
            {item.name}
          </Option>
        );
      });

      return result;
    }

    render() {
      const coinList = reactLocalStorage.getObject("coinList");
      const { form, visible, onCancel, modalData } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      return (
        <Modal
          visible={visible}
          title="결제 및 페이백 설정"
          okText="적용"
          onOk={this.onOk}
          cancelText="취소"
          onCancel={onCancel}
          style={{ minWidth: "800px" }}>
          <Form {...formItemLayout} onSubmit={this.onOk}>
            {modalData !== undefined ? (
              <FormItem label={<span>결제 코인&nbsp;</span>}>
                {coinList.find((coin) => coin.coinType === modalData.coinType)
                  ? coinList.find(
                      (coin) => coin.coinType === modalData.coinType
                    ).name
                  : "-"}
              </FormItem>
            ) : (
              <FormItem label={<span>결제 코인&nbsp;</span>}>
                {getFieldDecorator("coinType", {
                  rules: [
                    {
                      required: true,
                      message: "결제 코인을 선택해주세요!"
                    }
                  ]
                })(
                  <CoinSelectBox
                    placeholder="선택해주세요"
                    style={{ width: "376px" }}
                  />
                )}
              </FormItem>
            )}
            <FormItem label={<span>교환(전환) 코인&nbsp;</span>}>
              {getFieldDecorator("payCoinType", {
                rules: [
                  {
                    required: false,
                    message: "교환(전환) 코인을 선택해주세요!"
                  }
                ]
              })(
                <Select placeholder="선택해주세요." style={{ width: "376px" }}>
                  {this.renderOption()}
                </Select>
              )}
            </FormItem>
            <FormItem label={<span>페이백 코인&nbsp;</span>}>
              {getFieldDecorator("paybackCoinType", {
                rules: [
                  {
                    required: false,
                    message: "페이백 코인을 선택해주세요!"
                  }
                ]
              })(
                <Select placeholder="선택해주세요." style={{ width: "376px" }}>
                  {this.renderOption()}
                </Select>
              )}
            </FormItem>
            <FormItem label={<span>페이백 방식&nbsp;</span>}>
              {getFieldDecorator("paybackMethod", {
                rules: [
                  {
                    required: false,
                    message: "페이백 조건을 선택해주세요!"
                  }
                ]
              })(
                <SelectBox
                  placeholder="선택해주세요"
                  code={string.paybackType}
                  codeString={string.paybackTypeString}
                  style={{ width: "376px" }}
                />
              )}
            </FormItem>
            <FormItem label={<span>페이백 비율&nbsp;</span>}>
              {getFieldDecorator("paybackRatio", {
                rules: [
                  {
                    required: false,
                    message: "페이백 비율을 입력해주세요!"
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);
export default PaymentSettingRegistry;
