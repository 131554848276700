import React, { Component } from "react";
import { Modal, Descriptions, Input, Switch, Icon } from "antd";

import { formatDate } from "../../util";
import string from "../../string";
import { httpPut, httpUrl, httpGet } from "../../api/httpClient";
import SelectBox from "../common/SelectBox";
import Const from "../../const";

const Ditems = Descriptions.Item;

class UserDetail extends Component {
    state = {
        detailData: {},
        changePasswordUrl: ""
    };
    handleResetSecurityPassword = (idx) => {
        if (global.confirm("보안비밀번호를 0000으로 초기화 하시겠습니까?")) {
            httpGet(httpUrl.resetSecurityPassword, [idx], {}).then((res) => {
                if (res.data.result === "SUCCESS") {
                    alert("보안비밀번호가 0000으로 변경 되었습니다.")
                } else {
                    alert("오류가 발생하였습니다.")
                }
            });
        }
    };

    handleGetToken = (idx) => {
        httpGet(httpUrl.getUserToken, [idx], {}).then((res) => {
            if (res.data.result === "SUCCESS") {
                this.setState({
                    changePasswordUrl: `${
                        Const.serverProtocol +
                        "://" +
                        Const.clientIp +
                        ":" +
                        Const.clientPort
                        }/changePassword/${res.data.data}`
                });
            } else {
                this.setState({
                    changePasswordUrl: "오류가 발생하였습니다."
                });
            }
        });
    };

    onChangeModal = (value, type) => {
        var data = this.state.detailData;
        data[type] = value;
        this.setState({
            detailData: data
        });
    };


    getUserDetailList = (params = {}) => {
        const { searchType, searchText, startDate, endDate } = this.state
        let pageNum = params.pageNum || 1;

        httpGet(httpUrl.getUserList, [10, pageNum, searchType, searchText, startDate, endDate], {})
            .then(res => {
                const pagination = { ...this.state.pagination }
                pagination.total = res.data.totalCount
                this.setState({
                    list: res.data.userList,
                    pagination,
                })
            })

    }
    onUpdateUserDetail = (idx, recommender) => {
        httpPut(httpUrl.updateUserDetail, [], { idx: idx, recommender: recommender }).then((res) => {

            this.getUserDetailList({
                pageNum: this.state.pagination.current,
            });


        });

    };

    onCancel = () => {
        // const obj = Object.assign({}, this.props.modalData);
        // this.setState({
        //   detailData: obj
        // });
        this.props.onCancel();
    };


    componentDidMount() {
        const obj = Object.assign({}, this.props.modalData);
        this.setState({
            detailData: obj
        });
    }

    render() {
        const { visible, rootPage } = this.props;
        const { detailData } = this.state;

        return (
            <Modal
                visible={visible}
                title="회원 정보"
                onCancel={this.onCancel}
                cancelText="닫기"
                style={{ minWidth: "1000px" }}>
                {/* <div>{JSON.stringify(modalData)}</div> */}
                <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
                    <Ditems label="생성일">{formatDate(detailData.createDate)}</Ditems>
                    <Ditems label="탈퇴일">
                        {detailData.deleteDate ? formatDate(detailData.deleteDate) : "-"}
                    </Ditems>
                    <Ditems label="이름">

                        {detailData.name}


                    </Ditems>
                    <Ditems label="보안등급">{detailData.securityLevel}</Ditems>
                    <Ditems label="아이디(이메일)">{detailData.id}</Ditems>
                    <Ditems label="이메일 인증">
                        {string.certifiedString[detailData.emailVerified]}
                    </Ditems>
                    <Ditems label="휴대폰">

                        {detailData.phone}

                    </Ditems>
                    <Ditems label="본인코드 / 추천인코드">{detailData.recommendCode}&nbsp;[{detailData.name}]&nbsp;/
           &nbsp;&nbsp;&nbsp;



                        {detailData.recommender}

            [{detailData.recommenderName}]
          </Ditems>
                    <Ditems label="은행">

                        <div>{string.bankString[detailData.bankCode]}</div>

                    </Ditems>
                    <Ditems label="계좌">

                        {detailData.bankAccount}

                    </Ditems>
                    <Ditems label="예금주">

                        <div>{detailData.accountHolder}</div>

                    </Ditems>
                    <Ditems label="시리얼번호">{detailData.serial}</Ditems>
                    <Ditems label="OTP 인증여부">
                        {string.otpVerifyString[detailData.otpVerified]}
                    </Ditems>
                    <Ditems label="잔액 숨기기">

                        <div>

                            {detailData.hideBalanceFlag}
                        </div>



                    </Ditems>
                    <Ditems label="메모">

                        <div>{detailData.memo}</div>

                    </Ditems>
                </Descriptions>
                <div style={{ height: "10px" }} />
                <div className="flex-row flex-center">


                </div>
                <div className="flex-row flex-center" style={{ marginTop: '10px' }}>

                </div>
            </Modal>
        );
    }
}

export default UserDetail;
