import React, { Component } from "react";

import { Table, DatePicker, Input, Button, InputNumber, Switch, Icon } from "antd";

import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import { formatDate, startDateFormat, endDateFormat, amountFormat, coinMultiFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import ArbitrageSettingCreate from "./ArbitrageSettingCreate";
import ArbitrageSettingModify from "./ArbitrageSettingModify";
import ArbitrageSettingUser from "./ArbitrageSettingUser";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class ExchangeSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: [],
      selectedData: {},
      selectedCoinType: undefined,
      createVisible: false,
      modifyVisible: false,
      userVisible: false,
    };
  }

  componentDidMount() {
    this.getArbitrageSettingList();
  }
  setRefresh = (func) => {
      this.refreshUserList = func;
  }
  getArbitrageSettingList = () => {
      if (this.state.selectedCoinType == undefined) return;
    httpGet(httpUrl.arbitrageSettingList, [this.state.selectedCoinType], {}).then((res) => {
      this.setState({
        tables: res.data,
      });
    });
  };
//   onUpdateSettingValue = (callback) => {
//     httpPost(httpUrl.updateExchangeSetting, [], this.state.selectedData).then((res) => {
//       callback();
//     }).catch(()=>{
//       alert('수정 중 오류가 발생하였습니다.')
//     });
//   }
  render() {
    const { tables } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    const columns = [
      {
        title: "코인명",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{coinList.find((coin) => coin.coinType === data).name}</div>
      },
      {
        title: "교환대상",
        dataIndex: "targetCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{coinList.find((coin) => coin.coinType === data).name}</div>
      },
      {
        title: "주기(초)(최소/최대)",
        dataIndex: "interval",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{row.intervalMin + '/' + row.intervalMax}</div>
      },
      {
        title: "수량(최소/최대)",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(row.amountMin, row.coinType) + '/' + amountFormat(row.amountMax, row.coinType)}</div>
      },
      {
        title: "비율(%)",
        dataIndex: "ratio",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{data * 100}</div>
      },
      {
        title: "취소시간(n초뒤)",
        dataIndex: "cancelInterval",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "활성화",
        dataIndex: "activated",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
            <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={data}
                onChange={(checked) => {
                    if (checked) {
                        if (global.confirm(`자전을 활성화 하시겠습니까?`)) {
                          httpPost(httpUrl.arbitrageSettingActivate, [], {
                            coinType: row.coinType,
                            targetCoinType: row.targetCoinType,
                            activated: 1
                          }).then(res => {
                              this.getArbitrageSettingList();
                          });
                        }
                    }
                    else {
                        if (global.confirm(`자전을 중단 하시겠습니까?`)) {
                          httpPost(httpUrl.arbitrageSettingActivate, [], {
                            coinType: row.coinType,
                            targetCoinType: row.targetCoinType,
                            activated: 0
                          }).then(res => {
                              this.getArbitrageSettingList();
                          });
                        }
                    }
                }}
            />
      },
      {
        key: "modify",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
            <Button
              onClick={() => {
                this.setState({
                    modifyVisible: true,
                    selectedData: row
                });
              }}>
              정보수정
            </Button>
      },
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          자전설정
      </div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{display: 'inline-block'}}>
        <CoinSelectBox
            placeholder="코인선택"
            style={{ width: "160px" }}
            value={this.state.selectedCoinType}
            onChange={(value)=>{
                this.setState({selectedCoinType: value}, ()=>{
                    this.getArbitrageSettingList();
                });
            }}
            exceptCoinType={[3]}
        />
        </div>
        <div style={{display: 'inline-block'}}>
            <Button
                onClick={() => {
                    if (!this.state.selectedCoinType) {
                        alert('코인을 선택해주세요.');
                        return;
                    }
                    if (this.refreshUserList instanceof Function) this.refreshUserList();
                this.setState({
                    userVisible: true,
                }, ()=> {
                });
                }}>
                사용자설정
            </Button>
        </div>
        <div style={{float: 'right'}}>
          <Button
            onClick={() => {
              this.setState({ createVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
        </div>
        <div style={{}}>
        <Table
          rowKey={(record) => record.coinType+','+record.targetCoinType}
          rowClassName={(record) => (record.activated ? "" : "gray")}
          dataSource={tables}
          columns={columns}
        />
        </div>
        <ArbitrageSettingCreate
          visible={this.state.createVisible}
          onCancel={() => {
            this.setState({ createVisible: false });
          }}
          onOk={() => {
            this.setState({ createVisible: false });
            this.getArbitrageSettingList();
          }}
        />
        <ArbitrageSettingModify
          visible={this.state.modifyVisible}
          data={this.state.selectedData}
          onCancel={() => {
            this.setState({ modifyVisible: false });
          }}
          onOk={() => {
            this.setState({ modifyVisible: false });
            this.getArbitrageSettingList();
          }}
        />
        <ArbitrageSettingUser
            setRefresh={this.setRefresh}
          visible={this.state.userVisible}
          data={{coinType: this.state.selectedCoinType}}
          onCancel={() => {
            this.setState({ userVisible: false });
          }}
        />

      </div>
    );
  }
}

export default ExchangeSetting;
