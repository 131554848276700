import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Form, Modal, Select, Input, InputNumber, Switch, Icon } from "antd";

import string from "../../string";
import SelectBox from "../common/SelectBox";
import CoinSelectBox from "./../common/CoinSelectBox";
import { coinMultiFormat, priceMultiFormat, numberFormat } from "../../util";
import { httpPost, httpUrl } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";

const FormItem = Form.Item;
const Option = Select.Option;

const WalletRegistryModal = Form.create()(
  class extends Component {
    state = {
      registryData: {}
    };

    onSelect = (value, type) => {
      var data = this.state.registryData;
      data[type] = value;
      this.setState({
        registryData: data
      });
      // console.log(JSON.stringify(data));
    };

    onRegistry = () => {
      const { registryData } = this.state;
      const { type } = this.props;
      let createData = {};
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm(`생성하시겠습니까?`)) {
            if (type === "feeWallet") {
              createData = {
                coinType: registryData.coinType,
                address: registryData.address,
                memo: registryData.memo,
                seed: registryData.seed
              };
              httpPost(httpUrl.feeWalletCreate, [], createData)
                .then(result => {
                  showAlert("create");
                  this.props.onOk();
                  this.props.form.resetFields();
                })
                .catch(error => {});
            } else if (type === "coldWallet") {
              createData = {
                coinType: registryData.coinType,
                address: registryData.address,
                memo: registryData.memo
              };
              httpPost(httpUrl.coldWalletCreate, [], createData)
                .then(result => {
                  showAlert("create");
                  this.props.onOk();
                  this.props.form.resetFields();
                })
                .catch(error => {});
            } else if (type === "cautionWallet") {
              createData = {
                coinType: registryData.coinType,
                address: registryData.address,
                type: registryData.type
              };
              httpPost(httpUrl.cautionWalletCreate, [], createData)
                .then(result => {
                  showAlert("create");
                  this.props.onOk();
                  this.props.form.resetFields();
                })
                .catch(error => {});
            } else {
              createData = {
                ...registryData,
                price: priceMultiFormat(registryData.price) || 0,
                balance:
                  coinMultiFormat(
                    registryData.balance,
                    registryData.coinType
                  ) || 0,
                foundationBalance:
                  coinMultiFormat(
                    registryData.foundationBalance,
                    registryData.coinType
                  ) || 0,
                usePrice: registryData.usePrice || 0,
                stableFlag: registryData.stableFlag ? 1 : 0
              };
              // console.log(JSON.stringify(createData, null, 4));
              httpPost(httpUrl.foundationCreate, [], createData)
                .then(result => {
                  if (result.data.result === "SUCCESS") {
                    showAlert("create");
                    this.props.onOk();
                    this.props.form.resetFields();
                  } else {
                    alert(
                      "이미 등록되었거나 한번 등록되었다가 삭제된 지갑주소입니다.\n 삭제된 경우 해당 지갑주소를 검색하여 다시 활성화 시킬 수 있습니다."
                    );
                  }
                })
                .catch(error => {});
            }
          }
        }
      });
    };

    onCancel = () => {
      this.setState({ registryData: {} });
      this.props.form.resetFields();
      this.props.onCancel();
    };

    render() {
      const { registryData } = this.state;
      const { visible, type, form } = this.props;
      const { getFieldDecorator } = form;
      let coinList = reactLocalStorage.getObject("coinList");

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 17 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={
            type === "feeWallet"
              ? "출금지갑"
              : type === "coldWallet"
              ? "콜드월렛"
              : type === "cautionWallet"
              ? "의심지갑"
              : "재단"
          }
          okText="생성"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "800px" }}>
          <div className="walletRegistryModal-wrapper">
            <Form {...formItemLayout} onSubmit={this.onRegistry}>
              <FormItem label={<span>코인 종류&nbsp;</span>}>
                {getFieldDecorator("coinType", {
                  rules: [
                    {
                      required: true,
                      message: "코인 종류를 선택해주세요!"
                    }
                  ]
                })(
                  type === "foundation" ? (
                    <Select
                      style={{ width: "200px" }}
                      placeholder="선택해주세요."
                      onChange={value => {
                        this.onSelect(value, "coinType");
                      }}>
                      {coinList.map(coin => {
                        if (coin.stableFlag === null) {
                          return (
                            <Option
                              key={`${coin.coinType}`}
                              value={coin.coinType}>
                              {coin.name}
                            </Option>
                          );
                        } else return null;
                      })}
                    </Select>
                  ) : (
                    <CoinSelectBox
                      placeholder="선택해주세요"
                      style={{ width: "200px" }}
                      onChange={value => {
                        this.onSelect(value, "coinType");
                      }}
                    />
                  )
                )}
              </FormItem>
              {(type === "feeWallet" ||
                type === "coldWallet" ||
                type === "cautionWallet") && (
                <div>
                  {type === "cautionWallet" && (
                    <FormItem label={<span>종류&nbsp;</span>}>
                      {getFieldDecorator("type", {
                        rules: [
                          {
                            required: true,
                            message: "종류를 선택해주세요!"
                          }
                        ]
                      })(
                        <SelectBox
                          type="type"
                          placeholder="선택해주세요"
                          style={{ width: "200px" }}
                          code={string.typeCode}
                          codeString={string.cautionString}
                          onChange={this.onSelect}
                        />
                      )}
                    </FormItem>
                  )}
                  <FormItem label={<span>주소&nbsp;</span>}>
                    {getFieldDecorator("address", {
                      rules: [
                        {
                          required: true,
                          message: "주소를 입력해주세요!"
                        }
                      ]
                    })(
                      <Input
                        allowClear
                        onChange={e => {
                          this.onSelect(e.target.value, "address");
                        }}
                      />
                    )}
                  </FormItem>
                  {type !== "cautionWallet" && (
                    <FormItem label={<span>메모&nbsp;</span>}>
                      {/* <Input.TextArea
                        style={{ minHeight: "160px" }}
                        onChange={e => {
                          this.onSelect(e.target.value, "memo");
                        }}
                      /> */}
                      {getFieldDecorator("memo", {
                        rules: [
                          {
                            required: false,
                            message: "메모를 입력해주세요!"
                          }
                        ]
                      })(
                        <Input.TextArea
                          style={{ minHeight: "160px" }}
                          onChange={e => {
                            this.onSelect(e.target.value, "memo");
                          }}
                        />
                      )}
                    </FormItem>
                  )}
                </div>
              )}
              {type === "foundation" && (
                <div>
                  <FormItem label={<span>시가 총액&nbsp;</span>}>
                    {getFieldDecorator("marketCap", {
                      rules: [
                        {
                          required: false
                        },
                        {
                          validator: (rule, value, cb) => {
                            if (!registryData.coinType) {
                              cb("코인을 선택해주세요.");
                            } else {
                              if (
                                value &&
                                numberFormat(parseInt(value)) >=
                                  9223372036854775807
                              ) {
                                cb("입력하신 금액이 너무 큽니다.");
                              }
                            }
                            cb();
                          }
                        }
                      ]
                    })(
                      <InputNumber
                        min={0}
                        style={{ width: "200px" }}
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        onChange={value => {
                          this.onSelect(value, "marketCap");
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem label={<span>금액&nbsp;</span>}>
                    {getFieldDecorator("price", {
                      rules: [
                        {
                          required: false
                        },
                        {
                          validator: (rule, value, cb) => {
                            if (!registryData.coinType) {
                              cb("코인을 선택해주세요.");
                            } else {
                              if (
                                value &&
                                coinMultiFormat(
                                  parseInt(value),
                                  registryData.coinType
                                ) >= 9223372036854775807
                              ) {
                                cb("입력하신 금액이 너무 큽니다.");
                              }
                            }
                            cb();
                          }
                        }
                      ]
                    })(
                      <InputNumber
                        min={0}
                        // max={9223372036}
                        style={{ width: "200px" }}
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        onChange={value => {
                          this.onSelect(value, "price");
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem label={<span>가격설정&nbsp;</span>}>
                    <SelectBox
                      type="usePrice"
                      value={this.state.registryData.usePrice || 0}
                      style={{ width: "200px" }}
                      code={string.toggleCode}
                      codeString={string.activeHandlesCodes}
                      onChange={this.onSelect}
                    />
                    <div
                      style={{
                        color: "red",
                        marginTop: -10,
                        marginBottom: -20
                      }}>
                      입력하신 가격으로 사용하실려면 활성화를 선택해주세요.
                    </div>
                  </FormItem>
                  <FormItem label={<span>플랫 잔액&nbsp;</span>}>
                    {getFieldDecorator("balance", {
                      rules: [
                        {
                          required: false
                        },
                        {
                          validator: (rule, value, cb) => {
                            if (!registryData.coinType) {
                              cb("코인을 선택해주세요.");
                            } else {
                              if (
                                value &&
                                coinMultiFormat(
                                  parseInt(value),
                                  registryData.coinType
                                ) >= 9223372036854775807
                              ) {
                                cb("입력하신 금액이 너무 큽니다.");
                              }
                            }
                            cb();
                          }
                        }
                      ]
                    })(
                      <InputNumber
                        min={0}
                        // max={9223372036}
                        style={{ width: "200px" }}
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        onChange={value => {
                          this.onSelect(value, "balance");
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem label={<span>재단 코인 잔액&nbsp;</span>}>
                    {getFieldDecorator("foundationBalance", {
                      rules: [
                        {
                          required: false
                        },
                        {
                          validator: (rule, value, cb) => {
                            if (!registryData.coinType) {
                              cb("코인을 선택해주세요.");
                            } else {
                              if (
                                value &&
                                coinMultiFormat(
                                  parseInt(value),
                                  registryData.coinType
                                ) >= 9223372036854775807
                              ) {
                                cb("입력하신 금액이 너무 큽니다.");
                              }
                            }
                            cb();
                          }
                        }
                      ]
                    })(
                      <InputNumber
                        min={0}
                        // max={9223372036}
                        style={{ width: "200px" }}
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        onChange={value => {
                          this.onSelect(value, "foundationBalance");
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem label={<span>스테이블 코인여부&nbsp;</span>}>
                    <Switch
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      // checked={detailData.stableFlag}
                      onChange={checked => {
                        this.onSelect(checked, "stableFlag");
                      }}
                    />
                  </FormItem>
                  <FormItem label={<span>국가&nbsp;</span>}>
                    {getFieldDecorator("country", {
                      rules: [
                        {
                          required: true,
                          message: "국가를 입력해주세요!"
                        }
                      ]
                    })(
                      <Input
                        allowClear
                        style={{ width: "200px" }}
                        placeholder="KOREA"
                        onChange={e => {
                          this.onSelect(e.target.value, "country");
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem label={<span>웹사이트 링크&nbsp;</span>}>
                    {getFieldDecorator("websiteLink", {
                      rules: [
                        {
                          required: true,
                          message: "웹사이트 주소를 입력해주세요!"
                        }
                      ]
                    })(
                      <Input
                        allowClear
                        onChange={e => {
                          this.onSelect(e.target.value, "websiteLink");
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem label={<span>커뮤니티 링크&nbsp;</span>}>
                    {getFieldDecorator("communityLink", {
                      rules: [
                        {
                          required: true,
                          message: "커뮤니티 링크를 입력해주세요!"
                        }
                      ]
                    })(
                      <Input
                        allowClear
                        onChange={e => {
                          this.onSelect(e.target.value, "communityLink");
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem label={<span>백서&nbsp;</span>}>
                    {getFieldDecorator("whitepaperLink", {
                      rules: [
                        {
                          required: true,
                          message: "백서을 입력해주세요!"
                        }
                      ]
                    })(
                      <Input
                        allowClear
                        onChange={e => {
                          this.onSelect(e.target.value, "whitepaperLink");
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem label={<span>설명&nbsp;</span>}>
                    {getFieldDecorator("description", {
                      rules: [
                        {
                          required: true,
                          message: "설명을 입력해주세요!"
                        }
                      ]
                    })(
                      <Input.TextArea
                        style={{ minHeight: "160px", marginBottom: -20 }}
                        onChange={e => {
                          this.onSelect(e.target.value, "description");
                        }}
                      />
                    )}
                  </FormItem>
                </div>
              )}
            </Form>
          </div>
        </Modal>
      );
    }
  }
);
export default WalletRegistryModal;
