import React, { Component } from 'react'
import { httpGet, httpUrl,httpPut,httpDownload, httpPost, httpDelete } from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form,Divider,Icon } from 'antd'
import locale from "antd/es/date-picker/locale/ko_KR";
import fileDownload from "js-file-download";
import {
 formatDate,numberFormat,amountFormat,startDateFormat,endDateFormat 
  } from "../../util";
  import { showAlert } from '../../components/common/AlertModal';
  import string from "../../string";
  import SelectBox from "../../components/common/SelectBox";
  import { SwitcherOutlined,SearchOutlined } from "@ant-design/icons";
const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
class ExtraPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      selectedData: {},
      loading: false,
      searchText:"",
      searchType:"NAME",
      startDate: "",
      endDate: "",
      isLoaded: true,      
      pagination: {
        total: 0,
        current: 0,
      },
     extraPayType:"ALL",
      list: [],
      visible: false,
      approved:false,
     
    };


  }
 

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };
  onUpdateExtraPay = (params ={}) =>{
    let updateData = {
    idx: params.idx,
    paid: params.paid,
  
    };
    httpPut(httpUrl.updateExtraPay, [], updateData).then((res) => {
        this.getExtraPayList({
        pageNum: this.state.pagination.current,
      });
      showAlert("update");
  
    });
   };
   
   onUpdateExtraPay2 = (params ={}) =>{
    let updateData = {
    idx: params.idx,
    paid2: params.paid2,
  
    };
    httpPut(httpUrl.updateExtraPay2, [], updateData).then((res) => {
        this.getExtraPayList({
        pageNum: this.state.pagination.current,
      });
      showAlert("update");
  
    });
   };
   onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };


  handleChangeExtraPayType = (value) => {
    this.setState({
      extraPayType: value,

    });
  };


  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getExtraPayList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getExtraPayList({});

      }
    );
  };
 



  getExtraPayList = (params = {}) => {
    const{searchType,searchText,startDate,endDate,extraPayType} = this.state
 
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getExtraPayList, [10, pageNum,searchType,searchText,startDate,endDate,extraPayType],{})
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.extraPayList,
          pagination,
        })
      })

  }

  
  
  handleChangeDate = (date) => {
    // console.log(JSON.stringify(date))
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      // isLoaded: true,
    });
  };
 

  
  downloadExcel = () => {


    {
       if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
         httpDownload(
           httpUrl.extraPayListDownload,
           [            
             this.state.searchType,
             this.state.searchText,
             this.state.startDate,
             this.state.endDate,
             ],
           {}
         )
           .then((res) => {
             fileDownload(res, `지급승인관리 조회.xlsx`);
           })
           .catch((err) => { });
       }
     }
   };

  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  componentDidMount() {
    this.getExtraPayList();
  }
  

  render() {
    const {loading, selectedRowKeys ,extraPayType} = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        width: 100,
        render: (data) => 
        <div>{data}</div>    
      },      
      {
        title:"",
        key:"createDate",
        dataIndex:"createDate",      
        className:"support-column-number fs-15 lh-21",
        
        render:(data)=>
        <div style={{fontSize:12}}>{formatDate(data)}</div>
      },
      // {
        //   title:"",
        //   dataIndex: "regularMember",
        //   key:"regularMember",
        //   width:90,
        //   className: "support-column-number fs-15 lh-21",
        //   render: (data,row) => (
          //     <div style={{fontSize:13}}>
          //       {row.grade ===1 ? <div style={{fontSize:10.5,textAlign:"center"}}>딜러</div> : ""}
          //       {row.grade ===2 ?<div style={{fontSize:10.5 ,textAlign:"center"}}>대리점</div> : ""}
          //       {row.grade ===3 ? <div style={{fontSize:10.5 ,textAlign:"center"}}>지점</div> : ""}
          //       {row.grade ===4 ? <div style={{fontSize:10.5 ,textAlign:"center"}}>지사</div> : ""}
          //       {row.grade ===5 ? <div style={{fontSize:10.5 ,textAlign:"center"}}>본부</div> : ""}
      //       {data === 1 ? <div style={{fontWeight:600,border:'1px solid gray',borderRadius:'50px'}}>정회원</div> : <div style={{border:'1px solid #F6F6F6',borderRadius:'50px',color:'#AEAEAE'}}>소비자</div>}
      //    </div>
      //   )
      // },
      {
        title: "회원명",
        key: "userIdx",
        dataIndex: "userIdx",
        width:250,
        className: "support-column-number fs-15 lh-21",
        render:(data,row) =>
        <div>        
           {/* {row.regularMember == 1 ? "정회원"  : "소비자"}  */}
           {row.id} ({row.name})
        </div>
      },
      // {
        //   title: "회원이름",
        //   key: "name",
        //   dataIndex: "name",
        //   className: "support-column-number fs-15 lh-21",
        //   width:150,
        //   render:(data) =>
        //   <div>{data}</div>
        // },
        {
          title:"수당종류",
          key:"category",
          dataIndex:"category",
          width:170,
          className:"support-column-number fs-15 lh-21",
          render:(data,row)=> {
            if (data == "UPGRADE") return (<div>{string.extraPayCategoryTypeString[data]}</div>);
            if (data == "BONUS") return (<div><div>{string.extraPayCategoryTypeString[data]} </div>
         {/* <div>{row.grade  == 1 ? "딜러" : row.grade  == 2 ? "대리점"  :  row.grade  == 3 ? "지점" : row.grade  == 4 ? "지사" : row.grade  == 5 ? "본부" : ""}
                 &nbsp;({row.shareBonusCount} 
                 {row.grade  == 1 ? "/3 회차)" : row.grade  == 2 ? "/4 회차)"  :  row.grade  == 3 ? "/5 회차)" : row.grade  == 4 ? "/6 회차)" : row.grade  == 5 ? "/6 회차)" : ""} </div> */}
                 </div>); 
         if (data == "SALES") return (<div>{string.extraPayCategoryTypeString[data]}</div>);
         if (data == "SALES_PARENT") return (<div>{string.extraPayCategoryTypeString[data]}</div>);  
         if (data == "RANK") return (<div>{string.extraPayCategoryTypeString[data]}</div>); 
         if (data == "SALES_REORDER") return(<div>{string.extraPayCategoryTypeString[data]}</div>); 
         if (data == "SALES_REORDER_RECOMMEND") return(<div >{string.extraPayCategoryTypeString[data]}</div>); 
         else return (<div >{string.extraPayCategoryTypeString[data]}</div>); 
        }      
      },
      {
        title:"",
        key:"memo",
        dataIndex:"memo",        
        className:"support-column-number fs-15 lh-21",
        width:200,
        render:(data)=>
        <div align="left" style={{fontSize:11}}>{data}</div>
      },
       {
        title:"현금",
        key:"amountLss",
        dataIndex:"amountLss",          
        className:"support-column-number fs-15 lh-21",
        render:(data,row)=> {
          if (row.paid2 === 0 && row.category === "RANK" || row.paid2 === 0 && row.category === "BONUS" || row.paid2 === 0 && row.category === "SALES" || row.paid2 === 0 && row.category === "SALES_PARENT" || row.paid2 === 0 && row.category === "SALES_REORDER" || row.paid2 === 0 && row.category === "SALES_REORDER_RECOMMEND" ) return (
            <div style={{textAlign:"right"}}>&#8361; {amountFormat(data*100)}<div>        
         
              
       
          
            </div></div>);
//        if(row.paid2 === 1 && row.category === "RANK" || row.paid2 === 1 && row.category === "BONUS" || row.paid2 === 1 && row.category === "SALES" ) return (
//    <div >{amountFormat(data*100)} 원<div>
//    <Button
//            style={{fontSize:12,height:25,borderColor:"#FCB6BD",color:"#F81C1C"}}
//            onClick={() => {
//            if (global.confirm(row.idx + ' 번 현금지급을 취소하겠습니까?')){
//             this.onUpdateExtraPay2({
//                 idx: row.idx,
//                 paid2: 0,
//             });
//             }
//             }}>
//               취소
//           </Button></div></div>

// );
  if(row.paid2 === 1 && row.category === "RANK" || row.paid2 === 1 && row.category === "BONUS" || row.paid2 === 1 && row.category === "SALES"  || row.paid2 === 1 && row.category === "SALES_PARENT" || row.paid2 === 1 && row.category === "SALES_REORDER" || row.paid2 === 1 && row.category === "SALES_REORDER_RECOMMEND") return (
      <div style={{textAlign:"right"}}>&#8361; {amountFormat(data*100)} </div>);
      if(row.paid2 === 2 && row.category === "RANK" || row.paid2 === 2 && row.category === "BONUS" || row.paid2 === 2 && row.category === "SALES" || row.paid2 === 2 && row.category === "SALES_PARENT" || row.paid2 === 2 && row.category === "SALES_REORDER" || row.paid2 === 2 && row.category === "SALES_REORDER_RECOMMEND") return (
        <div style={{textAlign:"right"}}><del>&#8361; {amountFormat(data*100)}</del> </div>);    
  else return("-");


      }},

      
      {
        title:"",
        key:"paid2",
        dataIndex:"paid2",
        width:150,
        className:"support-column-number fs-15 lh-21",
        render:(data,row)=> {
        if (data === 0 && row.category === "SALES" || data === 0 && row.category === "SALES_PARENT" || data === 0 && row.category === "RANK" || data === 0 && row.category === "BONUS" || row.paid2 === 0 && row.category === "SALES_REORDER" || row.paid2 === 0 && row.category === "SALES_REORDER_RECOMMEND") return (<div  style={{color:"#9F9E9E",fontSize:12.5 }}>
           <Button
        style={{fontSize:12,height:25,width:100,color:"#148CFF",borderColor:"#148CFF",border:"1px solid #148CFF"}}
        onClick={() => {
        if (global.confirm(row.idx + ' 번을 현금지급 처리하시겠습니까?')){
         this.onUpdateExtraPay2({
             idx: row.idx,
             paid2: 1,
         });
         }
         }}>
          현금지급
       </Button>
       <Button
        style={{fontSize:12,height:25,width:100,color:"gray",borderColor:"gray"}}
        onClick={() => {
        if (global.confirm(row.idx + ' 번을 거절 처리하시겠습니까?')){
         this.onUpdateExtraPay2({
             idx: row.idx,
             paid2: 2,
         });
         }
         }}>
           지급거부
       </Button></div>);
        else if (data == 0 && row.category ==="UPGRADE" || data === 0 && row.category === "BUY") return ("");       
        if (data === 1 && row.category === "SALES" || data === 1 && row.category === "SALES_PARENT" || data === 1 && row.category === "RANK" || data === 1 && row.category === "BONUS" || row.paid2 === 1 && row.category === "SALES_REORDER" || row.paid2 === 1 && row.category === "SALES_REORDER_RECOMMEND") return (<div><b style={{color:'#148CFF'}}>지급완료</b>&nbsp;
        {/* <Button
        style={{fontSize:10,height:19,width:55,borderColor:"#FCB6BD",color:"#F81C1C"}}
        onClick={() => {
        if (global.confirm(row.idx + ' 번 현금지급을 취소하겠습니까?')){
         this.onUpdateExtraPay2({
             idx: row.idx,
             paid2: 0,
         });
         }
         }}>
           취 소
       </Button> */}
       <div style={{fontSize:11}}>{formatDate(row.updateDate2)}</div></div>);
       else if (data == 1 && row.category ==="UPGRADE" || data === 1 && row.category === "BUY" ) return (""); 
      
       if (data === 2 && row.category === "SALES" || data === 2 && row.category === "SALES_PARENT" || data === 2 && row.category === "RANK" || data === 2 && row.category === "BONUS"|| row.paid2 === 2 && row.category === "SALES_REORDER" || row.paid2 === 2 && row.category === "SALES_REORDER_RECOMMEND") return (<div  style={{fontSize:12.5}}><b style={{color:"gray"}}>거부완료</b>
       <div style={{fontSize:11, color:""}}>{formatDate(row.updateDate2)}</div></div>);
       else if (data == 2 && row.category ==="UPGRADE" || data === 2 && row.category === "BUY") return ("");  
      }
        
      },        
      {
        title:"포인트",
        key:"amountLss",
        dataIndex:"amountLss", 
        className:"support-column-number fs-15 lh-21",
        render:(data,row)=> {
         if (row.paid === 0) return (
          <div style={{textAlign:"right"}}>{amountFormat(data)} 포인트<div>
     
                  </div></div>)
         if (row.paid === 1) return (
         <div style={{textAlign:"right"}}> {amountFormat(data)} 포인트</div>
         )
         if (row.paid === 2) return (
          <div style={{textAlign:"right"}}> <del>{amountFormat(data)} 포인트 </del></div>
          )
         
        
        }
      },
    
      {
        title:"",
        key:"paid",
        dataIndex:"paid",
        width:150,
        className:"support-column-number fs-15 lh-21",
        render:(data,row)=> {
        if (data === 0) return (<div style={{color:"#9F9E9E",fontSize:12.5}}>
              <Button
                   style={{fontSize:12,height:25,width:100,color:"green",borderColor:"green",border:"1px solid green"}}
                   onClick={() => {
                   if (global.confirm(row.idx + ' 번 포인트를 지급하시겠습니까? 한번 지급하면 되돌릴 수 없습니다.')){
                    this.onUpdateExtraPay({
                        idx: row.idx,
                        paid: 1,
                    });
                    }
                    }}>
                     포인트지급
                  </Button>
                  <Button
                   style={{fontSize:12,height:25,width:100,color:"gray",borderColor:"gray"}}
                   onClick={() => {
                   if (global.confirm(row.idx + ' 번을 거절 처리하시겠습니까?')){
                    this.onUpdateExtraPay({
                        idx: row.idx,
                        paid: 2,
                    });
                    }
                    }}>
                      지급거부
                  </Button>
        </div>);       
        if (data === 1) return (<div><b style={{color:'green'}}>지급완료</b>&nbsp;
        {/* <Button
          style={{fontSize:10,height:19,width:55,borderColor:"gray",color:"gray"}}
            onClick={() => {
              if (global.alert('처리가 완료된 건입니다.')){
             
               }
               }}
        >완 료</Button> */}
        <div style={{fontSize:11}}>{formatDate(row.updateDate)}</div></div>);
        if (data === 2) return (<div style={{fontSize:12.5}}><b style={{color:"gray"}}>거부완료</b>
        <div style={{fontSize:11, color:"gray"}}>{formatDate(row.updateDate)}</div></div>);    
        }
        
      },
      // {
      //   title: "구매번호",
      //   key: "purchaseIdx",
      //   dataIndex: "purchaseIdx",
      //   className: "support-column-number fs-15 lh-21",
      //   width: 100,
      //   render: (data) => 
      //   <div>{data}</div>    
      // },      

     
      
      // {
      //   title:"페이",
      //   key:"amountLssp",
      //   dataIndex:"amountLssp",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data)=>
      //   <div>{amountFormat(data)}</div>
      // },
      // {
      //   title:"페이(락)",
      //   key:"amountLsspl",
      //   dataIndex:"amountLsspl",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data)=>
      //   <div>{amountFormat(data)}</div>
      // },  
      // {
      //   title:"지급예정일",
      //   key:"payDate",
      //   dataIndex:"payDate",
      //   width:150,
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data)=>
      //   <div>{data}</div>
      // },
      
      // {
      //   title:"실지급일",
      //   key:"updateDate",
      //   dataIndex:"updateDate",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data)=>
      //   <div>{formatDate(data)}</div>
      // },
      // {
      //   title:"실지급일2",
      //   key:"updateDate2",
      //   dataIndex:"updateDate2",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data)=>
      //   <div>{formatDate(data)}</div>
      // },
      // {
      //   title:"승인여부",
      //   key:"approved",
      //   dataIndex:"approved",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data)=> {
      //    if (data === 0) return (<div>미 승인</div>);
      //    else return (<div style={{color:'green'}}><b>승인</b></div>);
      //   }
        
      // },
      // {
      //   title:"지급여부",
      //   key:"paid",
      //   dataIndex:"paid",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data)=> {
      //   if (data === 0) return (<div>미 처리</div>);
      //   if (data === 1) return (<div style={{color:'green'}}><b>코인지급</b></div>);
      //   else return (<div style={{color:'red'}}>현금지급</div>);        
      //   }
        
      // },
     
      // {
      //   title:"",
      //   key:"",
      //   dataIndex:"",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data,row)=> {
      //     if (row.paid == 0) return (
      //           <div>
      //            <Button
      //              onClick={() => {
      //              if (global.confirm(row.idx + ' 번 코인을 지급하시겠습니까? 한번 지급하면 되돌릴 수 없습니다.')){
      //               this.onUpdateExtraPay({
      //                   idx: row.idx,
      //                   paid: 1,
      //               });
      //               }
      //               }}>
      //                 코인지급
      //             </Button>
      //            <Button
      //              onClick={() => {
      //              if (global.confirm(row.idx + ' 번을 현금지급 처리하시겠습니까?')){
      //               this.onUpdateExtraPay({
      //                   idx: row.idx,
      //                   paid: 2,
      //               });
      //               }
      //               }}>
      //                 현금지급
      //             </Button>
      //           </div>);
      //     else if (row.paid == 2) return (
      //       <div>
      //        <Button
      //          onClick={() => {
      //          if (global.confirm(row.idx + ' 번 현금지급을 취소하겠습니까?')){
      //           this.onUpdateExtraPay({
      //               idx: row.idx,
      //               paid: 0,
      //           });
      //           }
      //           }}>
      //             현금지급취소
      //         </Button>
      //       </div>);     

      //   }
      
       
      // },
    
     
      
   
   

    ];




    return (
      
      <div>
        
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
        <Divider orientation="left" style={{fontSize:21.5}}>지급승인관리 <span style={{fontSize:7}}>L7 STEM</span> </Divider>  
        
         

          
        </div>
        <Button  onClick={this.start} disabled={!hasSelected} loading={loading}>
                  <SwitcherOutlined/>선택해제
          </Button>
                <span style={{ marginLeft: 10 ,color:"#148CFF"}}>
                  {hasSelected ? `${selectedRowKeys.length} 개 선택` : ''}
                </span>
              <div className="extrapay-pay">
                
             
          <Button style={{color:"#148CFF",border:"1.5px solid #148CFF"}}
          onClick={() => {

             
            if (global.confirm('선택한 행들의 현금지급 처리 하시겠습니까?')) {
              httpPut(httpUrl.updateExtraPayMulti, [], {idxs: selectedRowKeys}).then((res) => {
                this.getExtraPayList({
                  pageNum: this.state.pagination.current,
                });
                showAlert("update");
              });
            }

          }}
          >
          현금지급</Button>&nbsp; 

          <Button style={{borderColor:"gray",color:"gray"}}
          onClick={() => {             
            if (global.confirm('선택한 행들의 현금지급을 취소 하시겠습니까?')) {
              httpPut(httpUrl.updateExtraPayCancelMulti, [], {idxs: selectedRowKeys}).then((res) => {
                this.getExtraPayList({
                  pageNum: this.state.pagination.current,
                });
                showAlert("update");
              });
              
             
            }

          }}
          >
          
          현금 초기화</Button>&nbsp;    

          <Button style={{color:"green",border:"1.5px solid green"}}
          onClick={() => {             
            if (global.confirm('선택한 행들의 포인트를 지급하시겠습니까? 한번 지급하면 되돌릴 수 없습니다. ( * 이미 지급된 건은 중복 지급되지 않습니다.)')) {
              httpPut(httpUrl.updateExtraPayPointMulti, [], {idxs: selectedRowKeys}).then((res) => {
                this.getExtraPayList({
                  pageNum: this.state.pagination.current,
                });
                showAlert("update");
              });
            }

          }}
         >
          포인트지급</Button>    &nbsp;     
           
          
        <div className="extrapay-excel">
          <Button onClick={this.downloadExcel}>        
        <Icon type="download" style={{ fontSize: "20px" }} />
          엑셀 다운로드
          </Button>
          </div>
        
        
        <div className="extrapay-search">


         <label>기간검색  &nbsp;</label>
          <RangePicker
          locale={locale}
          onChange={(date) => {
            this.handleChangeDate(date);
          }}
        />  &nbsp;
        <label htmlFor="">수당종류
        <SelectBox
            value={string.extraPayCategoryTypeString[extraPayType]}
            code={string.extraPayCategoryTypeCode}
            codeString={string.extraPayCategoryTypeString}
            onChange={(value) => {
              if (value !== extraPayType) {
                this.handleChangeExtraPayType(value);
              }
            }}
            style={{ width: "170px", marginLeft: "10px" }}
          />
        </label>
        <SelectBox
             style={{width:80}}
             value={this.state.searchType}
             code={string.extraPayCode}
             codeString={string.extraPayTypeString}
             onChange={(value) => {
               if (value !== this.state.searchType) {
                 this.searchTypeHandleChange(value);
               }
             }}
          /> 
          
          <Search
          placeholder={`검색어를 입력해주세요.`}
          enterButton={'검색'}
          allowClear
          onSearch={this.onSearch}
          style={{ width: 250 }}
        />
       
          &nbsp;
          </div>
          </div>
        <Table
          rowSelection={rowSelection} columns={columns}
          rowKey={(record) => record.idx}
          column={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px'}}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
          

      </div>
    )


  }
}

export default ExtraPay;
