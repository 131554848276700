import React, { Component } from "react";

import {
  Form,
  Modal,
  Input,
  Button,

} from "antd";
import { httpPost, httpPut, httpUrl, serverUrl } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";
import { InputNumber } from 'antd';

const FormItem = Form.Item;
const PaybackUpdate = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
       paybackRatio:"",
  
      };
        
    }

  


    onUpdate = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        let updateData = {
          ...values,
         
        };
      
        if (global.confirm("변경하시겠습니까?"))
        httpPost(httpUrl.paybackRatioUpdate,[],updateData)
           .then(res => {
            if (res.data) {
              this.props.onOk();
              form.resetFields();
            showAlert("update");
  
          } else {
                  
            alert("알수없는 오류가 발생하였습니다.");
          
        }
      })
      .catch((error) => {
        alert("알수 없는 오류가 발생하였습니다.");

      });

});
};


    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
      
    };

    componentDidMount() {}

    render() {
      const {
      
      } = this.state;
      const { visible, form, defaultValue, color } = this.props;
      const { getFieldDecorator } = form;
      
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      

      return (
        <Modal
          visible={visible}
          title="페이백 변경"
          okText="변경"
          cancelText="취소"
          onOk={this.onUpdate}
          onCancel={this.onCancel}
          style={{ minWidth: "300px" }}>
         
         <Form {...formItemLayout} onSubmit={this.onUpdate} >
        
        <FormItem>
          {getFieldDecorator("paybackRatio", {
            initialValue:0,
            rules: [
              {                
                message: "비율을 설정하세요 ex)페이백  0.3 = 30%",                
              },
            ]
            
          })(
          
          // <Input placeholder="예)30% => 0.3 , 50% => 0.5 설정" maxlength="20"/>
          <InputNumber
          style={{
          
            fontSize:45,
            width:160,
            height:80
          }}
          size="large"
          defaultValue="0"
          min="0"
          max="10"
          step="0.1"
          // onChange={onChange}
          stringMode
        />
          
          )}
        </FormItem>   
           
    
          </Form>
        </Modal>
      );
    }
  }
);

export default PaybackUpdate;


