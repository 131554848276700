import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Input, Select } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import SelectBox from "../../components/common/SelectBox";
import { formatDate, amountFormat } from "../../util";
import string from "../../string";

const Search = Input.Search;
const Option = Select.Option;

export default class PaybackLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      franchiseList: [],
      fCoinList: [],

      logList: [],
      pagination: {
        total: 0,
        current: 0
      },
      coinType: 0,
      searchType: "USER_ID",
      searchText: ""
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getPaybackLog({
      pageNum: pager.current
    });
  };

  coinTypeHandleChange = value => {
    this.setState({
      coinType: value
    });
  };

  searchTypeHandleChange = value => {
    this.setState({
      searchType: value
    });
  };

  onSearch = value => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getPaybackLog({ pageNum: 1 });
      }
    );
  };

  getPaybackLog = (params = {}) => {
    const { coinType, searchType, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getPaybackLog,
      [10, pageNum, coinType, searchType, searchText],
      {}
    )
      .then(result => {
        // console.log(JSON.stringify(result, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = result.data.currentPage;
        pagination.total = result.data.totalCount;
        this.setState({
          logList: result.data.log,
          pagination
        });
      })
      .catch(error => {});
  };

  componentDidMount() {
    httpGet(httpUrl.franchiseCoinType, [], {}).then(res => {
      this.setState({
        franchiseList: res.data,
        fCoinList: res.data.map(item => {
          return item.coinType;
        })
      });
      this.getPaybackLog();
    });
  }

  renderOption = () => {
    const { franchiseList, fCoinList } = this.state;
    let result = [];
    for (let i = 0; i < franchiseList.length; i++) {
      for (let j = 0; j < fCoinList.length; j++) {
        if (franchiseList[i].coinType === fCoinList[j]) {
          result.push(
            <Option
              value={franchiseList[i].coinType}
              key={`${franchiseList[i]}${i}`}>
              {franchiseList[i].name}
            </Option>
          );
        }
      }
    }
    return result;
  };

  render() {
    const { coinType, searchType, logList, pagination } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const logColums = [
      {
        title: "",
        dataIndex: "idx",
        // width: 80,
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div key={`createDate${index}`}>{formatDate(data)}</div>
        )
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "가맹점 아이디",
        dataIndex: "franchiseId",
        // width: 80,
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "유저 아이디",
        dataIndex: "userId",
        // width: 80,
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "비율",
        dataIndex: "ratio",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{`${data * 100} %`}</div>
      }
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          페이백 내역
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div
          className="flex-row flex-center flex-sb"
          style={{
            marginBottom: "10px"
          }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            코인{" "}
            <Select
              placeholder="선택해주세요."
              value={coinType}
              onChange={value => {
                if (value !== coinType) this.coinTypeHandleChange(value);
              }}
              style={{ width: "160px" }}>
              {this.renderOption()}
            </Select>
          </label>
          <div className="flex-row flex-center">
            <label htmlFor="" style={{ marginRight: "20px" }}>
              검색 조건{" "}
              <SelectBox
                value={string.paybackString[searchType]}
                code={string.paybackCode}
                codeString={string.paybackString}
                onChange={value => {
                  if (value !== searchType) this.searchTypeHandleChange(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
            <label htmlFor="" style={{ marginRight: "10px" }}>
              검색
            </label>
            <Search
              placeholder="검색어를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={value => {
                if (coinType === undefined) alert("코인을 선택해주세요.");
                else this.onSearch(value);
              }}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={record => record.idx}
          columns={logColums}
          dataSource={logList}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
