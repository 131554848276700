import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, DatePicker, Button } from "antd";

import { getUserStatistics } from "../../contexts/asyncContext";
import { numberFormat, dayFormat, statFormat } from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";

const { RangePicker } = DatePicker;

class UserStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 0
      },
      startDate: "",
      endDate: ""
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getUserStatistics({
      pageSize: 10,
      pageNum: pager.current,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });
  };

  handleChangeDate = value => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : ""
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  onSearch() {
    this.props.getUserStatistics({
      pageSize: 10,
      pageNum: 1,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getUserStatistics({
      pageSize: 10,
      pageNum: 1,
      startDate: "",
      endDate: ""
    });
  }

  render() {
    const statisticsColumns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        // key: 'statDate',
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{dayFormat(data)}</div>
      },
      {
        title: "회원가입한 회원",
        dataIndex: "joinUser",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "탈퇴한 회원",
        dataIndex: "exitUser",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "총 회원 수",
        dataIndex: "totalUser",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          회원 통계
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div className="flex-row flex-center m-b-10" style={{ float: "right" }}>
          <label>검색기간&nbsp;</label>

          <RangePicker
            locale={locale}
            onChange={date => {
              this.handleChangeDate(date);
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              this.onSearch();
            }}>
            검색
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="admin-table"
          // rowKey={record => record.statDate}
          columns={statisticsColumns}
          dataSource={this.props.result.userList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getUserStatistics: ({ pageSize, pageNum, startDate, endDate }) =>
      dispatch(getUserStatistics({ pageSize, pageNum, startDate, endDate }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(UserStatistics);
