import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Table, Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Icon, Checkbox, InputNumber } from "antd";
import { httpGet, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
  amountFormat,
  coinMultiFormat
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import { reactLocalStorage } from "reactjs-localstorage";

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;

class ArbitrageSettingUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tables: [],
      userId: '',
    }
  }
  
  componentDidMount() {
    this.getArbitrageSettingUserList();
    this.props.setRefresh(this.getArbitrageSettingUserList);
  }

  addUser = () => {
    httpGet(httpUrl.userIdx, [this.state.userId], {}).then((res) => {
      if (res.data == 0) {
        alert('없는 사용자입니다.')
      }
      else {
        httpPost(httpUrl.arbitrageUserCreate, [], {coinType: this.props.data.coinType, userIdx: res.data}).then((res) => {
          if (res.data !== 0) {
            this.getArbitrageSettingUserList();
            this.setState({userId: ''})
          } else {
            alert('사용자 추가에 실패하였습니다.')
          }
        }).catch(()=>{
          alert('사용자 추가에 실패하였습니다.')
        });

      }
    });
  }

  getArbitrageSettingUserList = () => {
    if (!this.props.data) return;
    httpGet(httpUrl.arbitrageUserList, [this.props.data.coinType], {}).then((res) => {
      this.setState({
        tables: res.data,
      });
    });
  };

  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const { visible, onCancel, form } = this.props;
    const { getFieldDecorator } = form;
        
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    
    const columns = [
      {
        title: "아이디",
        dataIndex: "userId",
        className: "support-column-number fs-15 lh-21",
      },
      {
        dataIndex: "userIdx",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
            <Button
              onClick={() => {
                if (global.confirm('삭제하시겠습니까?')) {

                  httpPost(httpUrl.arbitrageUserDelete, [], {coinType: this.props.data.coinType, userIdx: data}).then((res) => {
                    if (res.data !== 0) {
                      this.getArbitrageSettingUserList();
                    } else {
                      alert('사용자 삭제에 실패하였습니다.')
                    }
                  }).catch(()=>{
                    alert('사용자 삭제에 실패하였습니다.')
                  });
                }
        
              }}>
              삭제
            </Button>
      },
    ];

    return (
      <Modal
        visible={visible}
        title="자전수행 사용자 설정"
        cancelText="닫기"
        onCancel={onCancel}
        style={{ minWidth: "800px" }}
        footer={null}>
        <div>
            <Descriptions
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>

              <Ditems label="코인종류">
                {this.props.data && coinList.find((coin) => coin.coinType == this.props.data.coinType) ? coinList.find((coin) => coin.coinType == this.props.data.coinType).name : ''}
              </Ditems>

              </Descriptions>
        </div>
        <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
          <Input 
            style={{width: '80%'}}
            placeholder="아이디입력"
            onPressEnter={this.addUser}
            value={this.state.userId}
            onChange={(e)=>{this.setState({userId: e.target.value})}}
            />
          <Button style={{float: 'right'}} onClick={this.addUser}>사용자추가</Button>
        </div>
        <div>
          <Table
            rowKey={(record) => record.userIdx}
            dataSource={this.state.tables}
            columns={columns}
          />
        </div>
      </Modal>
    );
  }
}
export default Form.create()(ArbitrageSettingUser)