import React, { Component } from 'react'
import { httpGet, httpUrl,httpPost, httpDelete ,makeUrl} from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form,Divider,Icon,Upload,Modal } from 'antd'
import { showAlert } from "../../components/common/AlertModal";
import {
  formatDate, 
} from "../../util";
import { Alert } from 'antd';
import Marquee from 'react-fast-marquee';
import DailyRegistry from "../../components/system/DailyRegistry";
import PaybackUpdate from "../../components/system/PaybackUpdate";
const Search = Input.Search; 
const DailyAgreeMent = Form.create()(
class DailyAgreeMent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isLoaded: true,
      selectedRowKeys: [], // Check here to configure the default column
      selectedData: {},
      searchText:"",
      pagination: {
        total: 0,
        current: 0,
      },
      loading:false,
    
      list: [],
      visible: false,
      dailyVisible: false,
      paybackRatioVisible:false,
    };


  }
 

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };


  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };


  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getDailyAgreeMentList({
      pageNum: pager.current,
    });
  };

  



  getDailyAgreeMentList = (params = {}) => {
    const{searchText} = this.state
 
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getDailyAgreeMent, [10, pageNum,searchText])
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.dailyAgreeMentList,
          pagination,
        })
      })

  }


  
  onDeleteDaily=(address)=>{
 
    httpPost(httpUrl.DailyDelete,[],address).then((res) => {
    this.getDailyAgreeMentList({
      pageNum: this.state.pagination.current,
    });
    showAlert("update");
  })
  };


  // onDeleteDailySelective=()=>{

  //   httpPost(httpUrl.DailyDeleteSelective,[],{address:selectedRowKeys}).then((res) => {
  //   this.getDailyAgreeMentList({
  //     pageNum: this.state.pagination.current,
  //   });
  //   showAlert("delete");
  // })
  // };

    
  onDeleteDailyAll=()=>{
 
    httpPost(httpUrl.DailyDeleteAll,[]).then((res) => {
    this.getDailyAgreeMentList({
      pageNum: this.state.pagination.current,
    });
    showAlert("delete");
  })
  };

 

  componentDidMount() {
    this.getDailyAgreeMentList();
  }
  
    
      
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        // isLoaded: false,
      },
      () => {
        this.getDailyAgreeMentList();

      }
    );
  };

  render() {
  
    const { loading, paybackRatioVisible, selectedRowKeys} = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const props = {
      action: `${makeUrl(httpUrl.dailyCreateExcel, null)}`,
      withCredentials: true,
      multiple: false,
      beforeUpload: file => {
        if (global.confirm("등록 하시겠습니까?")) {
          return true;
        } else {
          return false;
        }
      },
      onSuccess: file => {
        var success = file.data.success;
        var fail = file.data.fail;
        var total = success + fail;

        var s =
          "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
        Modal.info({
          title: "등록결과",
          content: (
            <div>
              <p>
                {file.data.failMessages.length > 0 ? (
                  <div>
                    <div>{`${s}`}</div>
                    <br />
                    {`- 실패한 등록\n`}
                    {file.data.failMessages.length > 0 &&
                      file.data.failMessages.map(value => (
                        <div>{value}</div>
                      ))}
                  </div>
                ) : (
                  <div>{s}</div>
                )}
              </p>
            </div>
          ),
          onOk() { }
        });
        this.getDailyAgreeMentList();
      },
      onError(err) {
        Modal.error({
          title: "등록결과",
          content: "파일 업로드 실패"
        });
      }
    };


    const columns = [      
      {
        title: "이름",
        key: "name",
        dataIndex: "name",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "아이디",
        key: "id",
        dataIndex: "id",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "지갑주소",
        key: "address",
        dataIndex: "address",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "시리얼",
        key: "serial",
        dataIndex: "serial",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },    
      {
        title: "생성일",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{formatDate(data)}</div>    
      },
      {
        title:"",
        key:"",
        dataIndex:"",
        className:"support-column-number fs-15 lh-21",
        render:(row)=>
        <div><Button 
          style={{color:"brown",borderColor:"brown"}}
          onClick={() => {
          if(global.confirm("이름: "+row.name+"  "+"아이디:" + row.id+ '데일리 지급처리하시겠습니까?')){
           this.onDeleteDaily(row.address);
          }
        }}>
          지급
          </Button></div>
      },
     
    

    ];




    return (
      
      <div>
         <Alert    
            style={{
              width:350,
                     
            }}               
            message={
            <Marquee pauseOnHover gradient={false} style={{fontSize:12}}>
              등록된 회원은 데일리가 지급되지 않습니다.    
            </Marquee>
            }
            type="warning"
            banner            
            closable />
       
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",         
          }}>
           <Divider orientation="left" style={{fontSize:23}}>
            데일리 세팅&nbsp;
            <span style={{fontSize:7}}>L7 STEM</span>
           </Divider>  
         
           <div style={{textAlign:"right"}}>             
           <span>미지급자 수: 명</span> 
           
            </div> 
            
        </div>
        <div style={{
          position:'absolute',
          top:200
        }}>    
        
          <Button  onClick={this.start} disabled={!hasSelected} loading={loading}>
                 체크박스 초기화
          </Button>
            {/* &nbsp; {hasSelected ? `${selectedRowKeys.length} 개 선택` : ''} */}
          </div>
        {/* 메모입력,수정 Modal */}
          <div>
       
             </div>
          <div>
          <Button onClick={()=>{this.setState({dailyVisible: true})}} type="primary">등록</Button>&nbsp;  
              <Upload {...props} accept=".xlsx, .xls" showUploadList={false}>
                <Button style={{ marginRight: "10px" ,color:"green",borderColor:"green" }}>
                  <Icon type="upload" style={{ fontSize: "20px" }} />
                  엑셀파일로 등록
                </Button>
              </Upload>
                <a href={"/daily_create_template.xlsx"} download>
                <Button>
                  <Icon type="download" style={{ fontSize: "20px" }} />
                  엑셀양식
                </Button>
              </a>        
              &nbsp;
           &nbsp;
          <Button style={{color:'brown',borderColor:'brown'}}
           onClick={() => {             
            if (global.confirm('지급하시겠습니까?')) {
              httpPost(httpUrl.DailyDeleteSelective, [], {address: selectedRowKeys}).then((res) => {
                this.getDailyAgreeMentList({
                  pageNum: this.state.pagination.current,
                });
                showAlert("update");
              });
            }

          }}
        
        >선택지급
        </Button> &nbsp; 
                   
              <Button
         style={{borderColor:'brown',color:'white',backgroundColor:'brown'}}
         onClick={() => {
           if(global.confirm('정말 초기화 하시겠습니까?'))
           {
             this.onDeleteDailyAll();
            }
          }}
          >전체지급</Button>    
                
        <Search
          placeholder={`이름,주소를 입력해주세요.`}
          enterButton={'검색'}
          allowClear
          onSearch={this.onSearch}
          style={{ width: 400 ,position:"absolute",left:1440}}
        />
     
       
          </div>

        
        <Table 
          rowSelection={rowSelection}  column={columns}
          rowKey={(record) => record.address}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px' }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
        
           <DailyRegistry 
            visible={this.state.dailyVisible}
            onOk={() => {
            this.setState({
             dailyVisible: false
            });
            this.onSearch('');
          }}
          onCancel={() => {
            this.setState({
              dailyVisible: false
            });
          }}
            
           />  
           <PaybackUpdate 
            visible={this.state.paybackRatioVisible}
            onOk={() => {
            this.setState({
             paybackRatioVisible: false
            });
            this.onSearch('');
          }}
          onCancel={() => {
            this.setState({
              paybackRatioVisible: false
            });
          }}
            
           /> 
     

      </div>
    )


  }
}
);
export default DailyAgreeMent;
