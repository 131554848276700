import React, { Component } from 'react'
import { Table, Input, Button, DatePicker,Icon,Divider } from 'antd'
import { httpGet, httpUrl, httpDownload } from '../../api/httpClient';
import { formatDate, numberFormat, startDateFormat, endDateFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import fileDownload from "js-file-download"
import locale from "antd/es/date-picker/locale/ko_KR";
import { SearchOutlined, SelectOutlined } from '@ant-design/icons';


const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

export default class DepositRequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletedSearchType: false,
      startDate: "",
      endDate: "",
      searchType: "NAME",
      searchText: "",
      isLoaded: true,
      statusType: this.props.statusType || "ALL",
      pagination: {
        total: 0,
        current: 0,
      },
      list: [],
    };
  }

  handleChangeDate = (date) => {
    // console.log(JSON.stringify(date))
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      // isLoaded: true,
    });
  };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getDepositRequestList({
      pageNum: pager.current,
    });
  };

  getDepositRequestList = (params = {}) => {
    const { searchType, searchText, statusType, startDate, endDate } = this.state
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getDepositRequestList, [10, pageNum, searchType, searchText, statusType, startDate, endDate], {}).then(res => {
      // console.log(`data = ${JSON.stringify(res.data, null,4)}`)
      const pagination = { ...this.state.pagination }
      pagination.total = res.data.totalCount
      this.setState({
        list: res.data.depositRequestList,
        pagination,
      })
    })
  }

  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getDepositRequestList();
      }
    );
  };

  componentDidMount() {
    this.getDepositRequestList();

    // (this.props.type) => 'popup'
  }

  downloadExcel = () => {



    if (this.state.isLoaded) {
      alert("검색버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.depositRequestListDownload,
          [
            this.state.searchType,
            this.state.searchText,
            this.state.statusType,
            this.state.startDate,
            this.state.endDate,
          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `충전신청목록 조회.xlsx`);
          })
          .catch((err) => { });
      }
    }
  };


  render() {
    const { statusType } = this.props;
    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: data => (
        <div style={{fontSize:13}}>{data}</div>
          )
      },
      statusType ? {} : {
        title: "회원번호",
        key: "userIdx",
        dataIndex: "userIdx",
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div style={{fontSize:13}}>{data}</div>
            )
      },
      {
        title: "아이디",
        key: "id",
        dataIndex: "id",
        className: "support-cloumn-number fs-15 lh-21",
        render: data => (
          <div style={{fontSize:13}}>{data}</div>
            )
      },
      {
        title: "이름",
        key: "name",
        dataIndex: "name",
        className: "support-cloumn-number fs-15 lh-21",
        render: data => (
          <div style={{fontSize:13}}>{data}</div>
            )
      },
      {
        title: "전화번호",
        key: "phone",
        dataIndex: "phone",
        className: "support-cloumn-number fs-15 lh-21",
        render: data => (
          <div style={{fontSize:13}}>{data}</div>
            )
      },
      {
        title: "금액",
        key: "amount",
        dataIndex: "amount",
        className: "support-cloumn-number fs-15 lh-21",
        width:100,
        render: (data, row) => <div style={{fontSize:13 ,textAlign:"right"}}>{numberFormat(data)}</div>,
      },
      {
        title: "충전코드",
        key: "code",
        dataIndex: "code",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div style={{fontSize:13}}>{data}</div>,
      },
      {
        title: "상태",
        key: "status",
        dataIndex: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data) => {
          if (data == "COMPLETE") return (<div style={{ color: '#459B40' ,fontSize:13 }}>{string.requestChargeString[data]}</div>);
          else return (<div style={{fontSize:13}}>{string.requestChargeString[data]}</div>);
        },
      },
      {
        title: "요청 날짜",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontSize:13}}>{formatDate(data)}</div>
      },
      !statusType ? {} : {

        title: "",
        dataIndex: "log",
        key: "log",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div key={`log${index}`}>

            <Button
              onClick={() => {
                if (global.confirm(row.idx + '번 충전신청으로 처리하시겠습니까?')) {
                  this.props.onSelect(row.idx);
                }
              }}>
              <SelectOutlined/>선택
              </Button>
          </div>
        )
      }
    ];

    return (
      <div>
        {!this.props.statusType && (
          <div
            style={{
              fontSize: "20px",
              color: "#000",
              textAlign: "center",
              marginBottom: "60px",
              fontWeight: "bold",
            }}>
             <Divider orientation="left" style={{fontSize:23}}>충전신청목록 </Divider>   
          </div>
        )}
        {this.props.statusType && (
          <div>
            <div
              style={{
                fontSize: "20px",
                color: "#000",
                textAlign: "center",
                marginBottom: "30px",
                fontWeight: "bold",
                fontFamily:"serif"
              }}>
              충전신청목록
            </div>

            <div style={{
              fontSize: "14px",
              color: "#ff0000",
              marginBottom: "10px",
            }}>
              은행 오입금에 대한 적합한 충전신청 항목을 선택하면 해당 사용자에게 충전이 진행됩니다.<br />
        입금금액과 충전신청 금액이 다른 경우 입금금액을 기준으로 처리됩니다.
        </div>
          </div>
        )}
<div className="flex-row flex-center flex-sb m-b-10">

<Button onClick={this.downloadExcel}>
   <Icon type="download" style={{ fontSize: "20px" }} />엑셀 다운로드
   </Button>
<div className="flex-row flex-center">
        요청날짜&nbsp;
        <RangePicker
          locale={locale}
          onChange={(date) => {
            this.handleChangeDate(date);
          }}
        />
        &nbsp;
        <label htmlFor="searchType" style={{ }}></label>
        <SelectBox
          value={this.state.searchType}
          code={string.depositRequestSearchTypeCode}
          codeString={string.depositRequestSearchTypeString}
          onChange={(value) => {
            if (value !== this.state.searchType) {
              this.searchTypeHandleChange(value);
            }
          }}
        />
        &nbsp;
        <Search
          placeholder={`검색어를 입력해주세요.`}
          enterButton={<SearchOutlined/>}
          allowClear
          onSearch={this.onSearch}
          style={{ width: 300}}
        />

        
        </div>
     
</div>

        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px' }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}

        />
      </div>



    )
  }
}
