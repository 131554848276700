import { Button, DatePicker, Input, Table } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { StakingCoinModify, StakingCoinRegist } from "..";
import { httpGet, httpPost, httpUrl } from "../../api/httpClient";
import { formatDate } from "../../util";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class StakingCoinList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stakingCoinList: [],
      stakingCoinRegistVisible: false,
      stakingCoinModifyVisible: false,
      selectedRow: null,
      selectedCoinName: null,
    };
  }

  componentDidMount() {
    this.getCoinList();
  }
  getCoinList = () => {
    httpGet(httpUrl.stakingCoinList,[],{}).then((res) => {
      this.setState({
        stakingCoinList: res.data,
      });
    });
  };
  deleteCoin = (idx) => {
    if (global.confirm("코인타입 "+idx+"을 스테이킹 코인에서 제외하시겠습니까??")) {
      httpPost(httpUrl.stakingCoinDelete, [idx], {}).then((res) => {
        if (res.data) {
          this.getCoinList();
        } else {
          alert("알수없는 문제로 삭제에 실패하였습니다..");
        }
      });
    }
  }
  render() {
    const { stakingCoinList } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const columns = [
      {
        title: "코인번호",
        dataIndex: "coinType",
        key: "coinType",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "코인이름",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (<div style={{cursor: 'pointer', color: 'blue'}} onClick={()=>{
          this.setState({
            selectedRow:row,
            selectedCoinName:coinList.find((coin) => coin.coinType === data).name,
            stakingCoinModifyVisible: true
          });
        }}>{coinList.find((coin) => coin.coinType === data).name}</div>)
      },
      {
        title: "입금락기간(일)",
        dataIndex: "lockDay",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "생성일",
        dataIndex: "createDate",
        key: "createDate",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "삭제",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => {
                this.deleteCoin(data);
              }}>
              삭제
            </Button>
          </div>
        ),
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          스테이킹 코인목록
      </div>
        <div className="wrapper mt-35 width-1240" />
        <div>
          <Button
            onClick={() => {
              this.setState({ stakingCoinRegistVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
        </div>
        <Table
          rowKey={(record) => record.coinType}
          dataSource={stakingCoinList}
          columns={columns}
        />
        <StakingCoinRegist
          visible={this.state.stakingCoinRegistVisible}
          stakingCoinList={this.state.stakingCoinList}
          onCancel={() => {
            this.setState({ stakingCoinRegistVisible: false });
          }}
          onOk={() => {
            this.setState({ stakingCoinRegistVisible: false });
            this.getCoinList();
          }}
        />
        <StakingCoinModify
          visible={this.state.stakingCoinModifyVisible}
          stakingCoin={this.state.selectedRow}
          stakingCoinName={this.state.selectedCoinName}
          onCancel={() => {
            this.setState({ stakingCoinModifyVisible: false });
          }}
          onOk={() => {
            this.setState({ stakingCoinModifyVisible: false });
            this.getCoinList();
          }}
        />
      </div>
    );
  }
}

export default StakingCoinList;
