import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, Input, Button, DatePicker } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpUrl, httpGet, httpDownload } from "../../api/httpClient";
import locale from "antd/es/date-picker/locale/ko_KR";

import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import {
  numberFormat,
  amountFormat,
  formatDate,
  startDateFormat,
  endDateFormat,
  excelFormat,
} from "../../util";
import fileDownload from "js-file-download";

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class MergeLogList extends Component {
  state = {
    coinList: [],

    logList: [],
    pagination: {
      total: 0,
      current: 1,
    },
    logType: "ALL",
    searchText: "",
    startDate: "",
    endDate: "",
    isLoaded: false,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getLogList({
      pageNum: pager.current,
    });
  };

  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true,
    });
  };

  handleChangeLogType = (value) => {
    this.setState({
      logType: value,
      isLoaded: true,
    });
  };

  onSearch = (value) => {
    // let validate = this.props.match.params.logname === 'mergeList'?!this.state.coinType ;

    if (!this.state.coinType) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.getLogList();
        }
      );
    }
  };

  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true,
    });
  };

  getLogList = (params = {}) => {
    const { coinType, logType, searchText, startDate, endDate } = this.state;
    const { match } = this.props;

    let pageNum = params.pageNum || 1;
    let url =
      match.params.logname === "mergeList"
        ? httpUrl.getMergeLogList
        : httpUrl.getEthLogList;
    let arg = [10, pageNum, logType, coinType, searchText];
    if (match.params.logname === "ethFeeLog") {
      arg.push(startDate);
      arg.push(endDate);
    }
    httpGet(url, arg, {}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.total = res.data.totalCount;
      this.setState({
        logList:
          match.params.logname === "mergeList"
            ? res.data.mergeLogList
            : res.data.ethFeeLogList,
        pagination,
        isLoaded: false,
      });
    });
  };

  downloadExcel = () => {
    const {
      pagination,
      coinType,
      logType,
      searchText,
      startDate,
      endDate,
      isLoaded,
    } = this.state;

    const coinList = reactLocalStorage.getObject("coinList");
    if (!coinType) {
      alert("코인 종류를 선택해주세요.");
    } else {
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(
            httpUrl.downLoadEthLogList,
            [
              pagination.total,
              1,
              logType,
              coinType,
              searchText,
              startDate,
              endDate,
            ],
            {}
          ).then((res) => {
            let title = `${
              coinList.find((coin) => coin.coinType === coinType).name
            } 수수료 지급내역.xlsx`;
            if (startDate && endDate) {
              title =
                `${startDate && excelFormat(startDate)} ~ ${
                  endDate && excelFormat(endDate)
                }` +
                " " +
                title;
            }
            fileDownload(res, title);
          });
        }
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { match } = this.props;

    if (match.params.logname !== prevProps.match.params.logname) {
      this.setState({
        logList: [],
        pagination: {
          total: 0,
          current: 1,
        },
        logType: "ALL",
        coinType: undefined,
        startDate: "",
        endDate: "",
      });
    }
  }

  componentDidMount() {
    this.setState({ coinList: reactLocalStorage.getObject("coinList") });
  }

  render() {
    const { logList, coinType, logType } = this.state;
    const { match } = this.props;

    const logListColumn = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "주소",
        dataIndex: "address",
        key: "address",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "코인종류",
        dataIndex: "coinType",
        key: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {this.state.coinList.find((list) => list.coinType === data).name}
          </div>
        ),
      },
      {
        title: "수수료",
        dataIndex: "fee",
        key: "fee",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <span>{amountFormat(data, row.coinType)}</span>
        ),
      },
      {
        title: "상태",
        dataIndex: "status",
        key: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <span>{string.mergeLogString[data]}</span>,
      },
      {
        title: "일자",
        dataIndex: "createDate",
        key: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <span>{formatDate(data)}</span>,
      },
      {
        title: "넌스",
        key: "nonce",
        dataIndex: "nonce",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "재전송",
        dataIndex: "nonce",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => 
          <div>
            {row.status == 'PENDING' && row.trxId != 'Max rate limit reached' && (
              <a href={row.requestUrl} target='_blank' style={{marginRight: 20}}>재전송</a>
            )}
            <a href={"https://etherscan.io/tx/" + row.trxId} target='_blank'>이더스캔</a>
          </div>
      },
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          {match.params.logname === "mergeList"
            ? "모으기 내역"
            : "ERC-20 수수료지급내역"}
        </div>
        <div className="flex-row flex-center flex-sb">
          <div className="flex-row flex-center m-b-10">
            <label>코인 선택 </label>
            <CoinSelectBox
              placeholder="코인 종류를 선택해주세요."
              value={coinType}
              onChange={this.handleChangeCoinType}
              style={{ width: "240px", marginLeft: "5px", marginRight: "10px" }}
              exceptERC={match.params.logname === "mergeList" ? false : true}
              exceptCoinType={[1, 3]}
            />
            <SelectBox
              value={logType}
              code={string.mergeLogCode}
              codeString={string.mergeLogString}
              onChange={this.handleChangeLogType}
              style={{ width: "180px" }}
            />
          </div>
          <div className="flex-row flex-center m-b-10">
            <label>검색기준&nbsp;</label>
            <Search
              placeholder="주소를 입력해주세요"
              enterButton="검색"
              onSearch={this.onSearch}
              allowClear
              style={{ width: "300px" }}
            />
          </div>
        </div>
        {match.params.logname !== "mergeList" && (
          <div className="flex-row flex-center flex-sb m-b-10">
            <div className="flex-row flex-center">
              <label>검색 기간&nbsp;</label>
              <RangePicker
                locale={locale}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
              />
            </div>
            <Button onClick={this.downloadExcel}>엑셀 다운로드</Button>
          </div>
        )}
        <div style={{paddingBottom: '10px'}}>
          이더스캔에서 트랜잭션이 보이지 않고 전송이 지연되는 경우 "재전송"을 클릭하여 트랜잭션을 다시 보내주세요.<br/>
          재전송은 계속 클릭하셔도 무방합니다.(이중지불 되지 않습니다.)
        </div>
        <Table
          id="foundation-table"
          rowKey={(record) => record.idx}
          columns={logListColumn}
          dataSource={logList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default withRouter(MergeLogList);
