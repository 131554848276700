import React, { Component } from 'react'
import { httpGet, httpUrl,httpDownload, httpPut } from '../../api/httpClient';
import { Table, Input, Button,Icon,Divider} from 'antd'
import fileDownload from "js-file-download";
import {
  formatDate,numberFormat,amountFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import IncentiveRegistry from "../../components/adjustment/IncentiveRegistry";
import { showAlert } from '../../components/common/AlertModal';
import { PlusOutlined } from '@ant-design/icons';
// const Search = Input.Search;


class IncentiveLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
      // searchText:"",
      pagination: {
        current: 1,
        total: 0,
      },
    
      list: [],
      
      incentiveVisible :false,
    
    };


  }
 

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getIncentiveLogList({

      pageNum: pager.current,

    });
  };


  getIncentiveLogList = (params = {}) => {
    // const{searchText,searchType} = this.state
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getIncentiveLogList, [10, pageNum], {})
      .then(res => {
        console.log(JSON.stringify(res.data.incentiveLogList))
        const pagination = { ...this.state.pagination }
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.list,
          // incentiveLogList: res.data.list,
          pagination,
        })
      })
      .catch((error) => { });
  }

 onUpdateIncentive = (params ={}) =>{
  let updateData = {
  idx: params.idx,
  incentiveValid: params.incentiveValid,

  };

  httpPut(httpUrl.updateIncentiveLogList, [], updateData).then((res) => {
      this.getIncentiveLogList({
      pageNum: this.state.pagination.current,
    });
    showAlert("update");

  });
 };


 
 onSearch = (value) => {
  this.setState(
    {
      searchText: value,
      isLoaded: false,
    },
    () => {
      this.getIncentiveLogList({});

    }
  );
};

  componentDidMount() {
    this.getIncentiveLogList();
  }
  
  downloadExcel = () => {


    // if (this.state.isLoaded) {
    //   alert("검색버튼을 먼저 클릭해주세요.");
    // } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.incentiveLogListDownload,
          [
            
          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `수당지급목록 조회.xlsx`);
          })
          .catch((err) => { });
      
    }
  };


  render() {
  
    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{numberFormat(data)}</div>       
      },
      {
        title: "수당종류",
        key: "incentiveType",
        dataIndex: "incentiveType",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => 
        <div>{string.incentiveTypeString[data]}</div>  
   
      },
      {
        title: "수당지급자",
        key: "receiveUserIdx",
        dataIndex: "receiveUserIdx",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) =>        
        <div>{row.uid}</div>  
       
      },
      {
        title: "대상금액",
        key: "orgPrice",
        dataIndex: "orgPrice",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{amountFormat(data)}</div>
       
      },
      {
        title:"요율",
        key:"ratio",
        dataIndex:"ratio",
        className:"support-column-number fs-15 lh-21",
        render:(data) => <div>{data*100 + '%'}</div>
      },
      {
        title:"지급금액",
        key:"price",
        dataIndex:"price",
        className:"support-column-number fs-15 lh-21",
        render:(data) => <div>{amountFormat(data)}</div>
      },
      {
        title:"유효여부",
        key:"valid",
        dataIndex:"valid",
        className:"support-column-number fs-15 lh-21",
        render:(data) => <div>{string.validTypeString[data]}</div>
      },
      {
        title:"지급여부",
        key:"paid",
        dataIndex:"paid",
        className:"support-column-number fs-15 lh-21",
        render:(data) => <div>{string.paidTypeString[data]}</div>
      },
      {
        title:"전송이력번호",
        key:"transferLogIdx",
        dataIndex:"transferLogIdx",
        className:"support-column-number fs-15 lh-21",
        render: (data, row, index) => (data === null ? "-" : data),
      },
     
      {
        title:"관련사용자",
        key:"referUserIdx",
        dataIndex:"referUserIdx",
        className:"support-column-number fs-15 lh-21",
        render:(data,row) => <div>{row.uid2}</div>
      },
      {
        title:"생성일",
        key:"createDate",
        dataIndex:"createDate",
        className:"support-column-number fs-15 lh-21",
        render:(data) => <div>{formatDate(data)}</div>
      },
      {
        title:"은행요청번호",
        key:"depositRequestIdx",
        dataIndex:"depositRequestIdx",
        className:"support-column-number fs-15 lh-21",
        render:(data) => <div>{data}</div>
      },       
      {
        title:"",
        key:"",
        dataIndex:"",
        className:"support-column-number fs-15 lh-21",
        render:(data,row) => <div>
          {row.valid === 1 ?         
          <Button
          onClick={() => {
          if (global.confirm(row.idx + '번 거절처리 하시겠습니까?')) {
            this.onUpdateIncentive({
              idx: row.idx,
              incentiveValid: 0,
              
            });
          }
        }}
        >거절</Button> : <div /> } 
        {row.valid === 0 ?         
          <Button
        onClick={() => {
          if (global.confirm(row.idx + '번 승인처리 하시겠습니까?')) {
            this.onUpdateIncentive({
             idx: row.idx, 
            incentiveValid: 1,
            });
          }
        }}
        >승인</Button> : <div /> }
        
        </div>
      },   
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
           <Divider orientation="left" style={{fontSize:23}}>수당지급목록</Divider>  
        
         

          
        </div>
       
        
      
         {/* <Search
          placeholder={`검색어를 입력해주세요.`}
          enterButton="검색"
          allowClear
          onSearch={this.onSearch}
          style={{ width: 300, marginLeft: '10px' }}
        /> */}
         
        <Button onClick={()=>{this.setState({incentiveVisible: true})}} style={{marginRight: 10}}><PlusOutlined/>생성</Button>
          <Button onClick={this.downloadExcel}>
            <Icon type="download" style={{fontSize: "20px" }}/>엑셀 다운로드</Button>
         
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px' }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
          <IncentiveRegistry
          visible={this.state.incentiveVisible}
          onOk={() => {
            this.setState({
              incentiveVisible: false
            });
            this.onSearch('');
          }}
          onCancel={() => {
            this.setState({
              incentiveVisible: false
            });
          }}
        />
            
            

      </div>
    )


  }
}

export default IncentiveLog;
