import React, { Component } from "react";

import { Table, DatePicker, Input, Button , Divider} from "antd";

import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import { formatDate, startDateFormat, endDateFormat } from "../../util";
import NoticeRegist from "./NoticeRegist";
import NoticeModify from "./NoticeModify";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import { PlusOutlined } from "@ant-design/icons";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class NoticeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeList: [],
      pagination: {
        total: 0,
        current: 1
      },
      noticeRegistVisible: false,
      noticeModifyVisible: false,
      selectedRow: null,
    };
  }

  componentDidMount() {
    this.getNoticeList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, ()=>this.getNoticeList());
  };

  initialLoad = () => {
    this.setState({
      noticeList: [],
      pagination: {
        total: 0,
        current: 1
      }}, () => {
        this.getNoticeList();
      })
  }
  reload = () => {
    this.getNoticeList();
  }
  getNoticeList = () => {
    let pageNum = this.state.pagination.current;

    httpGet(httpUrl.noticeList,[10,pageNum,true,'ALL',''],{}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        noticeList: res.data.noticeList,
        pagination,
      });
    });
  };
  onDeleteHandle = (row, value) => {
    if (global.confirm(`${row.idx}번 공지를 ${value == 0 ? "복구" : "삭제"}하시겠습니까?`)) {
      httpPost(httpUrl.noticeDelete, [row.idx, value], {}).then((res) => {
        if (res.data) {
          this.reload();
        } else {
          alert("알수없는 문제로 실패하였습니다..");
        }
      });
    }
  };
  render() {
    const { noticeList } = this.state;

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "구분",
        dataIndex: "category",
        width: 140,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{string.noticeString[data]}</div>
      },
      {
        title: "제목",
        dataIndex: "title",
        key: "title",
        className: "fs-15 lh-21",
        render: (data, row, index) => <div style={{cursor: 'pointer', color: 'blue'}} onClick={()=>{
            this.setState({
              selectedRow:row,
              noticeModifyVisible:true
            });
        }}>{data.find(item => item.language === "ko").content}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{formatDate(data)}</div>
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (value != row.deleted) {
                    this.onDeleteHandle(row, value);
                  }
                }}
              />
          </div>
        ),
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{fontSize:23}}>공지사항 설정 </Divider>
      </div>
        <div className="wrapper mt-35 width-1240" />
        <div>
          <Button
            onClick={() => {
              this.setState({ noticeRegistVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            <PlusOutlined/>생성
          </Button>
        </div>
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={noticeList}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
        <NoticeRegist
          visible={this.state.noticeRegistVisible}
          onCancel={() => {
            this.setState({ noticeRegistVisible: false });
          }}
          onOk={() => {
            this.setState({ noticeRegistVisible: false });
            this.initialLoad();
          }}
        />
        <NoticeModify
          data={this.state.selectedRow}
          visible={this.state.noticeModifyVisible}
          onCancel={() => {
            this.setState({ noticeModifyVisible: false });
          }}
          onOk={() => {
            this.setState({ noticeModifyVisible: false });
            this.reload();
          }}
          updateSelectedImage={this.updateSelectedImage}
        />
      </div>
    );
  }
}

export default NoticeList;
