import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, Button, Icon,Divider } from "antd";

import CoinSelectBox from "./../../components/common/CoinSelectBox";
import { numberFormat, amountFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import UserLock from "../../components/foundation/UserLock";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import fileDownload from "js-file-download";

const Search = Input.Search;

class FoundationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foundationList: [],
      fCoinList: [],

      listData: [],
      searchText: "",
      orderType: "BALANCE",
      orderByType: "ASC",
      pagination: {
        total: 0,
        current: 1,
      },
      totalBalance: 0,
      totalLock: 0,
      totalAvailable: 0,

      userLockVisible: false,
      selectedUser: {},
      userLockList: [],
      userLockPagination: { total: 0, current: 1 },

      isLoaded: true,
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getFoundationUserList({
      pageNum: pager.current,
    });
  };

  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true,
    });
  };

  orderTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState({
        orderType: value,
        isLoaded: true,
      });
    }
  };

  orderByTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState({
        orderByType: value,
        isLoaded: true,
      });
    }
  };

  onSearch = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState(
        {
          searchText: value,
          isLoaded: false,
        },
        () => {
          this.getFoundationUserList({
            pageNum: 1,
          });
        }
      );
    }
  };

  downloadExcel = () => {
    const {
      coinType,
      pagination,
      orderType,
      orderByType,
      searchText,
      isLoaded,
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(httpUrl.foundationListExcel, [
            pagination.total,
            1,
            coinType,
            orderType,
            orderByType,
            searchText,
          ])
            .then((res) => {
              // console.log(JSON.stringify(res, null, 4));
              fileDownload(
                res,
                `${
                coinList.find((coin) => coin.coinType === coinType).name
                } 보유현황 조회.xlsx`
              );
            })
            .catch((error) => { });
        }
      }
    }
  };

  getUserLockList = (params = {}) => {
    const { userLockList, selectedUser, userLockPagination } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) {
      pageNum =
        userLockList.lockResponse.length === 1
          ? userLockPagination.current === 1
            ? userLockPagination.current
            : userLockPagination.current - 1
          : userLockPagination.current;
    }
    httpGet(
      httpUrl.foundationUserLockList,
      [10, pageNum, selectedUser.coinType, selectedUser.address],
      {}
    )
      .then((result) => {
        const userLockPagination = { ...this.state.userLockPagination };
        userLockPagination.current = result.data.currentPage;
        userLockPagination.total = result.data.totalCount;
        this.setState({
          userLockVisible: true,
          userLockList: result.data,
          userLockPagination,
          isLoaded: false,
        });
        // console.log(`userLock = ${JSON.stringify(params.row, null, 4)}`);
      })
      .catch((error) => { });
    if (params.refresh) {
      this.getFoundationUserList({
        pageNum: this.state.pagination.current,
      });
    }
  };

  userLockHandleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.userLockPagination };
    pager.current = pagination.current;
    this.setState({
      userLockPagination: pager,
    });
    this.getUserLockList({
      pageNum: pager.current,
    });
  };

  getFoundationUserList = (params = {}) => {
    const { coinType, orderType, orderByType, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.foundationUserList,
      [10, pageNum, coinType, orderType, orderByType, searchText],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          totalBalance: res.data.totalBalance,
          totalLock: res.data.totalLock,
          totalAvailable: res.data.totalAvailable,
          listData: res.data.foundationUserList,
          pagination,
        });
      })
      .catch((error) => { });
  };

  componentDidMount() {
    httpGet(httpUrl.getFoundationList, [], {}).then((res) => {
      this.setState({
        foundationList: res.data,
        fCoinList: res.data.map((item) => {
          return item.coinType;
        }),
      });
    });
  }

  render() {
    const {
      orderType,
      orderByType,
      listData,
      coinType,
      fCoinList,
      totalBalance,
      totalLock,
      totalAvailable,
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const listColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>,
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {coinList.find((coin) => coin.coinType === data)
              ? coinList.find((coin) => coin.coinType === data).name
              : ""}
          </div>
        ),
      },
      {
        title: "아이디",
        dataIndex: "id",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이름",
        dataIndex: "name",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "보유수량",
        dataIndex: "balance",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>,
      },
      {
        title: "Lock 수량",
        dataIndex: "lockBalance",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </div>
        ),
      },
      {
        title: "출금 가능 수량",
        dataIndex: "availableBalance",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>,
      },
      // {
      //   title: "상태",
      //   dataIndex: "deleted",
      //   className: "support-column-number fs-15 lh-21",
      //   render: data => <div>{string.foundationString[data]}</div>
      // },
      {
        title: "Lock 현황",
        dataIndex: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState(
                {
                  selectedUser: row,
                },
                () => {
                  this.getUserLockList();
                }
              );
            }}>
            현황
          </Button>
        ),
      },
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{fontSize:23}}>보유현황 조회 </Divider>
        </div>
        <div className="wrapper mt-35 width-1240" />
        <UserLock
          modalData={this.state.userLockList}
          visible={this.state.userLockVisible}
          pagination={this.state.userLockPagination}
          handleTableChange={this.userLockHandleTableChange}
          selectedUser={this.state.selectedUser}
          onOk={() => {
            this.setState({
              userLockVisible: false,
            });
          }}
          refresh={this.getUserLockList}
        />
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            코인{" "}
            <CoinSelectBox
              type="coinType"
              placeholder="선택해주세요."
              value={coinType}
              coinCode={fCoinList}
              onChange={(value) => {
                if (value !== coinType) this.coinTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <label htmlFor="">
            순서{" "}
            <SelectBox
              value={string.orderByString[orderByType]}
              code={string.orderByCode}
              codeString={string.orderByString}
              onChange={(value) => {
                if (value !== orderByType) this.orderByTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
            <SelectBox
              value={string.foundationOrderString[orderType]}
              code={string.foundationOrderCode}
              codeString={string.foundationOrderString}
              onChange={(value) => {
                if (value !== orderType) this.orderTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <div style={{ float: "right" }}>
            <div className="flex-row flex-center">
              <label htmlFor="search">검색 기준&nbsp;&nbsp;</label>
              <Search
                placeholder="아이디, 지갑주소를 입력하세요"
                enterButton="검색"
                allowClear
                onSearch={this.onSearch}
                style={{ width: "300px", marginLeft: "10px" }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <span>{`total : ${numberFormat(this.state.pagination.total)}`}</span>
          <span style={{ marginLeft: "20px" }}>
            {`총 보유수량 : ${numberFormat(totalBalance, coinType)}`}
          </span>
          <span style={{ marginLeft: "20px" }}>
            {`총 LOCK수량 : ${numberFormat(totalLock, coinType)}`}
          </span>
          <span style={{ marginLeft: "20px" }}>
            {`총 출금가능수량 : ${numberFormat(totalAvailable, coinType)}`}
          </span>
          <Button
            style={{ float: "right", marginBottom: "10px" }}
            onClick={this.downloadExcel}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <div style={{ color: "blue" }} className="m-b-1">
            * 사용자가 잔액 갱신을 하지 않을 경우 총 수량이 일치 하지 않을 수
            있습니다.
          </div>
          <div style={{ color: "blue" }}>
            * 전체잠금이 설정된 기간동안은 개별 락의 상태와 관계없이 해당 지갑의
            해당 코인에 대해 모든 출금이 금지됩니다.
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowKey={(record) => record.idx}
          columns={listColumns}
          dataSource={listData}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default FoundationList;
