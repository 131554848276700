import React, { Component } from "react";

import {
  Form,
  Modal,
  Input,
  Button,

} from "antd";
import { httpPost, httpUrl, serverUrl } from "../../api/httpClient";
import { showAlert } from "../common/AlertModal";


const FormItem = Form.Item;
const DailyRegistry = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        address:"",
     
      
      };
      
    }

    handleChange = (e) => {
      this.setState({
        name: e.target.value,
        address: e.target.value
      })
    }


    onRegistry = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        let createData = {
          ...values,
         
        };
      
        if (!err) {
          if (global.confirm("등록 하시겠습니까?"))
            httpPost(httpUrl.DailyCreate, [], createData)
              .then((res) => {
                if (res.data) {
                  this.props.onOk();
                  form.resetFields();            
                  showAlert("create");
                
               
                } else {
                  
                    alert("알수없는 오류가 발생하였습니다.");
                  
                }
              })
              // .catch((error) => {
              //   alert("이미 등록된 주소 입니다.");

              // });
        }
      });
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
      
    };

    componentDidMount() {}

    render() {
      const {
      
      } = this.state;
      const { visible, form, defaultValue, color } = this.props;
      const { getFieldDecorator } = form;
      
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      

      return (
        <Modal
          visible={visible}
          title="생성"
          okText="생성"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "400px" }}>
         
         <Form {...formItemLayout} onSubmit={this.onRegistry} >
        
        <FormItem label={<span>지갑주소&nbsp;</span>}>
          {getFieldDecorator("address", {
            rules: [
              {
                required: true,
                message: "지갑주소를 입력해주세요!",
                
              },
            ]
            
          })(<Input placeholder="지갑주소" maxlength="100"/>)}
        </FormItem>             
    
             
          </Form>
        </Modal>
      );
    }
  }
);

export default DailyRegistry;


