import React, { Component } from "react";
import { Modal, Table, Input, Button, Tag } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";
import SelectBox from "../common/SelectBox";
import string from "../../string";

const Search = Input.Search;

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      pagination: {
        current: 1,
        total: 0
      },
      searchText: "",
      searchType: "NAME",

      franchise: false,
      selectedUser: {}
    };
  }


  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getUserList({ pageNum: pager.current });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getUserList();
      }
    );
  };

  
  onOk = () => {
    this.props.onOk(
      this.state.selectedUser
    );
    this.props.onCancel();
  };

  onChangeTag = (value) => {
    this.setState({ selectedUser: value });
  };

  getUserList = (params = {}) => {
    const { searchText,searchType } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.userList, [10, pageNum, false, searchText,searchType], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          userList: res.data.userList,
          pagination
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    // console.log(`type: ${this.props.type}, \n userIdx: ${this.props.userIdx}`);
    this.getUserList();
  }

  render() {
    const { type, visible, onCancel } = this.props;
    const { franchise, selectedUser, userList, searchType } = this.state;
  
    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
          title: "아이디",
          dataIndex: "id",
          key: "id",
          className: "support-column-number fs-15 lh-21"
      },
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.props.onOk(row);
              this.props.onCancel();
              // this.onChangeTag(row);
            }}>
            선택
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title={"회원 정보"}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}
        footer={false}>
        {selectedUser.idx !== undefined && (
          <Tag key={selectedUser.idx}>
            {selectedUser.id} /{" "}
            {selectedUser.name === null || selectedUser.name === ""
              ? "-"
              : selectedUser.name}
          </Tag>
        )}
        <div className="flex-row flex-center flex-end m-b-10">
        <SelectBox
            value={string.userListTypeString[searchType]}
            code={string.userListTypeCode}
            codeString={string.userListTypeString}
            onChange={(value) => {
              if (value !== searchType) {
                this.searchTypeHandleChange(value);
              }
            }}
            style={{ width: "100px", marginLeft: "10px" }}
          />
          <Search
            placeholder={
              "아이디/ 이름을 입력해주세요."
            }
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 400 }}
          />
        </div>
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={userList}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}
export default UserList;
