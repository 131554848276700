import React, { Component } from 'react'
import { httpGet, httpUrl,httpPost, httpDelete,httpPut } from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form,Divider } from 'antd'
import { reactLocalStorage } from "reactjs-localstorage";
import {
  EditOutlined,DeleteOutlined, PlusOutlined
   } from '@ant-design/icons';
import {
   formatDate,
 
  numberFormat,
  startDateFormat,
  endDateFormat,
  
} from "../../util";
import string from "../../string";
import locale from "antd/es/date-picker/locale/ko_KR";
import SelectBox from "../../components/common/SelectBox";
import CouponCategoryRegistry from "../../components/coupon/CouponCategoryRegistry";

import { showAlert } from "../../components/common/AlertModal";


const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
const FormItem = Form.Item;
const CouponCategory = Form.create()(

class CouponCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      searchText:"",
      pagination: {
        total: 0,
        current: 0,
      },

      serviceData: [],
      selectedRow: "",
      selectData: {},
      prevData: {},
    
      list: [],
      visible: false,
      couponCategoryVisible: false,
    };


  }
 
  // handleChangeDepositType = (value) => {
  //   this.setState({
  //     depositType: value,

  //   });
  // };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getCouponCategoryList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getCouponCategoryList({});

      }
    );
  };

  onDeleteCategoryList=(idx,name,price,count,createDate)=>{
 
    httpPost(httpUrl.CouponCategoryDelete,[],idx).then((res) => {
    this.getCouponCategoryList({
      pageNum: this.state.pagination.current,
    });
    showAlert("delete");
  })
  };

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "idx") {
      data = row;
    } else if (type === "price") {
      data.price = value;
    } else {
      data.count = value;
    }
    this.setState({
      selectData: data
    });
  };


  onUpdate = () => {
    const { selectData } = this.state;
    let updateData = {
      idx: selectData.idx,
      price: selectData.price,
      count: selectData.count
    };
    if (global.confirm(`수정하시겠습니까?`)) {
      httpPost(httpUrl.CouponCategoryUpdate, [], updateData)
        .then(result => {
          showAlert("update");
          this.setState({
            selectedRow: "",
            selectData: {},
            prevData: {}
          });
          this.getCouponCategoryList();
        })
        .catch(error => {});
    }
  };



  getCouponCategoryList = (params = {}) => {
    const{searchText} = this.state
 
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getCouponCategoryList, [10, pageNum])
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.categoryList,
          pagination,
        })
      })

  }

  

 

  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  componentDidMount() {
    this.getCouponCategoryList();
  }
  

  render() {
    const {selectedRowKeys } = this.state;
    const rowSelection ={
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    const { selectedData } = this.state;
    const {form} = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      wrapperCol: {
        xs: { span: 10 },
        sm: { span: 13 }
      }
    };
    const {
      selectedRow, selectData,prevData,serviceData
    
    } = this.state;


    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div style={{fontSize:13}}>{data}</div>    
      },
      {
        title: "쿠폰종류",
        key: "name",
        dataIndex: "name",
        className: "support-column-number fs-15 lh-21",
        render:(data) =>
        <div style={{fontSize:13}}>{data}</div>
      },
      {
        title:"쿠폰금액",
        key:"price",
        dataIndex:"price",
        className:"support-column-number fs-15 lh-21",
        render: (data,index) => (
        // <div>{numberFormat(data)}</div>
        <div style={{fontSize:13}}>
        {selectedRow !== index ? (
          numberFormat(data)
        ) : (
          <Input
            style={{width:"100px"}}
            value={selectData.price}
            onChange={e => {
              this.onChangeModal(e.target.value, "price");
            }}
          />
        )}
      </div>
        )},
      {
        title:"쿠폰수량",
        key:"count",
        dataIndex:"count",
        className:"support-column-number fs-15 lh-21",
        render: (data,index) => (
        // <div>{numberFormat(data)}</div>
        <div style={{fontSize:13}}>
        {selectedRow !== index ? (
          numberFormat(data)
        ) : (
          <Input
            style={{width:"100px"}}
            value={selectData.count}
            onChange={e => {
              this.onChangeModal(e.target.value, "count");
            }}
          />
        )}
      </div>
        )},
      {
        title:"쿠폰생성일",
        key:"createDate",
        dataIndex:"createDate",
        className:"support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{formatDate(data)}</div>
      },
      {
        title:"",
        key:"",
        dataIndex:"",
        className:"support-column-number fs-15 lh-21",
        render:(row, index)=>
        <div>
          {selectedRow !== index && (
        <Button
                style={{height:25,fontSize:13}}
                onClick={value => {
                  this.onChangeModal(row.idx, "idx", index);
                  this.setState({
                    selectedRow: index,
                    selectData: row,
                    prevData: row
                  });
                }}>
                <EditOutlined/>변경
              </Button>
          )}
          &nbsp;
           {selectedRow === index && (
              <div>
                <Button
                  style={{ marginRight: "8px",fontSize:13 }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                 style={{fontSize:13 }}
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData
                    });
                  }}
                />
              </div>
            )}
            
          <Button 
          style={{marginTop:"1px", height:25,fontSize:13}}
          onClick={() => {
          if(global.confirm(row.name+' 삭제하시겠습니까?')){
           this.onDeleteCategoryList(row.idx);
          }
        }}>
          <DeleteOutlined />삭제
          </Button>
          </div>         
      },
      
   
      
   
   

    ];




    return (
      
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
           <Divider orientation="left" style={{fontSize:23}}>쿠폰 카테고리설정 </Divider>  
      

          
        </div>
        <Button onClick={()=>{this.setState({couponCategoryVisible: true})}} style={{marginLeft: 250}}><PlusOutlined/>생성</Button>
        {/* 메모입력,수정 Modal */}
    
        <Table
          
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px',width:1100, marginLeft:250 }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />

            <CouponCategoryRegistry 
            visible={this.state.couponCategoryVisible}
            onOk={() => {
            this.setState({
              couponCategoryVisible: false
            });
            this.onSearch('');
          }}
          onCancel={() => {
            this.setState({
              couponCategoryVisible: false
            });
          }}
            
           />  
         

      </div>
    )


  }
}
);
export default CouponCategory;