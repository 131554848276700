import React, { Component } from 'react'
import { httpGet, httpUrl,httpPut,httpPost, httpDelete } from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form,Divider } from 'antd'
import locale from "antd/es/date-picker/locale/ko_KR";
import {
 formatDate,numberFormat,amountFormat,startDateFormat,endDateFormat 
  } from "../../util";
  import { showAlert } from '../../components/common/AlertModal';
  import string from "../../string";
  import SelectBox from "../../components/common/SelectBox";
  import { SwitcherOutlined,SearchOutlined } from "@ant-design/icons";
  import UserList2 from "../../components/purchase/UserList2";
  
const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
class PurchaseTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      root: null,
      userVisible: false,
      selectedStemUser: {},
      reorder: [],
    };
  }

  componentDidMount() {
  }

  getTree = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(httpUrl.getPurchaseTree, [this.state.selectedStemUser.idx],{})
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        this.setState({
          root: res.data,
        })
      })
  }
  getReorder = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(httpUrl.getPurchaseReorder, [this.state.selectedStemUser.idx],{})
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        this.setState({
          reorder: res.data,
        })
      })
  }

  onSelectUser = (data) => {
      this.setState({ selectedStemUser: data }, ()=>{
        this.getTree();
        this.getReorder();
      });
  };
  
  renderTree = () => {
    return (
    <div style={{width: '100%', overflowX: 'auto'}}>
      {this.renderTreeItem(this.state.root)}
    </div>);
  }
  renderTreeItem = (item) => {
    const gradeString = [
      '-',
      '딜러',
      '대리점',
      '지점',
      '지사',
      '본부',
    ]
    return (
    <div style={{display: 'inline-block', border: '1px solid #000', verticalAlign: 'top'}}>
      <div style={{padding: '10px', marginBottom: '10px'}}>
        <div>{item.userId}</div>
        <div>{item.userName}</div>
        <div>{item.userPhone}</div>
        <div>{gradeString[item.userGrade]}</div>
        <div>누적매출(제외): {numberFormat(item.totalPrice)}({numberFormat(item.totalPriceExclude)})</div>
        <div>누적PV(제외): {numberFormat(item.totalPv)}({numberFormat(item.totalPvExclude)})</div>
        <div>주간매출(제외): {numberFormat(item.weeklyPrice)}({numberFormat(item.weeklyPriceExclude)})</div>
        <div>주간PV(제외): {numberFormat(item.weeklyPv)}({numberFormat(item.weeklyPvExclude)})</div>
      </div>
      <div style={{padding: '10px'}}>
        {item.children && item.children.map(children=>this.renderTreeItem(children))}
      </div>
    </div>);
  }

  render() {
    const {
      userVisible,
      selectedStemUser,
      root,
      reorder
    } = this.state;
    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
        <Divider orientation="left" style={{fontSize:21.5}}>매출조직 <span style={{fontSize:7}}>L7 STEM</span> </Divider>  

        </div>
        <div>
             
              <div style={{left:100}}>
                <Button
                  onClick={() => {
                    this.setState({
                      userVisible: true
                    });
                  }}>
                  {selectedStemUser.idx !== undefined ? "변경" : "선택"}
                </Button>
                {selectedStemUser.idx !== undefined && (
                  <div style={{display: 'inline-block', marginLeft: '20px'}}>
                    {selectedStemUser.id} {" "}  {" "}
                    ({selectedStemUser.name === null || selectedStemUser.name === ""
                      ? "-"
                      : selectedStemUser.name} {" "}  {" "}              
                
                    {selectedStemUser.recommendCode})
                  </div>
                )}
              </div>
              <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <div style={{fontSize:18}}>재구매</div>
                <div>
                  {reorder.map(row=>{
                    return (
                      <span style={{padding: '5px'}}>{row.reorderDate}</span>
                    )
                  })}
                </div>
              </div>
                <div style={{fontSize:18}}>조직도</div>

            {root && this.renderTree()}

          {userVisible && (
            <UserList2
              visible={userVisible}
              userIdx={0}
              onCancel={() => {
                this.setState({ userVisible: false });
              }}
              onOk={this.onSelectUser}
            />
          )}
          </div>
      </div>
    )


  }
}

export default PurchaseTree;
