import { DatePicker, Input, Table, Button } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpPost, httpUrl } from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { amountFormat, formatDate, numberFormat } from "../../util";
import ArbitrageManualCreate from "./ArbitrageManualCreate";



const Search = Input.Search;
const { RangePicker } = DatePicker;

class ArbitrageManualLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logList: [],
      pagination: {
        total: 0,
        current: 1
      },
      createVisible: false
    };
  }

  componentDidMount() {
    this.getLogList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, () => this.getLogList());
  };

  initialLoad = () => {
    this.setState({
      logList: [],
      pagination: {
        total: 0,
        current: 1
      }
    }, () => {
      this.getLogList();
    })
  }
  reload = () => {
    this.getLogList();
  }
  getLogList = () => {
    let pageNum = this.state.pagination.current;
    httpGet(httpUrl.arbitrageManualLogList, [10, pageNum], {}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        logList: res.data.logs,
        pagination,
      });
    });
  };
  render() {
    const { logList } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    const exchangeType = {
      CREATE: '등록함',
      CANCEL: '취소함',
      APPLY: '거래함'
    }
    const exchangeStatus = {
      PENDING: '대기중',
      CANCEL: '취소완료',
      MATCH: '거래완료'
    }
    const columns = [
      {
        title: "",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "작업자",
        dataIndex: "adminId",
        className: "support-column fs-15 lh-21",
      },
      // {
      //   title: "등록계정",
      //   dataIndex: "userIdx",
      //   className: "support-column-number fs-15 lh-21",
      // },
      {
        title: "작업내용",
        dataIndex: "exchangeType",
        className: "support-column fs-15 lh-21",
        render: (data) => <div>{exchangeType[data]}</div>
      },
      {
        title: "작업일",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "교환번호",
        dataIndex: "exchangeIdx",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "교환상태",
        dataIndex: "exchangeStatus",
        className: "support-column fs-15 lh-21",
        render: (data) => <div>{exchangeStatus[data]}</div>
      },
      {
        title: "FROM",
        dataIndex: "fromAmount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.fromCoinType) + ' ' + coinList.find((coin) => coin.coinType === row.fromCoinType).symbol}</div>
      },
      {
        title: "TO",
        dataIndex: "toAmount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.toCoinType) + ' ' + coinList.find((coin) => coin.coinType === row.toCoinType).symbol}</div>
      },
      {
        dataIndex: "exchangeStatus",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => {
          if (data == 'PENDING')
            return (
              <Button
                onClick={() => {
                  if (global.confirm('취소하시겠습니까?')) {
                    
                    httpPost(httpUrl.arbitrageManualCancel, [row.exchangeIdx], {}).then((res) => {
                      if (res.data) {
                        this.getLogList();
                      } else {
                        alert("알수없는 문제로 취소에 실패하였습니다..");
                      }
                    }).catch(()=>{
                      alert("알수없는 문제로 취소에 실패하였습니다..");
                    });
                  }
                }}>
                취소
              </Button>
            );
          else {
            return "-";
          }
        }
      },
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          수동자전이력
      </div>
        <div>
          <Button
            onClick={() => {
              this.setState({ createVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            생성
        </Button>
        </div>
        <div className="wrapper mt-35 width-1240" />
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.canceled ? "gray" : "")}
          dataSource={logList}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
        <ArbitrageManualCreate
          visible={this.state.createVisible}
          onCancel={() => {
            this.setState({ createVisible: false });
          }}
          onOk={() => {
            this.setState({ createVisible: false });
            this.getLogList();
          }}
        />
      </div>
    );
  }
}

export default ArbitrageManualLog;
