import React, { Component } from 'react'
import { httpGet, httpUrl,httpPost, httpDelete } from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form,Divider } from 'antd'
import { reactLocalStorage } from "reactjs-localstorage";
import {
   formatDate,
 
  numberFormat,
  startDateFormat,
  endDateFormat
} from "../../util";
import string from "../../string";
import locale from "antd/es/date-picker/locale/ko_KR";
import SelectBox from "../../components/common/SelectBox";
import HolidayRegistry from "../../components/system/HolidayRegistry";
import { showAlert } from "../../components/common/AlertModal";
import { PlusOutlined } from '@ant-design/icons';


const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
const FormItem = Form.Item;
const Holiday = Form.create()(

class Holiday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      searchText:"",
      pagination: {
        total: 0,
        current: 0,
      },
    
      list: [],
      visible: false,
      holidayVisible: false,
    };


  }
 
  // handleChangeDepositType = (value) => {
  //   this.setState({
  //     depositType: value,

  //   });
  // };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getHolidayList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getHolidayList({});

      }
    );
  };

  onDeleteHoliday=(holiday,memo)=>{
 
  httpPost(httpUrl.HolidayDelete,[],holiday).then((res) => {
  this.getHolidayList({
    pageNum: this.state.pagination.current,
  });
  showAlert("delete");
})
};
  



  getHolidayList = (params = {}) => {
    const{searchText} = this.state
 
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getHolidayList, [10, pageNum,searchText])
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.holidayList,
          pagination,
        })
      })

  }

  

 

  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  componentDidMount() {
    this.getHolidayList();
  }
  

  render() {
    const {selectedRowKeys } = this.state;
    const rowSelection ={
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    const { selectedData } = this.state;
    const {form} = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      wrapperCol: {
        xs: { span: 10 },
        sm: { span: 13 }
      }
    };
    const {
      selectedRow,
    
    } = this.state;


    const columns = [
      {
        title: "날짜",
        key: "holiday",
        dataIndex: "holiday",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "메모",
        key: "memo",
        dataIndex: "memo",
        className: "support-column-number fs-15 lh-21",
        render:(data) =>
        <div>{data}</div>
      },
      {
        title:"",
        key:"",
        dataIndex:"",
        className:"support-column-number fs-15 lh-21",
        render:(row)=>
        <div><Button 
          onClick={() => {
          if(global.confirm(row.memo+' 삭제하시겠습니까?')){
           this.onDeleteHoliday(row.holiday);
          }
        }}>
          삭제
          </Button></div>
      },
      
   
   

    ];




    return (
      
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{fontSize:23}}>공휴일 설정 </Divider>  
        
         

          
        </div>
        {/* 메모입력,수정 Modal */}
        
          <Button onClick={()=>{this.setState({holidayVisible: true})}} style={{marginLeft: 320}}><PlusOutlined/>생성</Button>

        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px',width:1000,marginLeft:320 }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
          <HolidayRegistry 
            visible={this.state.holidayVisible}
            onOk={() => {
            this.setState({
             holidayVisible: false
            });
            this.onSearch('');
          }}
          onCancel={() => {
            this.setState({
              holidayVisible: false
            });
          }}
            
           />  

      </div>
    )


  }
}
);
export default Holiday;
