import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import {IdcardOutlined, IdcardTwoTone} from '@ant-design/icons';
import { login } from "../../actions/loginAction";
import { Descriptions, Input, Button ,Divider} from "antd";
import { formatDate } from "./../../util";
import { httpPut, httpUrl } from "../../api/httpClient";
import InputPassword from "../../components/common/InputPassword";
import { showAlert } from "../../components/common/AlertModal";

const Ditems = Descriptions.Item;

class MyInfo extends Component {
  state = {
    userInfo: {},
    isUpdate: false,
    updateInfo: {},
    pwd: "",
    newPwd: null,
    otpVisble: false
  };

  onChangeModal = (value, type) => {
    var data = Object.assign(this.state.updateInfo);
    data[type] = value;
    this.setState({
      updateInfo: data
    });
    // console.log(`updateData = ${JSON.stringify(data, null, 4)}`);
  };

  onUpdate = () => {
    const { updateInfo, pwd, newPwd } = this.state;
    if (pwd === "") {
      alert("이전 비밀번호를 입력해주세요");
    } else if (pwd === newPwd) {
      alert("이전 비밀번호와 같습니다. 다시 입력해주세요");
    } else if (pwd !== newPwd || newPwd === null) {
      if (global.confirm(`수정하시겠습니까?`)) {
        let updateData = {
          ...updateInfo,
          password: newPwd,
          prePassword: pwd
        };
        // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
        httpPut(httpUrl.adminUserUpdate, [], updateData)
          .then((result) => {
            if (result.data.result == 'SUCCESS') {
              showAlert("update");
              delete updateData.prePassword;
              this.props.onLogin({
                ...updateData
              });
              this.setState({
                pwd: "",
                newPwd: null,
                isUpdate: false
              });
            }
            else {
              let msg = '수정에 실패하였습니다.'
              if (result.data.data == 'WRONG_PASSWORD') msg = '비밀번호가 틀렸습니다.';
              if (result.data.data == 'SAME_PASSWORD') msg = '신규 비밀번호가 이전과 동일합니다.';
              alert(msg)
            }
            // alert("변경이 완료되었습니다.");
          })
          .catch((error) => {});
      }
    }
  };

  componentDidMount() {
    this.setState({ userInfo: reactLocalStorage.getObject("adminUser") });
  }

  render() {
    const { otpVisble, userInfo, isUpdate, updateInfo } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
         <Divider orientation="left" style={{fontSize:23}}>내 정보  </Divider>
        </div>
        {otpVisble && (
          <InputPassword
            visible={otpVisble}
            onCancel={() => {
              this.setState({ otpVisble: false });
            }}
          />
        )}
        <div className="wrapper mt-35 width-1240" />
        {/* <div>{JSON.stringify(userInfo)}</div> */}
        <div style={{ clear: "both" }} />
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
          <Ditems label="생성일">{formatDate(userInfo.createDate)}</Ditems>
          <Ditems label="아이디">{userInfo.userId}</Ditems>
          <Ditems label="이름">
            {isUpdate ? (
              <Input
                value={userInfo.name}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "name");
                }}
              />
            ) : (
              userInfo.name
            )}
          </Ditems>
          <Ditems label="이메일">
            {isUpdate ? (
              <Input
                value={updateInfo.email}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "email");
                }}
              />
            ) : (
              userInfo.email
            )}
          </Ditems>
          <Ditems label="IP주소">{userInfo.ipAddress}</Ditems>
          <Ditems label="휴대폰번호">
            {isUpdate ? (
              <Input
                value={updateInfo.mobile}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^0-9]+$/g, "");
                  this.onChangeModal(value, "mobile");
                }}
              />
            ) : (
              userInfo.mobile
            )}
          </Ditems>
          {isUpdate && (
            <Ditems label="비밀번호">
              <Input.Password
                value={this.state.pwd}
                placeholder="현재 비밀번호 입력"
                onChange={(e) => {
                  this.setState({ pwd: e.target.value });
                }}
              />
            </Ditems>
          )}
          {isUpdate && (
            <Ditems label="새 비밀번호">
              <Input.Password
                value={this.state.newPwd}
                placeholder="새로운 비밀번호 입력"
                onChange={(e) => this.setState({ newPwd: e.target.value })}
              />
            </Ditems>
          )}
          {/* <Ditems label="OTP" span={2}>
            {userInfo.otpVerified ? "OTP 인증 완료" : "OTP 미인증"}
          </Ditems> */}
          <Ditems label="코인권한" span={2}>
            {userInfo.authCoinList !== undefined &&
              userInfo.authCoinList.map((list, index) => {
                let coinName = "";
                coinList.forEach((coin) => {
                  if (coin.coinType === list) {
                    coinName = coin.name;
                  }
                });
                return userInfo.authCoinList.length - 1 === index
                  ? coinName
                  : coinName + ", ";
              })}
          </Ditems>
          <Ditems label="접근권한" span={2}>
            {userInfo.authCoinList !== undefined &&
              userInfo.authList.map((menu) => {
                return (
                  <div key={menu.idx} style={{ marginBottom: "6px" }}>
                    <div>- {menu.nameKr}</div>
                    <div style={{ paddingLeft: "10px" }}>
                      {menu.subMenu !== null &&
                        menu.subMenu.map((subMenu, index) => (
                          <span style={{ marginRight: "10px" }}>
                            {subMenu.nameKr}
                            {index !== menu.subMenu.length - 1 && ","}
                          </span>
                        ))}
                    </div>
                  </div>
                );
              })}
          </Ditems>
        </Descriptions>

        <Button
          style={{ marginTop: 10 }}
          onClick={() => {
            this.setState({ otpVisble: true });
          }}>
          OTP {userInfo.otpKey === null ? "생성" : "변경"}{" "}
        </Button>
        {isUpdate ? (
          <div style={{ float: "right", width: "150px", marginTop: "10px" }}>
            <Button
              style={{ marginRight: "10px" }}
              onClick={() =>
                this.setState({
                  pwd: "",
                  newPwd: null,
                  isUpdate: false,
                  userInfo: userInfo
                })
              }>
              취소
            </Button>
            <Button onClick={() => this.onUpdate()}>변경</Button>
          </div>
        ) : (
          <Button
            style={{ float: "right", width: "150px", marginTop: "10px" }}
            onClick={() =>
              this.setState({ isUpdate: true, updateInfo: userInfo })
            }>
            수정하기
          </Button>
        )}
        <div style={{ clear: "both", height: "10px" }} />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInfo);
