import React, { Component } from "react";
import { Table } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import string from "../../string";
import { amountFormat, priceDivideFormat } from "../../util";
import { reactLocalStorage } from "reactjs-localstorage";
import SelectBox from "../../components/common/SelectBox";
import FranchiseAdjustmentMemoModal from "../../components/frachise/FranchiseAdjustmentMemoModal";

export default class Adjustment extends Component {
  state = {
    adjustmentList: [],
    pagination: {
      current: 1,
      total: 0
    },
    coinList: [],
    modalVisible: false,
    modalData: {}
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getAdjustmentList({
      pageNum: pager.current
    });
  };

  getAdjustmentList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(httpUrl.getAdjustmentList, [10, pageNum]).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        adjustmentList: res.data.list,
        pagination
      });
    });
  };

  componentDidMount() {
    this.getAdjustmentList();
    this.setState({ coinList: reactLocalStorage.getObject("coinList") });
  }

  onChangeStatus = (logDate, franchiseId, coinType, status) => {
    let coinName = "";
    this.state.coinList.forEach((list) => {
      if (coinType === list.coinType) coinName = list.name;
    });
    if (
      global.confirm(
        `${logDate}일자의 가맹점 ${franchiseId}의 ${coinName} 코인에 대해 상태를 ${string.franchiseAdjustmentString[status]}(으)로 변경하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.adjustmentUpdate, [], {
        logDate: logDate,
        franchiseId: franchiseId,
        coinType: coinType,
        status: status
      })
        .then((result) => {
          if (result.data) {
            this.state.adjustmentList.find(
              (x) =>
                x.logDate == logDate &&
                x.franchiseId == franchiseId &&
                x.coinType == coinType
            ).status = status;
            this.setState({ adjustmentList: this.state.adjustmentList });
          }
        })
        .catch((error) => {});
    }
  };

  handleModalVisible = (row) => {
    this.setState({
      modalVisible: true,
      modalData: row
    });
  };

  render() {
    const columns = [
      {
        key: "logDate",
        title: "일자",
        dataIndex: "logDate",
        className: "support-column-number fs-15 lh-21"
      },
      {
        key: "franchiseId",
        title: "가맹점ID",
        dataIndex: "franchiseId",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "은행",
        dataIndex: "bankCode",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => <div>{string.bankString[data]}</div>
      },
      {
        title: "계좌번호",
        dataIndex: "bankAccount",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "코인종류",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div>
            {this.state.coinList.map((list) => {
              if (data === list.coinType) return list.name;
              else return null;
            })}
          </div>
        )
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "단가",
        dataIndex: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{priceDivideFormat(data)}</div>
      },
      {
        title: "금액",
        dataIndex: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {amountFormat(row.amount * priceDivideFormat(data), row.coinType)}
          </div>
        )
      },
      {
        title: "수수료",
        dataIndex: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {amountFormat(
              row.amount * priceDivideFormat(data) * row.feeRatio,
              row.coinType
            )}
          </div>
        )
      },
      {
        title: "지급액",
        dataIndex: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {amountFormat(
              row.amount * priceDivideFormat(data) * (1 - row.feeRatio),
              row.coinType
            )}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            <SelectBox
              value={string.franchiseAdjustmentString[data]}
              code={string.franchiseAdjustmentCode}
              codeString={string.franchiseAdjustmentString}
              onChange={(value) => {
                this.onChangeStatus(
                  row.logDate,
                  row.franchiseId,
                  row.coinType,
                  value
                );
              }}
            />
          </div>
        )
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "support-column-number fs-15 lh-21 pointer",
        render: (data, row, index) => (
          <div
            onClick={() => {
              this.handleModalVisible(row);
            }}>
            {data == null || data == "" ? "(메모없음)" : data}
          </div>
        )
      }
    ];
    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          정산관리
        </div>
        <FranchiseAdjustmentMemoModal
          visible={this.state.modalVisible}
          modalData={this.state.modalData}
          onCancel={() => {
            this.setState({
              modalVisible: false,
              modalData: {}
            });
          }}
          refresh={(data) => {
            this.state.adjustmentList.find(
              (x) =>
                x.logDate == data.logDate &&
                x.franchiseId == data.franchiseId &&
                x.coinType == data.coinType
            ).memo = data.memo;
            this.setState({ adjustmentList: this.state.adjustmentList });
          }}
        />
        <div style={{ marginBottom: "10px" }}>
          <Table
            rowKey={(record) => record.idx}
            dataSource={this.state.adjustmentList}
            columns={columns}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}
