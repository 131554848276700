import React, { Component } from "react";
import {
  Input,
  Checkbox,
  Form,
  Row,
  Col,
  DatePicker,
  Button,
  InputNumber,
  Upload,
  Icon,
  Modal,
  Divider
} from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/ko_KR";

import { endDateFormat, coinMultiFormat, bigNum } from "../../util";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import { httpPut, httpUrl, httpGet, makeUrl } from "../../api/httpClient";
import UserListModal from "../../components/user/UserListModal";

const FormItem = Form.Item;

const FoundationCoinSendDivide = Form.create()(
  class extends Component {
    state = {
      userListVisible: false,
      coinList: [],
      foundationList: [],
      foundationData: {
        date: 0,
        count: 1
      }
    };

    validateValue = (value, type) => {
      let result = false;
      if (type === "amount") {
        var regNumber = /^[0-9]*$/;
        if (!regNumber.test(value)) {
          // alert("숫자만 입력가능합니다");
          return true;
        }
      }
      return result;
    };

    handleChange = (value, type) => {
      let obj = Object.assign({}, this.state.foundationData);
      if (type === "coinType") obj["destinationTag"] = "";
      if (type === "isLock") {
        obj["expireDate"] = "";
        obj["date"] = "";
        obj["count"] = "";
        obj["isRepeat"] = false;
      }
      if (type === "isRepeat") {
        obj["date"] = "";
        obj["count"] = "";
      }
      if (type === "expireDate") {
        value = endDateFormat(value);
      }
      if (type === "amount") {
        value = parseFloat(value);
      }
      if (type === "date" || type === "count") value = parseInt(value);
      obj[type] = value;

      this.setState({
        foundationData: obj
      });
      // if (!this.validateValue(value, type)) {
      // } else {
      // }
    };

    disabledDate = current => {
      // Can not select days before today and today
      return current < moment().startOf("day");
    };

    handleCalculateAvg = () => {
      const { foundationData } = this.state;
      if (
        foundationData.amount / foundationData.count > 0 &&
        foundationData.date !== ""
      ) {
        let amount = foundationData.amount;
        let count = foundationData.count;
        let avg = Math.floor((amount / count / amount) * 100 * 100) / 100;
        let rest = ((100 - avg * (count - 1)) * 100) / 100;
        return (
          <FormItem
            label={<span></span>}
            colon={false}>{`LOCK 해제비율: ${avg}% 마지막 해제: ${rest.toFixed(
            2
          )}%`}</FormItem>
        );
      } else {
        return null;
      }
    };

    onSubmit = e => {
      const { foundationData } = this.state;
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          //error가 없으면
          let selectedCoinData = {};
          this.state.foundationList.forEach(item => {
            if (item.coinType === this.state.foundationData.coinType) {
              selectedCoinData = item;
            }
          });
          let sendData = {
            amount: foundationData.isLock
              ? foundationData.amount
              : coinMultiFormat(foundationData.amount, foundationData.coinType),
            blockchain: foundationData.isBlockChain ? 1 : 0,
            coinType: foundationData.coinType,
            destinationTag:
              foundationData.destinationTag !== ""
                ? foundationData.destinationTag
                : 0,
            expireDate: foundationData.expireDate,
            date: foundationData.isRepeat ? foundationData.date : 0,
            count: foundationData.isRepeat ? foundationData.count : 1,
            to: foundationData.address,
            idx: selectedCoinData.idx,
            memo: foundationData.memo
          };
          if (global.confirm("재단코인을 배포하시겠습니까?")) {
            httpPut(httpUrl.foundationSendDivide, [], sendData).then(res => {
              if (res.data.result !== "FAIL") {
                alert("전송이 완료되었습니다.");
                this.props.form.resetFields();
                this.setState({
                  foundationData: {}
                });
              } else {
                if (res.data.data === "ADDRESS_NOT_EXIST") {
                  alert("주소가 올바르지 않습니다. 다시 확인해주세요.");
                } else if (res.data.data === "COIN_NOT_ENOUGH") {
                  alert("재단 코인 잔액이 부족합니다.");
                } else if (res.data.data === "FEE_NOT_ENOUGH") {
                  alert("플랫 잔액가 부족합니다.");
                }
              }
            });
          }
        }
      });
    };

    componentDidMount() {
      httpGet(httpUrl.getFoundationList, [], {}).then(res => {
        this.setState({
          foundationList: res.data,
          coinList: res.data.map(item => {
            return item.coinType;
          })
        });
      });
    }

    render() {
      const { foundationData } = this.state;
      const { form } = this.props;
      const { getFieldDecorator } = form;

      const props = {
        action: `${makeUrl(httpUrl.foundationExcelDivide, null)}`,
        withCredentials: true,
        multiple: false,
        beforeUpload: file => {
          if (global.confirm("배포 하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: file => {
          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s =
            "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
          Modal.info({
            title: "배포결과",
            content: (
              <div>
                <p>
                  {file.data.failAddresses.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- 실패한 배포\n`}
                      {file.data.failAddresses.length > 0 &&
                        file.data.failAddresses.map(value => (
                          <div>{value}</div>
                        ))}
                    </div>
                  ) : (
                    <div>{s}</div>
                  )}
                </p>
              </div>
            ),
            onOk() {}
          });
        },
        onError(err) {
          Modal.error({
            title: "배포결과",
            content: "파일 업로드 실패"
          });
        }
      };

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 16,
            offset: 8
          }
        }
      };

      return (
        <div>
          <div
            style={{
              fontSize: "20px",
              color: "#000",
              textAlign: "center",
              marginBottom: "30px",
              fontWeight: "bold"
            }}>
             <Divider orientation="left" style={{fontSize:23}}>재단코인배포8:2</Divider>  
          </div>
          <div style={{ clear: "both" }} />
          <div className="wrapper mt-35 width-1240" />
          <div
            style={{
              maxWidth: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 0,
              marginBottom: 0,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <Form {...formItemLayout} onSubmit={this.onSubmit}>
              <FormItem label={<span></span>} colon={false}>
                <Upload {...props} accept=".xlsx, .xls" showUploadList={false}>
                  <Button style={{ marginRight: "20px" }}>
                    <Icon type="upload" style={{ fontSize: "20px" }} /> 파일
                    업로드
                  </Button>
                </Upload>
                <a href={"/foundationFormat.xlsx"} download>
                  <Button>
                    <Icon type="download" style={{ fontSize: "20px" }} />
                    양식 다운로드
                  </Button>
                </a>
                <span style={{ color: "blue", marginLeft: "10px" }}>
                  * 여러 사람에게 배포 시 파일업로드 사용
                </span>
              </FormItem>
              <FormItem label={<span>coinType&nbsp;</span>}>
                <Row>
                  <Col span={6}>
                    {getFieldDecorator("coinType", {
                      rules: [
                        {
                          required: true,
                          message: "코인 종류를 선택해주세요!"
                        }
                      ]
                    })(
                      <CoinSelectBox
                        type="coinType"
                        placeholder="선택해주세요."
                        coinCode={this.state.coinList}
                        onChange={this.handleChange}
                        style={{ width: "160px" }}
                      />
                    )}
                  </Col>
                  <Col span={18}>
                    {foundationData.coinType === 3 && (
                      <FormItem style={{ marginBottom: 0 }}>
                        {getFieldDecorator("destinationTag", {
                          rules: [
                            {
                              required: true,
                              message: "destinationTag를 입력해주세요."
                            }
                          ]
                        })(
                          <Input
                            allowClear
                            onChange={e =>
                              this.handleChange(
                                e.target.value,
                                "destinationTag"
                              )
                            }
                            placeholder="destinationTag"
                            style={{ width: "300px" }}
                          />
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
              </FormItem>
              <div>
                <FormItem label={<span>address&nbsp;</span>}>
                  <div>
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "주소를 입력해주세요!"
                      }
                    ]
                  })(
                    <Input
                      allowClear
                      onChange={e => this.handleChange(e.target.value, "address")}
                      style={{ width: "360px" }}
                      placeholder="주소를 입력해주세요"
                    />
                  )}
                  <Button
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      if (!foundationData.coinType) {
                        alert('코인을 먼저 선택해주세요')
                        return;
                      }
                      this.setState({userListVisible: true});
                    }}>
                    회원검색
                  </Button>
                  </div>
                </FormItem>
              </div>
              <FormItem label={<span>lock&nbsp;</span>}>
                <Row>
                  <Col span={1}>
                    <Checkbox
                      checked={foundationData.isLock}
                      onChange={e => {
                        this.handleChange(e.target.checked, "isLock");
                      }}
                    />
                  </Col>
                  {foundationData.isLock && (
                    <Col span={6}>
                      <Form.Item className="inline-formitem m-b-0">
                        {getFieldDecorator("endDate", {
                          rules: [
                            {
                              required: true,
                              message: "LOCK 해제일시를 선택해주세요!"
                            }
                          ]
                        })(
                          <DatePicker
                            locale={locale}
                            placeholder="LOCK 해제일시"
                            disabledDate={this.disabledDate}
                            onChange={date => {
                              this.handleChange(date, "expireDate");
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {foundationData.expireDate && (
                    <Col>
                      <span>{`${endDateFormat(
                        foundationData.expireDate
                      )} 이후로 LOCK이 해제됩니다.`}</span>
                    </Col>
                  )}
                </Row>
              </FormItem>
              {foundationData.isLock && (
                <FormItem label={<span>반복&nbsp;</span>}>
                  <Row>
                    <Col span={1}>
                      <Checkbox
                        checked={foundationData.isRepeat}
                        onChange={e =>
                          this.handleChange(e.target.checked, "isRepeat")
                        }
                      />
                    </Col>
                    {foundationData.isRepeat && (
                      <span>
                        <Col span={6}>
                          <FormItem className="inline-formitem m-b-0">
                            {getFieldDecorator("date", {
                              rules: [
                                {
                                  required: true,
                                  message: "간격을 입력해주세요!"
                                },
                                {
                                  validator: (rule, value, cb) => value > 0,
                                  message: "수량은 0보다 커야 합니다"
                                }
                              ]
                            })(
                              <InputNumber
                                min={0}
                                formatter={value =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={value =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                onChange={value =>
                                  this.handleChange(value, "date")
                                }
                                style={{ width: "200px" }}
                                placeholder="간격을 입력해주세요"
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={1} />
                        <Col span={6}>
                          <FormItem className="inline-formitem m-b-0">
                            {getFieldDecorator("count", {
                              rules: [
                                {
                                  required: true,
                                  message: "횟수를 입력해주세요!"
                                },
                                {
                                  validator: (rule, value, cb) => value > 0,
                                  message: "수량은 0보다 커야 합니다"
                                }
                              ]
                            })(
                              <InputNumber
                                min={0}
                                formatter={value =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={value =>
                                  value.replace(/\$\s?|(,*)/g, "")
                                }
                                onChange={value =>
                                  this.handleChange(value, "count")
                                }
                                style={{ width: "200px" }}
                                placeholder="횟수를 입력해주세요"
                              />
                            )}
                          </FormItem>
                        </Col>
                      </span>
                    )}
                  </Row>
                </FormItem>
              )}
              <FormItem label={<span>amount&nbsp;</span>}>
                {getFieldDecorator("amount", {
                  rules: [
                    {
                      required: true,
                      message: "amount를 입력해주세요!"
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (value && foundationData.coinType === 0) {
                          cb("코인 종류를 선택해주세요.");
                        }
                        cb();
                      }
                    }
                  ]
                })(
                  <Input
                    allowClear
                    onChange={e => this.handleChange(e.target.value, "amount")}
                    style={{ width: "300px" }}
                    placeholder="수량을 입력해주세요"
                    onBlur={() => {
                      this.props.form.setFieldsValue({
                        amount: bigNum(foundationData.amount)
                      });
                      this.props.form.validateFields(["amount"], {
                        force: true
                      });
                    }}
                  />
                )}
              </FormItem>
              {this.handleCalculateAvg()}
              <FormItem label={<span>블록체인 전송여부&nbsp;</span>}>
                <Checkbox
                  checked={foundationData.isBlockChain}
                  onChange={e =>
                    this.handleChange(e.target.checked, "isBlockChain")
                  }
                />
              </FormItem>
              {foundationData.isLock && (
                <FormItem label={<span>LOCK 메모&nbsp;</span>}>
                  {getFieldDecorator("memo", {
                    rules: [
                      {
                        required: false,
                        message: "메모를 입력해주세요!"
                      }
                    ]
                  })(
                    <Input.TextArea
                      placeholder="메모를 입력해주세요"
                      allowClear
                      style={{ width: "360px", minHeight: "30px" }}
                      onChange={e => this.handleChange(e.target.value, "memo")}
                    />
                  )}
                </FormItem>
              )}
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  보내기
                </Button>
              </Form.Item>
            </Form>
          </div>
          <UserListModal
            visible={this.state.userListVisible}
            onOk={(value) => {
              httpGet(httpUrl.getAddress, [foundationData.coinType, value], {}).then(res => {
                if (res.data) {
                  this.props.form.setFieldsValue({
                    address: res.data.address
                  });
                  this.handleChange(res.data.address, "address")
                } 
                else alert('해당 코인의 지갑주소가 생성되지 않았습니다.')
              });
              this.setState({
                userListVisible: false,
              });
            }}
            onCancel={() => {
              this.setState({
                userListVisible: false,
              });
            }}
          />
        </div>
      );
    }
  }
);

export default FoundationCoinSendDivide;
