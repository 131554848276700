import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, Select, Button, DatePicker ,Icon,Divider} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import {
  numberFormat,
  amountFormat,
  formatDate,
  startDateFormat,
  endDateFormat,
  excelFormat,
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import fileDownload from "js-file-download";
import { SearchOutlined } from "@ant-design/icons";

const Option = Select.Option;
const { RangePicker } = DatePicker;

export default class TransferLog extends Component {
  state = {
    fullCoinList: [],
    transferLog: [],

    pagination: {
      current: 1,
      total: 0,
    },
    coinType: 0,
    requestType: "ALL",
    //searchType: ALL일 경우 searchText: null
    searchType: "ALL",
    transferType: "ALL",
    startDate: "",
    endDate: "",
    searchText: "",
    isLoaded: false,
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.getTransferLog({
      pageNum: pager.current,
    });
  };

  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true,
    });
  };

  handleChangeSearchText = (e) => {
    this.setState({
      searchText: e.target.value,
      isLoaded: true,
    });
  };

  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true,
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  handleChangeRequestType = (value) => {
    this.setState({
      requestType: value,
      isLoaded: true,
    });
  };

  handleChangeTransferType = (value) => {
    this.setState({
      transferType: value,
      isLoaded: true,
    });
  };

  handleChangeSearchType = (value) => {
    let searchText = "";
    if (value !== "ALL") {
      searchText = this.state.searchText;
    }
    this.setState({
      searchType: value,
      searchText,
      isLoaded: true,
    });
  };

  getTransferLog = (params = {}) => {
    let {
      coinType,
      requestType,
      searchType,
      startDate,
      endDate,
      searchText,
      transferType,
    } = this.state;
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getTransferLog,
      [
        10,
        pageNum,
        coinType,
        requestType,
        searchType,
        startDate,
        endDate,
        searchText,
        transferType,
      ],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        transferLog: res.data.list,
        pagination,
        isLoaded: false,
      });
    });
  };

  downloadExcel = () => {
    let {
      coinType,
      requestType,
      searchType,
      startDate,
      endDate,
      searchText,
      isLoaded,
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    if (!startDate || !endDate) {
      alert("검색 기간 날짜를 선택해주세요.");
    } else {
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(
            httpUrl.downloadTransferLogExcel,
            [
              this.state.pagination.total,
              1,
              coinType,
              requestType,
              searchType,
              startDate,
              endDate,
              searchText,
            ],
            {}
          ).then((res) => {
            fileDownload(
              res,
              // `${excelFormat(startDate)} ~ ${excelFormat(endDate)} ${
              //   coinList.find((coin) => coin.coinType === coinType).name}
               '거래현황.xlsx'
            );
          });
        }
      }
    }
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.setState({
      fullCoinList: [
        {
          coinType: 0,
          name: "전체",
        },
        ...coinList,
      ],
    });
    this.getTransferLog();
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");
    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:12.5}}>{numberFormat(data)}</div>,
      },
      {
        title: "거래 일시",
        dataIndex: "createDate",
        key: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:12.5}}>{formatDate(data)}</div>,
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{fontSize:12.5}}>{coinList.find((coin) => coin.coinType === data).name}</div>
        ),
      },
      {
        title: "전송 방법",
        dataIndex: "type",
        key: "type",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
          <div style={{fontSize:12.5}}>
            {string.transferString[data] ? string.transferString[data] : "기타"}
          </div>
        ),
      },
      {
        title: "보낸 주소",
        dataIndex: "from",
        key: "from",
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div style={{fontSize:12.5}}>{data}</div>
        )
      },
      {
        title: "받은 주소",
        dataIndex: "to",
        key: "to",
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div style={{fontSize:12.5}}>{data}</div>
        )
      },
      {
        title: "받은주소회원(번호.이름.ID)",
        dataIndex: "userIdx",
        key:"userIdx",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => <div style={{fontSize:12.5}}><div>{data === null ? "-" : "("+data+")" } {row.userName}</div><div>{row.userId}</div> </div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        width: 180,
        key: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div style={{fontSize:12.5}}>{amountFormat(data, row.coinType)}</div>,
      },     
      {
        title: "상태",
        dataIndex: "status",
        key: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:12.5}}>{string.requestLogString[data]}</div>,
      },
    ];

    const expandedRowRender = (record) => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: "txid",
          key: "txid",
          dataIndex: "txid",
          width: 480,
          className: "support-column-number fs-15 lh-21",
          render: (data) => <div style={{fontSize:12.5}}>{data === null ? "-" : data}</div>,
        },
        {
          title: "수수료",
          dataIndex: "fee",
          width: 180,
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => <div style={{fontSize:12.5}}>{amountFormat(data, row.coinType)}</div>,
        },
        {
          title: "메모",
          dataIndex: "memo",
          className: "support-column-number fs-15 lh-21",
          width: 300,
          render: (data) => <div style={{fontSize:12.5}}>{data ? data : "-"}</div>,
        },
        record.coinType === 3
          ? {
              title: "destinationTag",
              key: "destinationTag",
              dataIndex: "destinationTag",
              className: "support-column-number fs-15 lh-21",
            }
          : {
              colSpan: 0,
              width: 0,
              render: () => {
                return {
                  props: {
                    colSpan: 0,
                  },
                };
              },
            },
      ];
      return (
        <Table
          rowKey={(record) => record.idx}
          columns={dropColumns}
          dataSource={dataArr}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{fontSize:23}}>거래현황  </Divider>
        </div>
        <div className="m-b-10 flex-row flex-sb">
        <Button onClick={this.downloadExcel}> <Icon type="download" style={{ fontSize: "20px" }} />엑셀 다운로드 </Button>
          <div className="flex-row flex-center">
            <label style={{fontSize:13}}>코인 종류&nbsp;</label>
          <div className="flex-row flex-center">
            <Select
              placeholder="코인종류"
              style={{ width: "160px", marginRight: "10px" }}
              value={this.state.coinType}
              onChange={this.handleChangeCoinType}>
              {this.state.fullCoinList.map((coin) => (
                <Option key={coin.coinType} value={coin.coinType}>
                  {coin.name}
                </Option>
              ))}
            </Select>
          </div>
            <label style={{fontSize:13}}>요청 조건&nbsp;</label>
            <SelectBox
              code={string.requestLogCode}
              codeString={string.requestLogString}
              value={string.requestLogString[this.state.requestType]}
              onChange={(value) => {
                this.handleChangeRequestType(value);
              }}
              style={{ width: "100px", marginRight: 10 }}
            />
            <label style={{fontSize:13}}>전송 방법&nbsp;</label>
            <SelectBox
              codeString={string.transferString}
              value={string.transferString[this.state.transferType]}
              onChange={(value) => {
                this.handleChangeTransferType(value);
              }}
              style={{ width: "100px",marginRight:10 }}
            />
            <div className="flex-row flex-center m-r-10">
            <label style={{fontSize:13}}>검색기간&nbsp;</label>
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <div className="flex-row flex-center m-r-10">
         
              <label style={{fontSize:13}}>&nbsp;</label>
              <SelectBox
                code={string.walletLogCode}
                codeString={string.transferSearchString}
                value={string.transferSearchString[this.state.searchType]}
                onChange={(value) => {
                  this.handleChangeSearchType(value);
                }}
                style={{ width: "110px" }}
              />
              <Input
                placeholder="검색어를 입력해주세요."
                disabled={this.state.searchType === "ALL"}
                value={this.state.searchText}
                onChange={this.handleChangeSearchText}
                style={{ width: "240px" }}
              />
            <Button
              type="primary"
              style={{width:60}}
              onClick={() => {
                this.getTransferLog();
              }}>
              <SearchOutlined/>
            </Button>
            </div>
            </div>
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
      
        
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.transferLog}
          pagination={this.state.pagination}
          columns={columns}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}
