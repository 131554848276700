import React, { Component } from "react";
import { Form, Modal, Input, Checkbox } from "antd";

import string from "../../string";
import { httpPost, httpUrl } from "../../api/httpClient";
import SelectBox from "../common/SelectBox";

const FormItem = Form.Item;

const DetailRegistryModal = Form.create()(
  class extends Component {
    state = {
      registryData: {
        title: global.lanList.map(lan => {
          return {
            content: "",
            language: lan
          };
        }),
        content: global.lanList.map(lan => {
          return {
            content: "",
            language: lan
          };
        })
      },
      checked: false
    };

    onSelect = (value, type, language) => {
      const { registryData } = this.state;
      var data = Object.assign({}, registryData);
      let arr = data[type];
      if (type === "category") {
        data.category = value;
      } else if (type === "title") {
        arr = arr.map(item => {
          if (item.language === language) {
            return { content: value, language: language };
          } else return item;
        });
        data.title = arr;
      } else if (type === "content") {
        arr = arr.map(item => {
          if (item.language === language) {
            return { content: value, language: language };
          } else return item;
        });
        data.content = arr;
      } else {
        this.setState({
          checked: value
        });
      }
      this.setState({
        registryData: data
      });
      // console.log(JSON.stringify(data, null, 4));
    };

    onRegistry = () => {
      const { checked, registryData } = this.state;
      const { type } = this.props;
      let createData = {};
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm(`생성하시겠습니까?`)) {
            if (type === "notice") {
              createData = {
                category: registryData.category,
                title: registryData.title,
                content: registryData.content,
                main: checked ? 1 : 0
              };
              // console.log(JSON.stringify(createData, null, 4));
              httpPost(httpUrl.noticeCreate, [], createData)
                .then(result => {
                  this.props.onOk();
                  this.props.form.resetFields();
                })
                .catch(error => {});
            } else {
              createData = {
                title: registryData.title,
                content: registryData.content
              };
              // console.log(JSON.stringify(createData, null, 4));
              httpPost(httpUrl.faqCreate, [], createData)
                .then(result => {
                  this.setState({
                    checked: false
                  });
                  this.props.onOk();
                  this.props.form.resetFields();
                })
                .catch(error => {});
            }
          }
        }
      });
    };

    onCancel = () => {
      this.setState({
        registryData: {
          title: global.lanList.map(lan => {
            return {
              content: "",
              language: lan
            };
          }),
          content: global.lanList.map(lan => {
            return {
              content: "",
              language: lan
            };
          })
        },
        checked: false
      });
      this.props.onCancel();
      this.props.form.resetFields();
    };

    render() {
      const { checked } = this.state;
      const { visible, type, form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 17 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={type === "notice" ? "공지사항" : "FAQ"}
          okText="생성"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "800px" }}>
          <div>
            <Form {...formItemLayout} onSubmit={this.onRegistry}>
              {type === "notice" && (
                <FormItem label={<span>구분&nbsp;</span>}>
                  {getFieldDecorator("category", {
                    rules: [
                      {
                        required: true,
                        message: "카테고리를 선택해주세요!"
                      }
                    ]
                  })(
                    <SelectBox
                      type="category"
                      placeholder="선택해주세요"
                      code={string.noticeSelectCode}
                      codeString={string.noticeSelect}
                      onChange={this.onSelect}
                      style={{ width: "200px" }}
                    />
                  )}
                  <Checkbox
                    style={{ float: "right" }}
                    onChange={e => {
                      this.onSelect(e.target.checked, "checked");
                    }}
                    checked={checked}>
                    메인 노출
                  </Checkbox>
                </FormItem>
              )}
              {global.lanList.map((lan, index) => (
                <div key={index}>
                  <FormItem
                    key={`title${lan}${index}`}
                    label={<span>제목({lan})&nbsp;</span>}>
                    {getFieldDecorator(`title[${index}]`, {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: lan === "ko" ? true : false,
                          message: "제목을 입력해주세요!"
                        }
                      ]
                    })(
                      <Input
                        allowClear
                        onChange={e => {
                          this.onSelect(e.target.value, "title", lan);
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem
                    key={`content${lan}${index}`}
                    label={<span>내용({lan})&nbsp;</span>}>
                    {getFieldDecorator(`content[${index}]`, {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [
                        {
                          required: lan === "ko" ? true : false,
                          message: "내용을 입력해주세요!"
                        }
                      ]
                    })(
                      <Input.TextArea
                        onChange={e => {
                          this.onSelect(e.target.value, "content", lan);
                        }}
                      />
                    )}
                  </FormItem>
                </div>
              ))}
            </Form>
          </div>
        </Modal>
      );
    }
  }
);

export default DetailRegistryModal;
