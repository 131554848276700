import React, { Component } from "react";
import { Modal, Table, Button } from "antd";
import {
  formatDate,
  endDateFormat,
  amountFormat,
  numberFormat
} from "../../util";
import { httpGet, httpUrl } from "../../api/httpClient";
import LockDetail from "./LockDetail";
import LockCreateModal from "./../common/LockCreateModal";

class UserLock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailVisible: false,
      detailList: [],
      selectedDetail: {},
      detailPagination: {
        total: 0,
        current: 1
      },

      visible: false,
      modalType: "create",
      selectData: {}
    };
  }

  detailHandleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.detailPagination };
    pager.current = pagination.current;
    this.setState({
      detailPagination: pager
    });
    this.lockDetail({
      pageNum: pager.current
    });
  };

  lockDetail = (params = {}) => {
    const { detailList, selectedDetail, detailPagination } = this.state;

    let pageNum = params.pageNum || 1;
    // console.log(pageNum);
    if (params.refresh) {
      pageNum =
        detailList.length === 1
          ? detailPagination.current === 1
            ? detailPagination.current
            : detailPagination.current - 1
          : detailPagination.current;
    }
    httpGet(
      httpUrl.foundationUserLockDetail,
      [
        10,
        pageNum,
        this.props.selectedUser.coinType,
        this.props.selectedUser.address,
        endDateFormat(selectedDetail.expireDate)
      ],
      {}
    )
      .then(result => {
        const detailPagination = { ...this.state.detailPagination };
        detailPagination.current = result.data.currentPage;
        detailPagination.total = result.data.totalCount;
        this.setState({
          detailVisible: true,
          detailList: result.data.lockDetail,
          detailPagination
        });
        // console.log(`userLock = ${JSON.stringify(result.data, null, 4)}`);
      })
      .catch(error => {});
  };

  render() {
    const { visible, onOk, modalData, selectedUser } = this.props;

    const lockColumns = [
      // {
      //   title: "",
      //   dataIndex: "idx",
      //   key: "idx",
      //   width: 80,
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data, row) => <div>{numberFormat(data)}</div>
      // },
      {
        title: "마감일자",
        dataIndex: "expireDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{formatDate(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <div>
            {data === -1
              ? "전체잠금"
              : amountFormat(data, selectedUser.coinType)}
          </div>
        )
      },
      {
        title: "횟수",
        dataIndex: "count",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState(
                {
                  selectedDetail: row
                },
                () => {
                  this.lockDetail();
                }
              );
            }}>
            현황
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title="LOCK 현황"
        onCancel={onOk}
        style={{ minWidth: "1000px" }}
        footer={null}>
        <div style={{ clear: "both" }} />
        <LockDetail
          pageType="foundationStatus"
          title="마감 일자 별 LOCK 상세 현황"
          lockDetail={this.lockDetail}
          modalData={this.state.detailList}
          visible={this.state.detailVisible}
          pagination={this.state.detailPagination}
          handleTableChange={this.detailHandleTableChange}
          onOk={() => {
            this.setState({
              detailVisible: false
            });
          }}
          selected={this.props.selectedUser}
          selectedRow={this.state.selectedDetail}
          coinList={{
            coinType: this.props.selectedUser.coinType,
            address: this.props.selectedUser.address
          }}
          refresh={this.props.refresh}
        />
        <div style={{ marginBottom: "10px" }}>
          <span>{`LOCK 총 수량 : ${
            modalData.lockTotal === -1
              ? "전체 잠금"
              : amountFormat(
                  modalData.lockTotal,
                  this.props.selectedUser.coinType
                )
          }`}</span>
          {this.props.selectedUser.availableBalance !== 0 && (
            <Button
              style={{ float: "right", marginBottom: "10px" }}
              onClick={() => {
                // if (
                //   this.props.selectedUser.lockBalance === -1 ||
                //   this.props.selectedUser.availableBalance === 0
                // ) {
                //   alert("잠금 가능 수량이 없습니다.");
                // } else {
                // }
                this.setState({
                  visible: true,
                  modalType: "create"
                });
              }}>
              Lock 생성
            </Button>
          )}
          <LockCreateModal
            pageType="foundationStatus"
            modalType={this.state.modalType}
            editData={this.state.selectData}
            visible={this.state.visible}
            coinList={{
              coinType: this.props.selectedUser.coinType,
              address: this.props.selectedUser.address
            }}
            onCancel={() => {
              this.setState({
                visible: false,
                modalType: "create",
                selectData: {}
              });
            }}
            refresh={this.props.refresh}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <span style={{ color: "blue" }}>
            * 전체잠금이 설정된 기간동안은 개별 락의 상태와 관계없이 해당 지갑의
            해당 코인에 대해 모든 출금이 금지됩니다.
          </span>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          columns={lockColumns}
          rowKey={record => record.idx}
          style={{ marginBottom: "10px" }}
          dataSource={modalData.lockResponse}
          pagination={this.props.pagination}
          onChange={this.props.handleTableChange}
        />
      </Modal>
    );
  }
}

export default UserLock;
