import { Button, DatePicker, Input, Table } from "antd";
import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import MozInvestRegist from "./MozInvestRegist";
import MozInvestModify from "./MozInvestModify";
import { httpGet, httpPost, httpUrl } from "../../api/httpClient";
import { numberFormat, formatDate, amountFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class MozInvestRewardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1
      },
    };
  }

  componentDidMount() {
    this.getList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, ()=>this.getList());
  };

  initialLoad = () => {
    this.setState({
      list: [],
      pagination: {
        total: 0,
        current: 1
      }}, () => {
        this.getList();
      })
  }
  reload = () => {
    this.getList();
  }
  getList = () => {
    let pageNum = this.state.pagination.current;

    httpGet(httpUrl.mozInvestRewardList,[10,pageNum,],{}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.list,
        pagination,
      });
    });
  };
  render() {
    const { list } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    const categoryString = {
      INVEST: '투자',
      REWARD: '투자보상',
      RECOMMEND: '추천인보상'
    }
    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "아이디",
        dataIndex: "userId",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "이름",
        dataIndex: "userName",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "연락처",
        dataIndex: "userPhone",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "구분",
        dataIndex: "category",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => categoryString[data]
      },
      {
        title: "코인종류",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => coinList.find(x=>x.coinType == data).name
      },
      {
        title: "코인수량",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => amountFormat(data, row.coinType) + ' ' + coinList.find(x=>x.coinType == row.coinType).symbol
      },
      {
        title: "일시",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render:(data,row) =>
        <div>{formatDate(data)}</div>
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          투자보상내역
      </div>
        <div className="wrapper mt-35 width-1240" />

        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default MozInvestRewardList;
