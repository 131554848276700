import React, { Component } from "react";
import { Modal, Descriptions, Input, Switch, Icon, Button } from "antd";

import { formatDate } from "../../util";
import string from "../../string";
import { httpPut, httpUrl, httpGet } from "../../api/httpClient";
import SelectBox from "../common/SelectBox";
import Const from "../../const";

const Ditems = Descriptions.Item;

class UserStemDetail extends Component {
  state = {
    detailData: {},
    changePasswordUrl: ""
  };
  handleResetSecurityPassword = (idx) => {
    if (global.confirm("보안비밀번호를 0000으로 초기화 하시겠습니까?")) {
      httpGet(httpUrl.resetSecurityPassword, [idx], {}).then((res) => {
        if (res.data.result === "SUCCESS") {
          alert("보안비밀번호가 0000으로 변경 되었습니다.")
        } else {
          alert("오류가 발생하였습니다.")
        }
      });
    }
  };

  handleGetToken = (idx) => {
    httpGet(httpUrl.getUserToken, [idx], {}).then((res) => {
      if (res.data.result === "SUCCESS") {
        this.setState({
          changePasswordUrl: `${
            Const.serverProtocol +
            "://" +
            Const.clientIp +
            ":" +
            Const.clientPort
            }/changePassword/${res.data.data}`
        });
      } else {
        this.setState({
          changePasswordUrl: "오류가 발생하였습니다."
        });
      }
    });
  };

  

  onChangeModal = (value, type) => {
    var data = this.state.detailData;
    data[type] = value;
    this.setState({
      detailData: data
    });
  };

  onOk = () => {
    const { detailData } = this.state;
    let updateData = {
      ...detailData,
      hideBalanceFlag: detailData.hideBalanceFlag ? 1 : 0
    };
    // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
    if (global.confirm(`변경하시겠습니까?`)) {
      httpPut(httpUrl.userStemUpdate, [], updateData)
        .then((result) => {
          alert("변경이 완료되었습니다.");
          this.props.onOk();
        })
        .catch((error) => { });
    }
  };
  getUserDetailList = (params = {}) => {
    const { searchType, searchText, startDate, endDate } = this.state
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getUserList, [10, pageNum, searchType, searchText, startDate, endDate], {})
      .then(res => {
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.userStemList,
          pagination,
        })
      })

  }
  onUpdateUserDetail = (idx, recommender) => {
    httpPut(httpUrl.updateUserDetail, [], { idx: idx, recommender: recommender }).then((res) => {

      this.getUserDetailList({
        pageNum: this.state.pagination.current,
      });


    });

  };

  onCancel = () => {
    // const obj = Object.assign({}, this.props.modalData);
    // this.setState({
    //   detailData: obj
    // });
    this.props.onCancel();
  };


  componentDidMount() {
    const obj = Object.assign({}, this.props.modalData);
    this.setState({
      detailData: obj
    });
  }

  render() {
    const { visible, rootPage } = this.props;
    const { detailData } = this.state;
    


    return (
      <Modal
        visible={visible}
        title="L7stem 회원정보"
        onCancel={this.onCancel}
        onOk={rootPage === "franchise" ? this.props.onOk : this.onOk}
        okText={rootPage === "franchise" ? "확인" : "변경사항저장"}
        cancelText="닫기"
        style={{ minWidth: "1000px" }}>
        {/* <div>{JSON.stringify(modalData)}</div> */}
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
          
        <Ditems label="회원구분">
          <span> 
          {detailData.regularMember === 1 ? <div style={{fontWeight:'bold',border:'1px solid gray',borderRadius:'5px',width:60,textAlign:"center"}}>정회원</div>
           : <div style={{border:'1px solid lightgray',borderRadius:'5px',color:'gray',width:60,textAlign:"center"}}>소비자</div>}
          </span>
          </Ditems>
          <Ditems label="아이디">
           <div> {detailData.id}</div> 
          </Ditems>
          
            
          <Ditems label="보안레벨">{detailData.securityLevel} LEVEL</Ditems>  
          <Ditems label="이름">
            <Input
              value={detailData.name}
              placeholder="홍길동"
              style={{width:"250px"}}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "name");
              }}
            />
          </Ditems>

          <Ditems label="등급">
            <Input
              allowClear
              value={detailData.grade}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "grade");
              }}
              style={{width:"100px"}}
            /> &nbsp;
          [
            {detailData.grade === 1 ? "딜러"  :
           detailData.grade === 2 ? "대리점" :
           detailData.grade === 3 ? "지점" :
           detailData.grade === 4 ? "지사" :
           detailData.grade === 5? "본부" : "없음"}
          ]
            <br/>
          <span style={{fontSize:9}}>ex:) 0:없음, 1:딜러, 2:대리점, 3:지점, 4:지사, 5:본부</span>
          </Ditems>    
         
               
          <Ditems label="휴대폰번호">
            <Input
              style={{width:"250px"}}
              value={detailData.phone}
              placeholder="ex) 01012345678"
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]+$/g, "");
                this.onChangeModal(value, "phone");
              }}
            />
          </Ditems>
        
          <Ditems label="본인 | 추천인">[{detailData.name}]&nbsp;{detailData.recommendCode}&nbsp;|
           &nbsp;&nbsp;&nbsp;
            {/* <Button
              onClick={(e) => {
                if (global.prompt('변경할 추천인 코드 입력', detailData.recommender)) {

                  this.onChangeModal(e.target.value, "recommender");

                }
              }}>
              변경
            </Button> */}
              [{detailData.recommenderName ? detailData.recommenderName : "-"}]&nbsp;
            <Input
              value={detailData.recommender}
              onChange={(e) => {

                this.onChangeModal(e.target.value, "recommender");
              }}

              style={{ width: "80px" }}
            />&nbsp;

          </Ditems>

          <Ditems label="주민등록번호">
          <Input
                  allowClear
                  placeholder="주민등록번호를 입력해주세요."
                  value={detailData.registrationNumber}
                  style={{width:"250px"}}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "registrationNumber");
                  }}
                />
          </Ditems>

          <Ditems label="후원인">
          <Input
             
              allowClear
              value={detailData.parentIdx}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "parentIdx");
              }}
              style={{ width:"100px"}}
              />&nbsp;
              [{detailData.userName ? detailData.userName: "없음"}]
              {detailData.userRecommendCode}
          </Ditems>

          <Ditems label="은행명">
            {rootPage === "franchise" ? (
              <div>{string.bankString[detailData.bankCode]}</div>
            ) : (
                <SelectBox
                  placeholder="은행을 선택해주세요."
                  value={string.bankString[detailData.bankCode]}
                  onChange={(value) => {
                    if (value !== detailData.bankCode) {
                      this.onChangeModal(value, "bankCode");
                    }
                  }}
                  code={string.bankCode}
                  codeString={string.bankString}
                  style={{ width: "250px" }}
                />
              )}
          </Ditems>
          
          <Ditems label="예금주">
            {rootPage === "franchise" ? (
              <div>{detailData.accountHolder}</div>
            ) : (
                <Input
                  allowClear
                  placeholder="예금주명을 입력해주세요."
                  value={detailData.accountHolder}
                  style={{width:"250px"}}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "accountHolder");
                    
                  }}
                />
              )}
          </Ditems>  

         
          <Ditems label="계좌번호">
            {rootPage === "franchise" ? (
              <div>{detailData.bankAccount}</div>
            ) : (
                <Input
                  style={{width:"250px"}}
                  allowClear
                  placeholder="계좌번호를 입력해주세요."
                  value={detailData.bankAccount}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "bankAccount");
                  }}
                />
              )}
          </Ditems>      
          <Ditems label="소속">
          <Input
              placeholder="소속을 입력해주세요."
              allowClear
              value={detailData.group}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "group");
              }}
              style={{ width:"250px"}}
              />&nbsp;
              
           
          </Ditems>

          <Ditems label="이메일 인증">
            {string.certifiedString[detailData.emailVerified]}
          </Ditems>

          <Ditems label="시리얼번호">{detailData.serial}</Ditems>
          <Ditems label="OTP 인증여부">
            {string.otpVerifyString[detailData.otpVerified]}
          </Ditems>   

          <Ditems label="잔액 숨기기">
            {rootPage === "franchise" ? (
              <div>
                {detailData.hideBalanceFlag ? (
                  <Icon type="check" />
                ) : (
                    <Icon type="close" />
                  )}
              </div>
            ) : (
                <Switch
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  onChange={(checked) => {
                    this.onChangeModal(checked, "hideBalanceFlag");
                  }}
                />
              )}
          </Ditems>

          <Ditems label="생성일">{formatDate(detailData.createDate)}</Ditems>
          <Ditems label="탈퇴일">
            {detailData.deleteDate ? formatDate(detailData.deleteDate) : "-"}
          </Ditems>
          <Ditems>
            
          </Ditems>
          <Ditems label="메모">
            {rootPage === "franchise" ? (
              <div>{detailData.memo}</div>
            ) : (
                <Input.TextArea
                  placeholder="메모를 입력해주세요."
                  value={detailData.memo}
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "memo");
                  }}
                />
              )}
          </Ditems>
        </Descriptions>
        <div style={{ height: "10px" }} />
        <div className="flex-row flex-center">
          <Button
            className="m-r-10"
            onClick={() => {
              this.handleGetToken(detailData.idx);
            }}>
            비밀번호 변경링크 생성
          </Button>
          <div>
            <a href={this.state.changePasswordUrl}>
              {this.state.changePasswordUrl}
            </a>
          </div>
        </div>
        <div className="flex-row flex-center" style={{ marginTop: '10px' }}>
          <Button
            className="m-r-10"
            onClick={() => {
              this.handleResetSecurityPassword(detailData.idx);
            }}>
            보안비밀번호 초기화
          </Button>
        </div>
      </Modal>
    );
  }
}

export default UserStemDetail;
