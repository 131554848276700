import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Button, Input, Checkbox, Divider } from "antd";

import { getFaq } from "../../contexts/asyncContext";
import { numberFormat, formatDate } from "../../util";
import string from "../../string";
import { httpPut, httpUrl } from "../../api/httpClient";
import SelectBox from "../../components/common/SelectBox";
import DetailModal from "../../components/community/DetailModal";
import DetailRegistryModal from "./../../components/community/DetailRegistryModal";
import { showAlert } from "../../components/common/AlertModal";
import { PlusOutlined } from "@ant-design/icons";

const Search = Input.Search;

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchType: false,
      searchText: "",
      pagination: {
        total: 0,
        current: 0
      },
      detailRegistryModalVisible: false,
      detailModalVisible: false,
      detailData: {}
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getFaq({
      pageSize: 10,
      pageNum: pager.current,
      deleted: this.state.searchType,
      searchText: this.state.searchText
    });
  };

  searchTypeHandleChange = e => {
    this.setState({
      searchType: e.target.checked
    });
  };

  showDetail = row => {
    this.setState({
      detailModalVisible: true,
      detailData: row
    });
  };

  onSearch = value => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.props.getFaq({
          pageSize: 10,
          pageNum: 1,
          deleted: this.state.searchType,
          searchText: value
        });
      }
    );
  };

  onCreate = () => {
    showAlert("create");
    this.setState({
      detailRegistryModalVisible: false,
      searchType: false,
      searchText: ""
    });
    this.props.getFaq({
      pageSize: 10,
      pageNum: 1,
      deleted: false,
      searchText: ""
    });
  };

  onUpdate = () => {
    const { searchText, searchType, pagination } = this.state;
    showAlert("update");
    this.setState({
      detailModalVisible: false
    });
    this.props.getFaq({
      pageSize: 10,
      pageNum: pagination.current,
      deleted: searchType,
      searchText: searchText
    });
  };

  onDelete = (value, row) => {
    const { searchText, searchType, pagination } = this.state;

    let pageNum =
      row.deleted === 0 && searchType === false
        ? this.props.result.faqList.length === 1
          ? pagination.current === 1
            ? pagination.current
            : pagination.current - 1
          : pagination.current
        : pagination.current;

    if (
      global.confirm(
        `${row.title.find(item => item.language === "ko").content}을(를) ${
          row.deleted ? "복구" : "삭제"
        }하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.faqDelete, [row.idx], {})
        .then(result => {
          if (row.deleted) alert("restore");
          else alert("delete");
          this.props.getFaq({
            pageSize: 10,
            pageNum: pageNum,
            deleted: searchType,
            searchText: searchText
          });
        })
        .catch(error => {});
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getFaq({
      pageSize: 10,
      pageNum: 1,
      deleted: false,
      searchText: ""
    });
  }

  render() {
    const faqColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{formatDate(data)}</div>
      },
      {
        title: "제목",
        dataIndex: "title",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => {
          return (
            <div
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => this.showDetail(row)}>
              {data.find(item => item.language === "ko").content}
            </div>
          );
        }
      },
      {
        title: "상태",
        dataIndex: "deleted",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <div>
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={value => {
                  if (value !== row.deleted) {
                    this.onDelete(value, row);
                  }
                }}
              />
            </div>
          )
      }
    ];

    return (
      <div>
        {/* <div>{JSON.stringify(this.props)}</div> */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
         <Divider orientation="left" style={{fontSize:23}}>FAQ 설정 </Divider>
        </div>
        <div className="wrapper mt-35 width-1240" />
        <DetailRegistryModal
          type="faq"
          visible={this.state.detailRegistryModalVisible}
          onCancel={() => {
            this.setState({ detailRegistryModalVisible: false });
          }}
          onOk={this.onCreate}
        />
        <DetailModal
          type="faq"
          modalData={this.state.detailData}
          visible={this.state.detailModalVisible}
          checked={this.state.checked}
          onCancel={() => {
            this.setState({ detailModalVisible: false });
          }}
          onOk={this.onUpdate}
        />
        <div style={{ float: "left" }}>
          <Button
            onClick={() => {
              this.setState({ detailRegistryModalVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            <PlusOutlined/>생성
          </Button>
        </div>
        <div
          className="flex-row flex-center"
          style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="">
            검색조건&nbsp;&nbsp;&nbsp;
            <Checkbox
              style={{ marginRight: "10px" }}
              onChange={this.searchTypeHandleChange}>
              삭제 포함
            </Checkbox>
          </label>
          <Search
            placeholder="제목, 내용을 입력하세요"
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: "300px" }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="faq-table"
          rowKey={record => record.idx}
          columns={faqColumns}
          dataSource={this.props.result.faqList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getFaq: ({ pageSize, pageNum, deleted, searchText }) =>
      dispatch(getFaq({ pageSize, pageNum, deleted, searchText }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Faq);
