import React, { Component } from 'react'
import { httpGet, httpUrl,httpPost, httpDelete } from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form,Divider } from 'antd'
import { reactLocalStorage } from "reactjs-localstorage";
import {
  SearchOutlined
  } from '@ant-design/icons';

import {
   formatDate,
   amountFormat,
  numberFormat,
  startDateFormat,
  endDateFormat
} from "../../util";
import string from "../../string";
import locale from "antd/es/date-picker/locale/ko_KR";
import SelectBox from "../../components/common/SelectBox";


const Search = Input.Search;

class MoveLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
  
      
      
      pagination: {
        total: 0,
        current: 0,
      },
    
      list: [],
      visible: false,
      searchText: "",
    
    };


  }
 
  // handleChangeDepositType = (value) => {
  //   this.setState({
  //     depositType: value,

  //   });
  // };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getMoveLogList({
      pageNum: pager.current,
    });
  };

 
  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getMoveLogList({});

      }
    );
  };


  getMoveLogList = (params = {}) => {
   
    const {searchText} = this.state
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getMoveLogList, [10, pageNum, searchText],{})
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.movelog,
          pagination,
        })
      })

  }

  

  componentDidMount() {
    this.getMoveLogList();
  }
  

  render() {
    const coinList = reactLocalStorage.getObject("coinList");
 

    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "회원",
        key: "userId",
        dataIndex: "userId",
        className: "support-column-number fs-15 lh-21",
        render:(data,row) =>
        <div>{data}<div>{row.userName}</div></div>
      },
      // {
      //   title: "이름",
      //   key: "userName",
      //   dataIndex: "userName",
      //   className: "support-column-number fs-15 lh-21",
      //   render:(data) =>
      //   <div>{data}</div>
      // },
      {
        title: "지갑주소",
        key: "address",
        dataIndex: "address",
        className: "support-column-number fs-15 lh-21",
        render:(data) =>
        <div>{data}</div>
      },
      
      {
        title: "",
        key: "fromCoinType",
        dataIndex: "fromCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
            <div style={{fontSize:12.5}}>
              {coinList.find((coin) => coin.coinType === data)
                ? coinList.find((coin) => coin.coinType === data).name
                : ""}
            </div>
          ) 
      },
      {
        title: "",
        key: "fromCoinType",
        dataIndex: "fromCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
            <div style={{fontSize:12.5}}>
              ➡➡
            </div>
          ) 
      },
      {
        title: "",
        key: "toCoinType",
        dataIndex: "toCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data) => (
            <div style={{fontSize:12.5}}>
              {coinList.find((coin) => coin.coinType === data)
                ? coinList.find((coin) => coin.coinType === data).name
                : ""}
            </div>
          ) 
      },
     
      {
        title: "수량",
        key: "amount",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render:(data) =>
        <div>{data / 1000000000}</div>
      },
      {
        title: "시간",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render:(data) =>
        <div>{formatDate(data)}</div>
      },
  
      
   
   

    ];




    return (
      
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{fontSize:23}}>SBCP 이동내역 </Divider>  
        
        

          
        </div>
        {/* 메모입력,수정 Modal */}
        <div>
        {/* <SelectBox
          value={this.state.searchType}
          code={string.moveLogSearchTypeCode}
          codeString={string.moveLogSearchTypeString}
          onChange={(value) => {
            if (value !== this.state.searchType) {
              this.searchTypeHandleChange(value);
            }
          }}
          style={{ width: "80px",marginLeft:230}}
        /> */}
        <Search
          placeholder={`회원이름을 입력해주세요.`}
          enterButton={<SearchOutlined />}
          allowClear
          onSearch={this.onSearch}
          style={{ width: 300, marginLeft:230}}
        />
         <span style={{color:'blue',fontSize:12.5,marginLeft:320}}>매일 새벽2시 SB지갑에 보유하고 있는 SBCoin ➡ SBCPoint로 100코인, 300코인 ,500코인 단위로 이동됩니다.</span>
</div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px',width:1200,marginLeft:230 }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
       

      </div>
    )


  }
}

export default MoveLog;
