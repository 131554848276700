import React, { Component } from "react";
import { Modal, Table, Input, Button, Tag } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";

const Search = Input.Search;

class OrganizationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      pagination: {
        current: 1,
        total: 0
      },
      searchText: "",
      selectedUser: {}
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getOrganizationChoiceList({ pageNum: pager.current });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getOrganizationChoiceList();
      }
    );
  };

  onOk = () => {
    this.props.onOk(
      this.state.selectedUser
    );
    this.props.onCancel();
  };

  onChangeTag = (value) => {
    this.setState({ selectedUser: value });
  };

  getOrganizationChoiceList = (params = {}) => {
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getOrganizationChoiceList,
      [10, pageNum, this.props.userIdx, searchText, this.props.classification],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          franchise: true,
          userList: res.data.list,
          pagination
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    // console.log(`type: ${this.props.type}, \n userIdx: ${this.props.userIdx}`);
    this.getOrganizationChoiceList();
  }

  render() {
    const { type, visible, onCancel } = this.props;
    const { franchise, selectedUser, userList } = this.state;
    const classifications = ['', '총판', '지사', '대리점', '가맹점'];
    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "조직명",
        dataIndex: "name",
        key: "name",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "조직구분",
        dataIndex: "classification",
        key: "classification",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{
          classifications[data]
          }</div>
      },
      {
        title: "",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.props.onOk(row);
              this.props.onCancel();
              // this.onChangeTag(row);
            }}>
            선택
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title={"조직 정보"}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}
        footer={false}>
        {selectedUser.idx !== undefined && (
          <Tag key={selectedUser.idx}>
            {type ? selectedUser.id : selectedUser.userId} /{" "}
            {selectedUser.name === null || selectedUser.name === ""
              ? "-"
              : selectedUser.name}
          </Tag>
        )}
        <div className="flex-row flex-center flex-end m-b-10">
          <Search
            placeholder={
              "조직명을 검색해주세요."
            }
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 400 }}
          />
        </div>
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={userList}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}
export default OrganizationList;
