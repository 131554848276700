import React, { Component } from "react";
import { Modal, Table, Input, Button, Tag } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { amountFormat, numberFormat } from "../../util";
import SelectBox from "../common/SelectBox";
import string from "../../string";

const Search = Input.Search;

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      pagination: {
        current: 1,
        total: 0
      },
      searchText: "",

     
      selectedProduct: {}
    };
  }


  // searchTypeHandleChange = (value) => {
  //   // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
  //   this.setState({
  //     searchType: value,
  //     isLoaded: true,
  //   });
  // };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getProductList({ pageNum: pager.current });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getProductList();
      }
    );
  };

  onOk = () => {
    this.props.onOk(
      this.state.selectedProduct
    );
    this.props.onCancel();
  };

  onChangeTag = (value) => {
    this.setState({ selectedProduct: value });
  };

  getProductList = (params = {}) => {
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getProductList, [10, pageNum,  searchText], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          productList: res.data.productList,
          pagination
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    // console.log(`type: ${this.props.type}, \n userIdx: ${this.props.userIdx}`);
    this.getProductList();
  }

  render() {
    const { type, visible, onCancel } = this.props;
    const { franchise, selectedProduct, productList, searchType } = this.state;
  
    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div align="left">{numberFormat(data)}</div>
      },   
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div align="left">{data}</div>
      },
      {
        title: "소비자가",
        dataIndex: "price",
        key: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div align="left">{amountFormat(data)}</div>
     },
     {
        title: "소비자가 PV",
        dataIndex: "pv",
        key: "pv",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div align="left">{amountFormat(data)}</div>
     },
     {
      title: "재구매가",
      dataIndex: "priceReorder",
      key: "price",
      className: "support-column-number fs-15 lh-21",
      render: (data) => <div align="left">{amountFormat(data)}</div>
   },
   {
      title: "재구매 PV",
      dataIndex: "pvReorder",
      key: "pv",
      className: "support-column-number fs-15 lh-21",
      render: (data) => <div align="left">{amountFormat(data)}</div>
   },
      {
        title: "",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.props.onOk(row);
              this.props.onCancel();
              // this.onChangeTag(row);
            }}>
            선택
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title={"상품 정보"}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}
        footer={false}>
        {selectedProduct.idx !== undefined && (
          <Tag key={selectedProduct.idx}>
            {selectedProduct.price} /{" "}
            {selectedProduct.name === null || selectedProduct.name === ""
              ? "-"
              : selectedProduct.name}
          </Tag>
        )}
        <div className="flex-row flex-center flex-end m-b-10">
    
        <Search
          placeholder={`상품명을 입력해주세요.`}
          enterButton={'검색'}
          allowClear
          onSearch={this.onSearch}
          style={{ width: 350 }}
        />
        </div>
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={productList}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}
export default ProductList;
