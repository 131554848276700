import React, { Component } from "react";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import { Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Checkbox } from "antd";
import { httpPut, httpUrl, httpPost, serverUrl } from "../../api/httpClient";
import {
  formatDateSecond,
  formatDate,
  startDateFormat,
  endDateFormat,
} from "../../util";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from 'moment';

const FormItem = Form.Item;
const Ditems = Descriptions.Item;
const RangePicker = DatePicker.RangePicker;

class NoticeModify extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  componentDidMount() {
  }
  onUpdate = (e) => {
    let {form} = this.props;
    
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        values.idx = this.props.data.idx;
        values.title = {};
        values.content = {};
        global.languages.map((value, index)=>{
          values.title[value.code.toLowerCase()] = values['title'+value.code];
          values.content[value.code.toLowerCase()] = values['content'+value.code];
        });
        values.main = values.main ? 1 : 0
        console.log(JSON.stringify(values))
        httpPut(httpUrl.noticeUpdate, [], values).then((res) => {
          if (res.data !== 0) {
            form.resetFields();
            this.props.onOk();
          } else {
            alert("알수없는 문제로 등록에 실패하였습니다..");
          }
        });
      }
    });
  }
  handleDateChange = (value)=>{
    this.date = value;
  }
  render() {
    const { visible, onCancel, form, data } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    return (
      <Modal
        visible={visible}
        title="공지 수정"
        okText="수정"
        cancelText="취소"
        onOk={this.onUpdate}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        {data != null && (
        <div>
          <Form {...formItemLayout} onSubmit={this.onUpdate}>
              <FormItem style={{float: 'left', width: 300}} >
                {getFieldDecorator("category", { initialValue: data.category
                })(
                  <SelectBox
                    code={string.noticeSelectCode}
                    codeString={string.noticeSelect}
                    onChange={value => {
                      if (value !== this.state.categoryType)
                        this.setState({categoryType: value})
                    }}
                    style={{ width: "160px", marginRight: "10px" }}
                  />
                )}
                </FormItem>
              <FormItem style={{float: 'right', width: 200}}>
                {getFieldDecorator("main", { initialValue: data.main == 1, valuePropName: 'checked'
                })(
                  <Checkbox 
                    style={{ marginLeft: "10px" }}
                    >
                    메인노출
                  </Checkbox>
                )}
              </FormItem>
            <Descriptions
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>

              
              {global.languages.map((value, index)=>{
                let title = data.title.find(x=>x.language==value.code.toLowerCase());
                let content = data.content.find(x=>x.language==value.code.toLowerCase());
                title = title ? title.content : "";
                content = content ? content.content : "";
                return (
                <>
                  <Ditems label={"제목("+value.name+")"}>
                    <FormItem>
                    {getFieldDecorator("title"+value.code, { 
                      rules: [ { required: value.code=="Ko", message: "제목을 입력해 주세요" } ] ,
                      initialValue: title
                    })(<Input/>)}
                    </FormItem>
                  </Ditems>
                  <Ditems label={"내용("+value.name+")"}>
                    <FormItem >
                    {getFieldDecorator("content"+value.code, { 
                      rules: [ { required: value.code=="Ko", message: "내용을 입력해 주세요" } ]  ,
                      initialValue: content
                    })(<Input.TextArea/>)}
                      </FormItem>
                  </Ditems>
                </>
                )
              })}
              </Descriptions>
          </Form>
        </div>

        )}
      </Modal>
    );
  }
}

export default Form.create()(NoticeModify)