import React, { Component } from 'react'
import { httpGet, httpUrl, } from '../../api/httpClient';
import { Table, Input,Divider} from 'antd'
import {
  formatDate
} from "../../util";

import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import { SearchOutlined } from '@ant-design/icons';

const Search = Input.Search;


class WorkLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      searchText:"",
      pagination: {
        total: 0,
        current: 0,
      },
    
      list: [],
      visible: false,
      searchText:"",
      searchType:"ADMINNAME",
    };


  }
 

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getWorkLogList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getWorkLogList({});

      }
    );
  };
  

  getWorkLogList = (params = {}) => {
    const{searchText,searchType} = this.state
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getWorkLogList, [10, pageNum,searchText,searchType],)
      .then(res => {
        console.log(JSON.stringify(res.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.workLogList,
          pagination,
        })
      })

  }

 

  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true,
    });
  };

  componentDidMount() {
    this.getWorkLogList();
  }
  



  render() {
    const {selectedData} = this.state;   
    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div style={{fontSize:13}}>{data}</div>  
     
      },
      {
        title: "작업자",
        key: "adminName",
        dataIndex: "adminName",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => 
        <div style={{fontSize:13}}>{data}({row.adminId})</div>  
   
      },
      {
        title: "작업종류",
        key: "workName",
        dataIndex: "workName",
        className: "support-column-number fs-15 lh-21",
        render: (data) => 
        <div style={{fontSize:13}}>{data}</div>  
       
      },
      {
        title: "작업시간",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div style={{fontSize:13}}>{formatDate(data)}</div>
       
      },
      // {
      //   title:"작업번호",
      //   key:"depositIdx",
      //   dataIndex:"depositIdx",
      //   className:"support-column-number fs-15 lh-21",
      //   render:(data,row) => <div style={{fontSize:13}}>{data}{row.paymentIdx}</div>
      // },     
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          <Divider orientation="left" style={{fontSize:23}}>관리자 작업이력  </Divider>
        
         

          
        </div>
       
        
        <label htmlFor="searchType" style={{ marginLeft: '850px' }}>검색 대상&nbsp;</label>
        <SelectBox
          value={this.state.searchType}
          code={string.worklogSearchTypeCode}
          codeString={string.worklogSearchTypeString}
          onChange={(value) => {
            if (value !== this.state.searchType) {
              this.searchTypeHandleChange(value);
            }
          }}
        />
        <Search
          placeholder={`검색어를 입력해주세요.`}
          enterButton={<SearchOutlined/>}
          allowClear
          onSearch={this.onSearch}
          style={{ width: 300, marginLeft: '1px' }}
        />
         
        <Table
         
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px' ,width:1000, marginLeft:320}}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
          
            
            

      </div>
    )


  }
}

export default WorkLog;
