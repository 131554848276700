import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, Input, Button } from "antd";

import { getProcess } from "../../contexts/asyncContext";
import { numberFormat, formatDate, amountFormat } from "../../util";
import string from "../../string";
import { httpPut, httpUrl } from "../../api/httpClient";
import SelectBox from "../../components/common/SelectBox";

const Search = Input.Search;
class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusTypes: "ALL",
      reasonTypes: "ALL",
      searchTypes: "FROM",
      searchText: "",
      pagination: {
        total: 0,
        current: 0
      }
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getProcess({
      pageSize: 10,
      pageNum: pager.current,
      type: this.state.statusTypes,
      reasonType: this.state.reasonTypes,
      searchText: this.state.searchText
    });
  };

  reasonTypeHandleChange = value => {
    this.setState(
      {
        reasonTypes: value
      },
      () =>
        this.props.getProcess({
          pageSize: 10,
          pageNum: 1,
          type: this.state.statusTypes,
          reasonType: value,
          searchText: this.state.searchText
        })
    );
  };

  searchTypeHandleChange = value => {
    this.setState(
      {
        statusTypes: value
      },
      () =>
        this.props.getProcess({
          pageSize: 10,
          pageNum: 1,
          type: value,
          reasonType: this.state.reasonTypes,
          searchText: this.state.searchText
        })
    );
  };

  onSearch = (value, searchType) => {
    this.setState(
      {
        searchTypes: searchType,
        searchText: value
      },
      () => {
        this.props.getProcess({
          pageSize: 10,
          pageNum: 1,
          type: this.state.statusTypes,
          reasonType: this.state.reasonTypes,
          searchText: value
        });
      }
    );
  };

  onUpdate = (value, row) => {
    const { statusTypes, reasonTypes, searchText, pagination } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    let updateData = {
      idx: row.idx,
      status: value
    };
    if (
      global.confirm(
        coinList.find(coin => coin.coinType === row.coinType).name +
          "의 상태를 " +
          string.acceptString[value] +
          "로 변경하시겠습니까?"
      )
    ) {
      httpPut(httpUrl.processUpdate, [], updateData)
        .then(result => {
          this.props.getProcess({
            pageSize: 10,
            pageNum: pagination.current,
            type: statusTypes,
            reasonType: reasonTypes,
            searchText: searchText
          });
        })
        .catch(error => {});
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getProcess({
      pageSize: 10,
      pageNum: 1,
      type: "ALL",
      reasonType: "ALL",
      searchText: ""
    });
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const approvalColumns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 70,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "전송 방법",
        dataIndex: "type",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{string.sendString[data]}</div>
      },
      {
        title: "보낸 지갑",
        dataIndex: "from",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div
            style={{
              whiteSpace: "nowrap",
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
            {data ? data : "-"}
          </div>
        )
      },
      {
        title: "받은 지갑",
        dataIndex: "to",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div
            style={{
              whiteSpace: "nowrap",
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
            {data ? data : "-"}
          </div>
        )
      },
      {
        title: "사유",
        dataIndex: "reason",
        className: "support-column-number fs-15 lh-21",
        width: 300,
        render: data => <div>{string.reasonString[data]}</div>
      },

      {
        title: "승인 설정",
        dataIndex: "status",
        className: "support-column-number fs-15 lh-21",
        render:
          // data => <div>{string.acceptString[data]}</div>
          (data, row) => (
            <div>
              {data === "REQUEST" ? (
                <div>
                  {/* {string.acceptString[data]} */}
                  <div>
                    {/* <span className="pointer">승인</span>
                    <span>&nbsp;|&nbsp;</span>
                    <span className="pointer">거절</span> */}
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        this.onUpdate("ACCEPT", row);
                      }}>
                      승인
                    </Button>
                    <Button
                      onClick={() => {
                        this.onUpdate("REJECT", row);
                      }}>
                      거절
                    </Button>
                  </div>
                </div>
              ) : (
                <div>{string.acceptString[data]}</div>
              )}
            </div>
          )
      }
    ];

    const expandedRowRender = record => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: "수수료",
          dataIndex: "fee",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: "금액",
          dataIndex: "amount",
          className: "support-column-number fs-15 lh-21",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: "메모",
          dataIndex: "memo",
          className: "support-column-number fs-15 lh-21",
          width: 300,
          render: data => <div>{data ? data : "-"}</div>
        },
        {
          title: "자동 승인 여부",
          dataIndex: "autoFlag",
          className: "support-column-number fs-15 lh-21",
          width: 200,
          render: data => <div>{string.autoFlagString[data]}</div>
        }
      ];
      // console.log(`${JSON.stringify(dataArr, null, 4)}`);
      return (
        <Table
          rowKey={record => record.idx}
          columns={dropColumns}
          dataSource={dataArr}
          pagination={false}
        />
      );
    };

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          거래승인
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            대상{" "}
            <SelectBox
              value={string.statusString[this.state.statusTypes]}
              code={string.statusCode}
              codeString={string.statusString}
              onChange={value => {
                if (value !== this.state.statusTypes)
                  this.searchTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
            <SelectBox
              value={string.reasonString[this.state.reasonTypes]}
              code={string.reasonCode}
              codeString={string.reasonString}
              onChange={value => {
                if (value !== this.state.reasonTypes)
                  this.reasonTypeHandleChange(value);
              }}
              style={{ width: "280px", marginRight: "10px" }}
            />
          </label>
          <label htmlFor="">
            검색기준{" "}
            <Search
              placeholder="보낸지갑, 받은지갑을 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          tableLayout="fixed"
          id="process-table"
          rowKey={record => record.idx}
          columns={approvalColumns}
          dataSource={this.props.result.approvalStandbyList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getProcess: ({ pageSize, pageNum, type, reasonType, searchText }) =>
      dispatch(getProcess({ pageSize, pageNum, type, reasonType, searchText }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Process);
