import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { Table, DatePicker, Button } from "antd";

import { getTransferStatistics } from "../../contexts/asyncContext";
import { numberFormat, dayFormat, amountFormat, statFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import locale from "antd/es/date-picker/locale/ko_KR";

const { RangePicker } = DatePicker;

class Auto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 0
      },
      searchTypes: "ALL",
      startDate: "",
      endDate: ""
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.props.getTransferStatistics({
      pageSize: 10,
      pageNum: pager.current,
      type: this.state.searchTypes,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });
  };

  searchTypeHandleChange = value => {
    this.setState(
      {
        searchTypes: value,
        pagination: {
          ...this.state.pagination,
          current: 1
        }
      },
      () => {
        this.props.getTransferStatistics({
          pageSize: 10,
          pageNum: 1,
          type: this.state.searchTypes,
          startDate: this.state.startDate,
          endDate: this.state.endDate
        });
      }
    );
  };

  handleChangeDate = value => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : ""
    });
  };

  onSearch() {
    this.props.getTransferStatistics({
      pageSize: 10,
      pageNum: 1,
      type: this.state.searchTypes,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.result.totalCount !== state.pagination.total) {
      state.pagination.total = props.result.totalCount;
      state.pagination.current = props.result.currentPage;
      return {
        pagination: state.pagination
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getTransferStatistics({
      pageSize: 10,
      pageNum: 1,
      type: "ALL",
      startDate: "",
      endDate: ""
    });
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const statisticsColumns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        // key: 'statDate',
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{dayFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
            {/* {coinList.map(coin => {
              if (coin.coinType === data) {
                return coin.name;
              }
            })} */}
          </div>
        )
      },
      {
        title: "전송 방법",
        dataIndex: "transferType",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{string.sendString[data]}</div>
      },
      {
        title: "전송 수",
        dataIndex: "transferCount",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "전송한 총 회원 수",
        dataIndex: "transferFromUser",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "전송받은 총 회원 수",
        dataIndex: "transferToUser",
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{numberFormat(data)}</div>
      },
      {
        title: "총 금액",
        dataIndex: "transferAmount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          거래 통계
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            대상{" "}
            <SelectBox
              placeholder="선택해주세요."
              value={string.logString[this.state.searchTypes]}
              code={string.logCode}
              codeString={string.logString}
              onChange={value => {
                if (value !== this.state.searchTypes)
                  this.searchTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
          </label>
          <label
            htmlFor=""
            style={{
              marginRight: "20px"
            }}>
            검색기간{" "}
            <RangePicker
              locale={locale}
              onChange={date => {
                this.handleChangeDate(date);
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.onSearch();
              }}>
              검색
            </Button>
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="transfer-table"
          // rowKey={record => record.statDate}
          columns={statisticsColumns}
          dataSource={this.props.result.transferList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = state => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = dispatch => {
  return {
    getTransferStatistics: ({ pageSize, pageNum, startDate, endDate, type }) =>
      dispatch(
        getTransferStatistics({ pageSize, pageNum, startDate, endDate, type })
      )
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Auto);
