import React, { Component } from "react";
import { Table, DatePicker, Button } from "antd";
import { statFormat, dayFormat, amountFormat, numberFormat } from "../../util";
import { httpGet, httpUrl } from "../../api/httpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import locale from "antd/es/date-picker/locale/ko_KR";

const { RangePicker } = DatePicker;

export default class FeeDailyStatistics extends Component {
  state = {
    startDate: "",
    endDate: "",
    feeDateList: [],
    pagination: {
      total: 0,
      current: 1
    }
  };

  onChange = (date, dateString) => {
    this.setState({
      startDate: date.length !== 0 ? statFormat(date[0]) : "",
      endDate: date.length !== 0 ? statFormat(date[1]) : ""
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getFeeDateStatistic({
      pageNum: pager.current
    });
  };

  getFeeDateStatistic = (params = {}) => {
    let pageNum = params.pageNum || 1;
    httpGet(
      httpUrl.getFeeDateStatistic,
      [10, pageNum, this.state.startDate, this.state.endDate],
      {}
    ).then(res => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        feeDateList: res.data.list,
        pagination
      });
    });
  };

  componentDidMount() {
    this.getFeeDateStatistic();
  }

  render() {
    const coinList = reactLocalStorage.getObject("coinList");

    const columns = [
      {
        title: "일자",
        dataIndex: "statDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => <div>{dayFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "횟수",
        dataIndex: "count",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "수수료",
        dataIndex: "fee",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      }
    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          일별 수수료 통계
        </div>
        <div className="flex-row flex-center flex-end m-b-10">
          <div className="flex-row flex-center">
            <label>검색 기간&nbsp;</label>
            <RangePicker locale={locale} onChange={this.onChange} />
            <Button
              type="primary"
              onClick={() => {
                this.getFeeDateStatistic();
              }}>
              검색
            </Button>
          </div>
        </div>
        <Table
          rowKey={record => `${record.statDate}${record.coinType}`}
          columns={columns}
          dataSource={this.state.feeDateList}
          onChange={this.handleTableChange}
          pagination={this.state.pagination}
        />
      </div>
    );
  }
}
