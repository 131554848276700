import React, { Component } from "react";

import { Table, DatePicker, Input, Button } from "antd";

import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import { formatDate, startDateFormat, endDateFormat, amountFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import ArbitrageManualExchange from "../arbitrageManagement/ArbitrageManualExchange";

const Search = Input.Search;
const { RangePicker } = DatePicker;

class ExchangeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromCoinType: '',
      toCoinType: '',
      status: '',
      exchangeList: [],
      pagination: {
        total: 0,
        current: 1
      },
      manualExchangeVisible: false,
      manualExchangeIdx: 0,
      manualExchangeUsers: []
    };
  }

  componentDidMount() {
    this.getExchangeList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, () => this.getExchangeList());
  };

  initialLoad = () => {
    this.setState({
      exchangeList: [],
      fromCoinType: null,
      toCoinType: null,
      status: null,
      pagination: {
        total: 0,
        current: 1
      }
    }, () => {
      this.getExchangeList();
    })
  }
  reload = () => {
    this.getExchangeList();
  }
  getExchangeList = () => {
    let pageNum = this.state.pagination.current;
    let { fromCoinType, toCoinType, status } = this.state;
    httpGet(httpUrl.getExchangeList, [10, pageNum, fromCoinType, toCoinType, status], {}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        exchangeList: res.data.exchanges,
        pagination,
      });
    });
  };
  render() {
    const { exchangeList } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");
    const statusString = {
      PENDING: '대기중',
      MATCH: '교환완료',
      CANCEL: '취소완료',
    }
    const columns = [
      {
        title: "등록자",
        dataIndex: "fromUserId",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "FROM코인",
        dataIndex: "fromCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(row.fromAmount, row.fromCoinType) + ' ' + coinList.find((coin) => coin.coinType === data).symbol}</div>
      },
      {
        title: "수락자",
        dataIndex: "toUserId",
        className: "support-column-number fs-15 lh-21",
      },
      {
        title: "TO코인",
        dataIndex: "toCoinType",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{amountFormat(row.toAmount, row.toCoinType) + ' ' + coinList.find((coin) => coin.coinType === data).symbol}</div>
      },
      {
        title: "수수료",
        dataIndex: "",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => {
          let symbol = coinList.find((coin) => coin.coinType === row.fromCoinType).symbol;
          let feeAmount = amountFormat(row.fromAmount * row.fee, row.fromCoinType);
          let cancelFeeAmount = amountFormat(row.fromAmount * row.cancelFee, row.fromCoinType);
          if (row.status == 'MATCH') return feeAmount + ' ' + symbol;
          else if (row.status == 'CANCEL') return cancelFeeAmount + ' ' + symbol;
          else return '(예정)' + feeAmount + ' ' + symbol;
        }
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => <div>{statusString[data]}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {

        dataIndex: "status",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => {
          if (data == 'PENDING')
            return (
              <Button
                onClick={() => {
                  httpGet(httpUrl.arbitrageUserList, [row.fromCoinType], {}).then((res) => {
                    this.setState({ manualExchangeVisible: true, manualExchangeIdx: row.idx, manualExchangeUsers: res.data });
                  });
                }}>
                거래하기
              </Button>
            );
          else {
            return "-";
          }
        }
      },

    ];

    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
          교환목록
      </div>
        <div className="wrapper mt-35 width-1240" />

        <div className="flex-row flex-center" style={{ paddingBottom: '10px' }}>
          <div style={{ marginRight: '10px' }}>FROM코인</div>
          <CoinSelectBox
            placeholder="선택해주세요."
            onChange={(value) => {
              this.setState({ fromCoinType: value }, ()=>this.reload());
            }}
            value={this.state.fromCoinType == '' ? undefined : this.state.fromCoinType}
            style={{ width: "160px" }}
          />
          <div style={{ marginRight: '10px', marginLeft: '20px' }}>TO코인</div>
          <CoinSelectBox
            placeholder="선택해주세요."
            onChange={(value) => {
              this.setState({ toCoinType: value }, ()=>this.reload());
            }}
            value={this.state.toCoinType == '' ? undefined : this.state.toCoinType}
            style={{ width: "160px" }}
          />
          <Button style={{marginLeft: '20px'}}
            onClick={() => {
              this.setState({fromCoinType: '', toCoinType: ''}, ()=>this.reload())
            }}>
            선택해제
        </Button>

        </div>
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => (record.deleted ? "gray" : "")}
          dataSource={exchangeList}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
        <ArbitrageManualExchange
          visible={this.state.manualExchangeVisible}
          exchangeIdx={this.state.manualExchangeIdx}
          exchangeUsers={this.state.manualExchangeUsers}
          onCancel={() => {
            this.setState({ manualExchangeVisible: false });
          }}
          onOk={() => {
            this.setState({ manualExchangeVisible: false });
            this.getExchangeList();
          }}
        />
      </div>
    );
  }
}

export default ExchangeList;
