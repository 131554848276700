import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import {
  Member,
  MemberRead,
  MemberSb,
  MemberStem,
  MemberReadStem,
  SignupRewardLog,
  FeeWallet,
  Collection,
  Fee,
  Caution,
  Foundation,
  FoundationLinkus,
  MergeList,
  MergeLogList,
  Setting,
  Process,
  LimitAmount,
  WithDrawRequestLog,
  Distribute,
  Manual,
  Auto,
  FoundationList,
  FoundationLock,
  FoundationCoin,
  FoundationCoinSendNormal,
  FoundationCoinSendDivide,
  FoundationLog,
  DesignationList,
  DesignationStatus,
  FoundationDaily,
  FoundationDetail,
  Notice,
  Faq,
  Link,
  UserStatistics,
  LoginStatistics,
  TransferStatistics,
  FeeDailyStatistics,
  FeeMonthStatistics,
  AdminLoginLog,
  Admin,
  Service,
  BlockChain,
  Franchise,
  PurchaseLog,
  PaybackLog,
  PaybackSetting,
  TradeLog,
  TransferLog,
  TransferLogEx,
  AdjustmentLog,
  AdjustmentLogRead,
  AdjustmentShare,
  IncentiveLog,
  GlobalShare,
  GlobalShareRead,
  Organization,
  OrganizationRead,
  AdjustmentSetting,
  GlobalSetting,
  PaymentLog,
  PaymentLogRead,
  Category,
  MyInfo,
  CreateQR,
  BannerList,
  DepositRequestList,
  DepositList,
  GlobalShareDetailList,
  DepositListRead,
  EventList,
  ExchangeList,
  StakingBonusList,
  StakingBonusModify,
  StakingBonusRegist,
  StakingCoinList,
  StakingCoinModify,
  StakingCoinRegist,
  StakingHistory,
  StakingInterest,
  StakingStatus,
  WorkLog,
  Holiday,
  ArbitrageSetting,
  ArbitrageLog,
  ArbitrageFlowTotal,
  ArbitrageFlowList,
  ArbitrageManualLog,
  CouponLog,
  CouponOwn,
  CouponCategory,
  FranchiseApply,
  MoveLog,
  Purchase,
  ExtraPay,
  PurchaseTree,
  Product,
  ProductSum,
  Request,
  RequestGoods,
  DailyAgreeMent,
  PaybackRatio,

} from "../pages";
import Adjustment from "../pages/franchiseManagement/Adjustment";
import EventRewardList from "../pages/eventManagement/EventRewardList";
import EventRewardStatistics from "../pages/statisticsManagement/EventRewardStatistics";
import ExchangeFeeStatistics from "../pages/statisticsManagement/ExchangeFeeStatistics";
import SendPush from "../pages/systemManagement/SendPush";
import SignupRewardLogDix from "../pages/userManagement/SignupRewardLogDix";
import ExchangeSetting from "../pages/exchangeManagement/ExchangeSetting";
import MiningList from "../pages/investManagement/MiningList";
import MozInvestList from "../pages/mozInvestManagement/MozInvestList";
import MozInvestRewardList from "../pages/mozInvestManagement/MozInvestRewardList";
import PlapInvestList from "../pages/mozInvestManagement/PlapInvestList";
import PlapInvestRewardList from "../pages/mozInvestManagement/PlapInvestRewardList";
import KycList from "../pages/userManagement/KycList";


export default class Content extends Component {
  render() {
    return (
      <Layout.Content style={{ margin: "24px 16px 0" }}>
        <div style={{ padding: 24, background: "#fff" }}>
          <Switch>
            {/* 회원 */}
            <Route exact path="/user/memberOrigin" component={Member} />
            <Route exact path="/user/memberRead" component={MemberRead} />
            <Route exact path="/user/member" component={Member} />
            <Route exact path="/user/memberSb" component={MemberSb} />
            <Route exact path="/user/memberStem" component={MemberStem} />
            <Route exact path="/user/memberReadStem" component={MemberReadStem} />
            <Route path="/user/signuprewardlog" component={SignupRewardLog} />
            <Route path="/user/signuprewardlogDix" component={SignupRewardLogDix} />
            <Route exact path="/user/kyc" component={KycList} />
            
            
            {/* 지갑 */}
            <Route path="/wallet/feeWallet" component={FeeWallet} />
            <Route path="/wallet/collection" component={Collection} />
            <Route path="/wallet/fee" component={Fee} />
            <Route path="/wallet/caution" component={Caution} />
            <Route path="/wallet/foundation" component={Foundation} />
            <Route
              path="/wallet/foundationLinkus"
              component={FoundationLinkus}
            />
            <Route exact path="/wallet/walletMerge" component={MergeList} />
            <Route
              exact
              path="/wallet/walletMerge/:logname"
              component={MergeLogList}
            />

            {/* 거래 */}
            <Route path="/transaction/setting" component={Setting} />
            <Route path="/transaction/process" component={Process} />
            <Route path="/transaction/limitAmount" component={LimitAmount} />
            <Route
              path="/transaction/withDrawRequestLog"
              component={WithDrawRequestLog}
            />
            <Route path="/transaction/transferLog" component={TransferLog} />
            <Route path="/transaction/transferLogEx" component={TransferLogEx} />
            <Route path="/transaction/moveLogList" component={MoveLog}/>

            {/* 코인 */}
            <Route path="/coin/distribute" component={Distribute} />
            <Route path="/coin/manual" component={Manual} />
            <Route path="/coin/auto" component={Auto} />

            {/* 재단 */}
            <Route
              path="/foundation/foundationList"
              component={FoundationList}
            />
            <Route
              path="/foundation/foundationLock"
              component={FoundationLock}
            />
            {/* 수수료 있는거 */}
            <Route
              path="/foundation/foundationCoin"
              component={FoundationCoin}
            />
            {/* 수수료 없는거 */}
            <Route
              path="/foundation/foundationCoinSendNormal"
              component={FoundationCoinSendNormal}
            />
            <Route
              path="/foundation/foundationCoinSendDivide"
              component={FoundationCoinSendDivide}
            />
            <Route path="/foundation/foundationLog" component={FoundationLog} />
            <Route
              path="/foundation/designationLockList"
              component={DesignationList}
            />
            <Route
              path="/foundation/designationLockStatus"
              component={DesignationStatus}
            />
            <Route
              path="/foundation/foundationDaily"
              component={FoundationDaily}
            />
            <Route
              path="/foundation/foundationDetail"
              component={FoundationDetail}
            />

            {/* 게시글 */}
            <Route path="/community/notice" component={Notice} />
            <Route path="/community/faq" component={Faq} />
            <Route path="/community/link" component={Link} />

            {/* 통계 */}
            <Route
              path="/statistics/userStatistics"
              component={UserStatistics}
            />
            <Route
              path="/statistics/loginStatistics"
              component={LoginStatistics}
            />
            <Route
              path="/statistics/transferStatistics"
              component={TransferStatistics}
            />
            <Route path="/statistics/feeDate" component={FeeDailyStatistics} />
            <Route path="/statistics/feeMonth" component={FeeMonthStatistics} />
            <Route path="/statistics/adminLoginLog" component={AdminLoginLog} />
            <Route path="/statistics/eventRewardStat" component={EventRewardStatistics} />
            <Route path="/statistics/exchangeFeeStat" component={ExchangeFeeStatistics} />

            {/* 시스템 */}
            <Route path="/system/admin" component={Admin} />
            <Route path="/system/service" component={Service} />
            <Route path="/system/blockChain" component={BlockChain} />
            <Route path="/system/sendpush" component={SendPush} />
            <Route path="/system/holiday" component={Holiday} />
            <Route path="/system/worklog" component={WorkLog} />
            <Route path="/system/dailyAgreement" component={DailyAgreeMent} />
            <Route path="/system/paybackRatio" component={PaybackRatio} />

            {/* 가맹점관리 */}
            <Route path="/franchise/list" component={Franchise} />
            <Route path="/franchise/paymentLog" component={PurchaseLog} />
            <Route path="/franchise/paybackLog" component={PaybackLog} />
            <Route
              path="/franchise/paybackSetting"
              component={PaybackSetting}
            />
            <Route path="/trade" component={TradeLog} />
            <Route path="/franchise/adjustment" component={Adjustment} />

            {/* 정산관리 */}
            <Route path="/adjustment/adjustmentLog" component={AdjustmentLog} />
            <Route path="/adjustment/adjustmentLogRead" component={AdjustmentLogRead} />
            <Route
              path="/adjustment/adjustmentShare"
              component={AdjustmentShare}
            />
            <Route
              path="/adjustment/globalShare"
              component={GlobalShare}
            />
            <Route
              path="/adjustment/globalShareDetail"
              component={GlobalShareDetailList} 
            />
            <Route path="/adjustment/globalShareRead" component={GlobalShareRead} />
            <Route path="/adjustment/incentiveList" component={IncentiveLog} />

            {/* 결제관리 */}
            <Route path="/payment/organization" component={Organization} />
            <Route path="/payment/organizationRead" component={OrganizationRead} />
            <Route path="/payment/createQR" component={CreateQR} />
            <Route
              path="/payment/adjustmentSetting"
              component={AdjustmentSetting}
            />
            <Route path="/payment/paymentLog" component={PaymentLog} />
            <Route path="/payment/paymentLogRead" component={PaymentLogRead} />
            <Route path="/payment/category" component={Category} />
            <Route path="/payment/globalSetting" component={GlobalSetting} />
            

            {/* 배너관리 */}
            <Route path="/banner" component={BannerList} />

            {/* 정보수정 */}
            <Route path="/mypage/myinfo" component={MyInfo} />
            {/* 충전관리 */}
            <Route path="/charge/depositRequestList" component={DepositRequestList} />
            <Route path="/charge/depositList" component={DepositList} />
            <Route path="/charge/depositListRead" component={DepositListRead} />
            

            {/* 이벤트관리 */}
            <Route path="/event/eventList" component={EventList} />
            <Route path="/event/eventRewardList" component={EventRewardList} />

            {/* 교환관리 */}
            <Route path="/exchange/exchangeList" component={ExchangeList} />
            <Route path="/exchange/ExchangeSetting" component={ExchangeSetting} />

            {/* 스테이킹관리 */}
            <Route path="/staking/coinSetting" component={StakingCoinList} />
            <Route path="/staking/bonusSetting" component={StakingBonusList} />
            <Route path="/staking/status" component={StakingStatus} />
            <Route path="/staking/history" component={StakingHistory} />
            <Route path="/staking/interest" component={StakingInterest} />

            {/* 투자관리 */}
            <Route path="/invest/miningList" component={MiningList} />

            {/* 자전관리 */}
            <Route path="/arbitrage/ArbitrageSetting" component={ArbitrageSetting} />
            <Route path="/arbitrage/ArbitrageLog" component={ArbitrageLog} />
            <Route path="/arbitrage/ArbitrageFlowTotal" component={ArbitrageFlowTotal} />
            <Route path="/arbitrage/ArbitrageFlowList" component={ArbitrageFlowList} />
            <Route path="/arbitrage/ArbitrageManualLog" component={ArbitrageManualLog} />
            {/* 쿠폰관리*/}
            <Route path="/coupon/logList" component={CouponLog} />
            <Route path="/coupon/ownList" component={CouponOwn} />
            <Route path="/coupon/categoryList" component={CouponCategory} />
       
            {/*가맹점 신청*/}
            <Route path="/apply/applylist" component={FranchiseApply} />
           
             {/*수당관리*/}
             <Route path="/purchase/list" component={Purchase} />
             <Route path="/purchase/extrapaylist" component={ExtraPay} />
             <Route path="/purchase/purchaseTree" component={PurchaseTree} />
             <Route path="/purchase/productlist" component={Product} />
             <Route path="/purchase/product/sumlist" component={ProductSum} />
             <Route path="/purchase/requestlist" component={Request} />
             <Route path="/purchase/goodslist" component={RequestGoods} />
              {/*모즈투자*/}
              <Route path="/moz/invest/list" component={MozInvestList} />
              <Route path="/moz/invest/rewardList" component={MozInvestRewardList} />
              <Route path="/plap/invest/list" component={PlapInvestList} />
              <Route path="/plap/invest/rewardList" component={PlapInvestRewardList} />
            </Switch>
        </div>
      </Layout.Content>
    );
  }
}
