import React, { Component } from 'react'
import { httpGet, httpUrl,httpPut,httpPost, httpDelete } from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form,Divider } from 'antd'
import {
    formatDate,numberFormat,amountFormat
     } from "../../util";
import {
        EditOutlined,DeleteOutlined, FormOutlined
         } from '@ant-design/icons';
import { showAlert } from "../../components/common/AlertModal";


const Search = Input.Search;
class ProductSum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText:"",
      prevData: {},
      pagination: {
        total: 0,
        current: 0,
      },
      selectedRow: "",
      selectData: {},
      serviceData: [],
      list: [],
      visible: false,
      productVisible:false,

     
    };


  }




  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getProductSumList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getProductSumList({});

      }
    );
  };
 

  getProductSumList = (params = {}) => {
    const{searchText} = this.state
 
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getProductSumList, [10, pageNum,searchText])
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.productSumList,
          pagination,
        })
      })

  }

  

 
  componentDidMount() {
    this.getProductSumList();
  }
  

  render() {
  

    const columns = [
      {
        title: "날짜",
        key: "logDate",
        dataIndex: "logDate",
        className: "support-column-number fs-15 lh-21",  
        width:120,      
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "회원명",
        key: "userIdx",
        dataIndex: "userIdx",
        className: "support-column-number fs-15 lh-21",
        width:120,      
        render: (data,row) => 
        <div>{row.userName}</div>    
      },
      {
        title: "하위자",
        key: "childUserIdx",
        dataIndex: "childUserIdx",
        className: "support-column-number fs-15 lh-21",
        width:120,      
        render:(data,row) =>
        <div>{row.childUserName}</div>
        },     
        
     {
      title: "매출합계",
       key: "price",
      dataIndex: "price",
      className: "support-column-number fs-15 lh-21",
      width:120,      
      render:(data,row) =>
      <div>{amountFormat(data)}</div>
      },     
      {
        title: "PV",
        key: "pv",
        dataIndex: "pv",
        className: "support-column-number fs-15 lh-21",
        width:120,
        render:(data) =>
      <div>{amountFormat(data)}</div>
      },     
    
    
      

    ];




    return (
      
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
    
          }}>
         <Divider orientation="left" style={{fontSize:21.5}}>일별매출실적 <span style={{fontSize:7}}>L7 STEM</span> </Divider> 
        
         

          
        </div>
        {/* 메모입력,수정 Modal */}
        <div align="right">
        <Search
          placeholder={`회원명을 입력해주세요.`}
          enterButton={'검색'}
          allowClear
          onSearch={this.onSearch}
          style={{ width: 290 }}
        />
       </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px' }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
          
        

      </div>
    )


  }
}

export default ProductSum;
