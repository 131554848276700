import React, { Component } from 'react'
import { httpGet, httpUrl,httpPut,httpPost, httpDelete } from '../../api/httpClient';
import { Table, Input, Button, DatePicker,Form,Divider } from 'antd'
import {
    formatDate,numberFormat,amountFormat
     } from "../../util";
import {
        EditOutlined,DeleteOutlined, FormOutlined
         } from '@ant-design/icons';
import { showAlert } from "../../components/common/AlertModal";
import ProductRegistry from "../../components/purchase/ProductRegistry";
const Search = Input.Search;
const Product = Form.create()(

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText:"",
      prevData: {},
      pagination: {
        total: 0,
        current: 0,
      },
      selectedRow: "",
      selectData: {},
      serviceData: [],
      list: [],
      visible: false,
      productVisible:false,
      

     
    };


  }




  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getProductList({
      pageNum: pager.current,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: false,
      },
      () => {
        this.getProductList({});

      }
    );
  };

  onUpdate = () => {
    const { selectData } = this.state;
    let updateData = {
      idx: selectData.idx,
      name: selectData.name,
      price: selectData.price,
      pv: selectData.pv,
      priceReorder: selectData.priceReorder,
      pvReorder: selectData.pvReorder
    };
    if (global.confirm(`수정하시겠습니까?`)) {
      httpPost(httpUrl.productUpdate, [], updateData)
        .then(result => {
          showAlert("update");
          this.setState({
            selectedRow: "",
            selectData: {},
            prevData: {}
          });
          this.getProductList();
        })
        .catch(error => {});
    }
  };

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "idx") {
      data = row;
    } else if (type === "price") {
      data.price = value;
    } else if (type === "pv"){
      data.pv = value;
     } else if (type === "priceReorder") {
        data.priceReorder = value;
      } else if (type === "pvReorder"){
        data.pvReorder = value;
    } else {
      data.name = value;
    }
    this.setState({
      selectData: data
    });
  };

  onDeleteProductList=(idx,name,price,count,createDate)=>{
 
    httpPost(httpUrl.productDelete,[],idx).then((res) => {
    this.getProductList({
      pageNum: this.state.pagination.current,
    });
    showAlert("delete");
  })
  };



  getProductList = (params = {}) => {
    const{searchText} = this.state
 
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getProductList, [10, pageNum,searchText])
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.productList,
          pagination,
        })
      })

  }

  

 
  componentDidMount() {
    this.getProductList();
  }
  

  render() {
    const {selectedRowKeys } = this.state;
    const rowSelection ={
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    const {form} = this.props;
    const {
        selectedRow, selectData,prevData,serviceData
      
      } = this.state;

    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
      
        render: (data) => 
        <div>{data}</div>    
      },
      {
        title: "상품명",
        key: "name",
        dataIndex: "name",
       
        className: "support-column-number fs-15 lh-21",
        render:(data,index) =>(
          
            <div align="left" style={{fontSize:18}}>
            {selectedRow !== index ? (
              numberFormat(data)
            ) : (
              <Input
                style={{width:"100px"}}
                value={selectData.name}
                onChange={e => {
                  this.onChangeModal(e.target.value, "name");
                }}
              />
            )}
          </div>
            )},
      {
        title: "소비자가",
        key: "price",
        dataIndex: "price",
        className: "support-column-number fs-15 lh-21",
        render:(data,index) => (
            <div align="right">
            {selectedRow !== index ? (
              numberFormat(data)
            ) : (
              <Input
                style={{width:"100px"}}
                value={selectData.price}
                onChange={e => {
                  this.onChangeModal(e.target.value, "price");
                }}
              />
            )}
          </div>
            )},
        
      {
        title: "소비자PV",
        key: "pv",
        dataIndex: "pv",
        className: "support-column-number fs-15 lh-21",
        render:(data,index) => (
        <div  align="right">
        {selectedRow !== index ? (
          numberFormat(data)+" PV" 
        ) : (
          <Input
            style={{width:"100px"}}
            value={selectData.pv}
            onChange={e => {
              this.onChangeModal(e.target.value, "pv");
            }}
          />
        )}
      </div>
        )},     
        {
          title: "정회원가",
          key: "priceReorder",
          dataIndex: "priceReorder",
          className: "support-column-number fs-15 lh-21",
          render:(data,index) => (
              <div   align="right">
              {selectedRow !== index ? (
               numberFormat(data)
              ) : (
                <Input
                  style={{width:"100px"}}
                  value={selectData.priceReorder}
                  onChange={e => {
                    this.onChangeModal(e.target.value, "priceReorder");
                  }}
                />
              )}
            </div>
              )},
          
        {
          title: "정회원PV",
          key: "pvReorder",
          dataIndex: "pvReorder",
          className: "support-column-number fs-15 lh-21",
          render:(data,index) => (
          <div   align="right">
          {selectedRow !== index ? (
            numberFormat(data)+" PV"
          ) : (
            <Input
              style={{width:"100px"}}
              value={selectData.pvReorder}
              onChange={e => {
                this.onChangeModal(e.target.value, "pvReorder");
              }}
            />
          )}
        </div>
          )},
      
      {
        title: "생성일",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        
        render:(data,row) =>
        <div>{formatDate(data)}</div>
      },
      {
        title:"",
        key:"",
        dataIndex:"",
        className:"support-column-number fs-15 lh-21",
        width:200,
        render:(row, index)=>
        <div>
          {selectedRow !== index && (
        <Button
                style={{height:30,fontSize:13}}
                onClick={value => {
                  this.onChangeModal(row.idx, "idx", index);
                  this.setState({
                    selectedRow: index,
                    selectData: row,
                    prevData: row
                  });
                }}>
                <EditOutlined/>수정
        </Button>
          )}
          &nbsp;
           {selectedRow === index && (
              <div>
                <Button
                  style={{ marginRight: "8px",fontSize:13 }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                 style={{fontSize:13 }}
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData
                    });
                  }}
                />
              </div>
            )}
            
          <Button 
          style={{marginTop:"1px", height:30 ,fontSize:13}}
          onClick={() => {
          if(global.confirm(row.name+' 삭제하시겠습니까?')){
           this.onDeleteProductList(row.idx);
          }
        }}>
          <DeleteOutlined />삭제
          </Button>
          </div>         
      },
    //   {
    //     title: "삭제",
    //     key: "deleted",
    //     dataIndex: "deleted",
    //     className: "support-column-number fs-15 lh-21",
    //     width: 60,
    //     render:(data,row) =>
    //     <div>{data === 1 ? 'O' : '-'}</div>
    //   },
     

    ];




    return (
      
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}>
        <Divider orientation="left" style={{fontSize:21.5}}>상품관리 <span style={{fontSize:7}}>L7 STEM</span></Divider> 
        
         

          
        </div>
        {/* 메모입력,수정 Modal */}
        
        <div className="product-button">
        <Button onClick={()=>{this.setState({productVisible: true})}} ><FormOutlined />등록</Button>
        </div>
        <div className="product-search">
        <Search
          placeholder={`상품명을 입력해주세요.`}
          enterButton={'검색'}
          allowClear
          onSearch={this.onSearch}
          style={{ width: 350 }}
        />
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px", marginTop: '10px' }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          />
          
          <ProductRegistry 
            visible={this.state.productVisible}
            onOk={() => {
            this.setState({
                productVisible: false
            });
            this.onSearch('');
          }}
          onCancel={() => {
            this.setState({
                productVisible: false
            });
          }}
            
           />  

      </div>
    )


  }
}
);
export default Product;
