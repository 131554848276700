import React, { Component } from "react";
import { Modal, Table, Radio } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";

class LowerOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "lower1",
      lowerList1: [],
      lowerList2: []
    };
  }

  onChange = (e) => {
    // console.log('radio checked', e.target.value);
    this.setState({
      type: e.target.value
    });
  };

  getLowerOrganizationList = () => {
    httpGet(httpUrl.getLowerOrganizationList, [this.props.organizationIdx], {})
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        this.setState({
          lowerList1: res.data.lowerOrg1,
          lowerList2: res.data.lowerOrg2
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.getLowerOrganizationList();
  }

  render() {
    const { visible, onCancel } = this.props;
    const { type, lowerList1, lowerList2 } = this.state;

    const columns = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: type ? "이름" : "조직명",
        dataIndex: "name",
        key: "name",
        className: "support-column-number fs-15 lh-21"
      },
      {
        title: "가맹점 여부",
        dataIndex: "franchise",
        key: "franchise",
        className: "support-column-number fs-15 lh-21",
        render: (data) => <div>{data ? "O" : "X"}</div>
      }
    ];

    return (
      <Modal
        visible={visible}
        title={"하위 조직 정보"}
        onCancel={onCancel}
        footer={false}
        style={{ minWidth: "800px" }}>
        <div className="flex-row flex-center m-b-10">
          <Radio.Group onChange={this.onChange} value={type}>
            <Radio value="lower1">1단계</Radio>
            <Radio value="lower2">2단계</Radio>
          </Radio.Group>
        </div>
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={type === "lower1" ? lowerList1 : lowerList2}
          style={{ marginBottom: "10px" }}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default LowerOrganization;
