import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, InputNumber, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import { formatDate } from "../../util";

export default class PaybackSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logList: [],
      selectedRow: "",
      selectedData: {},
      prevData: {}
    };
  }

  onChangeModal = (value, type, row) => {
    var data = Object.assign({}, this.state.selectData);
    if (type === "coinType") {
      data[type] = value;
      data.ratio = row.ratio * 100;
    } else data[type] = value;
    // console.log(data);
    this.setState({
      selectData: data
    });
  };

  onUpdate = () => {
    const { selectData } = this.state;

    let updateData = {
      coinType: selectData.coinType,
      ratio: selectData.ratio / 100
    };
    // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
    httpPut(httpUrl.paybackUpdate, [], updateData)
      .then(result => {
        this.setState({ selectedRow: "", selectData: {}, prevData: {} }, () =>
          this.getPaybackSetting()
        );
      })
      .catch(error => {});
  };

  onDelete = value => {
    // console.log(value);
    const coinList = reactLocalStorage.getObject("coinList");
    if (
      global.confirm(
        `${
          coinList.find(coin => coin.coinType === value).name
        }을(를) 삭제하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.paybackDelete, [value], {})
        .then(result => {
          this.getPaybackSetting();
        })
        .catch(error => {});
    }
  };

  getPaybackSetting = (params = {}) => {
    httpGet(httpUrl.getPaybackSetting, [], {})
      .then(result => {
        this.setState({
          logList: result.data
        });
      })
      .catch(error => {});
  };

  componentDidMount() {
    this.getPaybackSetting();
  }

  render() {
    const { logList, selectedRow, selectData, prevData } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const logColums = [
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        // width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div>
            {coinList.find(coin => coin.coinType === data)
              ? coinList.find(coin => coin.coinType === data).name
              : ""}
          </div>
        )
      },
      {
        title: "비율",
        dataIndex: "ratio",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              `${data * 100} %`
            ) : (
              <InputNumber
                min={0}
                max={100}
                value={selectData.ratio}
                onChange={value => {
                  this.onChangeModal(value, "ratio");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "수정일",
        dataIndex: "updateDate",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>{data === null ? "-" : formatDate(data)}</div>
        )
      },
      {
        title: "설정",
        dataIndex: "set",
        // width: 80,
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              <Button
                onClick={() => {
                  this.setState({
                    selectedRow: index,
                    prevData: row
                  });
                  this.onChangeModal(row.coinType, "coinType", row);
                }}>
                {row.updateDate === null ? "생성하기" : "수정하기"}
              </Button>
            ) : (
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={this.onUpdate}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      selectedRow: "",
                      selectData: prevData
                    });
                  }}
                />
              </div>
            )}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "support-column-number fs-15 lh-21",
        render: (data, row, index) => (
          <div>
            {selectedRow === index ? (
              "-"
            ) : (
              <Button
                onClick={() => {
                  if (row.updateDate === null)
                    alert("생성 후 삭제가 가능합니다.");
                  else this.onDelete(row.coinType);
                }}>
                삭제하기
              </Button>
            )}
          </div>
        )
      }
    ];
    return (
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "bold"
          }}>
          페이백 설정
        </div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ clear: "both" }} />
        <Table
          rowKey={record => record.coinType}
          columns={logColums}
          dataSource={logList}
          pagination={false}
        />
      </div>
    );
  }
}
