import React, { Component } from "react";
// import { connect } from 'react-redux';
import { reactLocalStorage } from "reactjs-localstorage";
import { Modal, Table, Input, Button, Select } from "antd";

import string from "../../string";
import SelectBox from "../common/SelectBox";
import { numberFormat, formatDate, amountFormat } from "../../util";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import fileDownload from "js-file-download";

const Option = Select.Option;

class WalletLogModalSB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullCoinList: [],

      pagination: {
        total: 0,
        current: 1
      },
      walletLogVisible: false,
      walletLogList: [],
      searchTypes: "ALL",
      searchText: "",
      transferType: "ALL",
      coinType: 0
    };
  }

  showWalletLog = (params = {}) => {
    const { selectedSerial } = this.props;
    let page = params.page || 1;
    httpGet(
      httpUrl.walletLogList,
      [
        10,
        page,
        selectedSerial.serial,
        this.state.searchTypes,
        this.state.searchText,
        this.state.transferType,
        this.state.coinType
      ],
      {}
    ).then(result => {
      const pagination = { ...this.state.pagination };
      pagination.total = result.data.totalCount;
      pagination.current = result.data.currentPage;
      this.setState({
        walletLogList: result.data.walletLogList,
        pagination
      });
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.walletLogPagination };
    pager.current = pagination.current;
    this.setState({
      walletLogPagination: pager
    });
    this.showWalletLog({
      page: pagination.current
    });
  };

  handleChangeSearchText = value => {
    this.setState({
      searchText: value
    });
  };

  onSearch = () => {
    if (
      this.state.searchText === "" &&
      this.state.searchTypes !== "ALL" &&
      this.state.searchTypes !== "SERVER"
    ) {
      alert("검색어를 입력해주세요.");
    } else {
      this.showWalletLog();
    }
  };

  handleChangeTranferType = value => {
    this.setState({
      transferType: value
    });
  };

  handleChangeCoinType = value => {
    this.setState({
      coinType: value
    });
  };

  handleChangeSearchType = value => {
    if (value === "SERVER") {
      this.setState({
        searchText: "",
        searchTypes: value
      });
    } else {
      this.setState({
        searchTypes: value
      });
    }
  };

  downloadExcel = () => {
    if (this.state.isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.walletLogModalSBDownload,
          [
            this.state.pagination.current,
            1,
            this.state.searchTypes,
            this.state.searchText,
            this.state.transferType,
            this.state.coinType,
            this.props.selectedSerial.serial
          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `거래내역.xlsx`);
          })
          .catch((err) => { });
      }
    }
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.setState({
      fullCoinList: [
        {
          coinType: 0,
          name: "전체"
        },
        ...coinList
      ]
    });
    this.showWalletLog();


  }

  render() {
    const { visible, onOk } = this.props;
    const {
      walletLogList,
      pagination,
      searchTypes,
      transferType,
      coinType
    } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const walletColums = [
      {
        title: "",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontSize:"12px"}}>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        width: 120,
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontSize:"12px",textAlign:"left"}}>{formatDate(data)}</div>
      },
      {
        title: "코인종류",
        dataIndex: "coinType",
        width: 110,
        className: "support-column-number fs-15 lh-21",
        render: data => (
          <div style={{fontSize:"12px" ,textAlign:"left"}}>
            {coinList.find(coin => coin.coinType === parseInt(data)).name}
          </div>
        )
      },
      {
        title: "전송방법",
        dataIndex: "type",
        width: 90,      
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontSize:"12px", textAlign:"left"}}>{string.sendString[data]}</div>
      },
      // {
      //   title: '지갑',
      //   dataIndex: 'address',
      //   className: 'support-column-number fs-15 lh-21'
      // },
      {
        title: "보낸지갑",
        dataIndex: "from",
        width: 170,
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => {
          if (data == "PAYMENT") return (<div style={{wordWrap: "break-word",fontSize:"12px" ,textAlign:"left"}}>{data} <div style={{color:"#FF0000",fontSize:"11.5px"}}>(취소)</div></div>);
          if (row.to == "PAY BACK") return (<div style={{wordWrap: "break-word",fontSize:"12px",textAlign:"left" }}>{data}</div>);
          if (data == "PAY BACK") return (<div style={{wordWrap: "break-word",fontSize:"12px",textAlign:"left" }}>{data ? "페이백" : ""}</div>);
          if (data == "CHARGE") return (<div style={{wordWrap: "break-word", fontSize:"12px" ,textAlign:"left"}}>{data ? "사용자 충전" : ""}</div>);
          if (data == "SERVER_WALLET") return (<div style={{wordWrap: "break-word", fontSize:"12px" ,textAlign:"left"}}>{data ? "관리자 충전" : ""}</div>);
          if (data == "Unlock") return (<div style={{wordWrap: "break-word",fontSize:"12px",textAlign:"left"}}>{data ? "데일리 지급" : ""}</div>);
          if (data == "BONUS") return (<div style={{wordWrap: "break-word", fontSize:"12px",textAlign:"left"}}>{data ? "보너스" : ""} </div>);
          if (data == "SIGNUP") return (<div style={{wordWrap: "break-word",  fontSize:"12px",textAlign:"left"}}>{data ? "가입축하금" : ""}</div>)
          else return <div style={{wordWrap: "break-word", fontSize:"12px"}}>{data}</div>;
      },// ...this.getSearch('FROM'),
      },
      {
        title: "받은지갑",
        dataIndex: "to",
        className: "support-column-number fs-15 lh-21",
        width: 170,
        render: (data,row) => {
          if (row.from == "PAYMENT") return (<div style={{ wordWrap: "break-word", fontSize:"12px" ,textAlign:"left"}}>{data}</div>);
          if (data == "PAY BACK") return (<div style={{ wordWrap: "break-word", fontSize:"12px" ,textAlign:"left"}}>{data ? "페이백" : ""} <div style={{color:"#FF0000",fontSize:"11.5px"}}>(회수)</div></div>);
          if (data =="Unlock") return (<div style={{wordWrap: "break-word", fontSize:"12px",textAlign:"left"}}>{data ? "데일리 해제" :""}</div>)
          else return <div style={{wordWrap: "break-word",fontSize:"12px" }}>{data}<div style={{color:"brown",fontSize:"11px",textAlign:"left" }}>
             {row.memo != null && row.memo != "" ? "메모: "+row.memo : "" }</div></div>;
      },// ...
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) => 
        <div style={{fontSize:"13px", textAlign:"right"}}>{amountFormat(Math.floor(data, row.coinType )/1000000000)}</div>
        
      },  
      {
        title: "거래전",
        dataIndex: "fromBalance",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) => 
          <div style={{fontSize:"13px" , textAlign:"right"}}>{numberFormat(Math.floor(data / 1000000000))}</div>
      },
      {
        title: "거래후",
        dataIndex: "toBalance",
        className: "support-column-number fs-15 lh-21",
        render: (data,row) =>
          <div style={{fontSize:"13px" , textAlign:"right"}}>{numberFormat(Math.floor(data / 1000000000))}</div>
      },
      {
        title: "거래전(SB1,SB2)",
        dataIndex: "fromPaybackBalance",
        className: "support-column-number fs-15 lh-21",
        
        render: (data,row) => {
          if(row.coinType == 101) return(
          <div style={{fontSize:"13px" ,textAlign:"right"}}>{data === null ? "-" : numberFormat(Math.floor(data / 1000000000))} SB1
          <div style={{fontSize:"13px" ,textAlign:"right"}}>{data === null ? "-" : numberFormat(Math.floor(row.fromBalance / 1000000000) - (Math.floor(row.fromPaybackBalance/ 1000000000)))} SB2</div>
          </div>   
          );        
          else return <div style={{textAlign:"right"}}>-</div>;
          
          },
      },
      {
        title: "거래후(SB1,SB2)",
        dataIndex: "toPaybackBalance",
        className: "support-column-number fs-15 lh-21",
       
        render: (data,row) => {
          if(row.coinType == 101) return (
          <div style={{fontSize:"13px" , textAlign:"right"}}>{data === null ? "-" :  numberFormat(Math.floor(data / 1000000000))} SB1
           <div style={{fontSize:"13px" , textAlign:"right"}}>{data === null ? "-" : numberFormat(Math.floor(row.toBalance / 1000000000) - (Math.floor(row.toPaybackBalance/ 1000000000)))} SB2</div>
           </div>
           );
           else return<div style={{textAlign:"right"}}>-</div>;
         }
      },
      // {
      //   title: "거래전(SB2)",
      //   dataIndex: "fromPaybackBalance",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data,row) => 
      //    <div style={{fontSize:"13px",textAlign:"left"}}>{data === null ? "-" : numberFormat(Math.floor(row.fromBalance / 1000000000) - (Math.floor(data/ 1000000000)))}</div>,
      
      // },
      // {
      //   title: "거래후(SB2)",
      //   dataIndex: "toPaybackBalance",
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data,row) => 
      //     <div style={{fontSize:"13px",textAlign:"left"}}>{data === null ? "-" : numberFormat(Math.floor(row.toBalance / 1000000000) - (Math.floor(data/ 1000000000)))}</div>
         
      // },
      // {
      //   title: "메모",
      //   dataIndex: "memo",
      //   width: "200",
      //   className: "support-column-number fs-15 lh-21",
      //   render: data => (
      //     <div style={{ wordWrap: "break-word", width: "200px",fontSize:"13px" }}>
      //       {data ? data : "-"}
      //     </div>
      //   )
      // },
      {
        title: "상태",
        dataIndex: "status",
        width:60,
        className: "support-column-number fs-15 lh-21",
        render: data => <div style={{fontSize:"13px" ,textAlign:"left"}}>{string.requestLogString[data]}</div>
      }
    ];

    return (
      <Modal
        visible={visible}
        title="거래내역"
        cancelText="취소"
        onCancel={onOk}
        style={{ minWidth: "1700px" }}
        footer={null}>
        <div className="wallet-log-wrapper">
          <div className="flex-row flex-center flex-sb m-b-10">
            <div className="flex-row flex-center">
              <label>코인 종류&nbsp;</label>
              <Select
                placeholder="코인종류"
                style={{ width: "160px", marginRight: "10px" }}
                value={coinType}
                onChange={this.handleChangeCoinType}>
                {this.state.fullCoinList.map(coin => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
              <label>전송 방법&nbsp;</label>
              <SelectBox
                value={string.transferString[transferType]}
                codeString={string.transferString}
                onChange={this.handleChangeTranferType}
              />
            </div>
            <div className="flex-row flex-center">
              <SelectBox
                value={string.walletLogString[searchTypes]}
                code={string.walletLogCode}
                codeString={string.walletLogString}
                onChange={this.handleChangeSearchType}
                style={{ width: "200px" }}
              />
              <Input
                placeholder="보낸 지갑주소, 받은 지갑주소을 입력하세요"
                disabled={this.state.searchTypes === "SERVER"}
                onChange={e => {
                  this.handleChangeSearchText(e.target.value);
                }}
                allowClear
                style={{ width: "360px" }}
              />
              <Button
                type="primary"
                onClick={() => {
                  this.onSearch();
                }}>
                검색
              </Button>
            </div>
          </div>
          <div style={{ clear: "both" }} />

          <div className="flex-row flex-center flex-end m-b-10">
            <Button onClick={this.downloadExcel}>엑셀 다운로드</Button>
          </div>

          <Table
            id="member-log-table"
            rowKey={record => record.idx}
            columns={walletColums}
            dataSource={walletLogList}
            style={{ marginBottom: "10px" ,fontSize:"12px"}}
            pagination={pagination}
            onChange={this.handleTableChange}
          />
        </div>
      </Modal>
    );
  }
}

export default WalletLogModalSB;
