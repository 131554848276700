import React, { Component } from 'react'
import { httpGet, httpUrl } from '../../api/httpClient';
import { Table, Divider } from 'antd'

import {
  formatDate, amountFormat
} from "../../util";

class RequestGoods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      isLoaded: true,
      pagination: {
        total: 0,
        current: 0,
      },
    
      list: [],
      visible: false,

    };


  }
 
  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
    this.getRequestGoodsList({
      pageNum: pager.current,
    });
  };

  getRequestGoodsList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getRequestGoodsList, [10, pageNum, this.props.modalData.idx])
      .then(res => {
        // console.log(JSON.stringify(result.data.workLogList))
        const pagination = { ...this.state.pagination }
        pagination.total = res.data.totalCount
        this.setState({
          list: res.data.productPurchaseRequestGoodsList,
          pagination,
        })
      })

  }

  componentDidMount() {
    this.getRequestGoodsList();
  }


  render() {   

    const columns = [
      {
        title: "",
        key: "idx",
        dataIndex: "idx",
        className: "support-column-number fs-15 lh-21",
        width: 60,
        render: (data) =>
          <div >{data}</div>
      },
      // {
      //   title: "신청번호",
      //   key: "requestIdx",
      //   dataIndex: "requestIdx",
      //   width: 300,
      //   className: "support-column-number fs-15 lh-21",
      //   render: (data) =>
      //     <div> {data}</div>
      // },
      {
        title: "신청인",
        key: "userName",
        dataIndex: "userName",
        width: 300,
        className: "support-column-number fs-15 lh-21",
        render: (data,row) =>
          <div> {data}{row.userId}</div>
      },
      {
        title: "상품명",
        key: "productIdx",
        dataIndex: "productIdx",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
          <div
          align="left"
          >{row.productName}</div>
      },
      {
        title: "수량",
        key: "quantity",
        dataIndex: "quantity",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>{data}</div>
      },
      {
        title: "단가",
        key: "unitPrice",
        dataIndex: "unitPrice",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>{amountFormat(data)}</div>
      },
      {
        title: "금액",
        key: "price",
        dataIndex: "price",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>{amountFormat(data)}</div>
      },
      {
        title: "재구매",
        key: "reorder",
        dataIndex: "reorder",
        className: "support-column-number fs-15 lh-21",
        render: (data, row) =>
        <div>{data == 1 ? "O" : "X"} </div>
        
      },
     
      {
        title: "날짜",
        key: "createDate",
        dataIndex: "createDate",
        className: "support-column-number fs-15 lh-21",
        render: (data) =>
          <div>{formatDate(data)}</div>
      },
    



    ];




    return (

      <div>
       
        <div
          style={{
            fontSize: "20px",
            color: "#000",
            textAlign: "center",
            fontWeight: "bold",
          }}>
          {/* <Divider orientation="left" style={{ fontSize: 21.5 }}>구매상품목록 <span style={{ fontSize: 7 }}>L7 STEM</span></Divider> */}


        </div>

        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />


      </div>

    )


  }
}

export default RequestGoods;
